import { ANALYST_ROLE_ID } from '../../../Routes/constant';
import { vesselRegexWith2D, vesselRegexWith3D, vesselRegexWith4D } from '../../../utils/regex';
import { CommonFieldsProps, VesselInformationMainEnum } from './mainTypes.d';

export const getMainFieldsDropdown = ({
  tubeTypeData, testEndData, materialData, supportData, vesselData, errors, vesselMainData, isNWTTubeTypeEnabled, roleId,
}:CommonFieldsProps) => {
  const fields = [
    {
      type: 'select',
      label: 'Vessel',
      name: VesselInformationMainEnum.vessel,
      entries: vesselData,
      required: false,
      disabled: true,
    },
    {
      type: 'select',
      label: 'Test End',
      name: VesselInformationMainEnum.testEnd,
      entries: testEndData,
      required: false,
      disabled: String(roleId) === ANALYST_ROLE_ID,
    },
    {
      type: 'select',
      label: 'Tube Type',
      name: VesselInformationMainEnum.tubeType,
      entries: tubeTypeData,
      required: false,
      disabled: String(roleId) === ANALYST_ROLE_ID,
    },
    {
      type: 'select',
      label: 'Material',
      name: VesselInformationMainEnum.material,
      entries: materialData,
      required: false,
      disabled: String(roleId) === ANALYST_ROLE_ID,
    },
    {
      type: 'select',
      label: 'Support',
      name: VesselInformationMainEnum.support,
      entries: supportData,
      required: false,
      disabled: String(roleId) === ANALYST_ROLE_ID,
    },
    {
      type: 'text',
      label: 'NWT',
      name: VesselInformationMainEnum.NWT,
      regEx: vesselRegexWith3D,
      errors,
      disabled: !isNWTTubeTypeEnabled?.Description || String(roleId) === ANALYST_ROLE_ID,
    },
    {
      type: 'text',
      label: 'NWT/Bell/Land',
      name: VesselInformationMainEnum.nwtBellLand,
      regEx: vesselRegexWith3D,
      errors,
      disabled: isNWTTubeTypeEnabled?.Description || String(roleId) === ANALYST_ROLE_ID,
    },
    {
      type: 'text',
      label: 'NWT Under Fins',
      name: VesselInformationMainEnum.nwtUnderFins,
      regEx: vesselRegexWith3D,
      errors,
      disabled: isNWTTubeTypeEnabled?.Description || String(roleId) === ANALYST_ROLE_ID,
    },
    {
      type: 'text',
      label: 'Probe Diameter',
      name: VesselInformationMainEnum.probeDiameter,
      regEx: vesselRegexWith4D,
      errors,
      disabled: String(roleId) === ANALYST_ROLE_ID,
    },
  ];

  if (!vesselMainData?.IsMultiOffset) {
    fields.push({
      type: 'text',
      label: 'OD',
      name: VesselInformationMainEnum.OD,
      regEx: vesselRegexWith3D,
      errors,
      disabled: String(roleId) === ANALYST_ROLE_ID,
    });
  } else {
    fields.push(
      {
        type: 'text',
        label: 'OD1',
        name: VesselInformationMainEnum.OD1,
        regEx: vesselRegexWith3D,
        errors,
        disabled: String(roleId) === ANALYST_ROLE_ID,
      },
      {
        type: 'text',
        label: 'OD2',
        name: VesselInformationMainEnum.OD2,
        regEx: vesselRegexWith3D,
        errors,
        disabled: String(roleId) === ANALYST_ROLE_ID,
      },
      {
        type: 'text',
        label: 'OD3',
        name: VesselInformationMainEnum.OD3,
        regEx: vesselRegexWith3D,
        errors,
        disabled: String(roleId) === ANALYST_ROLE_ID,
      },
    );
  }

  return fields;
};

export const formElements = () => [
  {
    label: 'Tube Count',
    name: VesselInformationMainEnum.tubeCount,
    disabled: true,
    required: false,
  },
  {
    label: 'Tube Length',
    name: VesselInformationMainEnum.tubeLength,
    regEx: vesselRegexWith2D,
    required: false,
    disabled: true,
  },
];
