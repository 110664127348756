/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';

function CalibrationProcedureSection() {
  const { reportPdfResponse4, themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  return (
    <div className="p20">
      <table className="w100 logoTable">
        <tr>
          <td>
            <div className="commonTableBorderStyle mt10 p20 w100 pageBorder1000" style={{ borderColor: MainColor }}>
              <div
                className="tableAfterBorderStyle"
                style={{
                  backgroundColor: BorderColor,
                }}
              />
              <div
                className="tableBeforeBorderStyle"
                style={{
                  backgroundColor: BorderColor,
                }}
              />
              {/* <div className="subHeading textCenter mt10">Calibration Procedure</div> */}
              <div className="commonText mb20 mt20 customDataHtmlStyle" dangerouslySetInnerHTML={{ __html: reportPdfResponse4?.Caliberation_Procedure?.Description }} />
              {/* <div className="subHeading mt20 textCenter">Calibration Reference Standard</div> */}
              {/* <div className="commonText mt20 customDataHtmlStyle" dangerouslySetInnerHTML={{ __html: reportPdfResponse4?.Calibration_Reference_Standard }} /> */}
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default CalibrationProcedureSection;
