import React, { useEffect, useRef } from 'react';
import {
  Grid, Tab, Tabs,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ComponentBidDetails from './Components/bidDetails';
import ComponentUnitToTest from './Components/unitToTest';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../../Common/TabsStyles';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import {
  getAnalystMasterStart, getBidByIdStart, getBidPricingStart, removeBidState, setSelectedTab,
} from './redux/bidWorksheetSlice';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';
import { BidDetailsValues } from './utils/types';
import { BidPriceDetailNames } from './utils/constants';
import { resetJobStarterState } from '../jobStarter/addJob/redux/addJobSlice';
import { removeSelectedCustomer } from '../../addCustomer/redux/addCustomerSlice';
import EndPoints from '../../../Routes/EndPoints';
import BidInfo from './Components/bidInfo';
import { JobIcon } from '../../../assets/NavBarIcons';
import ComponentBreadcrumb from '../../../Common/ComponentBreadcrumb/ComponentBreadcrumb';

export default function ScreenBidWorksheetJobTabs() {
  const {
    selectedTab, bidId, isLoading, expenseDetails,
  } = useSelector((state:RootState) => state.bidsReducer);
  const {
    selectedJob, isLoading: jobLoading,
  } = useSelector((state:RootState) => state.jobStarterReducer);
  const {
    control, setValue, watch,
  } = useForm<BidDetailsValues>();

  const { historyStack } = useSelector((state:RootState) => state.masterDataReducer);
  const { selectedBid } = useSelector((state:RootState) => state.bidsReducer);

  const jobID = selectedBid?.BidDetails?.JobId;

  const targetDivRef = useRef<HTMLDivElement | null>(null);
  const goToTop = () => {
    const targetDiv = targetDivRef.current;
    targetDiv?.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const breadcrumbs = [
    { label: 'Job', url: `/jobs/${jobID || ''}`, icon: <JobIcon /> },
    { label: 'Bids', url: '/jobs/bid-worksheet', icon: <JobIcon /> },
  ];

  const TABS = [
    {
      id: 1,
      label: 'Client / Site',
      component: <BidInfo pwatch={watch} scrollTop={() => goToTop()} />,
      disabled: false,
    },
    {
      id: 2, label: 'Unit to Test', component: <ComponentUnitToTest watch={watch} />, disabled: !bidId,
    },
  ];
  const dispatch = useDispatch();
  const handleChangeTab = (val:number) => {
    dispatch(setSelectedTab(val));
  };

  useEffect(() => {
    if (selectedJob?.JobInfo) {
      const payload = {
        custId: selectedJob?.JobInfo?.CustId || '',
        siteId: selectedJob?.JobInfo?.SiteId || '',
        bidId: bidId || '',
      };
      dispatch(getBidPricingStart(payload));
    }
  }, [selectedJob]);

  // useEffect(() => {
  //   dispatch(getBidPricingValues(bidPricingValues));
  // }, [bidPricingValues]);

  useEffect(() => {
    dispatch(setLoader(isLoading || jobLoading));
  }, [isLoading, jobLoading]);

  useEffect(() => {
    dispatch(getAnalystMasterStart());
    const prevUrl = historyStack[historyStack.length - 1] || '';

    if (prevUrl.includes(EndPoints.ADD_UNIT)) {
      const arr = prevUrl.split('/');
      const id = arr[arr.length - 1];

      dispatch(setSelectedTab(2));
      dispatch(getBidByIdStart(String(id)));
    } else if (prevUrl.includes(EndPoints.UNIT)) {
      dispatch(setSelectedTab(2));
    }

    return () => {
      dispatch(removeBidState());
      dispatch(resetJobStarterState());
      dispatch(removeSelectedCustomer());
    };
  }, []);

  useEffect(() => {
    if (expenseDetails?.DriveExpense) {
      setValue(BidPriceDetailNames.DriveExpense, expenseDetails.DriveExpense);
      setValue(BidPriceDetailNames.LodgePerDiem, expenseDetails.LodgePerDiem);
    }
  }, [expenseDetails]);

  return (
    <>
      <ComponentPageHeader subHeading="Bid WorkSheet for JOB" fieldId={bidId || ''} fieldName="Bid ID" />
      <ComponentBreadcrumb crumbs={breadcrumbs} />
      <div className="container_job">
        <Tabs
          variant="scrollable"
          value={selectedTab - 1}
          onChange={(_, newValue) => handleChangeTab(TABS[newValue].id)}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.label}
              disabled={tab.disabled}
              style={selectedTab === tab.id ? selectedTabStyles : tabStyles}
              className="tab_styles"
              onClick={goToTop}
            />
          ))}
        </Tabs>
      </div>
      <div className="div_job_new" ref={targetDivRef}>
        <div className="div_container_CustomerInfo mt-2">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12} lg={8}>
              {TABS[selectedTab - 1]?.component}
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={4}>
              <ComponentBidDetails setValue={setValue} control={control} watch={watch} />
            </Grid>
          </Grid>

        </div>
      </div>
    </>
  );
}
