export enum RequestFormFields {
  vertical = 'vertical',
  safetyEquipmentRequired = 'safetyEquipmentRequired',
  outside = 'outside',
  flux = 'flux',
  absDiff = 'absDiff',
  crsDiff = 'crsDiff',
  stage = 'stage',
  jobId = 'jobId',
  manufacturer = 'manufacturer',
  serialId = 'serialId',
  model = 'model',
  vessel = 'vessel',
  tubeType = 'tubeType',
  material = 'material',
  OD = 'OD',
  ID = 'ID',
  tubeLength = 'tubeLength',
  tubeCount = 'tubeCount',
  NWT = 'NWT',
  NWTBellLand = 'NWTBellLand',
  NWTUnderFins = 'NWTUnderFins',
  workHrs = 'workHrs',
  note = 'note',
  retestOf = 'retestOf',
  units = 'units',
}

export const JobColumns = [
  { key: 'sn', label: 'SN' },
  { key: 'unitId', label: 'Unit ID' },
  { key: 'model', label: 'Model' },
  { key: 'vesselToolTip', label: 'Vessels' },
  { key: 'typeOfTest', label: 'Type Of Test' },
];

export const AddEquipmentColumns = [
  { key: 'sn', label: 'SN' },
  { key: 'status', label: 'Status' },
  { key: 'product', label: 'Product' },
  { key: 'category', label: 'Category' },
  { key: 'description', label: 'Description' },
  { key: 'serial', label: 'Serial #' },
];

export enum InventoryFormFields {
  discarded = 'discarded',
  status = 'status',
  assignedFor = 'assignedFor',
  serialised = 'serialised',
  product = 'product',
  material = 'material',
  description = 'description',
  uns = 'uns',
  onHand = 'onHand',
  serial = 'serial',
  category = 'category',
  equipmentType = 'equipmentType',
  location = 'location',
  specification = 'specification',
}

export const statusVariable = {
  CREATED: 'Created',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};
