import React, { useState } from 'react';
import {
  Grid, Card, Accordion, AccordionSummary, Typography, AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { CustomExpandIcon } from '../../../../../../../utils/AccordionUtils/AccordionUtils';
import { JobFlightInProps } from '../../../../utils/types';
import ComponentTextInput from '../../../../../../../Common/ComponentTextinput/ComponentTextInput';
import { JobScheduleFields } from '../../../../utils/constants';
import { RegexFiveWith2D } from '../../../../../../../utils/regex';
import { CommonDateInput } from '../../../../../../../Common/CommonDateInput';

function FlightIn({
  control,
  errors,
  flightIndepartureTime,
  setFlightInDipartureTime,
  flightInarivalTime,
  setFlightInArivalTime,
  flightOutDate,
}: JobFlightInProps) {
  const [expanded, setExpanded] = useState(false);
  const minFlightInDate = flightOutDate?.add(1, 'day');
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel2d-content"
        id="panel2d-header"
        className="accordionHeading"
        onClick={handleExpand}
        expandIcon={(
          <div />
        )}
      >
        <div className="customAccordionHeader">
          <div className="leftSideHeading">
            <CustomExpandIcon />
            <Typography style={{ marginLeft: '8px', marginRight: '8px' }} className="typography_Accordion">Flight In</Typography>
          </div>
          <div className="rightSideHeading">
            {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails className="borderTop">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <div className="customerInfo_main_container">
                <Grid container spacing={2}>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Date</label>
                      <CommonDateInput
                        minDate={minFlightInDate}
                        control={control}
                        errors={errors}
                        name={JobScheduleFields.flightInDate}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Airlines</label>
                      <ComponentTextInput
                        control={control}
                        inputProps={{ maxLength: 20 }}
                        name={JobScheduleFields.flightInAirline}
                        size="small"
                        id={JobScheduleFields.flightInAirline}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Departure Time</label>
                      <TimePicker
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        format="hh:mm A"
                        value={flightIndepartureTime}
                        onChange={(newValue) => setFlightInDipartureTime(newValue)}
                        className="timerInput nonCapitalizeField"
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Arrival Time</label>
                      <TimePicker
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        format="hh:mm A"
                        value={flightInarivalTime}
                        onChange={(newValue) => setFlightInArivalTime(newValue)}
                        className="timerInput nonCapitalizeField"
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">$ / Seat</label>
                      <ComponentTextInput
                        control={control}
                        regEx={RegexFiveWith2D}
                        name={JobScheduleFields.flightInSeat}
                        size="small"
                        id={JobScheduleFields.flightInSeat}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Confirmation #</label>
                      <ComponentTextInput
                        control={control}
                        inputProps={{ maxLength: 50 }}
                        name={JobScheduleFields.flightInConfirmation}
                        size="small"
                        id={JobScheduleFields.flightInConfirmation}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default FlightIn;
