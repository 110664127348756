import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../../Common/DataTable';
import { getCustomersStart } from '../redux/sliceSites';
import { stripCustPrefix } from '../../../utils/CommonFunctions';

interface CustomersData {
  sn: number;
  customerId: string;
  customer: string;
  id: string;
}

export default function ComponentSiteCustomersTable() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useDispatch();
  const { siteId } = useParams();
  const { customersList, isLoading, totalEntries } = useSelector((state: RootState) => state.siteReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
      siteId,
    };
    dispatch(getCustomersStart(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'customerId', label: 'Customer ID' },
      { key: 'customer', label: 'Customer' },
    ],
    [],
  );

  const data: CustomersData[] = useMemo(() => customersList.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      customerId: stripCustPrefix(item?.CustomerId),
      customer: item?.CustomerName,
      id: item?.CustomerId,
    };
    return transformedDataItem;
  }), [customersList]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <>
      <ComponentPageHeader subHeading="Customers" />
      <DataTable<CustomersData>
        data={data}
        columns={columns}
        totalRecords={totalEntries}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
      />
    </>
  );
}
