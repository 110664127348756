/* eslint-disable max-len */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import {
  Menu, MenuItem, Sidebar, SubMenu, useProSidebar,
} from 'react-pro-sidebar';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';
import taiLogo from '../../assets/tailogo-eddyCurrentNew.svg';
import taiHalfLogo from '../../assets/taihhalflogo.svg';
import addCustomerIcon from '../../assets/Icons/addCustomerIcon.svg';
import addJobIcon from '../../assets/Icons/addJobIcon.svg';
import { SidebarClosedIcon, SidebarOpenIcon } from '../../assets/NavBarIcons';
import './StyleNotification.scss';
import { ScreenProfile } from './ScreenProfile';
import TableData from '../ComponentDataTable/DataTable';
import { RootState } from '../../redux/rootState';
import { getUserProfileStart } from '../Profile/Redux/sliceMyProfile';
import menuItems from './ScreenSidebarMenu';
import { getMasterDataStart } from '../../redux/masters/masterSlice';
import { Empty } from '../ScreenAddSmc/Utils/TypeSmc';
import localStorageConstants, { sessionStorageConstants } from '../../utils/LocalStorageConstants';
import { ANALYST_ROLE_ID } from '../../Routes/constant';

export function Dashboard() {
  return (
    <TableData />
  );
}

export function Transactions() {
  return (
    <h1 className="header"> Reports Page</h1>
  );
}

export function ScreenSidebar() {
  const { collapseSidebar, collapsed } = useProSidebar();
  const [mobileMenu, setMobileMenu] = useState(false);
  const { userProfile } = useSelector((state: RootState) => state.userProfileReducer);
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);
  const hideSidebar = () => {
    setMobileMenu(false);
    sessionStorage.removeItem(sessionStorageConstants.SubMenuKeyID);
  };

  const handleSideBar = () => (
    collapsed
      ? <div className="forDeesktopView" onClick={() => { collapseSidebar(); }}><SidebarOpenIcon /></div>
      : <div className="forDeesktopView" onClick={() => { collapseSidebar(); }}><SidebarClosedIcon /></div>
  );

  const handleSideBarOnMob = () => (
    mobileMenu
      ? <div className="forMobileView" onClick={() => { setMobileMenu(false); }}><SidebarClosedIcon /></div>
      : <div className="forMobileView" onClick={() => { setMobileMenu(true); }}><SidebarOpenIcon /></div>
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
        // setIsNotificationOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    dispatch(getUserProfileStart());
  }, []);

  const getFirstLetter = (inputString: string) => inputString.charAt(0);
  const firstLetter = getFirstLetter(userProfile?.FirstName ? userProfile?.FirstName : '');
  const secondLetter = getFirstLetter(userProfile?.LastName ? userProfile?.LastName : '');

  const [openTooltip, setOpenTooltip] = React.useState(0);
  const handleCloseTooltip = () => {
    setOpenTooltip(0);
  };

  const handleOpenTooltip = (itemId: number) => {
    if (collapsed) {
      setOpenTooltip(itemId);
    } else {
      setOpenTooltip(0);
    }
  };

  const fetchMasterData = () => {
    dispatch(getMasterDataStart());
  };

  useEffect(() => {
    fetchMasterData();
  }, []);

  const handleSubmenuClick = (submenuKey: number) => {
    hideSidebar();
    sessionStorage.setItem(sessionStorageConstants.SubMenuKeyID, String(submenuKey));
  };

  const activeSubmenu = useMemo(
    () => Number(sessionStorage.getItem(sessionStorageConstants.SubMenuKeyID)),
    [handleSubmenuClick],
  );

  return (
    <div className="container_div">
      <Sidebar className="app" id={mobileMenu ? 'sideBarMenuShow' : 'sideBarMenuHide'}>
        <Menu
          transitionDuration={500}
          closeOnClick
          menuItemStyles={{
            button: {
              height: '36px',
              padding: '26px 15px !important',
              color: '#000000',
              '&.active': {
                borderLeft: '3px solid #00A751',
                backgroundColor: 'rgba(0, 167, 81, 0.1)',
                color: '#00A751',
              },
              '&:hover': {
                backgroundColor: 'rgba(0, 167, 81, 0.2)',
                color: '#00A751',
              },
            },
          }}
          className="sideBarMenuClass"
        >
          <MenuItem
            className="menu1"
          >
            <div
              className="img_container_navbar"
            >
              {collapsed ? <NavLink to="/dashboard" className="link"><img src={taiHalfLogo} alt="TAI Logo" height="42px" width="auto" /></NavLink> : <NavLink to="/dashboard" className="link"><img src={taiLogo} alt="TAI Logo" height="49px" width="180px" /></NavLink>}
            </div>
          </MenuItem>
          {menuItems(roleId || '').map((menuItem: Empty) => {
            if (!menuItem?.show) {
              return null;
            }
            if (!menuItem?.roleId) {
              return (
                <Tooltip
                  key={menuItem.id}
                  title={menuItem.label}
                  placement="right"
                  arrow
                  open={collapsed ? (Number(openTooltip) === menuItem.id) : false}
                  onClose={handleCloseTooltip}
                  onOpen={() => handleOpenTooltip(menuItem.id)}
                >
                  {/* {menuItem.subMenu ? (
                    <SubMenu
                      className={`${activeSubmenu === menuItem.activeSubmenuId ? 'SubmenuStyle' : ''} sideBarMenu`}
                      defaultOpen={activeSubmenu === menuItem.activeSubmenuId}
                      label={<span className="margin_3">{menuItem.label}</span>}
                      icon={menuItem.icon}
                    >
                      {menuItem.subMenu.map((subMenuItem: Empty) => (
                        <MenuItem
                          className={collapsed ? '' : 'sidebarSubMenuIcon'}
                          key={subMenuItem.id}
                          onClick={() => handleSubmenuClick(menuItem.activeSubmenuId)}
                          icon={subMenuItem.icon}
                          component={<NavLink to={subMenuItem.link} className="link" />}
                        >
                          {subMenuItem.label}
                        </MenuItem>
                      ))}
                    </SubMenu>
                  ) : (
                    <MenuItem
                      className="sideBarMenu"
                      component={<NavLink to={menuItem.link} className="link" />}
                      icon={menuItem.icon}
                      onClick={hideSidebar}
                    >
                      <span className="margin_3">{menuItem.label}</span>
                    </MenuItem>
                  )} */}
                  {menuItem.subMenu ? (
                    <SubMenu
                      className={`${activeSubmenu === menuItem.activeSubmenuId ? 'SubmenuStyle' : ''} sideBarMenu`}
                      defaultOpen={activeSubmenu === menuItem.activeSubmenuId}
                      label={<span className="margin_3">{menuItem.label}</span>}
                      icon={menuItem.icon}
                    >
                      {menuItem.subMenu.map((subMenuItem: Empty) => (
                        subMenuItem.subMenu ? (
                          <SubMenu
                            className={`${activeSubmenu === subMenuItem.activeSubmenuId ? 'SubmenuStyle' : ''} sideBarMenu`}
                            defaultOpen={activeSubmenu === subMenuItem.activeSubmenuId}
                            label={<span className="margin_3">{subMenuItem.label}</span>}
                            icon={subMenuItem.icon}
                          >
                            {subMenuItem.subMenu.map((nestedSubMenuItem: Empty) => (

                              nestedSubMenuItem.subMenu ? (
                                <SubMenu
                                  className={`${activeSubmenu === nestedSubMenuItem.activeSubmenuId ? 'SubmenuStyle' : ''} sideBarMenu`}
                                  defaultOpen={activeSubmenu === nestedSubMenuItem.activeSubmenuId}
                                  label={<span className="margin_3">{nestedSubMenuItem.label}</span>}
                                  icon={nestedSubMenuItem.icon}
                                >
                                  {nestedSubMenuItem.subMenu.map((SubNestedSubMenuItem: Empty) => (
                                    <MenuItem
                                      className={collapsed ? '' : 'sidebarSubMenuIcon'}
                                      key={SubNestedSubMenuItem.id}
                                      onClick={() => handleSubmenuClick(SubNestedSubMenuItem.activeSubmenuId)}
                                      icon={SubNestedSubMenuItem.icon}
                                      component={<NavLink to={SubNestedSubMenuItem.link} className="link" />}
                                    >
                                      {SubNestedSubMenuItem.label}
                                    </MenuItem>
                                  ))}
                                </SubMenu>
                              ) : (
                                <MenuItem
                                  className="sideBarMenu"
                                  component={<NavLink to={nestedSubMenuItem.link} className="link" />}
                                  icon={nestedSubMenuItem.icon}
                                  onClick={hideSidebar}
                                >
                                  <span className="margin_3">{nestedSubMenuItem.label}</span>
                                </MenuItem>
                              )
                            ))}
                          </SubMenu>
                        ) : (
                          <MenuItem
                            className="sideBarMenu"
                            component={<NavLink to={subMenuItem.link} className="link" />}
                            icon={subMenuItem.icon}
                            onClick={hideSidebar}
                          >
                            <span className="margin_3">{subMenuItem.label}</span>
                          </MenuItem>
                        )
                      ))}
                    </SubMenu>
                  ) : (
                    <MenuItem
                      className="sideBarMenu"
                      component={<NavLink to={menuItem.link} className="link" />}
                      icon={menuItem.icon}
                      onClick={hideSidebar}
                    >
                      <span className="margin_3">{menuItem.label}</span>
                    </MenuItem>
                  )}
                </Tooltip>
              );
            }
            if (menuItem?.roleId && Number(menuItem?.roleId) !== Number(roleId)) {
              return null;
            }
            return (
              // <Tooltip
              //   key={menuItem.id}
              //   title={menuItem.label}
              //   placement="right"
              //   arrow
              //   open={collapsed ? (Number(openTooltip) === menuItem.id) : false}
              //   onClose={handleCloseTooltip}
              //   onOpen={() => handleOpenTooltip(menuItem.id)}
              // >
              //   {menuItem.subMenu ? (
              //     <SubMenu
              //       className={`${activeSubmenu === menuItem.activeSubmenuId ? 'SubmenuStyle' : ''} sideBarMenu`}
              //       defaultOpen={activeSubmenu === menuItem.activeSubmenuId}
              //       label={<span className="margin_3">{menuItem.label}</span>}
              //       icon={menuItem.icon}
              //     >
              //       {menuItem.subMenu.map((subMenuItem: Empty) => (
              //         <MenuItem
              //           className={collapsed ? '' : 'sidebarSubMenuIcon'}
              //           key={subMenuItem.id}
              //           onClick={() => handleSubmenuClick(menuItem.activeSubmenuId)}
              //           icon={subMenuItem.icon}
              //           component={<NavLink to={subMenuItem.link} className="link" />}
              //         >
              //           {subMenuItem.label}
              //         </MenuItem>
              //       ))}
              //     </SubMenu>
              //   ) : (
              //     <MenuItem
              //       className="sideBarMenu"
              //       component={<NavLink to={menuItem.link} className="link" />}
              //       icon={menuItem.icon}
              //       onClick={hideSidebar}
              //     >
              //       <span className="margin_3">{menuItem.label}</span>
              //     </MenuItem>
              //   )}
              // </Tooltip>
              <Tooltip
                key={menuItem.id}
                title={menuItem.label}
                placement="right"
                arrow
                open={collapsed ? (Number(openTooltip) === menuItem.id) : false}
                onClose={handleCloseTooltip}
                onOpen={() => handleOpenTooltip(menuItem.id)}
              >
                {menuItem.subMenu ? (
                  <SubMenu
                    className={`${activeSubmenu === menuItem.activeSubmenuId ? 'SubmenuStyle' : ''} sideBarMenu`}
                    defaultOpen={activeSubmenu === menuItem.activeSubmenuId}
                    label={<span className="margin_3">{menuItem.label}</span>}
                    icon={menuItem.icon}
                  >
                    {menuItem.subMenu.map((subMenuItem: Empty) => (
                      subMenuItem.subMenu ? (
                        <SubMenu
                          className={`${activeSubmenu === subMenuItem.activeSubmenuId ? 'SubmenuStyle' : ''} sideBarMenu`}
                          defaultOpen={activeSubmenu === subMenuItem.activeSubmenuId}
                          label={<span className="margin_3">{subMenuItem.label}</span>}
                          icon={subMenuItem.icon}
                        >
                          {subMenuItem.subMenu.map((nestedSubMenuItem: Empty) => (

                            nestedSubMenuItem.subMenu ? (
                              <SubMenu
                                className={`${activeSubmenu === nestedSubMenuItem.activeSubmenuId ? 'SubmenuStyle' : ''} sideBarMenu`}
                                defaultOpen={activeSubmenu === nestedSubMenuItem.activeSubmenuId}
                                label={<span className="margin_3">{nestedSubMenuItem.label}</span>}
                                icon={nestedSubMenuItem.icon}
                              >
                                {nestedSubMenuItem.subMenu.map((SubNestedSubMenuItem: Empty) => (
                                  <MenuItem
                                    className={collapsed ? '' : 'sidebarSubMenuIcon'}
                                    key={SubNestedSubMenuItem.id}
                                    onClick={() => handleSubmenuClick(SubNestedSubMenuItem.activeSubmenuId)}
                                    icon={SubNestedSubMenuItem.icon}
                                    component={<NavLink to={SubNestedSubMenuItem.link} className="link" />}
                                  >
                                    {SubNestedSubMenuItem.label}
                                  </MenuItem>
                                ))}
                              </SubMenu>
                            ) : (
                              <MenuItem
                                className="sideBarMenu"
                                component={<NavLink to={nestedSubMenuItem.link} className="link" />}
                                icon={nestedSubMenuItem.icon}
                                onClick={hideSidebar}
                              >
                                <span className="margin_3">{nestedSubMenuItem.label}</span>
                              </MenuItem>
                            )
                          ))}
                        </SubMenu>
                      ) : (
                        <MenuItem
                          className="sideBarMenu"
                          component={<NavLink to={subMenuItem.link} className="link" />}
                          icon={subMenuItem.icon}
                          onClick={hideSidebar}
                        >
                          <span className="margin_3">{subMenuItem.label}</span>
                        </MenuItem>
                      )
                    ))}
                  </SubMenu>
                ) : (
                  <MenuItem
                    className="sideBarMenu"
                    component={<NavLink to={menuItem.link} className="link" />}
                    icon={menuItem.icon}
                    onClick={hideSidebar}
                  >
                    <span className="margin_3">{menuItem.label}</span>
                  </MenuItem>
                )}
              </Tooltip>
            );
          })}
        </Menu>
      </Sidebar>
      <div className="sub_div_navbar">
        <div className="inner_div_navBar">
          {handleSideBar()}
          {handleSideBarOnMob()}
          <div className="img_container_navbar">
            {roleId !== ANALYST_ROLE_ID && (
              <>
                <NavLink to="/jobs/add-job" className="link_style">
                  <Tooltip
                    placement="bottom"
                    title="Add Job"
                    arrow
                  >
                    <img src={addJobIcon} alt="add Job" />
                  </Tooltip>
                </NavLink>
                <NavLink to="/customers/add-customer" className="link_style">
                  <Tooltip
                    placement="bottom"
                    title="Add Customer"
                    arrow
                  >
                    <img src={addCustomerIcon} alt="add Customer" />
                  </Tooltip>
                </NavLink>
              </>
            )}
            {/* <div className="relative_div" ref={notificationRef}>
              <div className="dropdown">
                <Badge badgeContent={4} color="error" className="customNotificationIcon" onClick={() => setIsNotificationOpen(!isNotificationOpen)}>
                  <NotificationIcon />
                </Badge>
              </div>
              {
                isNotificationOpen
                && (
                  <div className="menu">
                    <ScreenNotification />
                  </div>
                )
              }
            </div> */}
            <div className="relative_div" ref={menuRef}>
              <div className="div_inner_header" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <div className="header_profile_pic ">
                  <span>
                    {firstLetter}
                    {secondLetter}
                  </span>
                </div>
                <div className="fontSize">
                  {userProfile.FirstName}
                </div>
              </div>
              {
                isMenuOpen
                && (
                  <div className="menu">
                    <ScreenProfile />
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <section className="section_Style">
          <Outlet />
        </section>
      </div>
    </div>
  );
}
