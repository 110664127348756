/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import {
  Grid, Input, Typography,
} from '@mui/material';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import CustomCheckbox from '../../../../../Common/ComponentCustomCheckBox';
import {
  Accordion, AccordionDetails, AccordionSummary, CustomExpandIcon,
} from '../../../../../utils/AccordionUtils/AccordionUtils';
import styles from '../../BidWorkSheetJob.module.scss';
import { BidPriceDetailNames } from '../../utils/constants';
import { BidDetailsFormComponentProps } from '../../utils/types';
import { chargeRegex } from '../../../../../utils/regex';
// import EndPoints from '../../../../../Routes/EndPoints';
import { RootState } from '../../../../../redux/rootState';
import { capitalizeFirstLetter } from '../../../../../utils/CommonFunctions';
import ComponentSelectInput from '../../../../../Common/ComponentSelectInput';
import { disableSaveButton } from '../../redux/bidWorksheetSlice';

export function BidDetailsFormComponent({ control, watch, setValue }:BidDetailsFormComponentProps) {
  // const { pathname } = useLocation();
  const { selectedBid, bidPricing, disableButton } = useSelector((state:RootState) => state.bidsReducer);
  // const [addBid, SetAddBid] = useState(false);

  const [expanded, setExpanded] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const handlePanelChange = (val:string) => {
    if (val === expanded) {
      setExpanded('');
      return;
    }
    setExpanded(val);
  };

  const TotalMiscExpense = watch(BidPriceDetailNames.TotalMiscExpense) || 0;
  const AdditionalCharges = watch(BidPriceDetailNames.AdditionalCharges) || 0;
  const TotalFlightExpense = watch(BidPriceDetailNames.TotalFlightExpense) || 0;
  const isOTSaturday = watch(BidPriceDetailNames.IsOTSaturday);
  const isOTSunday = watch(BidPriceDetailNames.IsOTSunday);
  const IsNightCharges = watch(BidPriceDetailNames.IsNightCharges);
  // const IsParkingCharges = watch(BidPriceDetailNames.IsParking);
  const isOTHoliday = watch(BidPriceDetailNames.IsOTHoliday);
  const isSafetyTraining = watch(BidPriceDetailNames.IsSafetyTraining);

  useEffect(() => {
    if (!isSafetyTraining) {
      setValue(BidPriceDetailNames.SafetyTrainingCharges, 0);
      setValue(BidPriceDetailNames.SafetyTraining, '');
    }
  }, [isSafetyTraining]);

  // const handleParkingChange = (value:boolean) => {
  //   if (!value) {
  //     setValue(BidPriceDetailNames.ParkingChanges, '');
  //   }
  // };

  // useEffect(() => {
  //   SetAddBid(pathname.includes(EndPoints.ADD_JOB_BID));
  // }, [pathname]);

  const handleChargeChange = (flag:boolean, value: string, apiVal: string) => {
    if (flag) {
      // eslint-disable-next-line @typescript-eslint/dot-notation, max-len
      setValue(value, selectedBid?.BidPriceDetails?.[value] || bidPricing?.AdditionalInfo?.[apiVal]);
    } else {
      setValue(value, '');
    }
  };

  const TotalBid = watch(BidPriceDetailNames.TotalBid);
  useEffect(() => {
    if (Number(TotalBid) < 0) {
      dispatch(disableSaveButton(true));
      // eslint-disable-next-line quotes
      setErrorMessage("Total bid can't be less then zero");
    } else {
      dispatch(disableSaveButton(false));
      setErrorMessage('');
    }
  }, [TotalBid]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_disabled">Tube Charge ($)</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.TubeCharge}
            id={BidPriceDetailNames.TubeCharge}
            isDisable
            size="small"
          />
        </div>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_disabled">Calculated Days</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.CalculatedDays}
            id={BidPriceDetailNames.CalculatedDays}
            isDisable
            size="small"
          />
        </div>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_disabled">Override Days</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.OverRideDays}
            id={BidPriceDetailNames.OverRideDays}
            isDisable
            size="small"
          />
        </div>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_disabled">Flux Leakage Days</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.FluxLeakageDays}
            id={BidPriceDetailNames.FluxLeakageDays}
            isDisable
            size="small"
          />
        </div>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_disabled">Drive Expense ($)</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.DriveExpense}
            id={BidPriceDetailNames.DriveExpense}
            isDisable
            size="small"
          />
        </div>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_disabled">Lodge Per Diem ($)</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.LodgePerDiem}
            id={BidPriceDetailNames.LodgePerDiem}
            isDisable
            size="small"
          />
        </div>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion expanded={expanded === '1'} onChange={() => handlePanelChange('1')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<CustomExpandIcon />}>
            <Typography style={{ margin: 'auto 2px' }} className="typography_Accordion">Flight Expense ($)</Typography>
            <Input
              type="text"
              className="bid_job_input_style"
              value={TotalFlightExpense}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Flight Charge ($)</label>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.FlightCharge}
                    id={BidPriceDetailNames.FlightCharge}
                    regEx={chargeRegex}
                    size="small"
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Airfare ($)</label>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.Airfare}
                    regEx={chargeRegex}
                    id={BidPriceDetailNames.Airfare}
                    size="small"
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Total ($)</label>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.TotalFlightExpense}
                    id={BidPriceDetailNames.TotalFlightExpense}
                    size="small"
                    isDisable
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion expanded={expanded === '2'} onChange={() => handlePanelChange('2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<CustomExpandIcon />}>
            <Typography style={{ margin: 'auto 2px' }} className="typography_Accordion">Misc Expense ($)</Typography>
            <Input
              type="text"
              className="bid_job_input_style"
              value={TotalMiscExpense}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {/* <Grid item md={6} sm={12} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Shipping Charges</label>
                        <TextField size="small" inputProps={{ min: 0, style: { textAlign: 'end' } }} />
                      </div>
                    </Grid> */}
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Equipment Charges ($)</label>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.EquipmentCharges}
                    id={BidPriceDetailNames.EquipmentCharges}
                    regEx={chargeRegex}
                    size="small"
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                  />
                </div>
              </Grid>
              {/* <Grid item md={6} sm={12} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Weekend /Holiday Charges</label>
                        <TextField size="small" inputProps={{ min: 0, style: { textAlign: 'end' } }} />
                      </div>
                    </Grid> */}
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Other Charges ($)</label>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.OtherCharges}
                    id={BidPriceDetailNames.OtherCharges}
                    regEx={chargeRegex}
                    size="small"
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Total ($)</label>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.TotalMiscExpense}
                    id={BidPriceDetailNames.TotalMiscExpense}
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                    isDisable
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Accordion expanded={expanded === '3'} onChange={() => handlePanelChange('3')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<CustomExpandIcon />}>
            <Typography style={{ margin: 'auto 2px' }} className="typography_Accordion">Additional Charges ($)</Typography>
            <Input
              type="text"
              className="bid_job_input_style"
              value={AdditionalCharges}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item md={12} sm={12} xs={12}>
                <div className={styles.additional_charges_div}>
                  <div className={styles.checkbox_style_div}>
                    <CustomCheckbox
                      control={control}
                      name={BidPriceDetailNames.IsCommunicationCharges}
                      label="Communication Charges ($)"
                      handleChange={(val) => handleChargeChange(val, capitalizeFirstLetter(BidPriceDetailNames.CommunicationCharges), 'CommunicationCharges')}
                    />
                  </div>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.CommunicationCharges}
                    id={BidPriceDetailNames.CommunicationCharges}
                    isDisable
                    size="small"
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className={styles.additional_charges_div}>
                  <div className={styles.checkbox_style_div}>
                    <CustomCheckbox
                      control={control}
                      isDisable
                      // handleChange={handleParkingChange}
                      name={BidPriceDetailNames.IsParking}
                      label="Parking ($)"
                    />
                  </div>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.ParkingChanges}
                    regEx={chargeRegex}
                    id={BidPriceDetailNames.ParkingChanges}
                    // isDisable={addBid ? true : !IsParkingCharges}
                    isDisable
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className={styles.additional_charges_div}>
                  <div className={styles.checkbox_style_div}>
                    <CustomCheckbox
                      control={control}
                      name={BidPriceDetailNames.IsSafetyCharges}
                      label="Safety Charges ($)"
                      isDisable
                      // handleChange={(val) => handleChargeChange(val, capitalizeFirstLetter(BidPriceDetailNames.SafetyCharges), 'SafetyCharges')}
                    />
                  </div>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.SafetyCharges}
                    id={BidPriceDetailNames.SafetyCharges}
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                    isDisable
                    regEx={chargeRegex}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className={styles.additional_charges_div}>
                  <div className={styles.checkbox_style_div}>
                    <CustomCheckbox
                      control={control}
                      name={BidPriceDetailNames.IsNightCharges}
                      label="Night Charges ($)"
                      handleChange={(val) => handleChargeChange(val, capitalizeFirstLetter(BidPriceDetailNames.NightCharges), 'NightCharges')}
                    />
                  </div>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.NightCharges}
                    id={BidPriceDetailNames.NightCharges}
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                    regEx={chargeRegex}
                    isDisable={!IsNightCharges}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className={styles.additional_charges_div}>
                  <div className={styles.checkbox_style_div}>
                    <CustomCheckbox
                      control={control}
                      name={BidPriceDetailNames.IsOTSaturday}
                      label="O/T Saturday ($)"
                      handleChange={(val) => handleChargeChange(val, capitalizeFirstLetter(BidPriceDetailNames.OTSaturday), 'OTSaturday')}
                    />
                  </div>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.OTSaturday}
                    regEx={chargeRegex}
                    id={BidPriceDetailNames.OTSaturday}
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                    isDisable={!isOTSaturday}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className={styles.additional_charges_div}>
                  <div className={styles.checkbox_style_div}>
                    <CustomCheckbox
                      control={control}
                      name={BidPriceDetailNames.IsOTSunday}
                      label="O/T Sunday ($)"
                      handleChange={(val) => handleChargeChange(val, capitalizeFirstLetter(BidPriceDetailNames.OTSunday), 'OTSunday')}
                    />
                  </div>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.OTSunday}
                    regEx={chargeRegex}
                    id={BidPriceDetailNames.OTSunday}
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                    isDisable={!isOTSunday}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className={styles.additional_charges_div}>
                  <div className={styles.checkbox_style_div}>
                    <CustomCheckbox
                      control={control}
                      name={BidPriceDetailNames.IsOTHoliday}
                      label="O/T Holiday ($)"
                      handleChange={(val) => handleChargeChange(val, capitalizeFirstLetter(BidPriceDetailNames.OTHoliday), 'OTHoliday')}
                    />
                  </div>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.OTHoliday}
                    regEx={chargeRegex}
                    id={BidPriceDetailNames.OTHoliday}
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                    isDisable={!isOTHoliday}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className={styles.additional_charges_div}>
                  <div className={styles.checkbox_style_div}>
                    <CustomCheckbox control={control} name={BidPriceDetailNames.IsSafetyTraining} label="Safety Training ($)" />
                  </div>
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.SafetyTraining}
                    regEx={chargeRegex}
                    id={BidPriceDetailNames.SafetyTraining}
                    inputProps={{
                      style: { textAlign: 'end' },
                    }}
                    isDisable={!isSafetyTraining}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className={styles.additional_charges_div}>
                  <div className={styles.checkbox_style_div} />
                  <ComponentTextInput
                    control={control}
                    name={BidPriceDetailNames.SafetyTrainingCharges}
                    id={BidPriceDetailNames.SafetyTrainingCharges}
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                    isDisable
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {/* <Grid item md={12} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_disabled">Total Expenses ($)</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.TotalExpenses}
            id={BidPriceDetailNames.TotalExpenses}
            inputProps={{
              min: 0,
              style: { textAlign: 'end' },
            }}
            isDisable
            size="small"
          />
        </div>
      </Grid> */}
      <Grid item md={12} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_disabled">Markup ($)</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.Markup}
            id={BidPriceDetailNames.Markup}
            inputProps={{
              min: 0,
              style: { textAlign: 'end' },
            }}
            isDisable
            size="small"
          />
        </div>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_disabled">Flux Shipping ($)</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.FluxShipping}
            id={BidPriceDetailNames.FluxShipping}
            inputProps={{
              min: 0,
              style: { textAlign: 'end' },
            }}
            isDisable
            size="small"
          />
        </div>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_disabled">Abs/Diff Shipping ($)</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.AbsShipping}
            id={BidPriceDetailNames.AbsShipping}
            inputProps={{
              min: 0,
              style: { textAlign: 'end' },
            }}
            isDisable
            size="small"
          />
        </div>
      </Grid>
      <Grid item md={5} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_job">Non-Prod. Days</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.NonProdDays}
            id={BidPriceDetailNames.NonProdDays}
            regEx={/^\d{1,2}$/}
            inputProps={{ min: 0, style: { textAlign: 'end' } }}
            size="small"
          />
        </div>
      </Grid>
      <Grid item md={7} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_disabled">Amount ($)</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.NonProdDaysAmount}
            id={BidPriceDetailNames.NonProdDaysAmount}
            isDisable
            inputProps={{
              min: 0,
              style: { textAlign: 'end' },
            }}
            size="small"
          />
        </div>
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_job">Correction Value($)</label>
          <ComponentSelectInput
            name={BidPriceDetailNames.CorrectionType}
            control={control}
            defaultValue="1"
            size="small"
            entries={[{ Text: '+', Value: '0' }, { Text: '-', Value: '1' }]}
          />
        </div>
      </Grid>
      <Grid item md={8} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_job">&nbsp;</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.CorrectionFactor}
            id={BidPriceDetailNames.CorrectionFactor}
            regEx={chargeRegex}
            // handleChange={handleCorrection}
            inputProps={{
              min: 0,
              style: { textAlign: 'end' },
            }}
            size="small"
          />
        </div>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <div className="div_label_text">
          <label className="label_Style_disabled">Total Bid ($)</label>
          <ComponentTextInput
            control={control}
            name={BidPriceDetailNames.TotalBid}
            id={BidPriceDetailNames.TotalBid}
            inputProps={{
              // min: 0,
              style: { textAlign: 'end' },
            }}
            size="small"
            isDisable
          />
        </div>
        {disableButton && <span className="errorMessage">{errorMessage}</span>}
      </Grid>
    </Grid>
  );
}
