import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import TableOne from './Components/TableOne';
import TableThree from './Components/TableThree';
import TableTwo from './Components/TableTwo';
import { CombineReportEnum, PDFReportEnum, ReportPart1 } from '../../helper';
import { RootState } from '../../../../redux/rootState';

function ComponentJobOrder() {
  const { tableContent } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  return (
    <>
      <table className="w100">
        <tr>
          <td className="textLeft pt10 pb10" style={{ padding: '5px' }}>
            {ReportPart1(tableContent, CombineReportEnum.COMBINE_Job_Order, PDFReportEnum.PDF_Job_Order, 'Job Order')}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="textCenter underlineHeadingText">
            Tickler
          </td>
        </tr>
      </table>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            gap: '30px',
            justifyContent: 'center',
          }}
          >
            <TableOne />
            <TableTwo />
          </div>
        </Grid>
        <Grid item md={6} sm={12}>
          <TableThree />
        </Grid>
      </Grid>
    </>
  );
}

export default ComponentJobOrder;
