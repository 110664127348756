// /* eslint-disable react/no-array-index-key */
// import React, { useEffect, useState } from 'react';
// import {
//   BarChart, Bar, YAxis, CartesianGrid, Tooltip, Legend,
//   XAxis,
//   Cell,
// } from 'recharts';
// import { DefectData, GraphVesselReport } from '../types';
// import { Empty } from '../../ScreenAddSmc/Utils/TypeSmc';
// import { addRandomColorToData, getBarSize } from '../helper';

// function ComponentBarChart({ graphData, primaryColor }: GraphVesselReport) {
//   const [graphDataChunks, setGraphDataChunks] = useState<Array<Empty[]>>([]);

//   const splitDataIntoChunks = (data: Empty[], chunkSize: number) => {
//     const chunks = [];
//     for (let i = 0; i < data.length; i += chunkSize) {
//       chunks.push(data.slice(i, i + chunkSize));
//     }
//     return chunks;
//   };

//   useEffect(() => {
//     const updatedData = addRandomColorToData(graphData.DefectComparison).map((item) => ({
//       ...item,
//       Key: `${item.Code}`,
//     }));

//     // Split the updated data into chunks of 16
//     const chunks = splitDataIntoChunks(updatedData as Empty[], 14);
//     setGraphDataChunks(chunks);
//   }, [graphData]);

//   // const customLabelList = () => {
//   //   if (!graphData || !Array.isArray(graphData.DefectComparison)) {
//   //     return null;
//   //   }

//   //   return graphData.DefectComparison.map((data, index) => (
//   //     <div key={`label-${index}`}>{`D-${index + 1} = ${data.DefectName}`}</div>
//   //   ));
//   // };

//   // const customLabelList = () => {
//   //   if (!graphData || !Array.isArray(graphData.DefectComparison)) {
//   //     return null;
//   //   }

//   //   // Helper function to chunk the array into smaller arrays of size 15
//   //   const chunkArray = (array, chunkSize) => {
//   //     const result = [];
//   //     for (let i = 0; i < array.length; i += chunkSize) {
//   //       result.push(array.slice(i, i + chunkSize));
//   //     }
//   //     return result;
//   //   };

//   //   // Chunk the DefectComparison array into chunks of 15
//   //   const chunks = chunkArray(graphData.DefectComparison, 15);

//   //   return (
//   //     <div style={{
//   //       display: 'flex', gap: 1, justifyContent: 'space-evenly', width: '100%',
//   //     }}
//   //     >
//   //       {chunks.map((chunk, chunkIndex) => (
//   //         <div key={`chunk-${chunkIndex}`} style={{ marginRight: '20px' }}>
//   //           {chunk.map((data, index) => (
//   //             <div key={`label-${chunkIndex}-${index}`} style={{ marginBottom: '10px' }}>
//   //               {`D-${chunkIndex * 15 + index + 1} = ${data.DefectName}`}
//   //             </div>
//   //           ))}
//   //         </div>
//   //       ))}
//   //     </div>
//   //   );
//   // };

//   // function customLabelList() {
//   //   if (!graphData || !Array.isArray(graphData.DefectComparison)) {
//   //     return null;
//   //   }

//   //   // Helper function to chunk the array into smaller arrays of size 15
//   //   const chunkArray = (array: DefectData[], chunkSize: number): DefectData[][] => {
//   //     const result: DefectData[][] = [];
//   //     for (let i = 0; i < array.length; i += chunkSize) {
//   //       result.push(array.slice(i, i + chunkSize));
//   //     }
//   //     return result;
//   //   };

//   //   // Chunk the DefectComparison array into chunks of 15
//   //   const chunks = chunkArray(graphData.DefectComparison, 10);

//   //   return (
//   //     <div style={{
//   //       display: 'flex',
//   //       gap: '20px',
//   //       justifyContent: 'flex-start',
//   //       placeItems: 'flex-start',
//   //       width: '100%',
//   //     }}
//   //     >
//   //       {chunks.map((chunk, chunkIndex) => (
//   //         <div key={`chunk-${chunkIndex}`} style={{ marginRight: '20px' }}>
//   //           {chunk.map((data, index) => (
//   //             <div key={`label-${chunkIndex}-${index}`} style={{ marginBottom: '6px', fontSize: '14px' }}>
//   //               {`D-${chunkIndex * 10 + index + 1} = ${data.DefectName}`}
//   //             </div>
//   //           ))}
//   //         </div>
//   //       ))}
//   //     </div>
//   //   );
//   // }

//   function customLabelList() {
//     if (!graphData || !Array.isArray(graphData.DefectComparison)) {
//       return null;
//     }

//     // Helper function to chunk the array into smaller arrays of size 10
//     const chunkArray = (array: DefectData[], chunkSize: number): DefectData[][] => {
//       const result: DefectData[][] = [];
//       for (let i = 0; i < array.length; i += chunkSize) {
//         result.push(array.slice(i, i + chunkSize));
//       }
//       return result;
//     };

//     const chunks = chunkArray(graphData.DefectComparison, 5);
//     const rows = [];
//     for (let i = 0; i < chunks.length; i += 3) {
//       rows.push(chunks.slice(i, i + 3));
//     }
//     // eslint-disable-next-line prefer-const
//     // let num = 1;
//     return (
//       <div style={{ marginBottom: '10px' }}>
//         {rows.map((row, rowIndex) => (
//           <div
//             key={`row-${rowIndex}`}
//             style={{
//               display: 'flex', gap: '25px', marginBottom: '10px', width: '100%',
//             }}
//           >
//             {row.map((chunk, chunkIndex) => (
//               <div
//                 key={`chunk-${rowIndex}-${chunkIndex}`}
//                 style={{
//                   width: '220px',
//                 }}
//               >
//                 {chunk.map((data, index) => {
//                   const label = `${data.Code} = ${data.DefectName}`;
//                   // num += 1;
//                   return (
//                     <div key={`label-${rowIndex}-${chunkIndex}-${index}`} style={{ marginBottom: '6px', fontSize: '13px' }}>
//                       {label}
//                     </div>
//                   );
//                 })}
//               </div>
//             ))}
//           </div>
//         ))}
//       </div>
//     );
//   }

//   return (
//     <>
//       <svg width={0} height={0}>
//         <defs>
//           {graphDataChunks.map((chunk, chunkIndex) => chunk.map((entry, index) => (
//             <pattern
//               key={`pattern-${chunkIndex}-${index}`}
//               id={`squarePattern-${chunkIndex}-${index}`}
//               patternUnits="userSpaceOnUse"
//               width={10}
//               height={10}
//             >
//               <rect width={10} height={10} fill={entry.colorHex} />
//               <rect x={1.5} y={1.5} width={12} height={12} fill="#FFFFFF" />
//             </pattern>
//           )))}
//         </defs>
//       </svg>

//       {graphDataChunks.map((chunk, chunkIndex) => (
//         <div
//           key={`chart-${chunkIndex}`}
//           className="barChartContainer"
//           style={{
//             borderColor: primaryColor,
//             marginTop: '20px', // Space between charts
//             marginBottom: '20px', // Space between charts
//           }}
//         >
//           <BarChart
//             width={700}
//             height={520}
//             data={chunk}
//           >
//             {/* <svg width={0} height={0}>
//               <defs>
//                 <pattern id="squarePattern" patternUnits="userSpaceOnUse" width={10} height={10}>
//                   <rect width={10} height={10} fill="#F6131A" />
//                   <rect x={1.5} y={1.5} width={12} height={12} fill="#FFFFFF" />
//                 </pattern>
//               </defs>
//             </svg> */}
//             <CartesianGrid />
//             <XAxis
//               dataKey="Key"
//             />
//             <YAxis />
//             <Tooltip />
//             <Legend />
//             {/* <Bar dataKey="DefectCount" fill="#F6131A" />
//             <Bar dataKey="RevisedDefectCount" fill="url(#squarePattern)" /> */}

//             <defs>
//               {chunk.map((entry, index) => (
//                 <linearGradient id={`gradient-${index}`} x1="0" y1="0" x2="1" y2="0" key={`gradient-${index}`}>
//                   <stop offset="0%" stopColor={entry.colorHex} stopOpacity={1} />
//                   <stop offset="100%" stopColor="#f3f3f3" stopOpacity={1} />
//                 </linearGradient>
//               ))}
//             </defs>

//             <Bar dataKey="DefectCount" barSize={getBarSize(chunk.length)}>
//               {chunk.map((entry, index) => (
//                 <Cell key={`cell-${index}`} fill={`url(#gradient-${index})`} />
//               ))}
//             </Bar>
//             {chunk.length > 0
//             && (
//             <Bar dataKey="RevisedDefectCount" barSize={getBarSize(chunk.length)}>
//               {chunk.map((entry, index) => (
//                 <Cell key={`cell-pattern-${index}`} fill={`url(#squarePattern-${chunkIndex}-${index})`} />
//               ))}
//             </Bar>
//             )}

//           </BarChart>
//         </div>
//       ))}
//       {/* <div style={{
//         width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
//       }}
//       > */}
//       <div style={{
//         width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto',
//       }}
//       >
//         {customLabelList()}
//         {/* </div> */}
//       </div>
//     </>
//   );
// }

// export default ComponentBarChart;

/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, YAxis, CartesianGrid, Tooltip, Legend,
  XAxis,
  Cell,
} from 'recharts';
import { DefectComparison, GraphVesselReport } from '../types';
import { Empty } from '../../ScreenAddSmc/Utils/TypeSmc';
import { addRandomColorToData, getBarSize } from '../helper';

function ComponentBarChart({ graphData, primaryColor }: GraphVesselReport) {
  const [graphDataChunks, setGraphDataChunks] = useState<Array<Empty[]>>([]);
  const [graphDefectDataChunks, setGraphDefectDataChunks] = useState<DefectComparison[]>([]);

  const splitDataIntoChunks = (data: Empty[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      chunks.push(data.slice(i, i + chunkSize));
    }
    return chunks;
  };

  useEffect(() => {
    const updatedData = addRandomColorToData(graphData.DefectComparison).map((item) => ({
      ...item,
      Key: `${item.Code}`,
    }));
    setGraphDefectDataChunks(updatedData);

    // Split the updated data into chunks of 16
    const chunks = splitDataIntoChunks(updatedData as Empty[], 14);
    setGraphDataChunks(chunks);
  }, [graphData]);

  function customLabelList() {
    if (!graphDefectDataChunks || !Array.isArray(graphDefectDataChunks)) {
      return null;
    }

    // Helper function to chunk the array into smaller arrays of size 10
    const chunkArray = (array: DefectComparison[], chunkSize: number): DefectComparison[][] => {
      const result: DefectComparison[][] = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    };

    const chunks = chunkArray(graphDefectDataChunks, 5);
    const rows = [];
    for (let i = 0; i < chunks.length; i += 3) {
      rows.push(chunks.slice(i, i + 3));
    }
    // eslint-disable-next-line prefer-const
    // let num = 1;
    return (
      <div style={{ marginBottom: '10px' }}>
        {rows.map((row, rowIndex) => (
          <div
            key={`row-${rowIndex}`}
            style={{
              display: 'flex', gap: '25px', marginBottom: '10px', width: '100%',
            }}
          >
            {row.map((chunk, chunkIndex) => (
              <div
                key={`chunk-${rowIndex}-${chunkIndex}`}
                style={{
                  width: '220px',
                }}
              >
                {/* {chunk.map((data, index) => {
                  const label = `${data.Code} = ${data.DefectName}`;
                  // num += 1;
                  return (
                    <div key={`label-${rowIndex}-${chunkIndex}-${index}`} style={{ marginBottom: '6px', fontSize: '13px' }}>
                      {label}
                    </div>
                  );
                })} */}
                {chunk.map((data, index) => (
                  <div
                    key={`label-${rowIndex}-${chunkIndex}-${index}`}
                    style={{
                      display: 'flex', alignItems: 'center', marginBottom: '6px', fontSize: '13px',
                    }}
                  >
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: data.colorHex,
                        marginRight: '8px',
                        borderRadius: '2px',
                      }}
                    />
                    <span>
                      {data.DefectName}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  return (
    <>
      <svg width={0} height={0}>
        <defs>
          {graphDataChunks.map((chunk, chunkIndex) => chunk.map((entry, index) => (
            <pattern
              key={`pattern-${chunkIndex}-${index}`}
              id={`squarePattern-${chunkIndex}-${index}`}
              patternUnits="userSpaceOnUse"
              width={10}
              height={10}
            >
              <rect width={10} height={10} fill={entry.colorHex} />
              <rect x={1.5} y={1.5} width={12} height={12} fill="#FFFFFF" />
            </pattern>
          )))}
        </defs>
      </svg>

      {graphDataChunks.map((chunk, chunkIndex) => (
        <div
          key={`chart-${chunkIndex}`}
          className="barChartContainer"
          style={{
            borderColor: primaryColor,
            marginTop: '20px', // Space between charts
            marginBottom: '20px', // Space between charts
          }}
        >
          <BarChart
            width={700}
            height={520}
            data={chunk}
          >
            <CartesianGrid />
            <XAxis
              dataKey="Key"
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <defs>
              {chunk.map((entry, index) => (
                <linearGradient id={`gradient-${index}`} x1="0" y1="0" x2="0" y2="1" key={`gradient-${index}`}>
                  <stop offset="0%" stopColor={entry.colorHex} stopOpacity={1} />
                  <stop offset="100%" stopColor="#f3f3f3" stopOpacity={1} />
                </linearGradient>
              ))}
            </defs>

            <Bar dataKey="DefectCount" barSize={getBarSize(chunk.length)}>
              {chunk.map((entry, index) => (
                <Cell
                  stroke="#000" // Border color
                  strokeWidth={1} // Border thickness
                  key={`cell-${index}`}
                  fill={`url(#gradient-${index})`}
                />
              ))}
            </Bar>
            {chunk.length > 0
            && (
            <Bar dataKey="RevisedDefectCount" barSize={getBarSize(chunk.length)}>
              {chunk.map((entry, index) => (
                <Cell
                  // stroke="#000" // Border color
                  // strokeWidth={1} // Border thickness
                  key={`cell-pattern-${index}`}
                  fill={`url(#squarePattern-${chunkIndex}-${index})`}
                />
              ))}
            </Bar>
            )}

          </BarChart>
        </div>
      ))}
      <div style={{
        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto',
      }}
      >
        {customLabelList()}
      </div>
    </>
  );
}

export default ComponentBarChart;
