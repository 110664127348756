import React, { useEffect, useState } from 'react';
import { TreeView, TreeItem } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';
import {
  createTreeDataStart,
  deleteTreeDataStart,
  getTreeDataStart,
  setTreeData,
} from '../redux/sliceDefectCode';
import FolderIcon from '../../../../assets/FolderIcon.svg';
import FileIcon from '../../../../assets/FileIcon.svg';
import AddFolder from '../../../../assets/addFolder.svg';
import EditFolder from '../../../../assets/treeEdit.svg';
// import DeleteFolder from '../../../../assets/deleteFolder.svg';
import Arrow from '../../../../assets/arrowUp.svg';
import { Empty } from '../../../ScreenAddSmc/Utils/TypeSmc';
import styles from '../utils/FolderStyle.module.scss';
import ComponentDeleteModal from '../../../../Common/DeleteModal/ComponentDeleteModal';

export default function NewFolderTree() {
  const { treeDataList, isSuccess } = useSelector((state: RootState) => state.defectCodeReducer);
  const [addFolderInput, setAddFolderInput] = useState('');
  const [editFolderInput, setEditFolderInput] = useState('');
  const [addingFolder, setAddingFolder] = useState<number | null>(null);
  const [editFolder, setEditFolder] = useState<number | null>(null);
  const [selectedAnalystDefectCodeListId,
    // setSelectedAnalystDefectCodeListId
  ] = useState<string | number>('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTreeDataStart());
    dispatch(setTreeData({
      CategoryName: '',
      Children: [],
      Id: 0,
      Name: '',
      ParentId: 0,
      SubCategoryName: '',
      TypeId: 0,
      Code: '',
      Color: '#FF7722',
      ActionRequired: true,
    }));
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setEditFolder(null);
      setAddingFolder(null);
    }
  }, [isSuccess]);

  const handleEditItem = (nodeId: number, nodeName: string) => {
    setEditFolder(nodeId);
    setEditFolderInput(nodeName);
  };

  const handleAddFolder = (nodeId: number) => {
    setAddingFolder(nodeId);
    setAddFolderInput('');
  };

  const handelDeleteModal = () => {
    const deleteData = {
      Id: selectedAnalystDefectCodeListId,
    };
    dispatch(deleteTreeDataStart(deleteData));
    setShowDeleteModal(false);
  };

  // const handleDelete = (id: number) => {
  //   setShowDeleteModal(true);
  //   setSelectedAnalystDefectCodeListId(id);
  // };

  const handleSaveAddFolder = (parentNodeId: number, TypeId: number) => {
    const newFolderPayload = {
      Type: 1,
      Id: 0,
      ParentId: Number(parentNodeId),
      TypeId,
      Value: addFolderInput || 'New Folder',
      Code: '',
      Color: '#FF7722',
      Description: '',
      ActionRequired: false,
    };
    dispatch(createTreeDataStart(newFolderPayload));
  };

  const handleSaveEdit = (parentNodeId: number, ParentId: number, TypeId: number) => {
    const updatedFolderPayload = {
      Type: 1,
      Id: parentNodeId,
      ParentId,
      TypeId,
      Value: editFolderInput,
      Code: '',
      Color: '#FF7722',
      Description: '',
      ActionRequired: false,
    };
    dispatch(createTreeDataStart(updatedFolderPayload));
  };

  const handleCancel = () => {
    setEditFolder(null);
    setAddingFolder(null);
  };

  const handleOnClick = (node: Empty) => {
    if (node.TypeId === 103 || node.TypeId === 104) {
      dispatch(setTreeData(node));
    } else {
      dispatch(setTreeData({
        CategoryName: '',
        Children: [],
        Id: 0,
        Name: '',
        ParentId: 0,
        SubCategoryName: '',
        TypeId: 0,
        Code: '',
        Color: '#FF7722',
        ActionRequired: true,
      }));
    }
  };

  const renderTree = (nodes: Empty) => nodes?.length > 0
    && nodes.map((node: Empty) => (
      <div key={node.Id} className="mainTest">
        <TreeItem
          onClick={() => handleOnClick(node)}
          nodeId={String(node.Id)}
          label={(
            <span style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }} onClick={() => handleOnClick(node)}>
              <span style={{ marginRight: '10px' }}>
                <img src={node.TypeId === 104 ? FileIcon : FolderIcon} alt="folder" />
              </span>
              <span className={styles.spanStyle}>{node.Name}</span>
            </span>
          )}
        >
          {editFolder === Number(node?.Id) ? (
            <div className="editDefectNode">
              <input
                type="text"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                maxLength={50}
                className="nonCapitalizeInput"
                value={editFolderInput}
                onChange={(e) => setEditFolderInput(e.target.value)}
              />
              <div className="smallDefectBtn">
                <button
                  type="button"
                  className="themeBtnGreen"
                  disabled={Boolean(!editFolderInput)}
                  onClick={() => handleSaveEdit(node.Id, node.ParentId, node.TypeId)}
                >
                  Save
                </button>
                <button type="button" className="themeBtnGray" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className="test addDefectNode">
              {addingFolder === node.Id ? (
                <div className="addDefectInput">
                  <input
                    type="text"
                    className="nonCapitalizeInput"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    maxLength={50}
                    value={addFolderInput}
                    onChange={(e) => setAddFolderInput(e.target.value)}
                  />
                  <div className="smallDefectBtn">
                    <button
                      type="button"
                      className="themeBtnGreen"
                      disabled={Boolean(!addFolderInput)}
                      onClick={() => handleSaveAddFolder(node.Id, node.TypeId + 1)}
                    >
                      Save
                    </button>
                    <button type="button" className="themeBtnGray" onClick={handleCancel}>
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="defectNodeBtns">
                  {node?.TypeId !== 104 && node?.TypeId !== 103 && (
                    <span onClick={() => handleAddFolder(node.Id)} style={{ marginLeft: '10px' }}>
                      <img src={AddFolder} alt="add folder" className="pointerClass" />
                    </span>
                  )}
                  {node?.TypeId !== 100 && node?.TypeId !== 104 && (
                    <span onClick={() => handleEditItem(node.Id, node.Name)} style={{ marginLeft: '10px' }}>
                      <img src={EditFolder} alt="Edit folder" className="pointerClass" />
                    </span>
                  )}
                  {/* {node?.TypeId !== 100 && (
                    <span onClick={() => handleDelete(node.Id)} style={{ marginLeft: '10px' }}>
                      <img src={DeleteFolder} alt="Delete folder" className="pointerClass" />
                    </span>
                  )} */}
                </div>
              )}
            </div>
          )}
          {node.Children && renderTree(node.Children)}
        </TreeItem>
      </div>
    ));

  return (
    <>
      <TreeView
        aria-label="multi-select"
        defaultCollapseIcon={<img src={Arrow} alt="Edit folder" className={styles.topIconImage} />}
        defaultExpandIcon={<img src={Arrow} alt="Edit folder" className={styles.topIconImage} />}
        multiSelect
        className="mainTreeStructure"
      >
        {renderTree([treeDataList])}
      </TreeView>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />
    </>
  );
}
