/* eslint-disable no-plusplus */
import EndPoints from '../Routes/EndPoints';
import { Empty } from '../Screens/ScreenAddSmc/Utils/TypeSmc';
import { CUSTOMER_ID } from '../Screens/addCustomer/utils/constants';
import StorageUtils from './StorageUtils';

export const handleCustomerId = (path:string) => {
  if (!path?.includes(EndPoints.ADD_CUSTOMER)) {
    StorageUtils.removeString(CUSTOMER_ID);
  }
};

export function convertDateFormat(dateStr:string) {
  if (!dateStr) return dateStr;
  const [day, month, year] = dateStr.split('-');

  const newDateStr = `${month}-${day}-${year}`;
  return newDateStr;
}

export function convertDateFormatDDMMYYYY(dateStr:string) {
  if (!dateStr) return dateStr;
  const [month, day, year] = dateStr.split('-');

  const newDateStr = `${day}-${month}-${year}`;
  return newDateStr;
}

export const getItemIndex = (page: number, rowsPerPage: number, index: number) => (page - 1) * (rowsPerPage) + (index) + 1;

export const scrollToErrorField = (fieldId:string) => {
  const fieldRef = document.getElementById(fieldId);
  if (fieldRef) {
    fieldRef.scrollIntoView({ behavior: 'smooth' });
  }
};

export function capitalizeFirstLetter(inputString: string) {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export function getTotalDaysInMonth(year: number, month:number) {
  const date = new Date(year, month - 1, 1);

  date.setMonth(date.getMonth() + 1);
  date.setDate(date.getDate() - 1);

  return date.getDate();
}

export function generateRandomId(length = 8) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }

  return randomId;
}

export function convertDate(dateString: string) {
  const parts = dateString.split('-');
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return new Date(formattedDate);
}

export function convertText(originalText: string) {
  const convertedText = originalText?.toLowerCase()?.replace(/(?:^|\s)\S/g, (match) => match?.toUpperCase());
  return convertedText;
}

const removeHtmlTags = (input: Empty) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = input;
  return tempElement.textContent || tempElement.innerText || '';
};

export const processData = (data: Empty) => data?.map((item: Empty) => ({
  ...item,
  Description: removeHtmlTags(item?.Description),
}));

export function formatDateString(dateString: string) {
  if (!dateString) {
    return '';
  }
  const datePart = dateString.split('T')[0];

  const date = new Date(datePart);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date.getTime())) {
    return '';
  }

  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

export function convertDateFormatTo(dateStr:string) {
  if (!dateStr) return dateStr;
  const [day, month, year] = dateStr.split('-');

  const newDateStr = `${month}/${day}/${year}`;
  return newDateStr;
}

export function convertDateRangeToMMDDYYYY(dateRangeStr: string): string {
  const [startDate, endDate] = dateRangeStr.split(' TO ');
  const convertToMMDDYYYY = (dateStr: string): string => {
    const [day, month, year] = dateStr.split('-');
    return `${month}-${day}-${year}`;
  };
  const convertedStartDate = convertToMMDDYYYY(startDate);
  const convertedEndDate = convertToMMDDYYYY(endDate);
  return `${convertedStartDate} TO ${convertedEndDate}`;
}

export function convertDateInString(dateStr: string): string {
  const regex = /(\d{2})-(\d{2})-(\d{4})/;
  const match = dateStr.match(regex);

  if (match) {
    const [day, month, year] = match[0].split('-');
    const formattedDate = `${month}-${day}-${year}`;
    return dateStr.replace(regex, formattedDate);
  }
  return dateStr; // return original string if no date is found
}

export function RemoveHtmlTags(str: string) {
  return str?.replace(/<\/?p>/gi, '');
}

export const toRoman = (num: number): string => {
  const romanNumerals: [string, number][] = [
    ['M', 1000],
    ['CM', 900],
    ['D', 500],
    ['CD', 400],
    ['C', 100],
    ['XC', 90],
    ['L', 50],
    ['XL', 40],
    ['X', 10],
    ['IX', 9],
    ['V', 5],
    ['IV', 4],
    ['I', 1],
  ];

  let result = '';
  let remainingNum = num; // Create a local variable to hold the value of num

  romanNumerals.forEach(([roman, value]) => {
    while (remainingNum >= value) {
      result += roman;
      remainingNum -= value;
    }
  });

  return result;
};

export function stripCustPrefix(input: string, prefix = 'Cust-'): string {
  if (input?.startsWith(prefix)) {
    return input.slice(prefix?.length);
  }
  return input;
}

export function formatDate(dateStr: string) {
  if (!dateStr) {
    return dateStr;
  }
  // Split the input string by '/'
  const [month, day, year] = dateStr.split('/');

  // Return the formatted date in YYYY-MM-DD format
  return `${month}-${day}-${year}`;
}

export function gaugeToInch(gauge : string) {
  // Constant factors for steel gauge conversion
  const baseThickness = 0.005;
  const factor = 92;
  const offset = 36;
  const scale = 39;

  // Calculate thickness in inches using the exponentiation operator
  const thickness = Number(baseThickness * (factor ** ((offset - Number(gauge)) / scale)));
  return String(thickness.toFixed(3));
}
