import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  sendSmcDetailsData, successAddingSmc, errorAddingSmc, AddSmcActions, TypeSendSmcDetailsData,
  getSmcUserListStart, successGetSmcUserList, failureGetSmcUserList, getSmcCustomerListStart, successGetSmcCustomerList,
  failureGetSmcCustomerList, failureUnlinkSmcCustomer, successUnlinkSmcCustomer, unlinkSmcCustomer, failureUnlinkSmcUser,
  successUnlinkSmcUser, unlinkSmcUser, failureGetSmcSitesList, getSmcSitesListStart, successGetSmcSitesList, unlinkSmcSites,
  successUnlinkSmcSites, failureUnlinkSmcSites, failureUpdateSmc, successUpdateSmc, updateSmc, failureGetUserList, successGetUserList,
  getUserListStart,
  sendAddSmcUsersData,
  successAddSmcUsers,
  errorAddSmcUsers,
  failureGetSmcUserById,
  getSmcUserByIdStart,
  successGetSmcUserById,
} from './sliceAddSmc';
import Config from '../../../Common/Config';
import {
  makePostRequest, makeGetRequest, makeDeleteRequest, makePutRequest,
} from '../../../Common/NetworkOps';
import {
  AddSmcDetailsApiResponseTypes, AddSmcFormInput, AddSmcUserApiResponseType, GetAllSmcUserTabApiResponse, GetSmcCustomerApiResponse,
  GetSmcSiteApiResponse,
  GetSmcUserListByIdApiResponse,
  GetUserListApiResponse,
  PayloadTypeGetSmcCustomerList,
  PayloadTypeGetSmcSiteList,
  PayloadTypeGetSmcUserByIdList,
  PayloadTypeGetSmcUserList,
  PayloadTypeGetUserList,
  PayloadTypeUnlinkSmc,
  PayloadTypeUnlinkSmcSite,
  PayloadTypeUnlinkSmcUser,
  SmcUserTypeApiBody,
  UnlinkSmcCustomerApiResponse, UnlinkSmcSiteApiResponse, UnlinkSmcUsersApiResponse, UpdateSmcApiResponse, UpdateSmcPayload,
} from '../Utils/TypeSmc';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';

async function AddSmc(data: AddSmcFormInput): Promise<AddSmcDetailsApiResponseTypes> {
  const body = {
    SmcName: data.smcName,
    TaiIdNo: data.taiIdNo,
    CountryCode: data.country,
    PhoneCode: data.phoneCode,
    PhoneNumber: data.phoneNumber,
    Url: data.url,
    Address: data.address,
    StateCode: data.state,
    CityName: data.city === 'Other' ? data?.otherCity : data?.city,
    IsOtherCity: data?.city === 'Other',
    ZipCode: data.zip,
    AddressOptional: data.addressOptional,
    SMCCustomers: data.smcCustomers,
    SMCSites: data.smcSites,
  };
  const url = `${Config.addSmc.addSmcDetails}`;
  const result = await makePostRequest<AddSmcDetailsApiResponseTypes>(url, body);
  return result.data;
}

export const epicAddSmcDetails = (action$: Observable<AddSmcActions>) => action$.pipe(
  filter(sendSmcDetailsData.match),
  map((x: TypeSendSmcDetailsData) => x.payload),
  mergeMap((data: AddSmcFormInput) => from(AddSmc(data)).pipe(
    map((res: AddSmcDetailsApiResponseTypes) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return successAddingSmc(res.BMT.Result.SMCId); // new one
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return errorAddingSmc(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(sendSmcDetailsData.match))),
    catchError((error: AxiosError<AddSmcDetailsApiResponseTypes>) => of(errorAddingSmc(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

// ---------------------------------------------------> // SMC USERS TAB
async function GetSmcUserList(data: PayloadTypeGetSmcUserList): Promise<GetAllSmcUserTabApiResponse> {
  // eslint-disable-next-line max-len
  const url = `${Config.addSmc.getSmcUserList}?&pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}&smcId=${data.SmcId}`;
  const result = await makeGetRequest<GetAllSmcUserTabApiResponse>(url);
  return result?.data;
}

export const epicGetSmcTabUserList = (action$: Observable<AddSmcActions>) => action$.pipe(
  filter(getSmcUserListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetSmcUserList) => from(GetSmcUserList(data)).pipe(
    map((res: GetAllSmcUserTabApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetSmcUserList(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return failureGetSmcUserList(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getSmcUserListStart.match))),
    catchError((error: AxiosError<GetAllSmcUserTabApiResponse>) => of(failureGetSmcUserList(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

// -------------------------------------> // GET:-SMC Details TAB Customer Listing

async function GetSmcCustomerList(data: PayloadTypeGetSmcCustomerList): Promise<GetSmcCustomerApiResponse> {
  // eslint-disable-next-line max-len
  const url = `${Config.addSmc.getSmcCustomerList}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&smcId=${data.smcId}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetSmcCustomerApiResponse>(url);
  return result?.data;
}

export const epicGetSmcTabCustomerList = (action$: Observable<AddSmcActions>) => action$.pipe(
  filter(getSmcCustomerListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetSmcCustomerList) => from(GetSmcCustomerList(data)).pipe(
    map((res: GetSmcCustomerApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetSmcCustomerList(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return failureGetSmcCustomerList(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getSmcCustomerListStart.match))),
    catchError((error: AxiosError<GetSmcCustomerApiResponse>) => of(failureGetSmcCustomerList(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

// --------------------------------> // GET:-SMC Details TAB Sites Listing

async function GetSmcSitesList(data: PayloadTypeGetSmcSiteList): Promise<GetSmcSiteApiResponse> {
  // eslint-disable-next-line max-len
  const url = `${Config.addSmc.getSmcSitesList}?searchItem=${data.searchQuery}&pageNumber=${data.page}&pageSize=${data.rowsPerPage}&smcId=${data.smcId}`;
  const result = await makeGetRequest<GetSmcSiteApiResponse>(url);
  return result?.data;
}

export const epicGetSmcTabSitesList = (action$: Observable<AddSmcActions>) => action$.pipe(
  filter(getSmcSitesListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetSmcSiteList) => from(GetSmcSitesList(data)).pipe(
    map((res: GetSmcSiteApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetSmcSitesList(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return failureGetSmcSitesList(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getSmcSitesListStart.match))),
    catchError((error: AxiosError<GetSmcSiteApiResponse>) => of(failureGetSmcSitesList(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

// ------------------------------> // UNLINK:-SMC Details TAB Customer Listing
async function UnlinkSmcCustomer(data: PayloadTypeUnlinkSmc): Promise<UnlinkSmcCustomerApiResponse> {
  const url = `${Config.addSmc.unlinkSmcCustomerList}?&SmcId=${data.SmcId}&CustomerId=${data.CustomerId}`;
  const result = await makeDeleteRequest<UnlinkSmcCustomerApiResponse>(url);
  return result?.data;
}

export const epicUnlinkSmcCustomer = (action$: Observable<AddSmcActions>) => action$.pipe(
  filter(unlinkSmcCustomer.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeUnlinkSmc) => from(UnlinkSmcCustomer(data)).pipe(
    mergeMap((res: UnlinkSmcCustomerApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload: PayloadTypeGetSmcCustomerList = {
          page: data.page,
          searchQuery: data.searchQuery,
          rowsPerPage: data.rowsPerPage,
          smcId: data.SmcId,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(successUnlinkSmcCustomer(), getSmcCustomerListStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(failureUnlinkSmcCustomer(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(unlinkSmcCustomer.match))),
    catchError((error: AxiosError<UnlinkSmcCustomerApiResponse>) => of(failureUnlinkSmcCustomer(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

// -----------------------------> // UNLINK:-SMC Details TAB Site Listing

async function UnlinkSmcSite(data: PayloadTypeUnlinkSmcSite): Promise<UnlinkSmcSiteApiResponse> {
  const url = `${Config.addSmc.unlinkSmcSiteList}?&SmcId=${data.SmcId}&SiteId=${data.SiteId}`;
  const result = await makeDeleteRequest<UnlinkSmcSiteApiResponse>(url);
  return result?.data;
}

export const epicUnlinkSmcSite = (action$: Observable<AddSmcActions>) => action$.pipe(
  filter(unlinkSmcSites.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeUnlinkSmcSite) => from(UnlinkSmcSite(data)).pipe(
    mergeMap((res: UnlinkSmcSiteApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload: PayloadTypeGetSmcCustomerList = {
          page: data.page,
          searchQuery: data.searchQuery,
          rowsPerPage: data.rowsPerPage,
          smcId: data.SmcId,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(successUnlinkSmcSites(), getSmcSitesListStart(payload));
      }
      return of(failureUnlinkSmcSites(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(unlinkSmcSites.match))),
    catchError((error: AxiosError<UnlinkSmcSiteApiResponse>) => of(failureUnlinkSmcSites(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

// ---------------------------------> // UNLINK:-SMC USER TAB Customer Listing

async function UnlinkSmcUser(data: PayloadTypeUnlinkSmcUser): Promise<UnlinkSmcUsersApiResponse> {
  const url = `${Config.addSmc.unlinkSmcUserList}?SmcId=${data.SmcId}&SmcUserId=${data.SmcUserId}`;
  const result = await makeDeleteRequest<UnlinkSmcUsersApiResponse>(url);
  return result?.data;
}

export const epicUnlinkSmcUser = (action$: Observable<AddSmcActions>) => action$.pipe(
  filter(unlinkSmcUser.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeUnlinkSmcUser) => from(UnlinkSmcUser(data)).pipe(
    mergeMap((res: UnlinkSmcUsersApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload = {
          page: data?.page,
          rowsPerPage: data?.rowsPerPage,
          searchQuery: data?.searchQuery,
          SmcId: data?.SmcId,
        };
        return of(successUnlinkSmcUser(), getSmcUserListStart(payload));
      }
      return of(failureUnlinkSmcUser(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(unlinkSmcUser.match))),
    catchError((error: AxiosError<UnlinkSmcUsersApiResponse>) => of(failureUnlinkSmcUser(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

// ---------------------------------> // GET:-SMC Users TAB add users Listing

async function GetUsersList(data: PayloadTypeGetUserList): Promise<GetUserListApiResponse> {
  // eslint-disable-next-line max-len
  const url = `${Config.addSmc.getUserList}?&pageNumber=${data.page}&pageSize=${data.rowsPerPage}&SearchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetUserListApiResponse>(url);
  return result?.data;
}

export const epicGetUsersList = (action$: Observable<AddSmcActions>) => action$.pipe(
  filter(getUserListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetUserList) => from(GetUsersList(data)).pipe(
    map((res: GetUserListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetUserList(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return failureGetUserList(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getUserListStart.match))),
    catchError((error: AxiosError<GetUserListApiResponse>) => of(failureGetUserList(error.response?.data.BMT.ResponseMessage as string))),
  )),
);
// ----------------------------------------------->> Post Api ADD Smc Users

async function AddSmcUser(data: SmcUserTypeApiBody): Promise<AddSmcUserApiResponseType> {
  const body = {
    SmcUserId: data.SmcUserId,
    SmcId: data.SmcId,
    LoginUserId: data.LoginUserId,
  };
  const url = `${Config.addSmc.addSmcUser}`;
  const result = await makePostRequest<AddSmcUserApiResponseType>(url, body);
  return result.data;
}

export const epicAddSmcUserDetails = (action$: Observable<AddSmcActions>) => action$.pipe(
  filter(sendAddSmcUsersData.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: SmcUserTypeApiBody) => from(AddSmcUser(data)).pipe(
    mergeMap((res: AddSmcUserApiResponseType) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload = {
          page: data?.page,
          rowsPerPage: data?.rowsPerPage,
          searchQuery: data?.searchQuery,
          SmcId: data?.SmcId,
        };
        return of(successAddSmcUsers(), getSmcUserListStart(payload)); // new one
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(errorAddSmcUsers(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(sendAddSmcUsersData.match))),
    catchError((error: AxiosError<AddSmcUserApiResponseType>) => of(errorAddSmcUsers(error.response?.data.BMT.ResponseMessage as string))),
  )),
);
// -----------------------------------> Get SmcUserList By Id

async function GetSmcUserByIdList(data: PayloadTypeGetSmcUserByIdList): Promise<GetSmcUserListByIdApiResponse> {
  // eslint-disable-next-line max-len
  const url = `${Config.addSmc.getSmcUserById}/${data.smcId}`;
  const result = await makeGetRequest<GetSmcUserListByIdApiResponse>(url);
  return result?.data;
}

export const epicGetSmcTabUserListById = (action$: Observable<AddSmcActions>) => action$.pipe(
  filter(getSmcUserByIdStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetSmcUserByIdList) => from(GetSmcUserByIdList(data)).pipe(
    map((res: GetSmcUserListByIdApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetSmcUserById(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return failureGetSmcUserById(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getSmcUserByIdStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<GetSmcUserListByIdApiResponse>) => of(failureGetSmcUserById(error.response?.data.BMT.ResponseMessage as string))),
  )),
);
// ----------------------------------------------> Update Smc
async function UpdateSmc(data: UpdateSmcPayload): Promise<UpdateSmcApiResponse> {
  const url = `${Config.addSmc.updateSmc}`;
  const result = await makePutRequest<UpdateSmcApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateSmc = (action$: Observable<AddSmcActions>) => action$.pipe(
  filter(updateSmc.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: UpdateSmcPayload) => from(UpdateSmc(data)).pipe(
    map((res: UpdateSmcApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT?.ResponseMessage);
        return successUpdateSmc();
      }
      showErrorToaster(res.BMT?.ResponseMessage);
      return failureUpdateSmc(res.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(updateSmc.match))),
    catchError((error: AxiosError<UpdateSmcApiResponse>) => of(failureUpdateSmc(error.response?.data.BMT?.ResponseMessage as string))),
  )),
);
