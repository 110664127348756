import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  GetReportPdfPayload, PdfColorProps, ReportPdfInitialState, ReportPdfResponse1, ReportPdfResponse2,
  ReportPdfResponse3,
  ReportPdfResponse4,
} from '../types';

const initialState: ReportPdfInitialState = {
  isLoading: false,
  reportPdfResponse1: {} as ReportPdfResponse1,
  reportPdfResponse2: {} as ReportPdfResponse2,
  reportPdfResponse3: {} as ReportPdfResponse3,
  reportPdfResponse4: {} as ReportPdfResponse4,
  checkedSections: [],
  themePdfColor: {
    BorderColor: '#4A73FF',
    MainColor: '#C0FBAC',
  },
  tableContent: [] as string[],
};

export const reportpdfSlice = createSlice({
  name: 'reportPdf',
  initialState,
  reducers: {

    setCheckedSections(state, action:PayloadAction<string[]>) {
      return {
        ...state,
        checkedSections: action.payload,
      };
    },

    resetReportPdfState() {
      return initialState;
    },

    getReportPdf1Start(state, _action:PayloadAction<GetReportPdfPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getReportPdf1Success(state, action:PayloadAction<ReportPdfResponse1>) {
      return {
        ...state,
        isLoading: false,
        reportPdfResponse1: action.payload,
      };
    },

    getReportPdf1Failure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getReportPdf2Start(state, _action:PayloadAction<GetReportPdfPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getReportPdf2Success(state, action:PayloadAction<ReportPdfResponse2>) {
      return {
        ...state,
        isLoading: false,
        reportPdfResponse2: action.payload,
      };
    },

    getReportPdf2Failure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getReportPdf3Start(state, _action:PayloadAction<GetReportPdfPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getReportPdf3Success(state, action:PayloadAction<ReportPdfResponse3>) {
      return {
        ...state,
        isLoading: false,
        reportPdfResponse3: action.payload,
      };
    },

    getReportPdf3Failure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getReportPdf4Start(state, _action:PayloadAction<GetReportPdfPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getReportPdf4Success(state, action:PayloadAction<ReportPdfResponse4>) {
      return {
        ...state,
        isLoading: false,
        reportPdfResponse4: action.payload,
      };
    },

    getReportPdf4Failure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    setThemePdfColor(state, action:PayloadAction<PdfColorProps>) {
      return {
        ...state,
        // BorderColor: action.payload.BorderColor,
        // MainColor: action.payload.MainColor,
        themePdfColor: action.payload,
      };
    },
    setTableContent(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        tableContent: action.payload,
      };
    },
  },
});

export const {
  resetReportPdfState, getReportPdf1Start, getReportPdf1Success,
  getReportPdf1Failure, getReportPdf2Start, getReportPdf2Success, getReportPdf2Failure,
  getReportPdf3Start, getReportPdf3Success, getReportPdf3Failure, getReportPdf4Start,
  getReportPdf4Success, getReportPdf4Failure, setCheckedSections, setThemePdfColor,
  setTableContent,
} = reportpdfSlice.actions;
export const reportPdfSliceReducer = reportpdfSlice.reducer;

export type ReportPdfActions =
  | ReturnType<typeof resetReportPdfState>
  | ReturnType<typeof getReportPdf1Start>
  | ReturnType<typeof getReportPdf1Success>
  | ReturnType<typeof getReportPdf1Failure>
  | ReturnType<typeof getReportPdf2Start>
  | ReturnType<typeof getReportPdf2Success>
  | ReturnType<typeof getReportPdf2Failure>
  | ReturnType<typeof getReportPdf3Start>
  | ReturnType<typeof getReportPdf3Success>
  | ReturnType<typeof getReportPdf3Failure>
  | ReturnType<typeof getReportPdf4Start>
  | ReturnType<typeof getReportPdf4Success>
  | ReturnType<typeof getReportPdf4Failure>
  | ReturnType<typeof setCheckedSections>
  | ReturnType<typeof setThemePdfColor>
  | ReturnType<typeof setTableContent>;
