import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CoOrdinates,
  GetTubeChartPayload,
  GridType,
  TubeChartConfiguration,
  TubeChartDefects,
  TubeChartDefectsPayload,
  TubeChartInitialValues,
  TubeConfiguration,
  UpdateTubeConfiguration,
  VanishType,
} from '../../utils/tubeChartTypes';
import { MasterData } from '../../../../utils/type';

const initialState:TubeChartInitialValues = {
  isLoading: false,
  tubeCharts: [],
  vesselId: '',
  jobOrderId: '',
  gridType: '' as GridType,
  vanishType: VanishType.Left,
  relativeCord: { x: 0, y: 0 },
  chartList: [],
  sectionValues: [],
  selectedSection: '0',
  scale: 1,
  tubeChartDefects: [],
  selectedChartId: '',
};

const TubeChartSlice = createSlice({
  name: 'tubeChartSlice',
  initialState,
  reducers: {

    setSelectedSection(state, action:PayloadAction<string>) {
      return {
        ...state,
        selectedSection: action.payload,
      };
    },

    setScale(state, action:PayloadAction<number>) {
      return {
        ...state,
        scale: action.payload,
      };
    },

    setSectionValues(state, action:PayloadAction<MasterData[]>) {
      return {
        ...state,
        sectionValues: action.payload,
      };
    },

    setRelativeCord(state, action:PayloadAction<CoOrdinates>) {
      return {
        ...state,
        relativeCord: action.payload,
      };
    },

    setGridType(state, action:PayloadAction<GridType>) {
      return {
        ...state,
        gridType: action.payload,
      };
    },

    setVanishType(state, action:PayloadAction<VanishType>) {
      return {
        ...state,
        vanishType: action.payload,
      };
    },

    getTubeChartsStart(state, action:PayloadAction<GetTubeChartPayload>) {
      return {
        ...state,
        isLoading: true,
        vesselId: action.payload.VesselId,
        jobOrderId: action.payload.JobOrder,
      };
    },

    getTubeChartsSuccess(state, action:PayloadAction<TubeChartConfiguration[]>) {
      return {
        ...state,
        isLoading: false,
        tubeCharts: action.payload,
      };
    },

    getChartListSuccess(state, action:PayloadAction<TubeConfiguration[]>) {
      return {
        ...state,
        isLoading: false,
        chartList: action.payload,
      };
    },

    getTubeChartsFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getTubeChartByIdStart(state, _action:PayloadAction<string>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getTubeChartByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getTubeChartDefectsStart(state, _action:PayloadAction<TubeChartDefectsPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getTubeChartDefectsSuccess(state, action:PayloadAction<TubeChartDefects[]>) {
      return {
        ...state,
        isLoading: false,
        tubeChartDefects: action.payload,
      };
    },

    getTubeChartDefectsFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    updateTubeChartStart(state, _action:PayloadAction<UpdateTubeConfiguration>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    updateTubeChartSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    updateTubeChartFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateTubeChartScreenShotStart(state, _action:PayloadAction<UpdateTubeConfiguration>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    updateTubeChartScreenShotSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    updateTubeChartScreenShotFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getTubeChartsStart, getTubeChartsFailure, getTubeChartsSuccess, getTubeChartByIdFailure,
  getTubeChartByIdStart, updateTubeChartStart, updateTubeChartSuccess,
  updateTubeChartFailure, setGridType, setVanishType, setRelativeCord, getChartListSuccess,
  setSectionValues, setSelectedSection, setScale, getTubeChartDefectsStart, getTubeChartDefectsFailure, getTubeChartDefectsSuccess,
  updateTubeChartScreenShotStart, updateTubeChartScreenShotSuccess, updateTubeChartScreenShotFailure,
} = TubeChartSlice.actions;

export const TubeChartReducer = TubeChartSlice.reducer;

export type TubeChartActions =
| ReturnType<typeof getTubeChartsStart>
| ReturnType<typeof getTubeChartsFailure>
| ReturnType<typeof getTubeChartsSuccess>
| ReturnType<typeof getTubeChartByIdFailure>
| ReturnType<typeof getTubeChartByIdStart>
| ReturnType<typeof updateTubeChartStart>
| ReturnType<typeof updateTubeChartSuccess>
| ReturnType<typeof updateTubeChartFailure>
| ReturnType<typeof setGridType>
| ReturnType<typeof setVanishType>
| ReturnType<typeof setRelativeCord>
| ReturnType<typeof getChartListSuccess>
| ReturnType<typeof setSectionValues>
| ReturnType<typeof setSelectedSection>
| ReturnType<typeof setScale>
| ReturnType<typeof getTubeChartDefectsStart>
| ReturnType<typeof getTubeChartDefectsFailure>
| ReturnType<typeof getTubeChartDefectsSuccess>
| ReturnType<typeof updateTubeChartScreenShotStart>
| ReturnType<typeof updateTubeChartScreenShotSuccess>
| ReturnType<typeof updateTubeChartScreenShotFailure>;
