// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Empty = any;
export interface FreonMainFormValues {
  customerSearch: string;
  customer: string;
  contact: string;
  shipDate: Empty;
  shipVia: string;
  shipAddress: string;
  customerAddressOptional: string;
  customerAddress: string;
  blueCylinders: string;
  cylindersPrice: number;
  additionalAmount: number;
  billAmount: number;
  invoice: string;
  invoiceDate: Empty;
  PO: string;
}

export enum EnumAddFreonForm {
  customerSearch = 'customerSearch',
  customer = 'customer',
  contact = 'contact',
  shipDate = 'shipDate',
  shipVia = 'shipVia',
  shipAddress = 'shipAddress',
  blueCylinders = 'blueCylinders',
  cylindersPrice = 'cylindersPrice',
  additionalAmount = 'additionalAmount',
  billAmount = 'billAmount',
  invoice = 'invoice',
  invoiceDate = 'invoiceDate',
  PO = 'PO',
  customerAddressOptional = 'customerAddressOptional',
  customerAddress = 'customerAddress',
}

export interface UpdateFreonApiResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: null;
  }
}

export interface BMTResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: {
      UnitPrice: number;
    };
  };
}

export interface PayloadTypes {
  type: number;
  KitId: string,
  CustId: string;
  PurchaseOrderNumber: string;
  ContactNumber: string;
  ShipDate: string;
  ShipVia: string;
  ShippingAddress: string;
  NumberOfKits: number;
  PerUnitPrice: number;
  AdditionalAmount: number;
  TotalAmount: number;
  InVoiceNumber: string;
  InvoiceDate: string;
}

export interface PayloadAddFreonCylinderTypes {
  type: number;
  // KitId: string,
  CustId: string;
  PurchaseOrderNumber: string;
  ContactNumber: string;
  ShipDate: string;
  ShipVia: string;
  ShippingAddress: string;
  NumberOfKits: number;
  PerUnitPrice: number;
  AdditionalAmount: number;
  TotalAmount: number;
  InVoiceNumber: string;
  InvoiceDate: string;
}

export interface InitialStateTypes {
  freonCylinderData: PayloadAddFreonCylinderTypes;
  isLoading: boolean;
  responseMessage: string;
  unitPriceInitial: number;
  isSuccess: boolean;
}

export interface FreonDetails {
  type: number;
  KitId: string;
  CustId: string;
  PurchaseOrderNumber: string;
  ContactNumber: string;
  ShipDate: string;
  ShipVia: string;
  NumberOfKits: number;
  PerUnitPrice: number;
  AdditionalAmount: number;
  TotalAmount: number;
  InVoiceNumber: string;
  InvoiceDate: string;
}

export interface EditFreonResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: null;
  }
}

export interface GetFreonByIdPayload {
  KitId: string;
}
