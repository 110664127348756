/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { ComponentSelctInput, Entries } from './types';

function ComponentSelectInputInspectedBy({
  control, name, defaultValue, size, entries, errors, required, className, disabled, handleChange, showColor = false,
}: ComponentSelctInput) {
  const error = errors?.[name]?.message;

  const handleOnChange = (onChange: (...event: any[]) => void, value: string) => {
    onChange(value);
    if (handleChange) {
      handleChange(value);
    }
  };

  const showColorsWithSelectInput = (color: string | undefined, text: string) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{
        height: '10px',
        width: '10px',
        borderRadius: '5px',
        backgroundColor: color,
        marginRight: '18px',
      }}
      />
      {text}
    </div>
  );
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, formState }) => (
        <>
          <Select
            className={`${className} ${disabled ? 'disableSelect' : ''}`}
            size={size as 'small' | 'medium' | undefined}
            value={field.value}
            id={name}
            disabled={disabled}
            fullWidth
            onChange={(event: SelectChangeEvent<string | number>) => handleOnChange(field.onChange, String(event.target.value))}
            error={Boolean(formState?.errors[name])}
            required={required}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '220px',
                  maxWidth: '220px',
                },
              },
            }}
          >
            {entries.length > 0 && entries?.map((item: Entries) => (
              <MenuItem style={{ display: 'flex', alignItems: 'center' }} className="menu-item-enter" value={item?.Text} key={item?.Text}>
                {showColor ? showColorsWithSelectInput(item?.ColorCode, item?.Text?.toString()) : item?.Text}
              </MenuItem>
            ))}
          </Select>
          {error && <span className="errorMessage">{String(error)}</span>}
        </>
      )}
    />
  );
}

export default ComponentSelectInputInspectedBy;
