import React from 'react';
import { useSelector } from 'react-redux';
import { ComponentStripChartProps } from '../types';
// import phaseChart from '../../../assets/phase-chart.svg';
// import StripChartTable from './stripChartTable';
import { RootState } from '../../../redux/rootState';

function ComponentStripsChart({ newDetails: newDeta̵ils, showSn }: ComponentStripChartProps) {
  const { themePdfColor, reportPdfResponse1 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor } = themePdfColor;
  // const CalibrationStripChartData = reportPdfResponse3?.Calibration_Strip_Charts;
  return (
    <div className="p30">
      <div className="pageHeader">
        {showSn && (
        <div className="topHeader">
          <div>Strip Chart</div>
          <div />
          <div>{`S/N ${reportPdfResponse1?.JobOrderData?.SerialNumber}`}</div>
        </div>
        )}
      </div>
      <div className="bigHeadingText textCenter">
        {newDeta̵ils?.VesselName}
      </div>
      <div
        className="phaseChartImageContainer"
        style={{
          borderColor: BorderColor,
        }}
      >
        {newDeta̵ils?.ImageConfig ? (
          <img src={newDeta̵ils.ImageConfig} alt="Strip chart" />
        ) : (
          <div />
        )}
      </div>
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '7px',
      }}
      >
        {
        newDeta̵ils.Row === 0
          ? 'Calibration Strip Chart'
          : `${newDeta̵ils.Title} (Row ${newDeta̵ils.Row} Tube ${newDeta̵ils.Tube})`
        }
      </div>
    </div>
  );
}

export default ComponentStripsChart;
