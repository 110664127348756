/* eslint-disable max-len */
import React from 'react';

export function EmailIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.71875" y="0.796387" width="20.4815" height="20.4815" stroke="#00ADF6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.375 7.78611L9.45839 12.5178C10.0711 12.9941 10.9289 12.9941 11.5416 12.5178L17.625 7.78611V14.5715C17.625 14.8416 17.5178 15.1004 17.3268 15.2911C17.1361 15.4821 16.8772 15.5893 16.6071 15.5893C13.7683 15.5893 7.23166 15.5893 4.39286 15.5893C4.12278 15.5893 3.86391 15.4821 3.67323 15.2911C3.48221 15.1004 3.375 14.8416 3.375 14.5715L3.375 7.78611Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.625 6.43575C17.623 6.74144 17.4828 7.03696 17.2321 7.23205L11.125 11.9821C10.7572 12.2681 10.2428 12.2681 9.87503 11.9821L3.76789 7.23205C3.51716 7.03696 3.37703 6.74144 3.375 6.43575V6.42862C3.375 6.15855 3.48221 5.89968 3.67323 5.709C3.86391 5.51798 4.12278 5.41077 4.39286 5.41077H16.6071C16.8772 5.41077 17.1361 5.51798 17.3268 5.709C17.5178 5.89968 17.625 6.15855 17.625 6.42862V6.43575Z" fill="white" />
    </svg>
  );
}
