import { Grid, Paper, TextField } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { htmlToText } from 'html-to-text';
import ArrowIcon from '../../../../assets/ArrowIcon.svg';
import RightArrowIcon from '../../../../assets/RightArrowIcon.svg';
import DataTable from '../../../../Common/DataTable';
import styles from '../../utils/style.module.scss';
import { InspectionData, SetRecomTextPayload } from '../../utils/types';
import { RootState } from '../../../../redux/rootState';
import { setRecomText } from '../../redux/recommendationSlice';
import { FollowForm } from '../../utils/constants';

export default function RecomDetails() {
  const [isGridVisible, setIsGridVisible] = useState(true);
  const { selectedRecm, recomText, inspectionIndicationData } = useSelector((state: RootState) => state.recommendationReducer);
  const dispatch = useDispatch();

  const data: InspectionData[] = useMemo(() => inspectionIndicationData?.SummaryList?.map((item, index) => {
    const transformedDataItem = {
      vessel: item?.VesselName,
      indication: item.DefectType,
      tubes: Number(item.Tubes),
      // percentage: String(item?.Pct),
      sn: index,
      id: item?.JobOrder,
    };
    return transformedDataItem;
  }), [inspectionIndicationData]);

  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'vessel', label: 'Vessel' },
      { key: 'indication', label: 'Indication' },
      { key: 'tubes', label: 'Tubes' },
      // { key: 'percentage', label: '%' },
    ],
    [],
  );

  const toggleGridVisibility = () => {
    setIsGridVisible(!isGridVisible);
  };

  const handleRecomTextChange = (value: string) => {
    const payload: SetRecomTextPayload = {
      value,
      replaceText: true,
      preAdd: false,
    };
    dispatch(setRecomText(payload));
  };

  return (
    <Grid container spacing={1} style={{ padding: '16px 0px' }}>
      {isGridVisible && (
        <Grid item xs={12} md={isGridVisible ? 6 : 12} className={isGridVisible ? styles.gridVisible : styles.gridHidden}>
          <Paper elevation={0}>
            <div className={styles.InspectionDiv}>
              Inspection Indications
            </div>
            <DataTable<InspectionData>
              data={data}
              totalRecords={data?.length || 0}
              columns={columns}
              handleData={() => { }}
              customClassName="recommendationsTableStyle"
            />
            <div className={styles.InspectionText}>
              <TextField
                name={FollowForm.inspectionField}
                id={FollowForm.inspectionField}
                value={htmlToText(selectedRecm?.InspectionSummary?.[0]?.AdditionalInfo || '')}
                disabled
                multiline
                fullWidth
                rows={12}
              />
            </div>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12} md={isGridVisible ? 6 : 12} className={styles.gridVisible}>
        <Paper elevation={0} style={{ backgroundColor: '#f3f3f9' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={styles.ArrowDiv} onClick={toggleGridVisibility}>
              <img src={isGridVisible ? ArrowIcon : RightArrowIcon} alt="arrowIcon" />
            </div>
            <TextField
              className={styles.RecommendationText}
              name={FollowForm.recommendations}
              id={FollowForm.recommendations}
              rows={29}
              inputProps={{ maxLength: 6000 }}
              value={recomText}
              onChange={(e) => handleRecomTextChange(e.target.value)}
              multiline
              fullWidth
            />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
