import { DefectComparison } from './types';

/* eslint-disable no-nested-ternary */
export function reportHeading(value:string) {
  switch (value) {
    case 'ECT':
      return 'Eddy Current Testing';
    case 'FLT':
      return 'Flux Leakage Inspection';
    case 'ADT':
      return 'Eddy Current Testing';
    default:
      return '';
  }
}

// export enum CombineReportEnum {
//   COMBINE_HEADER_WITH_LOGO = 'Header With Logo (from 1st Job)',
//   COMBINE_HEADER_WITHOUT_LOGO = 'Header w/o Logo (from 1st Job)',
//   COMBINE_TABLE_OF_CONTENTS = 'Table of Contents',
//   COMBINE_VESSEL_INFORMATION = 'Vessel Information (from all Jobs)',
//   COMBINE_DEFECT_GRAPH = 'Defect Graph (from all Jobs)',
//   COMBINE_SUMMARY_OF_INSPECTION = 'Summary of Inspection (from all Jobs)',
//   COMBINE_RECOMMENDATIONS = 'Recommendations (from 1st Job)',
//   COMBINE_DATA_SHEETS = 'Data Sheets (from all Jobs)',
//   COMBINE_TUBE_CHARTS = 'Tube Charts (from all Jobs)',
//   COMBINE_PHOTOS = 'Photos (from all Jobs)',
//   COMBINE_STRIP_CHARTS = 'Strip Charts (from all Jobs)',
//   COMBINE_PHASE_CHARTS = 'Phase Charts (from all Jobs)',
//   COMBINE_CALIBRATION_PROCEDURE = 'Calibration Procedure (from 1st Job)',
//   COMBINE_EXPLANATION_OF_ABBREVIATIONS = 'Explanation Of Abbreviations',
//   COMBINE_INSPECTION_PROCEDURE = 'Inspection Procedure',
//   COMBINE_HAZARD_ANALYSIS = 'Hazard Analysis (from 1st Job)',
//   COMBINE_EXPENSE_REPORT = 'Expense Report (from all Jobs)',
// }

// export enum PDFReportEnum {
//   PDF_HEADER_WITH_LOGO = 'Header With Logo',
//   PDF_HEADER_WITHOUT_LOGO = 'Header w/o Logo',
//   PDF_TABLE_OF_CONTENTS = 'Table of Contents',
//   PDF_VESSEL_INFORMATION = 'Vessel Information',
//   PDF_DEFECT_GRAPH = 'Defect Graph',
//   PDF_SUMMARY_OF_INSPECTION = 'Summary of Inspection',
//   PDF_RECOMMENDATIONS = 'Recommendations',
//   PDF_DATA_SHEETS = 'Data Sheets',
//   PDF_TUBE_CHARTS = 'Tube Charts',
//   PDF_PHOTOS = 'Photos',
//   PDF_STRIP_CHARTS = 'Strip Charts',
//   PDF_PHASE_CHARTS = 'Phase Charts',
//   PDF_CALIBRATION_PROCEDURE = 'Calibration Procedure',
//   PDF_EXPLANATION_OF_ABBREVIATIONS = 'Explanation Of Abbreviations',
//   PDF_INSPECTION_PROCEDURE = 'Inspection Procedure',
//   PDF_HAZARD_ANALYSIS = 'Hazard Analysis',
//   PDF_EXPENSE_REPORT = 'Expense Report',
// }

export enum CombineReportEnum {
  COMBINE_HEADER_WITH_LOGO = 'Header With Logo (from 1st Job)',
  COMBINE_HEADER_WITHOUT_LOGO = 'Header w/o Logo (from 1st Job)',
  COMBINE_TABLE_OF_CONTENTS = 'Table of Contents',
  COMBINE_Job_Order = 'Job Order',
  COMBINE_VESSEL_INFORMATION = 'Vessel Information (from all Jobs)',
  COMBINE_SUPPORT_BAY_LENGTH = 'Support Bay Length',
  COMBINE_DEFECT_GRAPH = 'Defect Graph (from all Jobs)',
  COMBINE_SUMMARY_OF_INSPECTION = 'Summary of Inspection (from all Jobs)',
  COMBINE_RECOMMENDATIONS = 'Recommendations (from 1st Job)',
  COMBINE_DATA_SHEETS = 'Data Sheets (from all Jobs)',
  COMBINE_TUBE_CHARTS = 'Tube Charts (from all Jobs)',
  COMBINE_PHOTOS = 'Photos (from all Jobs)',
  COMBINE_STRIP_CHARTS = 'Strip Charts (from all Jobs)',
  COMBINE_PHASE_CHARTS = 'Phase Charts (from all Jobs)',
  COMBINE_CALIBRATION_PROCEDURE = 'Calibration Procedure (from 1st Job)',
  COMBINE_EXPLANATION_OF_ABBREVIATIONS = 'Explanation Of Abbreviations',
  COMBINE_INSPECTION_PROCEDURE = 'Inspection Procedure',
  COMBINE_HAZARD_ANALYSIS = 'Hazard Analysis (from 1st Job)',
  COMBINE_EXPENSE_REPORT = 'Expense Report (from all Jobs)',
  // COMBINE_SUPPORT_BAY_LENGTH = 'Support Bay Length',
}

export enum PDFReportEnum {
  PDF_HEADER_WITH_LOGO = 'Header With Logo',
  PDF_HEADER_WITHOUT_LOGO = 'Header w/o Logo',
  PDF_TABLE_OF_CONTENTS = 'Table of Contents',
  PDF_Job_Order = 'Job Order',
  PDF_VESSEL_INFORMATION = 'Vessel Information',
  PDF_SUPPORT_BAY_LENGTH = 'Support Bay Length',
  PDF_DEFECT_GRAPH = 'Defect Graph',
  PDF_SUMMARY_OF_INSPECTION = 'Summary of Inspection',
  PDF_RECOMMENDATIONS = 'Recommendations',
  PDF_DATA_SHEETS = 'Data Sheets',
  PDF_TUBE_CHARTS = 'Tube Charts',
  PDF_PHOTOS = 'Photos',
  PDF_STRIP_CHARTS = 'Strip Charts',
  PDF_PHASE_CHARTS = 'Phase Charts',
  PDF_CALIBRATION_PROCEDURE = 'Calibration Procedure',
  PDF_EXPLANATION_OF_ABBREVIATIONS = 'Explanation Of Abbreviations',
  PDF_INSPECTION_PROCEDURE = 'Inspection Procedure',
  PDF_HAZARD_ANALYSIS = 'Hazard Analysis',
  PDF_EXPENSE_REPORT = 'Expense Report',
  // PDF_SUPPORT_BAY_LENGTH = 'Support Bay Length',
}

interface PartProps {
  tableContent: string[];
  combineKey: string;
  pdfKey: string;
  title: string;
}

export function ReportPart({
  tableContent, combineKey, pdfKey, title,
}: PartProps): string {
  const partIndex = tableContent.includes(combineKey)
    ? tableContent.indexOf(combineKey)
    : tableContent.indexOf(pdfKey);

  return `Part- ${partIndex} ${title}`;
}

export function ReportPart1(
  tableContent: string[],
  combineKey: string,
  pdfKey: string,
  title: string,
): string {
  const partIndex = tableContent.includes(combineKey)
    ? tableContent.indexOf(combineKey)
    : tableContent.includes(pdfKey)
      ? tableContent.indexOf(pdfKey)
      : -1; // Fallback if neither key is found

  return partIndex !== -1
    ? `Part - ${partIndex} ${title}` // 1-based index
    : `${title}`; // Fallback message
}

// // Function to generate random hex color
// export function getRandomColor() {
//   const letters = '0123456789ABCDEF';
//   let color = '#';
//   for (let i = 0; i < 6; i += 1) {
//     color += letters[Math.floor(Math.random() * 16)];
//   }
//   return color;
// }

const STATIC_COLORS = [
  '#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#A133FF', '#FF8F33',
  '#33FFF7', '#FF3333', '#33FF8F', '#FF3381', '#8F33FF', '#FFC133',
  '#33C1FF', '#FF8F33',
];

export function addRandomColorToData(data: DefectComparison[]) {
  return data.map((item, index) => ({
    ...item,
    // colorHex: getRandomColor(),
    colorHex: STATIC_COLORS[index % STATIC_COLORS.length],
  }));
}

export const getBarSize = (chunkLength: number) => {
  const maxBarWidth = 65; // Maximum desired width for a bar
  const availableWidth = 700; // Width of the chart
  const minBarWidth = 15; // Minimum width for a bar

  // Calculate the size based on available width divided by chunk length
  const calculatedBarSize = Math.max(Math.floor(availableWidth / chunkLength), minBarWidth);

  return Math.min(calculatedBarSize, maxBarWidth); // Ensure it doesn't exceed maxBarWidth
};
