import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  AccommodationTableData,
  JobScheduleFormInfo,
  JobScheduleListData,
  JobsAndSiteData,
  JobsAndSiteTableData,
  JobsScheduleTableData,
  TravelListData,
} from './types';
import { Empty } from '../../../ScreenAddSmc/Utils/TypeSmc';
import { stripCustPrefix } from '../../../../utils/CommonFunctions';

export function formatJobsData(data: JobScheduleListData[]) {
  const res: JobsScheduleTableData[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: JobScheduleListData, index) => {
    const obj = {
      sn: index,
      action: 'schedule',
      jobId: '',
      custId: '',
      customer: '',
      site: '',
      bidName: '',
      city: '',
      id: '',
      serialToolTip: [''],
      modelToolTip: [''],
      bidId: '',
    };
    obj.id = col.BidId;
    obj.jobId = col.JobId;
    obj.custId = stripCustPrefix(col.CustId);
    obj.customer = col.CustomerName;
    obj.site = col.SiteName;
    obj.city = col.CityName;
    obj.bidName = col.BidName;
    obj.serialToolTip = col.SerialNumber;
    obj.modelToolTip = col.Model;
    obj.bidId = col.BidId;

    res.push(obj);
  });

  return res;
}

export function formatJobsDataXl(data: JobScheduleListData[]) {
  const res: Empty = [];

  if (data?.length === 0) return [];

  data?.forEach((col: JobScheduleListData) => {
    const obj = {
      JobId: '',
      CustId: '',
      Customer: '',
      Site: '',
      BidName: '',
      city: '',
      SerialNumber: [''],
      Model: [''],
      BidId: '',
    };
    obj.JobId = col.JobId;
    obj.CustId = stripCustPrefix(col.CustId);
    obj.Customer = col.CustomerName;
    obj.Site = col.SiteName;
    obj.city = col.CityName;
    obj.BidName = col.BidName;
    obj.SerialNumber = col.SerialNumber;
    obj.Model = col.Model;
    obj.BidId = col.BidId;

    res.push(obj);
  });

  return res;
}

export function accommodationJobsData(data: TravelListData[]) {
  const res: AccommodationTableData[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: TravelListData, index) => {
    const obj = {
      sn: index,
      action: 'edit&delete',
      accommodation: '',
      dateIn: '',
      dateOut: '',
      city: '',
      phone: '',
      confirmation: '',
      id: 0,
    };

    obj.accommodation = col.HotelName;
    obj.dateIn = col.DateIn;
    obj.dateOut = col.DateOut;
    obj.city = col.CityName;
    obj.phone = col.PhoneNumber;
    obj.confirmation = col.ConfirmationCode || '';
    obj.id = col.HotelId;

    res.push(obj);
  });

  return res;
}

export function jobsAndSiteData(data: JobsAndSiteData[]) {
  const res: JobsAndSiteTableData[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: JobsAndSiteData, index) => {
    const obj = {
      sn: index,
      mfg: '',
      model: '',
      serial: '',
      tubeCount: '',
      analystAction: '',
      reTest: false,
      jobOrder: '' as string | JSX.Element,
      vesselToolTip: [''],
      reportAction: 'add',
      equipmentToolTip: [''],
      id: '',
      readyToSend: false,
      IsReportPresent: false,
    };

    obj.mfg = col.Manufacturer;
    obj.model = col.ModelNumber;
    obj.serial = col.SerialNumber;
    obj.tubeCount = col.TubeCount;
    obj.reTest = col.ReTest !== 'No';
    // eslint-disable-next-line react/react-in-jsx-scope
    obj.jobOrder = col.ScheduleDetails ? (
      <Link to={`/job-order/${col.UnitId}`} className="JobOrderLink">
        {col.UnitId}
      </Link>
    ) : '';
    obj.id = col.UnitId;
    obj.vesselToolTip = col?.Vessel || [];
    obj.equipmentToolTip = col?.Equipment || [];
    obj.analystAction = col.ScheduleDetails;
    obj.readyToSend = col.IsReadyToSend || false;
    obj.IsReportPresent = col.IsReportPresent;
    res.push(obj);
  });

  return res;
}

export function formatHotelListData(data: TravelListData[]) {
  const res: AccommodationTableData[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: TravelListData, index) => {
    const obj = {
      sn: index,
      action: 'edit&delete',
      state: '',
      dateIn: '',
      price: '',
      city: '',
      motel: '',
      job: '',
      phone: '',
      id: '',
    };

    obj.state = col.StateName;
    obj.dateIn = col.DateIn;
    obj.price = `$ ${col.Price}`;
    obj.city = col.CityName;
    obj.motel = col.HotelName;
    obj.phone = col.PhoneNumber || '';
    obj.id = col.HotelId;
    obj.job = col.JobCode;

    res.push(obj);
  });

  return res;
}
// eslint-disable-next-line max-len
export function getFormValues(values:JobScheduleFormInfo, bidId: Empty, travelDetailsValues: Empty, commentValues: string, instructionsValues: string) {
  return {
    BidId: bidId,
    TravelInfo: {
      Direction: travelDetailsValues?.travelDirection || '',
      FlightOutDate: dayjs(values?.flightOutDate).format('DD-MM-YYYY') || '',
      FlightOutAirlines: travelDetailsValues?.flightOutAirline || '',
      FlightOutDepartureTime: travelDetailsValues?.flightOutdepartureTime ? dayjs(travelDetailsValues?.flightOutdepartureTime).format('hh:mm A') : '',
      FlightOutArrivalTime: travelDetailsValues?.flightOutarivalTime ? dayjs(travelDetailsValues?.flightOutarivalTime).format('hh:mm A') : '',
      FlightOutSeat: travelDetailsValues?.flightOutSeat || 0,
      FlightOutConfirmation: travelDetailsValues?.flightOutConfirmation,
      FlightInDate: dayjs(values?.flightInDate).format('DD-MM-YYYY') || '',
      FlightInAirlines: travelDetailsValues?.flightInAirline || '',
      FlightInDepartureTime: travelDetailsValues?.flightIndepartureTime ? dayjs(travelDetailsValues?.flightIndepartureTime).format('hh:mm A') : '',
      FlightInArrivalTime: travelDetailsValues?.flightInarivalTime ? dayjs(travelDetailsValues?.flightInarivalTime).format('hh:mm A') : '',
      FlightInSeat: travelDetailsValues?.flightInSeat || 0,
      FlightInConfirmation: travelDetailsValues?.flightInConfirmation,
      CarRentalCompany: travelDetailsValues?.carRentalCompany || '',
      CarRentalPhone: travelDetailsValues?.carRentalPhone || '',
      CarRentalPrice: travelDetailsValues?.carRentalPrice || 0,
      CarRentalConfirmationNumber: travelDetailsValues?.carRentalConfirmation || '',
      CarRentalInstruction: travelDetailsValues?.carInstruction || '',
    },
    SchedulingInstruction: {
      Comment: commentValues || '',
      Instruction: instructionsValues || '',
    },
  };
}

export function setValuesForm(setValue: SetValue, JobScheduleFields: JobScheduleFormInfo) {
  setValue(JobScheduleFields.hotelMotel, '');
  setValue(JobScheduleFields.hotelAddress, '');
  setValue(JobScheduleFields.hotelZip, '');
  setValue(JobScheduleFields.hotelCity, '');
  setValue(JobScheduleFields.hotelState, '');
  setValue(JobScheduleFields.hotelAddressOptional, '');
  setValue(JobScheduleFields.hotelConfirmation, '');
  setValue(JobScheduleFields.hotelPrice, '');
  setValue(JobScheduleFields.late, false);
}
