import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';
import { PhotosCompProps } from '../../types';
import { CombineReportEnum, PDFReportEnum, ReportPart1 } from '../../helper';

function PhotosComp({ vesselName, imageUrl, photoLabel }:PhotosCompProps) {
  const { themePdfColor, reportPdfResponse1, tableContent } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor } = themePdfColor;

  return (
    <div className="p30">
      <div className="pageHeader">
        <div className="topHeader">
          <div>
            {ReportPart1(tableContent, CombineReportEnum.COMBINE_PHOTOS, PDFReportEnum.PDF_PHOTOS, 'Photos')}
          </div>
          <div>{`S/N ${reportPdfResponse1?.JobOrderData?.SerialNumber}`}</div>
        </div>
      </div>
      {photoLabel
      && <div className="underlineHeadingText textCenter">{photoLabel}</div>}
      <div className="bigHeadingText textCenter">
        {vesselName}
      </div>
      <div
        className="phaseChartImageContainer"
        style={{
          borderColor: BorderColor,
        }}
      >
        {
        imageUrl.length > 0 ? <img src={imageUrl} alt="Photos" /> : null
        }
      </div>
    </div>
  );
}

export default PhotosComp;
