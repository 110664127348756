import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  SitesActions, getSitesStart, successGetSites, failureGetSites, deleteSiteStart,
  successDeleteSite, failureDeleteSite, getSiteTypeStart, getSiteTypeSuccess, getSiteTypeFailure,
  getSiteByIdStart, successGetSiteById, failureGetSiteById, updateSiteByIdStart, successUpdateSiteById,
  failureUpdateSiteById, getCustomersStart, successGetCustomers, failureGetCustomers,
} from './sliceSites';
import Config from '../../../Common/Config';
import { makeDeleteRequest, makeGetRequest, makePutRequest } from '../../../Common/NetworkOps';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  CustomersResponse,
  DeleteSiteApiResponse, GetAllSitesApiResponse, GetSiteDetailsByIdApiResponse, PayloadTypeDeleteSites, PayloadTypeGetSiteDetailsById,
  PayloadTypeGetSitesStart, PayloadUpdateSite, SiteTypeApiResponse, UpdateSiteApiResponse,
} from '../utils/types';

// Get Site List -----------------
async function GetSitesList(data: PayloadTypeGetSitesStart): Promise<GetAllSitesApiResponse> {
  const url = `${Config.sites.getSitesList}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllSitesApiResponse>(url);
  return result?.data;
}

export const epicGetSitesList = (action$: Observable<SitesActions>) => action$.pipe(
  filter(getSitesStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetSitesStart) => from(GetSitesList(data)).pipe(
    map((res: GetAllSitesApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetSites(res?.BMT.Result);
      }
      return failureGetSites(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getSitesStart.match))),
    catchError((error: AxiosError<GetAllSitesApiResponse>) => of(failureGetSites(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

// Delete Site ------------
async function DeleteSite(data: PayloadTypeDeleteSites): Promise<DeleteSiteApiResponse> {
  const url = `${Config.sites.DeleteSite}?SiteId=${data.siteId}`;
  const result = await makeDeleteRequest<DeleteSiteApiResponse>(url);
  return result?.data;
}

export const epicDeleteSite = (action$: Observable<SitesActions>) => action$.pipe(
  filter(deleteSiteStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteSites) => from(DeleteSite(data)).pipe(
    mergeMap((res: DeleteSiteApiResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(successDeleteSite(), getSitesStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(failureDeleteSite(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteSiteStart.match))),
    catchError((error: AxiosError<DeleteSiteApiResponse>) => of(failureDeleteSite(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

// Get Site Type List --------------
async function SiteTypeData(): Promise<SiteTypeApiResponse> {
  const url = `${Config.sites.getSiteTypeData}`;
  const result = await makeGetRequest<SiteTypeApiResponse>(url);
  return result.data;
}

export const epicGetSiteTypeData = (action$ : Observable<SitesActions>) => action$.pipe(
  filter(getSiteTypeStart.match),
  map((x) => x.payload),
  mergeMap(() => from(SiteTypeData()).pipe(
    map((res: SiteTypeApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getSiteTypeSuccess(res.BMT.Result);
      }
      return getSiteTypeFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getSiteTypeStart.match))),
    catchError((error) => of(getSiteTypeFailure(error))),
  )),
);

// Get Site Data by ID -----------
async function GetSiteDetailsById(data: PayloadTypeGetSiteDetailsById): Promise<GetSiteDetailsByIdApiResponse> {
  const url = `${Config.sites.getSiteDetailsById}?SiteId=${data.siteId}`;
  const result = await makeGetRequest<GetSiteDetailsByIdApiResponse>(url);
  return result?.data;
}

export const epicGetSiteDetailsById = (action$: Observable<SitesActions>) => action$.pipe(
  filter(getSiteByIdStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetSiteDetailsById) => from(GetSiteDetailsById(data)).pipe(
    map((res: GetSiteDetailsByIdApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetSiteById(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return failureGetSiteById(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getSiteByIdStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<GetSiteDetailsByIdApiResponse>) => of(failureGetSiteById(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

// Update Site --------------
async function updateSite(data: PayloadUpdateSite): Promise<UpdateSiteApiResponse> {
  const url = `${Config.sites.updateSiteDetails}`;
  const result = await makePutRequest<UpdateSiteApiResponse>(url, data);
  return result.data;
}
export const epicUpdateUnit = (action$ : Observable<SitesActions>) => action$.pipe(
  filter(updateSiteByIdStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data:PayloadUpdateSite) => from(updateSite(data)).pipe(
    map((res: UpdateSiteApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT?.ResponseMessage);
        return successUpdateSiteById();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return failureUpdateSiteById(res.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(updateSiteByIdStart.match))),
    catchError((error: AxiosError<UpdateSiteApiResponse>) => of(failureUpdateSiteById(error.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

// Get Customers List -----------------
async function GetCustomersList(data: PayloadTypeGetSitesStart): Promise<CustomersResponse> {
  // eslint-disable-next-line max-len
  const url = `${Config.sites.customersDetails}?SiteId=${data.siteId}&pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<CustomersResponse>(url);
  return result?.data;
}

export const epicGetCustomersList = (action$: Observable<SitesActions>) => action$.pipe(
  filter(getCustomersStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetSitesStart) => from(GetCustomersList(data)).pipe(
    map((res: CustomersResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetCustomers(res?.BMT.Result);
      }
      return failureGetCustomers(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getCustomersStart.match))),
    catchError((error: AxiosError<CustomersResponse>) => of(failureGetCustomers(error.response?.data.BMT.ResponseMessage as string))),
  )),
);
