import React from 'react';
import { useSelector } from 'react-redux';
import styles from './CompanyInfo.module.css';
import { RootState } from '../../../../../../redux/rootState';

export default function ContractorDetails() {
  const {
    contactTitle, contactFirstName, contactLastName, contactEmailId,
    contactCountryCode, contactPhone, contactAlternativePhone,
  } = useSelector(
    (state: RootState) => state.addCustomerReducer.formData,
  );
  const { salutations } = useSelector((state: RootState) => state.masterDataReducer);
  const salutationTitle = salutations?.find((item) => item?.Value === String(contactTitle))?.Text;

  return (
    <div className={styles.companyInfoSection}>
      <h2 className={styles.heading}>Contractor Details</h2>
      <div className={styles.section}>
        <div className={styles.innerSection}>
          <label>Name: </label>
          {' '}
          {(contactFirstName || contactLastName) ? (
            <>
              {salutationTitle}
              {' '}
              {contactFirstName}
              {' '}
              {contactLastName}
            </>
          ) : (
            <span />
          )}

        </div>
        <div className={styles.innerSection}>
          <label>Email ID: </label>
          {' '}
          {contactEmailId}
        </div>
        <div className={styles.innerSection}>
          <label>Phone: </label>
          {' '}
          {contactPhone ? (
            <>
              { contactCountryCode }
              {' '}
              {contactPhone}
            </>
          ) : (
            <span />
          )}
        </div>
        <div className={styles.innerSection}>
          <label>Alternative Phone: </label>
          {' '}
          {contactAlternativePhone ? (
            <>
              {contactCountryCode}
              {' '}
              {contactAlternativePhone}
            </>
          ) : (
            <span />
          )}

        </div>

      </div>
    </div>
  );
}
