import {
  Grid, Card, Tooltip, IconButton, TextField,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { Controller } from 'react-hook-form';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { RootState } from '../../../../redux/rootState';
import RequiredFiled from '../../../../utils/RequiredField';
import { CustomerInformationForm, ICustomerInformation } from '../../utils/types';
import './StyleMainTab.scss';
import AddSMCModal from '../../../../Common/AddSMCModal';
import CustomCheckbox from '../../../../Common/ComponentCustomCheckBox';
import { extRegex, phoneRegex } from '../../../../utils/regex';
import { RHFAutocompleteFieldWithState } from '../../../../Common/ComponentAutoSelectWithState';
import { getCityStateSuggestionStart } from '../../../../redux/masters/masterSlice';

export default function CompanyInformation({
  control, errors, watch, setValue, trigger,
}: ICustomerInformation): JSX.Element {
  const {
    markups, countries, phoneCodes, isError, pinCodeDetails, cityStateSuggestions, stateList,
  } = useSelector((state: RootState) => state.masterDataReducer);
  const [show, setShow] = useState(false);
  const customerAlert = watch(CustomerInformationForm.customerAlert);
  const bidAlert = watch(CustomerInformationForm.bidAlert);
  const smc = watch(CustomerInformationForm.smc);
  const companyName = watch(CustomerInformationForm.companyName);
  const companyZip = watch(CustomerInformationForm.companyZip);
  const companyState = watch(CustomerInformationForm.companyState);
  const [inputValue, setInputValue] = React.useState('');
  const { smcList } = useSelector((state: RootState) => state.getSmcUserReducer);
  const { selectedCustomerDetails, customerId } = useSelector((state: RootState) => state.addCustomerReducer);
  const dispatch = useDispatch();
  const handleTabCell = (smcId: string) => {
    setValue(CustomerInformationForm.smcId, smcId);
    const foundSmcName = smcList.find((item) => item.SmcId === smcId);
    if (foundSmcName) {
      const smcName = foundSmcName.SmcName;
      setValue(CustomerInformationForm.companyName, smcName);
      trigger(CustomerInformationForm.companyName);
    }
    setShow(false);
  };
  useEffect(() => {
    if (smc === false) {
      setValue(CustomerInformationForm.companyName, '');
    }
  }, [smc]);
  useEffect(() => {
    if (bidAlert === false) {
      setValue(CustomerInformationForm.bidAlertNote, '');
    }
  }, [bidAlert]);
  useEffect(() => {
    if (customerAlert === false) {
      setValue(CustomerInformationForm.customerNote, '');
    }
  }, [customerAlert]);

  const message = useMemo(() => ({
    companyName: 'Company Name is required',
    customerNote: 'Customer Notes is required',
    bidAlertNote: 'Bid Alert Notes is required',
  }), []);

  // const otherCity = {
  //   Value: 'Other',
  //   Text: 'Other',
  // };

  // const city = watch(CustomerInformationForm.companyCity);

  // const renderCityInput = () => {
  //   if (city === 'Other') {
  //     return true;
  //   }
  //   return false;
  // };

  // const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    if (selectedCustomerDetails?.CompanyInformation) {
      dispatch(getCityStateSuggestionStart({
        CityName: selectedCustomerDetails?.CompanyInformation?.CityName || '',
        StateName: selectedCustomerDetails?.CompanyInformation?.StateName || '',
      }));
    }
  }, [selectedCustomerDetails, customerId]);

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          Company Information
        </div>
        <div className="customer_info_checkbox dropdown_side_label">
          <div className="customer_info_header_checkbox">
            <CustomCheckbox
              name={CustomerInformationForm.customerAlert}
              control={control}
              label="Customer Alert"
            />
            <CustomCheckbox
              name={CustomerInformationForm.smc}
              control={control}
              label="SMC"
            />
            <CustomCheckbox
              name={CustomerInformationForm.bidAlert}
              control={control}
              label="Bid Alert"
            />
          </div>

          <div className="dropdown_container p-relative mob-w-100">
            <div className="dropdown_side_label">
              Markup
              <RequiredFiled />
            </div>
            <div className="selectListStyle p-relative mob-w-100">
              <ComponentSelectInput
                className="markup_dropdown"
                name={CustomerInformationForm.markup}
                control={control}
                errors={errors}
                size="small"
                entries={markups}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                Customer Name
                <RequiredFiled />
              </label>
              <ComponentTextInput
                control={control}
                required
                capitalizeFirst
                errors={errors}
                inputProps={{ maxLength: 100 }}
                name={CustomerInformationForm.customerName}
                id={CustomerInformationForm.customerName}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                Address
                <RequiredFiled />
              </label>
              <ComponentTextInput
                control={control}
                errors={errors}
                className="nonCapitalizeField"
                required
                inputProps={{ maxLength: 100 }}
                name={CustomerInformationForm.address}
                id={CustomerInformationForm.address}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Code</label>
              <ComponentSelectInput
                name={CustomerInformationForm.companyCountryCode}
                control={control}
                size="small"
                entries={phoneCodes}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={5} xl={2}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Phone</label>
              <ComponentTextInput
                control={control}
                name={CustomerInformationForm.companyPhone}
                regEx={phoneRegex}
                id={CustomerInformationForm.companyPhone}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={1}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Ext</label>
              <ComponentTextInput
                control={control}
                name={CustomerInformationForm.companyExt}
                regEx={extRegex}
                id={CustomerInformationForm.companyExt}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Address(optional)</label>
              <ComponentTextInput
                control={control}
                inputProps={{ maxLength: 200 }}
                className="nonCapitalizeField"
                name={CustomerInformationForm.companyAddress}
                id={CustomerInformationForm.companyAddress}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={5} xl={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                Country
                <RequiredFiled />
              </label>
              <ComponentSelectInput
                name={CustomerInformationForm.companyCountry}
                required
                errors={errors}
                control={control}
                size="small"
                entries={countries}
                handleChange={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  setValue && setValue(CustomerInformationForm.companyZip, '');
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  setValue && setValue(CustomerInformationForm.COMPANY_OTHER_CITY, '');
                  // setInputValue('');
                  trigger(CustomerInformationForm.companyZip);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                Zip
                {/* <RequiredFiled /> */}
              </label>
              <ComponentTextInput
                control={control}
                required
                inputProps={{ maxLength: 10 }}
                className="nonCapitalizeField"
                errors={errors}
                name={CustomerInformationForm.companyZip}
                id={CustomerInformationForm.companyZip}
                size="small"
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                handleChange={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  setValue && setValue(CustomerInformationForm.COMPANY_OTHER_CITY, '');
                  setInputValue('');
                  trigger(CustomerInformationForm.COMPANY_OTHER_CITY);
                }}
              />
            </div>
            {isError ? (<span className="errorMessage">Invalid zip code</span>) : ''}
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                State
              </label>
              <ComponentSelectInput
                name={CustomerInformationForm.companyState}
                disabled={!!companyZip}
                errors={errors}
                control={control}
                size="small"
                entries={stateList}
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                handleChange={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  setValue && setValue(CustomerInformationForm.COMPANY_OTHER_CITY, '');
                  setInputValue('');
                  trigger(CustomerInformationForm.COMPANY_OTHER_CITY);
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={5} xl={2}>
            <div id={CustomerInformationForm.COMPANY_OTHER_CITY} className="div_label_text">
              <label className="label_Style_Customer">
                City
              </label>
              <RHFAutocompleteFieldWithState
                // eslint-disable-next-line no-nested-ternary
                options={companyZip ? pinCodeDetails?.City ? [...pinCodeDetails.City] : [] : cityStateSuggestions || []}
                companyZip={companyZip}
                control={control}
                name={CustomerInformationForm.COMPANY_OTHER_CITY}
                id={CustomerInformationForm.COMPANY_OTHER_CITY}
                errors={errors}
                setValue={setValue}
                companyState={companyState}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
            </div>
          </Grid>
          {(smc) && (
            <Grid item md={4} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_Customer">
                  Company Name
                  <RequiredFiled />
                </label>
                <Controller
                  name={CustomerInformationForm.companyName}
                  control={control}
                  render={() => (
                    <TextField
                      size="small"
                      onClick={() => setShow(true)}
                      value={companyName}
                      InputProps={{
                        endAdornment: (
                          <Tooltip title="Search here..">
                            <IconButton onClick={() => setShow(true)}>
                              <SearchIcon />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                      error={!!errors[CustomerInformationForm.companyName]}
                      helperText={!!errors[CustomerInformationForm.companyName] && message.companyName}
                    />
                  )}
                />
              </div>
            </Grid>
          )}

          {customerAlert && (
            <Grid item md={12} sm={12} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_Customer">
                  Customer Alert Notes
                  <RequiredFiled />
                </label>
                <Controller
                  control={control}
                  name={CustomerInformationForm.customerNote}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      value={value}
                      onChange={(event) => {
                        onChange(event.target.value);
                        trigger(CustomerInformationForm.customerNote);
                      }}
                      multiline
                      inputProps={{ maxLength: 255 }}
                      rows={8.8}
                      // maxRows={8.8}
                      error={!!errors[CustomerInformationForm.customerNote]}
                      helperText={!!errors[CustomerInformationForm.customerNote] && message.customerNote}
                    />
                  )}
                />
              </div>
            </Grid>
          )}
          {bidAlert && (
            <Grid item md={12} sm={12} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_Customer">
                  Bid Alert Notes
                  <RequiredFiled />
                </label>
                <Controller
                  control={control}
                  name={CustomerInformationForm.bidAlertNote}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      value={value}
                      onChange={(event) => {
                        onChange(event.target.value);
                        trigger(CustomerInformationForm.bidAlertNote);
                      }}
                      inputProps={{ maxLength: 255 }}
                      multiline
                      rows={8.8}
                      // maxRows={8.8}
                      error={!!errors[CustomerInformationForm.bidAlertNote]}
                      helperText={!!errors[CustomerInformationForm.bidAlertNote] && message.bidAlertNote}
                    />
                  )}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      <AddSMCModal show={show} setOpenFrom={() => setShow(false)} handleTabCell={handleTabCell} />
    </Card>
  );
}
