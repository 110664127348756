/* eslint-disable react/no-danger */
import React from 'react';
import { Empty } from '../../ScreenAddSmc/Utils/TypeSmc';
import { BidsItems } from '../types';
import { BID_QUOTE, DOT_QUOTE, PDF_QUOTE } from '../constant';
import taiLogo from '../../../assets/tailogo-eddyCurrent.png';

class ComponentToPrint extends React.Component<Empty> {
  render() {
    const {
      bidFieldsValue, bidTableData, bidPdfDetailsData, userProfile,
      comment,
    } = this.props;

    const renderRemark = (serviceLength: number) => {
      if (serviceLength > 1) {
        return (
          <div
            style={{ wordBreak: 'break-word', wordWrap: 'break-word' }}
            className="notesData"
            dangerouslySetInnerHTML={{ __html: String(bidPdfDetailsData?.ForMoreThanOneUnitBid) }}
          />
        );
      }
      return (
        <div
          style={{ wordBreak: 'break-word', wordWrap: 'break-word' }}
          className="notesData"
          dangerouslySetInnerHTML={{ __html: String(bidPdfDetailsData?.ForSingleUnitBid) }}
        />
      );
    };

    const renderServices = (services: Empty) => {
      if (services?.Services?.length === 0) {
        return (
          <>
            <td style={{ width: '38%', textAlign: 'left' }}>{' '}</td>
            <td style={{ width: '15%', textAlign: 'center' }}>{' '}</td>
            <td style={{ width: '12%', textAlign: 'center' }}>{' '}</td>
            <td style={{ width: '23%', textAlign: 'center' }}>{' '}</td>
          </>
        );
      }
      return (
        <>
          <td style={{ width: '38%', textAlign: 'left' }}>
            {services?.Services?.map((servicesData: Empty) => (
              <div style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                {servicesData?.Service}
              </div>
            ))}
            {renderRemark(services?.Services?.length)}
          </td>
          <td style={{ width: '15%', textAlign: 'center' }}>
            {services?.Services?.map((servicesData: Empty) => (
              <div style={{ wordBreak: 'break-word', wordWrap: 'break-word' }}>
                {servicesData?.ModelType.replace('/', '/ ')}
              </div>
            ))}
          </td>
          <td style={{ width: '12%', textAlign: 'center' }}>
            {services?.Services?.map((servicesData: Empty) => (
              <div>
                {servicesData?.TubeQty}
              </div>
            ))}
          </td>
          <td style={{ width: '23%', textAlign: 'center' }}>
            {services?.Services?.map((servicesData: Empty) => (
              <div style={{ wordBreak: 'break-word', wordWrap: 'break-word' }}>
                {servicesData?.Location}
              </div>
            ))}
          </td>
        </>
      );
    };

    const renderCustomService = () => {
      if (bidTableData?.length === 0) {
        return (
          <tr className="tableBodyPdfBid">
            <td style={{ width: '38%', textAlign: 'left' }}>{' '}</td>
            <td style={{ width: '15%', textAlign: 'center' }}>{' '}</td>
            <td style={{ width: '12%', textAlign: 'center' }}>{' '}</td>
            <td style={{ width: '23%', textAlign: 'center' }}>{' '}</td>
            <td style={{ width: '12%' }}>{' '}</td>
          </tr>
        );
      }
      return (
        bidTableData?.map((ele: BidsItems) => (
          <tr className="tableBodyPdfBid customTablePdfBidBody">
            <td style={{ width: '38%', textAlign: 'left' }}>
              <div>
                {ele?.services}
              </div>
              <div>{ele?.remark}</div>
            </td>
            <td style={{ width: '15%', textAlign: 'center' }}>{ele?.model}</td>
            <td style={{ width: '12%', textAlign: 'center' }}>{ele?.tubeQty}</td>
            <td style={{ width: '23%', textAlign: 'center' }}>{ele?.location}</td>
            <td style={{ width: '12%' }}>{ele?.total}</td>
          </tr>
        ))
      );
    };
    return (
      <div className="printable-content">
        <table>
          <thead>
            <tr>
              <td>
                <div className="header-space">
                  <div
                    style={{
                      display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px',
                    }}
                  >
                    <div className="ms-3">
                      <img src={taiLogo} style={{ width: '200px', height: 'auto' }} alt="" />
                    </div>
                    <div>
                      <div className="firstText me-3">1000 Cobb Place Boulevard NW Bldg 300 Suite 300</div>
                      <div className="firstText me-3">Kennesaw GA 30144</div>
                      <div className="firstText me-3">(770)763-5930 | (800)554-4127</div>
                    </div>
                  </div>
                  <div className="me-3 ms-3">
                    <div className="divider mt-2 mb-2" />
                    <table border={0} style={{ width: '100%' }}>
                      <tr>
                        <td style={{ fontWeight: '700', fontSize: '14px', paddingBottom: '8px' }}>QUOTE PREPARED FOR:</td>
                      </tr>
                      <tr>
                        <td>
                          <table border={0} style={{ width: '100%' }}>
                            <tr>
                              <td style={{ width: '65%' }}>
                                <table border={0} style={{ width: '100%' }}>
                                  <tr>
                                    <td style={{ fontWeight: '500', fontSize: '12px', paddingBottom: '0px' }}>{bidFieldsValue?.contactName}</td>
                                  </tr>
                                  <tr>
                                    <td style={{ fontWeight: '500', fontSize: '12px', paddingBottom: '0px' }}>{bidFieldsValue?.customer}</td>
                                  </tr>
                                  <tr>
                                    <td style={{ fontWeight: '500', fontSize: '12px', paddingBottom: '0px' }}>{bidFieldsValue?.address}</td>
                                  </tr>
                                  <tr>
                                    <td style={{ fontWeight: '500', fontSize: '12px', paddingBottom: '0px' }}>{bidFieldsValue?.city}</td>
                                  </tr>
                                </table>
                              </td>
                              <td style={{ width: '35%' }}>
                                <table border={0} style={{ width: '100%' }}>
                                  <tr>
                                    <td style={{ fontWeight: '700', fontSize: '12px', paddingBottom: '0px' }}>Effective Date</td>
                                    <td style={{ fontWeight: '500', fontSize: '12px', paddingBottom: '0px' }}>{bidFieldsValue?.effectiveDate}</td>
                                  </tr>
                                  <tr>
                                    <td style={{ fontWeight: '700', fontSize: '12px', paddingBottom: '0px' }}>Quote Number</td>
                                    <td style={{ fontWeight: '500', fontSize: '12px', paddingBottom: '0px' }}>{bidFieldsValue?.quoteNumber}</td>
                                  </tr>
                                  <tr>
                                    <td style={{ fontWeight: '700', fontSize: '12px', paddingBottom: '0px' }}>Customer Number</td>
                                    <td style={{ fontWeight: '500', fontSize: '12px', paddingBottom: '0px' }}>{bidFieldsValue?.customerNumber}</td>
                                  </tr>
                                  <tr>
                                    <td style={{ fontWeight: '700', fontSize: '12px', paddingBottom: '0px' }}>Payment Terms</td>
                                    <td style={{ fontWeight: '700', fontSize: '12px', paddingBottom: '0px' }}>{bidFieldsValue?.paymentTerms}</td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label style={{
                            fontSize: '12px', fontWeight: '500', textTransform: 'uppercase', textAlign: 'justify', marginTop: '20px',
                          }}
                          >
                            <span style={{ fontWeight: '700' }}>JOB Location: </span>
                            <div style={{ display: 'inline', wordBreak: 'break-all' }}>{bidFieldsValue?.jobLocation}</div>
                          </label>
                        </td>
                      </tr>
                    </table>
                    <div className="divider mt-2 mb-2" />
                  </div>
                </div>
              </td>
            </tr>
          </thead>
          <tbody style={{
            height: '100%',
          }}
          >
            <tr>
              <td>
                <div className="content">
                  <div className="me-3 ms-3">
                    <label>
                      {/* <span style={{ fontWeight: '700' }}>NOTE: </span> */}
                      <div
                        className="notesData"
                        dangerouslySetInnerHTML={{ __html: String(comment) }}
                        style={{ wordBreak: 'break-word', wordWrap: 'break-word', fontSize: '13px' }}
                      />
                    </label>
                  </div>

                  <div
                    style={{ paddingTop: '12px', width: '100%' }}
                    className="pe-3 ps-3"
                  >
                    <table style={{ width: '100%', borderCollapse: 'collapse' }} border={0} cellSpacing={2} cellPadding={2}>
                      <tr className="tableHeadPdfBid">
                        <th style={{ width: '38%' }}>Services</th>
                        <th style={{ width: '15%' }}>Model / Type</th>
                        <th style={{ width: '12%' }}>Tube Qty</th>
                        <th style={{ width: '23%' }}>Location</th>
                        <th style={{ width: '12%' }}>Total</th>
                      </tr>
                      {bidPdfDetailsData?.Items?.map((ele: Empty) => (
                        <tr className="tableBodyPdfBid">
                          {renderServices(ele)}
                          <td style={{ width: '12%' }}>{ele?.TotalBidAmount}</td>
                        </tr>
                      ))}
                      {renderCustomService()}
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div className="footer-space me-3 ms-3">
                  <div className="divider mt-2 mb-2" />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div className="agreementText">
                      {PDF_QUOTE}
                    </div>
                    <div className="agreementText mt-2" style={{ fontWeight: 700 }}>
                      {BID_QUOTE}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                      <div className="agreementClassName">
                        <div className="thankYouText mt-1">
                          THANK YOU !
                        </div>
                        <div className="thankYouText mt-1">
                          {DOT_QUOTE}
                        </div>
                        <div className="thankYouText">
                          {userProfile?.FirstName}
                          &nbsp;
                          {userProfile?.LastName}
                        </div>
                        <div className="thankYouText">
                          (
                          {userProfile?.Email}
                          )
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '45px',
                      marginTop: '10px',
                      marginBottom: '8px',
                    }}
                    className="bottomWrapper"
                  >
                    <div className="bottomTextClass">Non-Destructive Inspections</div>
                    <div className="bottomTextClass">Eddy Current * Flux Leakage * Oil Analysis</div>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}
export default ComponentToPrint;
