/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  Controller, Control, Path, FieldValues, FieldErrors,
} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { getCitySuggestionStart, resetCitySuggestion } from '../../redux/masters/masterSlice';
import { Empty } from '../../Screens/ScreenAddSmc/Utils/TypeSmc';
import { RootState } from '../../redux/rootState';

interface RHFAutocompleteFieldProps<O extends { City: string }, TField extends FieldValues> {
  control: Control<TField>;
  name: Path<TField>;
  options: O[];
  placeholder?: string;
  errors?: FieldErrors;
  disabled?: boolean;
  setValue?: Empty;
}

// eslint-disable-next-line max-len
export function RHFAutocompleteField<O extends { City: string }, TField extends FieldValues>({ errors, disabled, ...props }: RHFAutocompleteFieldProps<O, TField>) {
  const {
    control, options, name, setValue,
  } = props;
  const [inputValue, setInputValue] = React.useState('');
  const { isCityLoading } = useSelector((state: RootState) => state.masterDataReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!inputValue) {
      return;
    }
    if (inputValue.length <= 3) {
      dispatch(getCitySuggestionStart(inputValue || ''));
    }
  }, [inputValue]);

  const error = errors ? errors[name] : null;

  useEffect(() => {
    dispatch(resetCitySuggestion());
  }, []);

  const handleEndAdornmentClick = () => {
    if (name) {
      setValue(name, '');
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          <Autocomplete
            size="small"
            freeSolo
            disabled={disabled}
            loading={isCityLoading}
            value={value ? options?.find((option) => value === option?.City) ?? null : null}
            getOptionLabel={(option: Empty) => option.City}
            onChange={(event, newValue) => {
              onChange(newValue ? (newValue as Empty)?.City : inputValue ?? null);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              onChange(newInputValue as Empty);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                placeholder="Search city here..."
                error={Boolean(error?.message)}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  inputProps: { ...params.inputProps, maxLength: 30 },
                  endAdornment: (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                    <div onClick={handleEndAdornmentClick}>
                      {isCityLoading ? <span className="cityCircularLoading"><CircularProgress size={20} /></span> : null}
                      {params.InputProps.endAdornment}
                    </div>
                  ),
                }}
              />
            )}
          />
          {error && <span className="errorMessage">{String(error.message)}</span>}
        </>
      )}
    />
  );
}
