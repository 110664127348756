import {
  Button, Card, Grid, IconButton, TextField, Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useParams } from 'react-router-dom';
import CustomCheckbox from '../../../Common/ComponentCustomCheckBox';
import RequiredFiled from '../../../utils/RequiredField';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import ComponentSelectInput from '../../../Common/ComponentSelectInput';
import { phoneRegex } from '../../../utils/regex';
import AddSMCModal from '../../../Common/AddSMCModal';
import { RootState } from '../../../redux/rootState';
import { SiteForm, editSitePayload } from '../utils/helpers';
import EndPoints from '../../../Routes/EndPoints';
import { MainFormValues, SiteComponentProps } from '../utils/types';
import { updateSiteByIdStart } from '../redux/sliceSites';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import { RHFAutocompleteFieldWithState } from '../../../Common/ComponentAutoSelectWithState';
import { getCityStateSuggestionStart } from '../../../redux/masters/masterSlice';

function ComponentEditSiteMethods({
  control, errors, watch, setValue, handleSubmit, setError, trigger,
}: SiteComponentProps) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { siteId } = useParams();

  const smc = watch(SiteForm.smc);
  const siteZip = watch(SiteForm.zip);
  const siteState = watch(SiteForm.state);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inputValue, setInputValue] = React.useState('');
  const { smcList } = useSelector((state: RootState) => state.getSmcUserReducer);
  const { siteTypeData, siteDetailsById } = useSelector((state: RootState) => state.siteReducer);
  const {
    countries, phoneCodes, isError, pinCodeDetails, stateList, cityStateSuggestions,
  } = useSelector((state: RootState) => state.masterDataReducer);
  const handleTabCell = (smcId: string) => {
    setValue(SiteForm.smcId, smcId);
    const foundSmcName = smcList.find((item) => item.SmcId === smcId);
    if (foundSmcName) {
      const smcName = foundSmcName.SmcName;
      setValue(SiteForm.safetyCompanyName, smcName);
    }
    setShow(false);
  };

  const handleCancel = () => {
    navigate(EndPoints.SITES);
  };

  const onSubmit = (data: MainFormValues) => {
    const payload = editSitePayload(data, siteId as string);
    if (siteZip && payload.CityName) {
      const cityNew1 = pinCodeDetails?.City.find(((item) => item.Value === payload.CityName));
      if (cityNew1) {
        dispatch(updateSiteByIdStart(payload));
      } else {
        setError(SiteForm.SITE_OTHER_CITY, { type: 'custom', message: 'Select a valid City Name' });
      }
    } else if (siteZip && !payload.CityName) {
      dispatch(updateSiteByIdStart(payload));
    } else if (!siteZip && payload.CityName) {
      const cityNew2 = cityStateSuggestions?.find(((item) => item.City === payload.CityName));
      if (cityNew2) {
        dispatch(updateSiteByIdStart(payload));
      } else {
        setError(SiteForm.SITE_OTHER_CITY, { type: 'custom', message: 'Select a valid City Name' });
      }
    } else {
      dispatch(updateSiteByIdStart(payload));
    }
  };

  const smcChange = () => {
    if (smc !== false) {
      setValue(SiteForm.safetyCompanyName, '');
    }
  };

  useEffect(() => {
    if (siteDetailsById?.SiteId) {
      dispatch(getCityStateSuggestionStart({
        CityName: siteDetailsById?.City || '',
        StateName: siteDetailsById?.State || '',
      }));
    }
  }, [siteDetailsById, siteId]);

  // const otherCity = {
  //   Value: 'Other',
  //   Text: 'Other',
  // };

  // const city = watch(SiteForm.city);

  // const renderCityInput = () => {
  //   if (city === 'Other') {
  //     return true;
  //   }
  //   return false;
  // };

  // const handleZipChange = () => {
  //   setValue(SiteForm.SITE_OTHER_CITY, '');
  // };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ComponentPageHeader fieldId={String(siteId)} />
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Card>
            <div className="customerInfo_container">
              <div className="customerInfo">
                Edit Site
              </div>
              <div className="customer_info_checkbox dropdown_side_label">
                <div className="customer_info_header_checkbox">
                  <CustomCheckbox
                    name={SiteForm.smc}
                    control={control}
                    label="SMC"
                    handleChange={smcChange}
                  />
                </div>
              </div>
            </div>
            <div className="customerInfo_main_container">
              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Site
                      <RequiredFiled />
                    </label>
                    <ComponentTextInput
                      control={control}
                      capitalizeFirst
                      errors={errors}
                      inputProps={{ maxLength: 32 }}
                      regEx={/^[A-Za-z0-9 ]+$/}
                      name={SiteForm.site}
                      id={SiteForm.site}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Contact
                    </label>
                    <ComponentTextInput
                      control={control}
                      errors={errors}
                      className="nonCapitalizeField"
                      inputProps={{ maxLength: 255 }}
                      name={SiteForm.contact}
                      id={SiteForm.contact}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Address
                      {/* <RequiredFiled /> */}
                    </label>
                    <ComponentTextInput
                      className="nonCapitalizeField"
                      name={SiteForm.address}
                      id={SiteForm.address}
                      control={control}
                      errors={errors}
                      size="small"
                    />
                  </div>
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Address (Optional)
                    </label>
                    <ComponentTextInput
                      control={control}
                      className="nonCapitalizeField"
                      name={SiteForm.addressOptional}
                      id={SiteForm.addressOptional}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Country
                      <RequiredFiled />
                    </label>
                    <ComponentSelectInput
                      name={SiteForm.country}
                      errors={errors}
                      control={control}
                      size="small"
                      entries={countries}
                      handleChange={() => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        setValue && setValue(SiteForm.zip, '');
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        setValue && setValue(SiteForm.SITE_OTHER_CITY, '');
                        setInputValue('');
                        trigger(SiteForm.zip);
                      }}
                    />
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Zip
                      {/* <RequiredFiled /> */}
                    </label>
                    <ComponentTextInput
                      control={control}
                      // regEx={extRegex}
                      inputProps={{ maxLength: 10 }}
                      // handleChange={handleZipChange}
                      errors={errors}
                      name={SiteForm.zip}
                      id={SiteForm.zip}
                      size="small"
                      handleChange={() => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        setValue && setValue(SiteForm.SITE_OTHER_CITY, '');
                        setInputValue('');
                        trigger(SiteForm.SITE_OTHER_CITY);
                      }}
                    />
                  </div>
                  {isError ? (<span className="errorMessage">Invalid zip code</span>) : ''}
                </Grid>
                {/* <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      State
                    </label>
                    <ComponentTextInput
                      control={control}
                      errors={errors}
                      isDisable
                      name={SiteForm.state}
                      id={SiteForm.state}
                      size="small"
                    />
                  </div>
                </Grid> */}

                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      State
                    </label>
                    <ComponentSelectInput
                      name={SiteForm.state}
                      disabled={!!siteZip}
                      errors={errors}
                      control={control}
                      size="small"
                      entries={stateList}
                      handleChange={() => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        setValue && setValue(SiteForm.SITE_OTHER_CITY, '');
                        setInputValue('');
                        trigger(SiteForm.SITE_OTHER_CITY);
                      }}
                    />
                  </div>
                </Grid>

                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    {/* <label className="label_Style_Customer">
                      City
                    </label>
                    {renderCityInput() ? (
                      <RHFAutocompleteField
                        options={citySuggestions || []}
                        control={control}
                        name={SiteForm.SITE_OTHER_CITY}
                      />
                    )
                      : (
                        <ComponentSelectInput
                          name={SiteForm.city}
                          control={control}
                          errors={errors}
                          size="small"
                          entries={pinCodeDetails?.City ? [...pinCodeDetails.City, otherCity] : []}
                        />
                      )} */}
                    <label className="label_Style_Customer">
                      City
                    </label>
                    <RHFAutocompleteFieldWithState
                      // eslint-disable-next-line no-nested-ternary
                      options={siteZip ? pinCodeDetails?.City ? [...pinCodeDetails.City] : [] : cityStateSuggestions || []}
                      companyZip={siteZip}
                      control={control}
                      name={SiteForm.SITE_OTHER_CITY}
                      id={SiteForm.SITE_OTHER_CITY}
                      errors={errors}
                      setValue={setValue}
                      companyState={siteState}
                      inputValue={inputValue}
                      setInputValue={setInputValue}
                    />
                  </div>
                </Grid>
                <Grid item md={1} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Code</label>
                    <ComponentSelectInput
                      name={SiteForm.code}
                      control={control}
                      size="small"
                      entries={phoneCodes}
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Phone</label>
                    <ComponentTextInput
                      control={control}
                      name={SiteForm.phone}
                      regEx={phoneRegex}
                      id={SiteForm.phone}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Cell</label>
                    <ComponentTextInput
                      control={control}
                      name={SiteForm.cell}
                      regEx={phoneRegex}
                      id={SiteForm.cell}
                      size="small"
                    />
                  </div>
                </Grid>
                {(smc) ? (
                  <Grid item md={4} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_Customer">
                        Safety Company Name
                        <RequiredFiled />
                      </label>
                      <Controller
                        name={SiteForm.safetyCompanyName}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            size="small"
                            value={value}
                            required={smc}
                            onChange={onChange}
                            InputProps={{
                              endAdornment: (
                                <Tooltip title="Search here..">
                                  <IconButton onClick={() => setShow(true)}>
                                    <SearchIcon />
                                  </IconButton>
                                </Tooltip>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                ) : (
                  <Grid item md={4} sm={6} xs={12} />
                )}
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Site Type
                    </label>
                    <ComponentSelectInput
                      name={SiteForm.siteType}
                      errors={errors}
                      control={control}
                      size="small"
                      entries={siteTypeData}
                    />
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Note
                    </label>
                    <Controller
                      control={control}
                      name={SiteForm.note}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          value={value}
                          onChange={onChange}
                          multiline
                          inputProps={{ maxLength: 255 }}
                          rows={4}
                          maxRows={4}
                        />
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <AddSMCModal show={show} setOpenFrom={() => setShow(false)} handleTabCell={handleTabCell} />
          </Card>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <div className="save_and_next_div">
            <div>
              <Button className="button_cancel" onClick={handleCancel}>Cancel</Button>
            </div>
            <div className="button_margin_left">
              <Button type="submit" className="button_save_and_next" disabled={isError}>Save</Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

export default ComponentEditSiteMethods;
