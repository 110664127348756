import * as Yup from 'yup';
import { StripChartData, StripChartEnum } from '../type';

export function setStripChartValues(stripChartDataById:StripChartData, setValue: SetValue) {
  setValue(StripChartEnum.zone, stripChartDataById?.Zone);
  setValue(StripChartEnum.row, stripChartDataById?.Row);
  setValue(StripChartEnum.tube, stripChartDataById?.Tube);
  setValue(StripChartEnum.section, stripChartDataById?.Section);
  setValue(StripChartEnum.title, stripChartDataById?.Title);
}

export const StripChartDefaultValue = {
  [StripChartEnum.zone]: '',
  [StripChartEnum.row]: '',
  [StripChartEnum.tube]: '',
  [StripChartEnum.section]: '',
  [StripChartEnum.title]: '',
};

export const StripChartModalSchema = Yup.object({
  [StripChartEnum.zone]: Yup.string().required('Zone is required'),
  [StripChartEnum.row]: Yup.string().required('Row is required'),
  [StripChartEnum.tube]: Yup.string().required('Tube is required'),
  [StripChartEnum.section]: Yup.string().required('Section is required'),
  // [StripChartEnum.title]: Yup.string().required('Title is required'),
  [StripChartEnum.title]: Yup.string(),
});
