import * as Yup from 'yup';

export const mainFormSchema = Yup.object({
  smc: Yup.boolean(),
  SMCId: Yup.string(),
  SMCName: Yup.string().test({
    name: 'required-with-smc',
    test(value) {
      const { smc } = this.parent;
      if (smc) {
        return !!value || this.createError();
      }
      return true;
    },
  }),
  site: Yup.string().required('Site is required'),
  contact: Yup.string(),
  address: Yup.string(),
  optionalAddress: Yup.string(),
  zip: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  siteOtherCity: Yup.string(),
  cityCode: Yup.string(),
  country: Yup.string().required('Country is required'),
  phoneCode: Yup.string(),
  phone: Yup.string(),
  cell: Yup.string(),
  siteType: Yup.number(),
  note: Yup.string(),
});
