import dayjs from 'dayjs';

export enum CustomerSiteFormFields {
  CustomerId = 'customerId',
  CustomerName = 'customerName',
  CustomerContact = 'customerContact',
  CustomerAddress = 'customerAddress',
  CustomerOptionalAdress = 'customerOptionalAdress',
  CustomerZip = 'customerZip',
  CustomerState = 'customerState',
  CustomerCity = 'customerCity',
  CustomerCountry = 'customerCountry',
  CustomerPhoneCode = 'customerPhoneCode',
  CustomerPhone = 'customerPhone',
  CustomerSMC = 'customerSMC',
  Contact = 'contact',
  Cell = 'cell',
  PhoneCode = 'phoneCode',
  Phone = 'phone',
  SiteId = 'siteId',
  SiteName = 'siteName',
  SiteContact = 'SiteContact',
  SiteAddress = 'siteAddress',
  SiteOptionalAddress = 'siteOptionalAddress',
  SiteZip = 'siteZip',
  SiteState = 'siteState',
  SiteCity = 'siteCity',
  SiteCountry = 'siteCountry',
  SitePhoneCode = 'sitePhoneCode',
  SitePhone = 'sitePhone',
  SiteCell = 'siteCell',
  SiteType = 'siteType',
  SiteSMC = 'siteSMC',
  SiteNote = 'siteNote',
}

export const Datatablestyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  // height: 'fit-content',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: '6px 24px 24px 24px',
  height: '650px',
  '@media (max-width: 768px)': {
    p: '6px 6px 15px',
    height: 'auto',
  },
};

export const TravelDefaultData = {
  Direction: '',
  FlightOutDate: dayjs(),
  FlightOutAirlines: '',
  FlightOutDepartureTime: '',
  FlightOutArrivalTime: '',
  FlightOutSeat: '',
  FlightOutConfirmation: '',
  FlightInDate: dayjs().add(1, 'day'),
  FlightInAirlines: '',
  FlightInDepartureTime: '',
  FlightInArrivalTime: '',
  FlightInSeat: '',
  FlightInConfirmation: '',
  CarRentalCompany: '',
  CarRentalPhone: '',
  CarRentalPrice: '',
  CarRentalConfirmationNumber: '',
  CarRentalInstruction: '',
};

export const HotelDefaultData = {
  hotelDateOut: dayjs(),
  hotelDateIn: dayjs(),
  hotelCity: '',
};
