export const MAIN_TAB = 1;
export const CLIENT_SITE_TAB = 2;
export const VESSELS_TAB = 3;
export const ANALYST_NOTE_TAB = 4;
export const COMMENTS_TAB = 5;

export enum JobOrderSiteFormFields {
  ClientId = 'clientId',
  ClientName = 'clientName',
  ClientContact = 'clientContact',
  ClientAddress = 'clientAddress',
  ClientOptionalAddress = 'clientOptionalAddress',
  ClientZip = 'clientZip',
  ClientState = 'clientState',
  ClientCity = 'clientCity',
  ClientPhoneCode = 'clientPhoneCode',
  ClientPhone = 'clientPhone',
  SiteId = 'siteId',
  SiteName = 'siteName',
  SiteContact = 'SiteContact',
  SiteAddress = 'siteAddress',
  SiteOptionalAddress = 'siteOptionalAddress',
  SiteCountry = 'siteCountry',
  SiteZip = 'siteZip',
  SiteState = 'siteState',
  SiteCity = 'siteCity',
  otherSiteCity = 'otherSiteCity',
  SitePhoneCode = 'sitePhoneCode',
  SitePhone = 'sitePhone',
  SiteCell = 'siteCell',
}

export enum UnitInfoOrderFormNames {
  readyToSend = 'readyToSend',
  ASMESection = 'ASMESection',
  testType = 'testType',
  flux = 'FLT',
  crossaxial = 'ECT',
  absolute = 'ADT',
  odTubes = 'odTubes',

  leak = 'leak',
  outside = 'outside',
  safetyClass = 'safetyClass',
  testedBefore = 'testedBefore',
  informedCust = 'informedCust',
  startDate = 'startDate',
  endDate = 'endDate',
  jobTime = 'jobTime',
  reTestDate = 'reTestDate',
  location = 'location',
  sendReportTo = 'sendReportTo',

  serialVerified = 'serialVerified',
  manufacturer = 'manufacturer',
  machineType = 'machineType',
  model = 'model',
  type = 'type',
  style = 'style',
  serialNumber = 'serialNumber',
  serialNumberSite = 'serialNumberSite',
  expenses = 'expenses',
  by = 'by',
  reviewedBy = 'reviewedBy',
  inspectedBy = 'inspectedBy',

  spotTest = 'spotTest',
  pct = 'pct',
}

export const JobOrderMainDefaultValue = {
  readyToSend: false,
  ASMESection: false,
  odTubes: '',
  testType: UnitInfoOrderFormNames.flux,

  leak: false,
  outside: false,
  safetyClass: false,
  testedBefore: false,
  informedCust: false,
  startDate: null,
  endDate: null,
  jobTime: '',
  reTestDate: null,
  location: '',
  sendReportTo: '',

  serialVerified: false,
  manufacturer: '',
  machineType: '',
  model: '',
  type: '',
  style: '',
  serialNumber: '',
  serialNumberSite: '',
  expenses: '',
  by: '',
  reviewedBy: '',
  inspectedBy: [],

  spotTest: true,
  pct: '',
};

export enum VesselFormJobOrder {
  estimatedTubeQty = 'estimatedTubeQty',
  providedTubeQty = 'providedTubeQty',
}
