/* eslint-disable no-bitwise */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';
import { AllDefects, TubeConfiguration } from '../../VesselInformation/utils/tubeChartTypes';
import { Polygon } from '../../VesselInformation/components/TubeChart/Diagram/gridClass';
import { TubeDetailPoly, TubePolyType } from '../types';
import TubeChartLayout from './tubeChartLayout';
import { CombineReportEnum, PDFReportEnum, ReportPart1 } from '../helper';

function TubeBundleLayoutSection() {
  const { reportPdfResponse3, tableContent } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  const tubesPoly:TubePolyType[] = useMemo(
    () => reportPdfResponse3?.Tube_Bundle_Layout?.map((tubeDetails) => {
      let allDefects:AllDefects[] = [];
      const temp:TubeDetailPoly[] = tubeDetails?.map((details) => {
        const chartDetails:TubeConfiguration = {
          CircleInfos: details?.CircleInfos,
          IsOte: details?.IsOte,
          TubeSize: details?.TubeSize,
          NumOfRows: details?.NumOfRows,
          RowLength: details?.RowLength,
          Panel: details?.Panel,
          Group: details?.Group,
          Layout: details?.Layout,
          IsTestEndChanges: details.OriginalTestEnd,
          ChartId: details.ChartId,
          VesselId: details.VesselId,
          VesselTop: details.VesselTop,
          Offset: details.Offset,
          OriginalTestEnd: details.OriginalTestEnd,
          RemovedConfig: details.RemovedConfig,
        };
        const poly = new Polygon(chartDetails);
        poly.displayDefects(details?.Defects);
        allDefects = [...allDefects, ...poly.allDefects];

        return {
          poly,
          details,
        };
      });

      const uniqueDefectsMap = new Map<string, AllDefects>();

      allDefects.forEach((defect) => {
        uniqueDefectsMap.set(defect.color, defect);
      });

      const uniqueDefectsArray = Array.from(uniqueDefectsMap.values());

      return {
        tubePolyList: temp,
        allDefects: uniqueDefectsArray,
      };
    }),
    [reportPdfResponse3?.Tube_Bundle_Layout],
  );

  function getTextColor(backgroundColor: string) {
    // Convert HEX to RGB
    const rgb = parseInt(backgroundColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff; // Calculate brightness
    const brightness = (0.299 * r + 0.587 * g + 0.114 * b); // Return light or dark text based on brightness
    return (brightness < 128) ? '#FFFFFF' : '#000000'; // White for dark, black for light
  }

  function customLabelList(tubeDataDefectList: AllDefects[]) {
    if (!tubeDataDefectList || !Array.isArray(tubeDataDefectList)) {
      return null;
    }

    if (tubeDataDefectList.length === 0) {
      return 'No Significant defects were found';
    }

    // Helper function to chunk the array into smaller arrays of size 10
    const chunkArray = (array: AllDefects[], chunkSize: number) => {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    };

    const chunks = chunkArray(tubeDataDefectList, 5);
    const rows = [];
    for (let i = 0; i < chunks.length; i += 2) {
      rows.push(chunks.slice(i, i + 2));
    }

    return (

      <div style={{ marginBottom: '10px' }}>
        {rows.map((row) => (
          <div style={{
            display: 'flex',
            gap: '40px',
            width: '100%',
          }}
          >
            {row.map((chunk) => (
              <div style={{
                width: '360px',
              }}
              >
                {chunk.map((data: AllDefects) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <div style={{
                      display: 'flex',
                    }}
                    >
                      <div
                        className="tubeChartLegend"
                        style={{
                          backgroundColor: data.color !== '' ? data.color : 'transparent',
                          fontSize: '9px',
                          color: getTextColor(data.color !== '' ? data.color : 'transparent'),
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                          marginRight: '3px',
                        }}
                      >
                        {data.tag}
                      </div>
                      <div style={{ fontSize: '12px', color: data.actionRequired ? 'red' : 'inherit' }}>{` = ${data.description}`}</div>
                    </div>
                    {data.actionRequired && <div style={{ marginLeft: '40px', fontSize: '12px' }}>REQUIRES ACTION</div>}
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="largePage">
      {tubesPoly?.map((tubeDetails, index) => {
        const hasVisiblePoly = tubeDetails?.tubePolyList?.some(
          (details) => details?.poly?.totalVisible > 0,
        );

        if (!hasVisiblePoly) {
          return null;
        }

        return (
          <>
            <div className="pl30 pr30 pageHeader">
              <div className="topHeader">
                <div className="mt10">
                  {ReportPart1(tableContent, CombineReportEnum.COMBINE_TUBE_CHARTS, PDFReportEnum.PDF_TUBE_CHARTS, 'Tube Bundle Layout')}

                  {/* {`Part ${toRoman(index + 1)} - Tube Bundle Layout`} */}
                </div>
                <div>{`S/N ${reportPdfResponse3?.JobOrderData?.SerialNumber}`}</div>
              </div>
              <div className="bigHeadingText textCenter mt10">
                {tubeDetails?.tubePolyList?.[0]?.details?.VesselName}
              </div>
            </div>
            <div className="pl30 pr30 tubeBundleHeader">
              <div className="tubesNumberedArrow">
                <div>
                  Tubes Numbered
                  <br />
                  {' '}
                  Left to Right
                </div>
                <div className="mt20">
                  Rows Numbered
                  <br />
                  {' '}
                  Top to Bottom
                </div>
              </div>
              <div className="topVesselArrow">Top of Vessel</div>
              <div>{tubeDetails?.tubePolyList?.[0]?.details?.TestEnd}</div>
            </div>
            {tubeDetails?.tubePolyList?.map((details) => (
              <TubeChartLayout key={details?.details?.ChartId} poly={details?.poly} />
            ))}

            <div style={{
              width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto',
            }}
            >
              {customLabelList(tubeDetails.allDefects)}
            </div>

            {index !== tubesPoly.length - 1 && <div className="page-break" />}
          </>
        );
      })}
    </div>
  );
}

export default TubeBundleLayoutSection;
