import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { ObjectSchema } from 'yup';

import { Button, Grid, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import './VacationModalStyle.module.scss';
import { AddLeaveFormEnum, AddLeaveFormFields, TypesComponentVacationModal } from '../../../utils/types';
import { LocalizationContext } from '../../../../../../Common/Localization/LocalizationProvider';
import ComponentTextInput from '../../../../../../Common/ComponentTextinput/ComponentTextInput';
import ComponentSelectInput from '../../../../../../Common/ComponentSelectInput';
import { addLeaveFormSchema } from '../../../utils/validations';
import RequiredFiled from '../../../../../../utils/RequiredField';
import { AddLeaveFormDefaultValues, VACATION_CONFIRMATION_MSG, addLeaveType } from '../../../utils/constants';
import { RootState } from '../../../../../../redux/rootState';
import { addAnalystLeave } from '../../../redux/AddAnalystSlice';
import ConfirmationModal from '../../../../../../Common/ConfirmationModal/ConfirmationModal';
import { ConfirmationIcon } from '../../../../../../assets/Icons/AnalystIcons/ConfirmationIcon';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  padding: '10px 30px',
  '@media (max-width: 768px)': {
    width: '90%',
  },
};
export default function ComponentVacationModal({
  rowId, show, setOpenFrom, handleCloseComponentVacationModal,
}: TypesComponentVacationModal): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs());
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs());
  const [showConformationModal, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState<AddLeaveFormFields>();
  const { analystId } = useParams();
  const dispatch = useDispatch();
  const {
    control, handleSubmit, formState: { errors }, setValue,
  } = useForm<AddLeaveFormFields>({
    defaultValues: AddLeaveFormDefaultValues,
    resolver: yupResolver(addLeaveFormSchema as ObjectSchema<AddLeaveFormFields>),
  });

  const { analystLeaveMasterData, analystLeaveData } = useSelector((state: RootState) => state.AddAnalystSliceReducer);
  const { analystList } = useSelector((state: RootState) => state.bidsReducer);

  const analystName = useMemo(() => analystList?.find((ele) => String(ele?.Value) === String(analystId)), [analystList]);

  useEffect(() => {
    if (analystId && analystName?.Text) {
      setValue(AddLeaveFormEnum.analyst, analystName?.Text);
    }
    if (rowId !== '') {
      const rowData = analystLeaveData?.Data?.find((ele) => Number(ele?.Id) === Number(rowId));
      if (rowData?.Id) {
        const analyst = analystList?.find((ele) => String(ele?.Value) === String(rowData?.AnalystId));
        setValue(AddLeaveFormEnum.description, rowData?.Note);
        setValue(AddLeaveFormEnum.leaveType, rowData?.EventId?.toString());
        if (analyst?.Text) {
          setValue(AddLeaveFormEnum.analyst, analyst?.Text);
        }
        setValue(AddLeaveFormEnum.contact, rowData?.Contact);
        if (rowData?.EndDate) {
          setToDate(dayjs(rowData?.EndDate, 'DD-MM-YYYY'));
        }
        if (rowData?.StartDate) {
          setFromDate(dayjs(rowData?.StartDate, 'DD-MM-YYYY'));
        }
      }
      return;
    }
    setValue(AddLeaveFormEnum.description, '');
    setValue(AddLeaveFormEnum.leaveType, '');
  }, [show]);

  const updateForm = (data: AddLeaveFormFields, name = '') => {
    const payload = {
      id: rowId,
      EventId: data?.leaveType,
      AnalystId: analystId,
      AnalystName: name,
      StartDate: dayjs(fromDate).format('DD-MM-YYYY') || '',
      EndDate: dayjs(toDate).format('DD-MM-YYYY') || '',
      Contact: data?.contact || '',
      Note: data?.description || '',
    };
    dispatch(addAnalystLeave({ payload, type: addLeaveType.MAIN_LEAVE_ADD_UPDATE, callback: handleCloseComponentVacationModal }));
  };

  const handleSave = (data: AddLeaveFormFields) => {
    if (rowId) {
      updateForm(data, analystName?.Text);
      return;
    }
    const payload = {
      AnalystId: analystId,
      EventId: data?.leaveType,
      AnalystName: analystName?.Text || '',
      StartDate: dayjs(fromDate).format('DD-MM-YYYY') || '',
      EndDate: dayjs(toDate).format('DD-MM-YYYY') || '',
      Contact: data?.contact || '',
      Note: data?.description || '',
    };
    dispatch(addAnalystLeave({ payload, type: addLeaveType.MAIN_LEAVE_ADD_UPDATE, callback: handleCloseComponentVacationModal }));
  };

  const onSubmit = (data: AddLeaveFormFields) => {
    const leavestaken = toDate?.diff(fromDate, 'day') || 0;
    const totalDaysRemaining = Number(analystLeaveData.AllowedLeave) - Number(analystLeaveData?.VacationSickLeaveSum);
    const canTakeLeave = totalDaysRemaining < leavestaken;

    if (canTakeLeave) {
      setShowConfirmation(true);
      setFormData(data);
      return;
    }
    handleSave(data);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleConfirmation = () => {
    setShowConfirmation(false);
    if (formData) {
      handleSave(formData);
    }
  };

  return (
    <>
      <Modal
        open={show}
        onClose={setOpenFrom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="customerInfo_container padding flex-row">
              <div className="customerInfo">
                {rowId !== '' ? 'Edit Event' : 'Add Event'}
              </div>
              <IconButton
                aria-label="close"
                onClick={setOpenFrom}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="dateWrapper" style={{ marginTop: '20px' }}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Analyst
                    </label>
                    <ComponentTextInput
                      control={control}
                      isDisable
                      inputProps={{ maxLength: 200 }}
                      name={AddLeaveFormEnum.analyst}
                      className="nonCapitalizeField"
                      id={AddLeaveFormEnum.analyst}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Events
                      <RequiredFiled />
                    </label>
                    <ComponentSelectInput
                      showColor
                      name={AddLeaveFormEnum.leaveType}
                      defaultValue=""
                      errors={errors}
                      control={control}
                      size="small"
                      entries={analystLeaveMasterData?.Data?.length > 0 ? analystLeaveMasterData?.Data : []}
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      From
                      <RequiredFiled />
                    </label>
                    <DatePicker
                      value={fromDate}
                      format="MM-DD-YYYY"
                      onChange={(newValue) => {
                        if (toDate && toDate?.isBefore(newValue)) {
                          setToDate(newValue);
                        }
                        setFromDate(newValue);
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      To
                      <RequiredFiled />
                    </label>
                    <DatePicker
                      value={toDate}
                      minDate={fromDate}
                      onChange={(newValue) => setToDate(newValue)}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Contact
                    </label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 15 }}
                      name={AddLeaveFormEnum.contact}
                      className="nonCapitalizeField"
                      id={AddLeaveFormEnum.contact}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Note
                    </label>
                    <ComponentTextInput
                      control={control}
                      multiline
                      rowSize={4}
                      maxLength={4}
                      inputProps={{ maxLength: 200 }}
                      name={AddLeaveFormEnum.description}
                      className="nonCapitalizeField"
                      id={AddLeaveFormEnum.description}
                      size="small"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid container spacing={2} sx={{ marginTop: '0px' }}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button onClick={setOpenFrom} className="button_cancel">{translations.cancel}</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button
                      type="submit"
                      className="button_save_and_next"
                    >
                      {translations.save}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      {
        showConformationModal && (
          <ConfirmationModal
            confirmationIcon={<ConfirmationIcon />}
            show={showConformationModal}
            setOpenFrom={handleConfirmationClose}
            handleConfirmation={handleConfirmation}
            text={`${analystName?.Text} ${VACATION_CONFIRMATION_MSG}`}
          />
        )
      }
    </>

  );
}
