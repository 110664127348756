import {
  Box, Button, Grid, Modal, Tab, Tabs,
} from '@mui/material';
import React, {
  useEffect,
  useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  AddUnitModalProps, AssociateUnitPayload, GeneralUnitListingData,
  JobListingPayload,
} from '../../utils/types';
import DataTable from '../../../../../Common/DataTable';
import { Datatablestyle } from '../../../jobStarter/addJob/utils/constants';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../../../../Common/TabsStyles';
import EndPoints from '../../../../../Routes/EndPoints';
import { RootState } from '../../../../../redux/rootState';
import { associateUnitsStart, getAssociatedUnitsStart, getJobUnitsStart } from '../../redux/bidWorksheetSlice';
import { constructBidPricingPayload, formatGeneralUnitListingData } from '../../utils/helper';

export function AddUnitModal({ handleClose, showModal, watch }: AddUnitModalProps) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState<GeneralUnitListingData[]>([]);
  const [activeTab, setActiveTab] = React.useState(1);
  const [unitIds, setUnitIds] = useState<string[]>([]);
  const {
    bidId, generalUnitList, totalGeneralUnits, selectedBid, jobAssociatedUnit, totalJobAssociatedUnits,
  } = useSelector((state:RootState) => state.bidsReducer);

  const bidPricingValues = watch();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const modalClose = () => {
    handleClose();
    setUnitIds([]);
    setPage(1);
    setRowsPerPage(10);
    setSearchQuery('');
    setActiveTab(1);
  };

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
      bidId: '',
    };
    if (activeTab === 2) {
      dispatch(getAssociatedUnitsStart(payload));
    }

    if (activeTab === 1) {
      const jobPayload:JobListingPayload = {
        page,
        searchQuery,
        rowsPerPage,
        jobId: selectedBid?.BidDetails?.JobId,
      };
      dispatch(getJobUnitsStart(jobPayload));
    }
  };

  const columns = useMemo(
    () => [
      { key: 'select', label: 'Select' },
      { key: 'date', label: 'Date' },
      { key: 'jobId', label: 'Job #' },
      { key: 'mfg', label: 'Mfg' },
      { key: 'model', label: 'Model' },
      { key: 'serialNumber', label: 'Serial #' },
      { key: 'vesselsToolTip', label: 'Vessels' },
    ],
    [],
  );

  const TABS = [
    { id: 1, label: 'Associated Units' },
    { id: 2, label: 'General Units' },
  ];

  const handleChangeTab = (tabId: number) => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
      bidId: '',
    };
    if (tabId === 2 && generalUnitList.length === 0) {
      dispatch(getAssociatedUnitsStart(payload));
    }
    if (tabId === 1) {
      const jobPayload:JobListingPayload = {
        page,
        searchQuery,
        rowsPerPage,
        jobId: selectedBid?.BidDetails?.JobId,
      };
      dispatch(getJobUnitsStart(jobPayload));
    }
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (activeTab === 2) {
      setData(formatGeneralUnitListingData(generalUnitList));
      setTotalRecords(totalGeneralUnits);
    } else {
      setData(formatGeneralUnitListingData(jobAssociatedUnit));
      setTotalRecords(totalJobAssociatedUnits);
    }
  }, [generalUnitList, activeTab, jobAssociatedUnit]);

  const handleButtonClick = () => {
    navigate(`${EndPoints.ADD_UNIT}/${bidId}`);
  };

  const handleCheckBox = (rowId:string) => {
    const temp = data;
    const specific = temp.find((ele:GeneralUnitListingData) => ele.id === rowId);
    if (specific) {
      specific.select = !(specific.select);
    }
    if (specific?.select) {
      setUnitIds([...unitIds, rowId]);
    } else {
      const arr = unitIds.filter((ele:string) => ele !== rowId);
      setUnitIds(arr);
    }
    setData(temp);
  };

  const handleSave = () => {
    modalClose();
    const bidPayload = selectedBid?.BidDetails;
    const bidPricingPayload = constructBidPricingPayload(bidPricingValues);
    const payload:AssociateUnitPayload = {
      BidId: bidId,
      UnitIds: unitIds,
      BidDetails: { ...bidPayload, JobId: selectedBid?.BidDetails.JobId },
      BidPriceDetails: bidPricingPayload,
    };
    dispatch(associateUnitsStart(payload));
  };

  return (
    <Modal
      open={showModal}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={Datatablestyle}>
        <div className="customerInfo_container flex-row">
          <div className="customerInfo">
            Unit Selector
          </div>
        </div>
        <div className="customModalBtnContainer">
          <Tabs
            variant="scrollable"
            value={activeTab - 1}
            onChange={(_, newValue) => handleChangeTab(TABS[newValue].id)}
            style={tabsStyles}
            TabIndicatorProps={stylesTabIndicator}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.id}
                label={tab.label}
                style={activeTab === tab.id ? selectedTabStyles : tabStyles}
                className="tab_styles"
              />
            ))}
          </Tabs>
        </div>
        {/* </div> */}
        <DataTable<GeneralUnitListingData>
          handleButtonClick={handleButtonClick}
          data={data}
          columns={columns}
          totalRecords={totalRecords}
          buttonText="Create Unit"
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          handleCheckBox={handleCheckBox}
          checkBoxColumns={['select']}
          customClassName="tableWithExtraTab"
        />
        <Grid item md={12} sm={12} xs={12}>
          <div className="save_and_next_div">
            <div>
              <Button className="button_cancel" onClick={modalClose}>Cancel</Button>
            </div>
            <div className="button_margin_left">
              {/* Pass Id Of The Selected Customer In The Handle Change */}
              <Button className="button_save_and_next" onClick={handleSave}>Select</Button>
            </div>
          </div>
        </Grid>
      </Box>
    </Modal>
  );
}
