/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { debounceTime, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PropsComponentTextInput } from './TypesComponentTextInput';

function ComponentTextInput({
  control, name, handleChange, defaultValue, maxLength, inputProps, id, isDisable,
  errors, required, placeholder, regEx, className, rowSize, multiline = false, type, adornmentProps = true, capitalizeFirst = false,
  inputRef, maxTextLength = null, autoComplete = 'off', autoFocus, onKeyDown,
}: PropsComponentTextInput) {
  const [onChange$] = useState(() => new Subject());
  const handleOnChange = (text: string, onChange: (...event: any[]) => void) => {
    if (maxTextLength && text?.length > maxTextLength) {
      return;
    }
    if (regEx && text.trim()?.length > 0 && !regEx.test(text)) {
      return;
    }
    if (capitalizeFirst) {
      const newValue = text.charAt(0).toUpperCase() + text.slice(1);
      onChange$.next(newValue);
      onChange(newValue);
      return;
    }
    if (isDisable) return;
    onChange$.next(text);
    onChange(text);
  };

  const onChangeValue = (text: string) => {
    if (!handleChange) return;
    handleChange(name, text);
  };
  useEffect(() => {
    const subscription = onChange$.pipe(
      map((text) => text),
      debounceTime(300),
    ).subscribe((text) => {
      onChangeValue(text as string);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [onChange$]);

  const error = errors?.[name]?.message;

  const getAdornmentProps = () => {
    if (adornmentProps) return { inputProps };
    return inputProps;
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <TextField
          size="small"
          fullWidth
          onChange={(text) => handleOnChange(text.currentTarget.value, onChange)}
          value={value}
          className={className || ''}
          id={id}
          type={type}
          autoComplete={autoComplete}
          InputProps={getAdornmentProps()}
          placeholder={placeholder}
          maxRows={maxLength}
          rows={rowSize}
          autoFocus={autoFocus}
          multiline={multiline}
          required={required}
          disabled={isDisable}
          inputRef={inputRef || null}
          error={!!error}
          helperText={error}
          onKeyDown={onKeyDown}
        />
      )}
    />
  );
}

export default ComponentTextInput;
