import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../../Common/DataTable';
import ComponentDeleteModal from '../../../Common/DeleteModal/ComponentDeleteModal';
import { exportToExcelData } from '../../../Common/PageHeader/helper';
import { TableColumns } from './utils/DataCustomerHeaderCell';
import { RecommendationCodesData, RecommendationCodesExcelData } from './utils/TypeRecommendationCodes';
import ComponentAddCodesModal from './addCodesModal';
import { setLoader } from '../../../redux/loaderSlice';
import { DEFAULT_VALUE_CODES } from '../constant';
import { RootState } from '../../../redux/rootState';
import {
  getRecommendationCodesData,
  onDeleteRecommendationCodes,
  onFailureCreateRecommendationCodesData, onSuccessDeleteRecommendationCodes,
} from './Redux/sliceRecommendationCodes';
import { formatTableData } from './utils/helper';
import { processData } from '../../../utils/CommonFunctions';

export default function RecommendationCodesScreen() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [rowId, setRowId] = useState('');
  const [updatedData, setUpdatedData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const {
    isLoading, recommendationCodesData, codeCreated, codeDeleted,
  } = useSelector((state: RootState) => state.RecommendationCodesReducer);
  const { showDeleteIcon } = useSelector((state: RootState) => state.masterDataReducer);

  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setSelectedId(id);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleDeleteModal = () => {
    dispatch(onDeleteRecommendationCodes(selectedId));
  };

  const handleEdit = (id: string) => {
    setShowAddModal(true);
    setRowId(id);
  };

  const handleData = () => {
    const payload = {
      page,
      rowsPerPage,
      searchQuery,
      type: DEFAULT_VALUE_CODES,
    };
    dispatch(getRecommendationCodesData(payload));
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    const modifiedData = processData(recommendationCodesData?.Data);
    setUpdatedData(modifiedData);
  }, [recommendationCodesData]);

  const excelData = useMemo(() => updatedData?.map((item:RecommendationCodesExcelData) => {
    const transformedDataItems = {
      Codes: item?.Definition,
      Description: item?.Description,
    };
    return transformedDataItems;
  }), [updatedData]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Recommendation_Codes',
    );
  };

  const handleButtonClick = () => {
    setShowAddModal(true);
  };

  useEffect(() => {
    if (codeCreated || codeDeleted) {
      setShowAddModal(false);
      setDeleteModal(false);
      handleData();
      dispatch(onFailureCreateRecommendationCodesData(false));
      dispatch(onSuccessDeleteRecommendationCodes(false));
      setRowId('');
    }
  }, [codeCreated, codeDeleted]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading="RECOMMENDATION CODES" downloadExcel={downloadExcel} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable<RecommendationCodesData>
            handleButtonClick={handleButtonClick}
            data={recommendationCodesData?.Data?.length > 0 ? formatTableData(recommendationCodesData?.Data, showDeleteIcon) : []}
            columns={TableColumns}
            totalRecords={recommendationCodesData?.TotalRecords}
            buttonText="Add"
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            htmlColumnsWithKey={['descriptionHtml']}
          />
        </Grid>
      </Grid>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />
      <ComponentAddCodesModal
        rowId={rowId}
        show={showAddModal}
        setOpenFrom={() => {
          setShowAddModal(false);
          setRowId('');
        }}
      />
    </>
  );
}
