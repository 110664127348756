/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import SummaryInspectionTable from './summaryInspectionTable';
import { RootState } from '../../../redux/rootState';
import {
  CombineReportEnum, PDFReportEnum, reportHeading, ReportPart1,
} from '../helper';

function SummaryInspectionSection() {
  const {
    reportPdfResponse2, reportPdfResponse1, themePdfColor, tableContent,
  } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  return (
    <div className="p10">
      <div className="pageHeader">
        <div className="topHeader">
          <div>
            {ReportPart1(tableContent, CombineReportEnum.COMBINE_SUMMARY_OF_INSPECTION, PDFReportEnum.PDF_SUMMARY_OF_INSPECTION, 'Summary of Inspection')}
          </div>
          <div>{`S/N ${reportPdfResponse2?.JobOrderData?.SerialNumber}`}</div>
        </div>
        <div className="underlineHeadingText textCenter">Summary of Inspection</div>
        <div className="headingDescriptionText">
          {`An ${reportHeading(reportPdfResponse1?.FrontPage?.TestType)} inspection was performed due to a known/suspected tube leak with the
          following results.`}
        </div>
      </div>
      {reportPdfResponse2?.Summary_of_Inspection?.map((details) => (
        <SummaryInspectionTable summaryDetails={details} primaryColor={BorderColor} secondaryColor={MainColor} />
      ))}
    </div>
  );
}

export default SummaryInspectionSection;
