import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  GetDropDownList,
  GetVesselMainPayload, VesselMainDataById, VesselMainInitialValues, VesselMainPayloadAPI,
  VesselPayloadType,
} from '../../utils/mainTypes';
import { GetIsASMEPayload } from '../../utils/type';

const initialState:VesselMainInitialValues = {
  vesselMainData: {} as VesselMainDataById,
  isLoading: false,
  tubeTypeData: {} as GetDropDownList[],
  testEndData: {} as GetDropDownList[],
  materialData: {} as GetDropDownList[],
  supportData: {} as GetDropDownList[],
  vesselData: {} as GetDropDownList[],
  isASMESection: false,
};

const VesselMainSlice = createSlice({
  name: 'vesselMainSlice',
  initialState,
  reducers: {
    getVesselMainByIdStart(state, _action:PayloadAction<GetVesselMainPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getVesselMainByIdSuccess(state, action:PayloadAction<VesselMainDataById>) {
      return {
        ...state,
        isLoading: false,
        vesselMainData: action.payload,
      };
    },
    getVesselMainByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    resetVesselMainData(state) {
      return {
        ...state,
        vesselMainData: {} as VesselMainDataById,
      };
    },
    updateVesselMainStart(state, _action:PayloadAction<VesselMainPayloadAPI>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateVesselMainSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateVesselMainFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // -- Get Tube Type Dropdown Data
    getTubeTypeStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getTubeTypeSuccess(state, action: PayloadAction<GetDropDownList[]>) {
      return {
        ...state,
        isLoading: false,
        tubeTypeData: action.payload,
      };
    },
    getTubeTypeFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // -- Get Test End Dropdown Data
    getTestEndStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getTestEndSuccess(state, action: PayloadAction<GetDropDownList[]>) {
      return {
        ...state,
        isLoading: false,
        testEndData: action.payload,
      };
    },
    getTestEndFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // -- Get Material Dropdown Data
    getMaterialStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getMaterialSuccess(state, action: PayloadAction<GetDropDownList[]>) {
      return {
        ...state,
        isLoading: false,
        materialData: action.payload,
      };
    },
    getMaterialFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // -- Get Support Dropdown Data
    getSupportStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getSupportSuccess(state, action: PayloadAction<GetDropDownList[]>) {
      return {
        ...state,
        isLoading: false,
        supportData: action.payload,
      };
    },
    getSupportFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // -- Get Vessel Dropdown Data
    getVesselStart(state, _action: PayloadAction<VesselPayloadType>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getVesselSuccess(state, action: PayloadAction<GetDropDownList[]>) {
      return {
        ...state,
        isLoading: false,
        vesselData: action.payload,
      };
    },
    getVesselFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // -- Is ASME
    getIsASMEStart(state, _action: PayloadAction<GetIsASMEPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getIsASMESuccess(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: false,
        isASMESection: action.payload,
      };
    },
    getIsASMEFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getVesselMainByIdStart, getVesselMainByIdFailure, getVesselMainByIdSuccess,
  updateVesselMainFailure, updateVesselMainStart, updateVesselMainSuccess,
  getTubeTypeStart, getTubeTypeSuccess, getTubeTypeFailure,
  getTestEndStart, getTestEndSuccess, getTestEndFailure,
  getMaterialStart, getMaterialSuccess, getMaterialFailure,
  getSupportStart, getSupportSuccess, getSupportFailure,
  getVesselStart, getVesselSuccess, getVesselFailure,
  resetVesselMainData,
  getIsASMEStart, getIsASMESuccess, getIsASMEFailure,
} = VesselMainSlice.actions;

export const VesselMainReducer = VesselMainSlice.reducer;

export type VesselMainActions =
    | ReturnType<typeof getVesselMainByIdFailure>
    | ReturnType<typeof getVesselMainByIdStart>
    | ReturnType<typeof getVesselMainByIdSuccess>
    | ReturnType<typeof updateVesselMainFailure>
    | ReturnType<typeof updateVesselMainStart>
    | ReturnType<typeof updateVesselMainSuccess>
    | ReturnType<typeof getTubeTypeStart>
    | ReturnType<typeof getTubeTypeSuccess>
    | ReturnType<typeof getTubeTypeFailure>
    | ReturnType<typeof getTestEndStart>
    | ReturnType<typeof getTestEndSuccess>
    | ReturnType<typeof getTestEndFailure>
    | ReturnType<typeof getMaterialStart>
    | ReturnType<typeof getMaterialSuccess>
    | ReturnType<typeof getMaterialFailure>
    | ReturnType<typeof getSupportStart>
    | ReturnType<typeof getSupportSuccess>
    | ReturnType<typeof getSupportFailure>
    | ReturnType<typeof getVesselStart>
    | ReturnType<typeof getVesselSuccess>
    | ReturnType<typeof getVesselFailure>
    | ReturnType<typeof resetVesselMainData>
    | ReturnType<typeof getIsASMEStart>
    | ReturnType<typeof getIsASMESuccess>
    | ReturnType<typeof getIsASMEFailure>;
