/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import BillingInformation from './billingInformation';
import PrimaryContact from './primaryContact';
import './StyleMainTab.scss';
import {
  CustomerInformationForm, MainFormValues,
} from '../../utils/types';
import CustomerContact from './customerContact';
import { mainFormSchema } from '../../utils/validations';
import { addCustomerStart, editCustomerStart, setFormData } from '../../redux/addCustomerSlice';
import { MainDefaultValue } from '../../utils/constants';
import { RootState } from '../../../../redux/rootState';
import { getPinCodeDetailsStart, removePinCodeDetails, resetCityStateSuggestion } from '../../../../redux/masters/masterSlice';
import { assignValues, formatMainRequest } from '../../utils/helper';
import EndPoints from '../../../../Routes/EndPoints';
import CompanyInformation from './companyInformation';
import { scrollToErrorField } from '../../../../utils/CommonFunctions';

export default function ComponentMainTab() {
  const {
    control, handleSubmit, formState: { errors }, watch, getValues, setValue, reset, trigger, setError,
  } = useForm<MainFormValues>({
    resolver: yupResolver(mainFormSchema as ObjectSchema<MainFormValues>),
    defaultValues: MainDefaultValue,
  });
  const dispatch = useDispatch();
  const { selectedCustomerDetails, customerId } = useSelector((state: RootState) => state.addCustomerReducer);
  const { pinCodeDetails, cityStateSuggestions } = useSelector((state: RootState) => state.masterDataReducer);
  const path = useLocation();
  const navigate = useNavigate();

  const companyZip = watch(CustomerInformationForm.companyZip);
  const countryName = watch(CustomerInformationForm.companyCountry);

  const { isError } = useSelector((state: RootState) => state.masterDataReducer);

  const formRef = useRef(null);

  useEffect(() => {
    if (errors) {
      const errorField = Object.keys(errors)[0];
      scrollToErrorField(errorField);
    }
  }, [errors]);

  // ------------------ > previous code
  // useEffect(() => {
  //   const subscription = watch((value) => {
  //     if (selectedCustomerDetails) {
  //       const timeoutId = setTimeout(() => {
  //         dispatch(setFormData(value as MainFormValues));
  //       }, 400);
  //       return () => clearTimeout(timeoutId);
  //     }
  //     return () => {};
  //   });

  //   return () => subscription.unsubscribe();
  // }, [watch, selectedCustomerDetails]);
  const [formData, setAllFormData] = useState<MainFormValues>({} as MainFormValues);
  const watchForm = watch();
  useEffect(() => {
    // Checking if selectedCustomerDetails has data before running the logic
    if (selectedCustomerDetails && Object.keys(selectedCustomerDetails).length > 0) {
      const timeoutId = setTimeout(() => {
        const watchedData = watch();
        if (watchedData) {
          setAllFormData(watchedData);
        }
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, [selectedCustomerDetails, watchForm]);

  // Dispatching updated formData when it chnges and is not empty
  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      dispatch(setFormData(formData));
    }
  }, [formData]);

  const handleReset = async () => {
    if (path?.pathname?.includes(EndPoints.ADD_CUSTOMER)) {
      reset();
    }
  };

  useEffect(() => {
    handleReset();
  }, [path]);

  useEffect(() => {
    if (selectedCustomerDetails?.CompanyInformation) {
      assignValues(selectedCustomerDetails, setValue);
    }
  }, [selectedCustomerDetails]);

  useEffect(() => {
    dispatch(removePinCodeDetails());
  }, []);

  const onSubmit = (data: MainFormValues) => {
    const req = formatMainRequest(data, customerId || '');
    if (companyZip && req.CompanyInformation.CityName) {
      const cityNew1 = pinCodeDetails?.City.find(((item) => item.Value === req.CompanyInformation.CityName));
      if (cityNew1) {
        if (customerId) {
          dispatch(editCustomerStart(req));
        } else {
          dispatch(addCustomerStart(req));
        }
      } else {
        setError(CustomerInformationForm.COMPANY_OTHER_CITY, { type: 'custom', message: 'Select a valid City Name' });
        // showErrorToaster('Select a valid City Name');
      }
    } else if (companyZip && !req.CompanyInformation.CityName) {
      if (customerId) {
        dispatch(editCustomerStart(req));
      } else {
        dispatch(addCustomerStart(req));
      }
    } else if (!companyZip && req.CompanyInformation.CityName) {
      const cityNew2 = cityStateSuggestions?.find(((item) => item.City === req.CompanyInformation.CityName));
      if (cityNew2) {
        if (customerId) {
          dispatch(editCustomerStart(req));
        } else {
          dispatch(addCustomerStart(req));
        }
      } else {
        setError(CustomerInformationForm.COMPANY_OTHER_CITY, { type: 'custom', message: 'Select a valid City Name' });
        // showErrorToaster('Select a valid City Name');
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (customerId) {
        dispatch(editCustomerStart(req));
      } else {
        dispatch(addCustomerStart(req));
      }
    }
  };

  const handleCancel = () => {
    navigate(`/${EndPoints.Customers}`);
  };

  useEffect(() => {
    if (companyZip && companyZip?.length >= 5) {
      dispatch(removePinCodeDetails());
      dispatch(resetCityStateSuggestion());
      setValue(CustomerInformationForm.companyState, '');
      // setValue(CustomerInformationForm.COMPANY_OTHER_CITY, '');
      const queryPayload = {
        pinCode: companyZip,
        countryCode: getValues(CustomerInformationForm.companyCountry),
      };
      dispatch(getPinCodeDetailsStart(queryPayload));
    } else if (companyZip?.length < 5) {
      dispatch(removePinCodeDetails());
      dispatch(resetCityStateSuggestion());
      setValue(CustomerInformationForm.companyState, '');
      // setValue(CustomerInformationForm.COMPANY_OTHER_CITY, '');
    }
  }, [companyZip, countryName]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (pinCodeDetails?.City && companyZip) {
      setValue(CustomerInformationForm.companyState, pinCodeDetails.StateName);
      trigger([CustomerInformationForm.companyState]);
    }
  }, [pinCodeDetails]);

  return (
    <div className="div_container_CustomerInfo">
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown} noValidate ref={formRef}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12} id="main-tab-content">
            <Grid container spacing={2}>
              {' '}
              <Grid item md={12} sm={12} xs={12}>
                <CompanyInformation
                  errors={errors}
                  control={control}
                  watch={watch}
                  trigger={trigger}
                  setValue={setValue}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <PrimaryContact errors={errors} control={control} />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <BillingInformation control={control} setValue={setValue} getValues={getValues} watch={watch} />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <CustomerContact control={control} errors={errors} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={handleCancel}>Cancel</Button>
              </div>
              <div className="button_margin_left">
                <Button type="submit" className="button_save_and_next" disabled={isError}>Save</Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
