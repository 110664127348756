/* eslint-disable max-len */
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import FirstPageSection from './firstPageSection';
import TableContentSection from './tableContentSection';
import VesselBayLengthInfoSection from './vesselBayLengthInfoSection';
import VesselInfoSection from './VesselInfoSection';
import SummaryInspectionSection from './summaryInspectionSection';
import RecommendationSection from './recommendationSection';
import DataSheetSection from './dataSheetSection';
import CalibrationProcedureSection from './calibrationProcedureSection';
import AbbreviationsSection from './abbreviationsSection';
import BartChartNewSection from './BartChartNewSection';
import LineChartSection from './LineChartSection';
import TubeBundleLayoutSection from './TubeBundleLayoutSection';
import { RootState } from '../../../redux/rootState';
import StripChartSection from './StripChartSection';
import ExpenseReport from './ComponentsExpenseReport';
import HazardAnalysis from './ComponentHazardAnalysisReport';
import { DocumentParts } from '../constant';
import ComponentPhotos from './ComponentPhotos';
import InspectionProcedure from './inspectionSummary';
import ComponentJobOrder from './ComponentJobOrder/ComponentJobOrder';

const ComponentToPrint = forwardRef<HTMLDivElement>((props, ref) => {
  const {
    checkedSections, reportPdfResponse3, reportPdfResponse2, reportPdfResponse4,
  } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  const checkArray = (num: number) => {
    if (checkedSections.some((value) => Number(value) > num)) {
      return true;
    }
    return false;
  };

  return (
    <div ref={ref} style={{ margin: '8px' }} className="printable-content">
      <div className="w100">
        <FirstPageSection />
        {/* {checkArray(Number(DocumentParts.Job_Order)) && <div className="page-break" />} */}
        { checkedSections?.includes(DocumentParts.Table_of_Contents)
       && (
       <>
         <TableContentSection />
         {checkArray(Number(DocumentParts.Job_Order)) && <div className="page-break" />}
       </>
       )}
        { checkedSections?.includes(DocumentParts.Job_Order)
       && (
       <>
         <ComponentJobOrder />
         {/* {checkedSections?.length >= 4 && <div className="page-break" />} */}
         {checkArray(Number(DocumentParts.Job_Order)) && <div className="page-break" />}
       </>
       )}

        { checkedSections?.includes(DocumentParts.Vessel_Information)
        && (
        <>
          <VesselInfoSection />
          {checkArray(Number(DocumentParts.Vessel_Information)) && <div className="page-break" />}
          <VesselBayLengthInfoSection />
          {checkArray(Number(DocumentParts.Vessel_Information)) && <div className="page-break" />}
        </>
        )}
        {checkedSections?.includes(DocumentParts.Defect_Graph) && <BartChartNewSection />}
        {/* {(checkedSections?.includes(DocumentParts.Defect_Graph) && reportPdfResponse1?.Comparison_Graphs?.length > 0) && (
          <>
            <BartChartNewSection />
            {(checkArray(Number(DocumentParts.Defect_Graph)) || reportPdfResponse1?.Comparison_Graphs?.length > 1) && <div className="page-break" />}
          </>
        )} */}

        {(checkedSections?.includes(DocumentParts.Summary_of_Inspection) && reportPdfResponse2?.Summary_of_Inspection?.length > 0)
          && (
          <>
            <SummaryInspectionSection />
            {checkArray(Number(DocumentParts.Summary_of_Inspection)) && <div className="page-break" />}
          </>
          )}

        {(checkedSections?.includes(DocumentParts.Recommendations)
            && reportPdfResponse2?.Recommendations?.[0]?.Recommendation?.length > 0) && (
            <>
              <RecommendationSection />
              {checkArray(Number(DocumentParts.Recommendations)) && <div className="page-break" />}
            </>
        )}

        {checkedSections?.includes(DocumentParts.Data_Sheets) && (
          <>
            <DataSheetSection />
            {checkArray(Number(DocumentParts.Data_Sheets)) && !checkedSections?.includes(DocumentParts.Tube_Charts) && <div className="page-break" />}
          </>
        )}

        {checkedSections?.includes(DocumentParts.Tube_Charts) && <TubeBundleLayoutSection />}
        {(checkedSections?.includes(DocumentParts.Photos) && reportPdfResponse3?.Photos?.length > 0) && (
          <>
            <ComponentPhotos />
            {checkArray(Number(DocumentParts.Photos)) && <div className="page-break" />}
          </>
        ) }
        {checkedSections?.includes(DocumentParts.Strip_Charts) && reportPdfResponse3?.Calibration_Strip_Charts?.length > 0 && (
        <>
          <StripChartSection data={reportPdfResponse3?.Calibration_Strip_Charts} showTable={reportPdfResponse3?.Calibration_Strip_Charts?.length > 0} label="Strip Chart" />
          {checkArray(Number(DocumentParts.Strip_Charts)) && <div className="page-break" />}
        </>
        ) }
        {checkedSections?.includes(DocumentParts.Strip_Charts) && reportPdfResponse3?.Tube_Strip_Charts?.length > 0 && (
          <>
            <StripChartSection data={reportPdfResponse3?.Tube_Strip_Charts} showTable={reportPdfResponse3?.Calibration_Strip_Charts?.length === 0} label="Strip Chart" />
            {checkArray(Number(DocumentParts.Strip_Charts)) && <div className="page-break" />}
          </>
        ) }
        {checkedSections?.includes(DocumentParts.Phase_Charts) && reportPdfResponse4?.Phase_Charts?.length > 0 && (
          <>
            <LineChartSection />
            {checkArray(Number(DocumentParts.Phase_Charts)) && <div className="page-break" />}
          </>
        ) }
        {checkedSections?.includes(DocumentParts.Calibration_Procedure) && (
          <>
            <CalibrationProcedureSection />
            {checkArray(Number(DocumentParts.Calibration_Procedure)) && <div className="page-break" />}
          </>
        )}
        {checkedSections?.includes(DocumentParts.Inspection_Procedure) && (
        <>
          <InspectionProcedure />
          {(checkArray(Number(DocumentParts.Inspection_Procedure)) || checkedSections?.includes(DocumentParts.Explanation_Of_Abbreviations)) && <div className="page-break" />}
        </>
        )}
        {checkedSections?.includes(DocumentParts.Hazard_Analysis) && reportPdfResponse4?.Hazard_Analysis?.length > 0 && (
        <>
          <HazardAnalysis />
          {(checkArray(Number(DocumentParts.Hazard_Analysis)) || checkedSections?.includes(DocumentParts.Explanation_Of_Abbreviations)) && <div className="page-break" />}
        </>
        )}
        {checkedSections?.includes(DocumentParts.Expense_Report) && (
          <>
            <ExpenseReport />
            {checkedSections?.includes(DocumentParts.Explanation_Of_Abbreviations) && <div className="page-break" />}
            {/* <div className="page-break" /> */}
          </>
        )}
        {checkedSections?.includes(DocumentParts.Explanation_Of_Abbreviations) && (
        <AbbreviationsSection />
        )}
      </div>
    </div>
  );
});

export default ComponentToPrint;
