import { formatDate } from '../../../../utils/CommonFunctions';
import { SelectedCustomerDetails } from '../../../addCustomer/utils/types';
import { SelectedSite } from '../../jobStarter/addJob/utils/types';
import { BidPriceDetailNames, BidWorkSheetFormNames } from './constants';
import {
  AnalystDetails, BidDetailsValues, GeneralUnitListingData, GetBidByIdResponse, UnitListing, UnitListingData,
} from './types';

export const assignCustomerValues = (setValue:SetValue, details: SelectedCustomerDetails) => {
  [
    { name: BidWorkSheetFormNames.customerSMC, value: details?.CompanyInformation?.SMC },
    { name: BidWorkSheetFormNames.customerMarkup, value: details?.CompanyInformation?.MarkupId },
    { name: BidWorkSheetFormNames.customerName, value: details?.CompanyInformation?.CustomerName },
  ].forEach(({ name, value }) => setValue(name, value));
};

export const assignSiteValues = (setValue:SetValue, details: SelectedSite) => {
  [
    { name: BidWorkSheetFormNames.siteSMC, value: details?.SMC },
    { name: BidWorkSheetFormNames.outOfCountry, value: details?.OutOfCountry },
    { name: BidWorkSheetFormNames.site, value: details?.SiteName },
    { name: BidWorkSheetFormNames.address, value: details?.Address },
    { name: BidWorkSheetFormNames.zip, value: details?.Pincode },
    { name: BidWorkSheetFormNames.state, value: details?.State },
    { name: BidWorkSheetFormNames.city, value: details?.City },
    { name: BidWorkSheetFormNames.country, value: details?.CountryCode },
    { name: BidWorkSheetFormNames.siteType, value: details?.SiteTypeId },
  ].forEach(({ name, value }) => setValue(name, value));
};

export const assignAnalystValues = (setValue:SetValue, details: AnalystDetails, driveFrom:string) => {
  const cityDetails = details?.BaseCity?.City === driveFrom ? details?.BaseCity : details?.SecondaryCity;
  [
    { name: BidWorkSheetFormNames.analystAddress, value: cityDetails?.Address },
    { name: BidWorkSheetFormNames.analystZip, value: cityDetails?.Zip },
    { name: BidWorkSheetFormNames.analystCity, value: cityDetails?.City },
    { name: BidWorkSheetFormNames.analystState, value: cityDetails?.State },
    { name: BidWorkSheetFormNames.analystCountry, value: cityDetails?.Country },
  ].forEach(({ name, value }) => setValue(name, value));
};

export function formatUnitListingData(data: UnitListing[]) {
  const res:UnitListingData[] = [];

  if (data.length === 0) return [];

  data.forEach((col:UnitListing, index) => {
    const obj = {
      sn: index,
      action: 'edit&delete',
      location: '',
      model: '',
      serialNumber: '',
      vesselsToolTip: [''],
      tubeCount: 0,
      price: '0',
      id: '',
    };

    obj.location = col.Location;
    obj.model = col.Model;
    obj.serialNumber = col.SerialNumber;
    obj.vesselsToolTip = col.Vessels.split(',') || [];
    obj.tubeCount = col.TubeCount;
    obj.price = `$ ${col.Price}`;
    obj.id = col.UnitId;

    res.push(obj);
  });

  return res;
}

export const getBidValue = (value: number) => (value > 0 ? value : '');

export const assignBidPricing = (setValue:SetValue, details:GetBidByIdResponse) => {
  const priceDetails = details?.BidPriceDetails;
  const cType = Number(priceDetails?.CorrectionFactor) < 0 ? '1' : '0';
  const cValue = cType === '1' ? priceDetails?.CorrectionFactor?.slice(1) : priceDetails?.CorrectionFactor;

  [
    { name: BidPriceDetailNames.TubeCharge, value: priceDetails?.TubeCharge },
    { name: BidPriceDetailNames.CalculatedDays, value: priceDetails?.CalculatedDays },
    { name: BidPriceDetailNames.OverRideDays, value: priceDetails?.OverrideDays },
    { name: BidPriceDetailNames.FluxLeakageDays, value: priceDetails?.FluxLeakageDays },
    { name: BidPriceDetailNames.DriveExpense, value: priceDetails?.DriveExpense },
    { name: BidPriceDetailNames.LodgePerDiem, value: priceDetails?.LodgePerDiem },
    { name: BidPriceDetailNames.LodgePerDiem, value: priceDetails?.LodgePerDiem },
    { name: BidPriceDetailNames.FlightCharge, value: getBidValue(priceDetails?.FlightCharge) },
    { name: BidPriceDetailNames.Airfare, value: getBidValue(priceDetails?.Airfare) },
    { name: BidPriceDetailNames.TotalFlightExpense, value: priceDetails?.TotalFlightExpense },
    { name: BidPriceDetailNames.EquipmentCharges, value: getBidValue(priceDetails?.EquipmentCharge) },
    { name: BidPriceDetailNames.OtherCharges, value: getBidValue(priceDetails?.OtherCharge) },
    { name: BidPriceDetailNames.TotalMiscExpense, value: priceDetails?.TotalMiscExpense },
    { name: BidPriceDetailNames.CommunicationCharges, value: getBidValue(priceDetails?.CommunicationCharge) },
    { name: BidPriceDetailNames.ParkingChanges, value: getBidValue(priceDetails?.Parking) },
    { name: BidPriceDetailNames.SafetyCharges, value: getBidValue(priceDetails?.SafetyCharge) },
    { name: BidPriceDetailNames.NightCharges, value: getBidValue(priceDetails?.NightCharge) },
    { name: BidPriceDetailNames.OTSaturday, value: getBidValue(priceDetails?.OtSaturday) },
    { name: BidPriceDetailNames.OTSunday, value: getBidValue(priceDetails?.OtSunday) },
    { name: BidPriceDetailNames.OTHoliday, value: getBidValue(priceDetails?.OtHoliday) },
    { name: BidPriceDetailNames.SafetyTraining, value: getBidValue(priceDetails?.SafetyTrainingTime) },
    { name: BidPriceDetailNames.SafetyTrainingCharges, value: priceDetails?.SafetyTrainingCharge },
    { name: BidPriceDetailNames.IsCommunicationCharges, value: priceDetails?.IsCommunicationCharge },
    { name: BidPriceDetailNames.IsParking, value: priceDetails?.IsParking },
    { name: BidPriceDetailNames.IsSafetyCharges, value: priceDetails?.IsSafetyCharge },
    { name: BidPriceDetailNames.IsNightCharges, value: priceDetails?.IsNightCharge },
    { name: BidPriceDetailNames.IsOTSaturday, value: priceDetails?.IsOtSaturday },
    { name: BidPriceDetailNames.IsOTSunday, value: priceDetails?.IsOtSunday },
    { name: BidPriceDetailNames.IsOTHoliday, value: priceDetails?.IsOtHoliday },
    { name: BidPriceDetailNames.IsSafetyTraining, value: priceDetails?.IsSafetyTraining },
    { name: BidPriceDetailNames.TotalExpenses, value: priceDetails?.TotalExpense },
    { name: BidPriceDetailNames.Markup, value: priceDetails?.Markup },
    { name: BidPriceDetailNames.AbsShipping, value: priceDetails?.AbsDiffShipping },
    { name: BidPriceDetailNames.FluxShipping, value: priceDetails?.FluxShipping },
    { name: BidPriceDetailNames.NonProdDays, value: getBidValue(priceDetails?.NonProdDays) },
    { name: BidPriceDetailNames.NonProdDaysAmount, value: priceDetails?.Amount },
    { name: BidPriceDetailNames.CorrectionFactor, value: cValue },
    { name: BidPriceDetailNames.CorrectionType, value: cType },
    { name: BidPriceDetailNames.TotalBid, value: priceDetails?.TotalBid },
  ].forEach(({ name, value }) => setValue(name, value));
};

export function formatGeneralUnitListingData(data: UnitListing[]) {
  const res:GeneralUnitListingData[] = [];
  if (data.length === 0) return [];
  data.forEach((col:UnitListing, index) => {
    const obj = {
      sn: index,
      date: '',
      jobId: '',
      mfg: '',
      vesselsToolTip: [''],
      model: '',
      serialNumber: '',
      id: '',
      select: false,
    };
    obj.date = formatDate(col?.Date);
    obj.jobId = col.JobId;
    obj.serialNumber = col.SerialNumber;
    obj.vesselsToolTip = col.Vessels.split(',');
    obj.mfg = col.Manufacturer;
    obj.id = col.UnitId;
    obj.model = col.Model;
    res.push(obj);
  });
  return res;
}

export function constructBidPricingPayload(bidPricingInfo:BidDetailsValues) {
  const payload = {
    TubeCharge: Number(bidPricingInfo.tubeCharge) || 0,
    CalculatedDays: Number(bidPricingInfo.calculatedDays) || 0,
    OverrideDays: Number(bidPricingInfo.overRideDays) || 0,
    FluxLeakageDays: Number(bidPricingInfo.fluxLeakageDays) || 0,
    DriveExpense: Number(bidPricingInfo.driveExpense) || 0,
    LodgePerDiem: Number(bidPricingInfo.lodgePerDiem) || 0,
    FlightCharge: Number(bidPricingInfo.flightCharge) || 0,
    Airfare: Number(bidPricingInfo.airfare) || 0,
    TotalFlightExpense: Number(bidPricingInfo.totalFlightExpense) || 0,
    EquipmentCharge: Number(bidPricingInfo.equipmentCharges) || 0,
    OtherCharge: Number(bidPricingInfo.otherCharges) || 0,
    TotalMiscExpense: Number(bidPricingInfo.totalMiscExpense) || 0,
    CommunicationCharge: Number(bidPricingInfo.CommunicationCharge) || 0,
    Parking: Number(bidPricingInfo.ParkingCharge) || 0,
    SafetyCharge: Number(bidPricingInfo.SafetyCharge) || 0,
    NightCharge: Number(bidPricingInfo.NightCharge) || 0,
    OtSaturday: Number(bidPricingInfo.OtSaturday) || 0,
    OtSunday: Number(bidPricingInfo.OtSunday) || 0,
    OtHoliday: Number(bidPricingInfo.OtHoliday) || 0,
    SafetyTrainingTime: Number(bidPricingInfo.safetyTraining) || 0,
    SafetyTrainingCharge: Number(bidPricingInfo.safetyTrainingCharges) || 0,
    TotalExpense: Number(bidPricingInfo.totalExpenses) || 0,
    Markup: Number(bidPricingInfo.markup) || 0,
    FluxShipping: Number(bidPricingInfo.fluxShipping) || 0,
    AbsDiffShipping: Number(bidPricingInfo.absShipping) || 0,
    NonProdDays: Number(bidPricingInfo.nonProdDays) || 0,
    Amount: Number(bidPricingInfo.nonProdDaysAmount) || 0,
    TotalBid: Number(bidPricingInfo.totalBid) || 0,
    IsCommunicationCharge: bidPricingInfo.isCommunicationCharges,
    IsParking: bidPricingInfo.isParking,
    IsSafetyCharge: bidPricingInfo.isSafetyCharges,
    IsNightCharge: bidPricingInfo.isNightCharges,
    IsOtSaturday: bidPricingInfo.isOTSaturday,
    IsOtSunday: bidPricingInfo.isOTSunday,
    IsOtHoliday: bidPricingInfo.isOTHoliday,
    IsSafetyTraining: bidPricingInfo.isSafetyTraining,
    // CorrectionFactor: String(bidPricingInfo.correctionType) === '0' ? bidPricingInfo.correctionFactor : `-${bidPricingInfo.correctionFactor}`,
    CorrectionFactor: String(bidPricingInfo.correctionType) === '0' || bidPricingInfo.correctionFactor === '0'
      ? bidPricingInfo.correctionFactor
      : `-${bidPricingInfo.correctionFactor}`,
  };

  return payload;
}

export const getValidNumeric = (n:number) => {
  if (n) {
    return Number(n);
  }
  return 0;
};
