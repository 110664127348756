import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../../../../Common/DataTable';
import {
  DeleteUnitPayload, UnitListingData,
  UnitToTestProp,
} from '../../utils/types';
import { UnitListingColumns } from '../../utils/constants';
import EndPoints from '../../../../../Routes/EndPoints';
import { RootState } from '../../../../../redux/rootState';
import { deleteUnitStart, getAssociatedUnitsStart } from '../../redux/bidWorksheetSlice';
import { formatUnitListingData } from '../../utils/helper';
import { AddUnitModal } from './addUnitModal';
import ComponentDeleteModal from '../../../../../Common/DeleteModal/ComponentDeleteModal';

function ComponentUnitToTest({ watch }:UnitToTestProp) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const navigate = useNavigate();
  const {
    bidId, unitList, totalUnits,
  } = useSelector((state:RootState) => state.bidsReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleData = () => {
    const payload = {
      page,
      rowsPerPage,
      searchQuery,
      bidId,
    };

    dispatch(getAssociatedUnitsStart(payload));
  };

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDelete = (unitId:string) => {
    setSelectedId(unitId);
    setDeleteModal(true);
  };
  const handleDeleteModal = () => {
    const payload:DeleteUnitPayload = {
      unitId: selectedId,
      bidId,
    };
    dispatch(deleteUnitStart(payload));
    setDeleteModal(false);
  };

  const handleEdit = (unitId:string) => {
    navigate(`${EndPoints.UNIT}/${unitId}`);
  };

  // const handleSave = () => {
  //   const bidPayload = selectedBid?.BidDetails;
  //   const bidPricingPayload = constructBidPricingPayload(bidPricingValues);
  //   const payload: UpdateBidPayload = {
  //     BidId: bidId,
  //     BidDetails: { ...bidPayload, JobId: selectedBid?.BidDetails.JobId },
  //     BidPriceDetails: bidPricingPayload,
  //   };
  //   dispatch(updateBidStart(payload));
  // };

  return (
    <>
      <DataTable<UnitListingData>
        handleButtonClick={handleButtonClick}
        data={formatUnitListingData(unitList) || []}
        columns={UnitListingColumns}
        totalRecords={totalUnits}
        buttonText="Add Unit"
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
      />
      <AddUnitModal handleClose={handleClose} showModal={showModal} watch={watch} />
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />
      <br />
      {/* <Grid item xs={12} sm={12} md={12}>
        <div className="save_and_next_div">
          <Button type="submit" className="button_save_and_next" onClick={handleSave}>Save</Button>
        </div>
      </Grid> */}
    </>
  );
}
export default ComponentUnitToTest;
