export const PDF_QUOTE = `If this quote is accepted and work performed hereunder by TAI,
TAI’s total liability in connection with the work is limited to
the amount paid to TAI for the work. IN NO EVENT WILL TAI BE
LIABLE FOR ANY INDIRECT OR CONSEQUENTIAL LOSS, DAMAGE,
COST OR EXPENSE OF ANY KIND WHATEVER AND HOWEVER CAUSED,
EVEN IF TAI HAS BEEN ADVISED OF THEIR POSSIBILITY.
No action arising out of TAI’s work, regardless of
the form of action, may be brought more than one year
after the cause of action accrued. Exclusive jurisdiction
and venue for any such action against TAI shall be
in the Courts of Cobb County, Georgia.`;
export const BID_QUOTE = `THIS BID INCLUDES A WRITTEN REPORT PLUS ALL
KNOWN EXPENSES AND IS VALID FOR A PERIOD OF THIRTY DAYS.`;
export const DOT_QUOTE = '_______________________________________________';
