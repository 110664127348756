import React, { useEffect } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  selectedTabStyles, stylesTabIndicator, tabsStyles, tabStyles,
} from '../../../Common/TabsStyles';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import {
  ASME_SECTION_V_TAB, BAY_LENGTHS_TAB, DEFECT_TAB,
  LABEL_ASME_SECTION_V_TAB, LABEL_BAY_LENGTHS_TAB, LABEL_DEFECT_TAB,
  LABEL_MAIN_TAB, LABEL_PHASE_CHART_TAB, LABEL_STRIP_CHART_TAB, LABEL_TUBE_CHART_TAB,
  MAIN_TAB, PHASE_CHART_TAB, STRIP_CHART_TAB, TUBE_CHART_TAB,
} from '../utils/constant';
import MainComponent from '../components/MainComponent';
import DefectComponent from '../components/DefectComponent';
import TubeChartComponent from '../components/TubeChart';
import BayLengthComponent from '../components/Baylength';
import AsmeSectionVComponent from '../components/Asme';
import PhaseChartComponent from '../components/PhaseChartComponent';
import StripeChartComponent from '../components/StripeChartComponent';
import { RootState } from '../../../redux/rootState';
import { setSelectedTab } from '../redux/sliceVesselInformation';
import localStorageConstants from '../../../utils/LocalStorageConstants';
import { ANALYST_ROLE_ID } from '../../../Routes/constant';
import { getIsASMEStart } from '../redux/VesselMainRedux/vesselMainSlice';

export default function EditVesselInfo() {
  const { tabId, jobOrderId } = useParams();
  const dispatch = useDispatch();
  const { selectedTab } = useSelector((state: RootState) => state.VesselInformationReducer);
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  function showSelectedTabComponent(tab: number): JSX.Element {
    switch (tab) {
      case MAIN_TAB:
        return <MainComponent />;
      case DEFECT_TAB:
        return <DefectComponent />;
      case TUBE_CHART_TAB:
        return <TubeChartComponent />;
      case BAY_LENGTHS_TAB:
        return <BayLengthComponent />;
      case ASME_SECTION_V_TAB:
        return <AsmeSectionVComponent />;
      case PHASE_CHART_TAB:
        return <PhaseChartComponent />;
      default:
        return <StripeChartComponent />;
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedTab(newValue));
  };

  useEffect(() => {
    if (tabId) {
      dispatch(setSelectedTab(Number(tabId)));
    } return () => {
      dispatch(setSelectedTab(MAIN_TAB));
    };
  }, [tabId]);

  useEffect(() => {
    const payload = {
      jobOrderId: jobOrderId || '',
    };
    dispatch(getIsASMEStart(payload));
  }, []);

  return (
    <>
      <ComponentPageHeader subHeading={roleId === ANALYST_ROLE_ID ? 'REPORT' : 'VESSEL'} />
      <div className="container_job">
        <div className="edit_vessel_tab_sec">
          <Tabs
            style={tabsStyles}
            value={selectedTab}
            onChange={handleChange}
            TabIndicatorProps={stylesTabIndicator}
            className="edit_vessel_tab_row"
          >
            <Tab
              label={LABEL_MAIN_TAB}
              value={MAIN_TAB}
              style={selectedTab === MAIN_TAB ? selectedTabStyles : tabStyles}
            />
            <Tab
              label={LABEL_DEFECT_TAB}
              value={DEFECT_TAB}
              style={selectedTab === DEFECT_TAB ? selectedTabStyles : tabStyles}
            />
            <Tab
              label={LABEL_TUBE_CHART_TAB}
              value={TUBE_CHART_TAB}
              style={selectedTab === TUBE_CHART_TAB ? selectedTabStyles : tabStyles}
            />
            <Tab
              label={LABEL_BAY_LENGTHS_TAB}
              value={BAY_LENGTHS_TAB}
              style={selectedTab === BAY_LENGTHS_TAB ? selectedTabStyles : tabStyles}
            />
            <Tab
              label={LABEL_ASME_SECTION_V_TAB}
              value={ASME_SECTION_V_TAB}
              style={selectedTab === ASME_SECTION_V_TAB ? selectedTabStyles : tabStyles}
            />
            <Tab
              label={LABEL_PHASE_CHART_TAB}
              value={PHASE_CHART_TAB}
              style={selectedTab === PHASE_CHART_TAB ? selectedTabStyles : tabStyles}
            />
            <Tab
              label={LABEL_STRIP_CHART_TAB}
              value={STRIP_CHART_TAB}
              style={selectedTab === STRIP_CHART_TAB ? selectedTabStyles : tabStyles}
            />
          </Tabs>
        </div>
      </div>
      <div className="div_job_container_info">
        {showSelectedTabComponent(selectedTab)}
      </div>
    </>
  );
}
