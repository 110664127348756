import * as Yup from 'yup';
import { LangTypes } from '../../../assets/lang/Types/langTypes';
import { phoneRegex } from '../../../utils/regex';

export const addSmcDetailsValidationSchema = (translations: LangTypes) => Yup.object().shape({
  smcName: Yup
    .string()
    .required('Company name is required')
    .matches(/^[a-zA-Z0-9\s]+$/, translations.companyAlphaNumeric)
    .max(255, translations.companyNot255),
  taiIdNo: Yup
    .string()
    .required('TAI ID number is required'),
  phoneNumber: Yup
    .string()
    .required(translations.phoneRequired)
    .matches(phoneRegex, translations.phoneMatches)
    .max(20, translations.not20),
  phoneCode: Yup
    .string()
    .required(translations.dropdownRequired),
  url: Yup
    .string()
    .url(translations.invalidUrlFormat)
    .max(255, translations.urlNot100),
  address: Yup
    .string(),
  // .required('Address is required'),
  addressOptional: Yup
    .string(),
  zip: Yup.string(),
  city: Yup.string(),
  country: Yup
    .string(),
  state: Yup
    .string(),
  smcSites: Yup
    .array(),
  smcCustomers: Yup
    .array(),
  tempSmcCustomer: Yup
    .string(),
  tempSmcSites: Yup
    .string(),
  tempSmcSiteCustomer: Yup
    .string(),
  smcSiteCustomers: Yup
    .string(),
  tempCustomers: Yup
    .string(),
});
