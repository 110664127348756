/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useEffect, useMemo } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AddDefectComponentPropTypes } from './utils/type';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import { CommonDateInput } from '../../../Common/CommonDateInput';
import { DefectInformationEnum } from '../utils/type';
import ComponentSelectInput from '../../../Common/ComponentSelectInput';
import { UpdateDefectPayload, getAddDefectPayload, setDefectValues } from '../utils/helper';
import { addDefectSchema } from '../utils/validation';
import { AddDefectDefaultData, AddDefectSelectFields, AddDefectTextFields } from '../utils/constant';
import { DefectInformationFields } from '../utils/defectType';
import {
  createDefectStart, getDefectByIdStart, getDefectDropdownStart, resetDefectData, updateVesselDefectStart,
} from '../redux/DefectRedux/sliceVesselDefect';
import { RootState } from '../../../redux/rootState';
import Common24HTimeInput from '../../../Common/Common24HTimePicker';
import RequiredFiled from '../../../Common/RequiredField';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function AddDefectComponent({
  show, setOpenFrom, rowId, setRowId, callback,
}: AddDefectComponentPropTypes): JSX.Element {
  const dispatch = useDispatch();
  const { jobOrderId, vesselId } = useParams();

  const {
    control, handleSubmit, formState: { errors }, setValue, watch, trigger,
  } = useForm<DefectInformationFields>({
    resolver: yupResolver(addDefectSchema),
    defaultValues: AddDefectDefaultData as unknown as DefectInformationFields,
  });
  const { defectDataById, allDefectDropdownData } = useSelector((state: RootState) => state.VesselDefectReducer);

  const locationWatch = watch(DefectInformationEnum.location);

  const onSubmit = (data: DefectInformationFields) => {
    if (defectDataById?.Id) {
      const payLoad = UpdateDefectPayload(data, defectDataById);
      dispatch(updateVesselDefectStart({ payLoad, callback }));
      setOpenFrom();
    } else {
      const payLoad = getAddDefectPayload(data, jobOrderId || '', vesselId || '');
      dispatch(createDefectStart({ payLoad, callback }));
      setOpenFrom();
    }
  };

  useEffect(() => {
    if (defectDataById) {
      setDefectValues(defectDataById, setValue);
    }
  }, [defectDataById]);

  useEffect(() => {
    if (rowId && show) {
      const payload = {
        id: rowId,
      };
      dispatch(getDefectByIdStart(payload));
    } return () => {
      setRowId('');
      dispatch(resetDefectData());
    };
  }, [rowId, show]);

  useEffect(() => {
    const payload = {
      param: 'abc', // ---- Temp  ------->>>>>>
      jobOrder: jobOrderId || '',
      vesselId: vesselId || '',
    };
    dispatch(getDefectDropdownStart(payload));
  }, []);

  const selectFields = useMemo(
    () => AddDefectSelectFields(allDefectDropdownData, locationWatch, setValue, trigger),
    [allDefectDropdownData, locationWatch, setValue, defectDataById, trigger],
  );

  return (
    <div>
      <Dialog
        maxWidth="md"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          {rowId ? 'Edit Defect' : 'Add Defect'}
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              {selectFields.map((item) => (
                <Grid key={item.name} item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className={item.disabled ? 'label_Disable' : 'label_Style_Customer'}>
                      {item.label}
                      {!item.disabled && <RequiredFiled /> }
                    </label>
                    <ComponentSelectInput
                      name={item.name}
                      control={control}
                      errors={errors}
                      handleChange={item.onChange}
                      disabled={item.disabled}
                      size="small"
                      entries={item.entries || []}
                    />
                  </div>
                </Grid>
              ))}
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Date
                    <RequiredFiled />
                  </label>
                  <CommonDateInput
                    control={control}
                    errors={errors}
                    name={DefectInformationEnum.date}
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Time
                    <RequiredFiled />
                  </label>
                  <Common24HTimeInput
                    control={control}
                    errors={errors}
                    name={DefectInformationEnum.time}
                  />
                </div>
              </Grid>
              {AddDefectTextFields.map((item) => (
                <Grid key={item.label} item md={3} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      {item.label}
                      <RequiredFiled />
                    </label>
                    <ComponentTextInput
                      control={control}
                      regEx={/^([1-9][0-9]*)?$/}
                      inputProps={{ maxLength: 3 }}
                      name={item.name}
                      id={item.name}
                      size="small"
                      errors={errors}
                    />
                  </div>
                </Grid>
              ))}
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button type="button" className="button_cancel" onClick={setOpenFrom}>Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">Save</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddDefectComponent;
