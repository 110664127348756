import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';
import { TubeData } from '../types';

function StripChartTable({ calibrationTableData }: { calibrationTableData: TubeData }) {
  const { themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  return (
    <table className="bordered-table w100">
      <div
        className="tableAfterThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />
      <div
        className="tableBeforeThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />
      <tr>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Tube Type
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Material
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Nom Wall Thick
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          End Wall Thick
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          OD
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Test Type
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Probe Diameter
        </td>
      </tr>
      <tr>
        <td className="textCenter">{calibrationTableData?.TubeType}</td>
        <td className="textCenter">{calibrationTableData?.Material}</td>
        <td className="textCenter">{calibrationTableData?.NominalWallThickness}</td>
        <td className="textCenter">{calibrationTableData?.EndWallThickness}</td>
        <td className="textCenter">{calibrationTableData?.OuterDiameter}</td>
        <td className="textCenter">{calibrationTableData?.TestType}</td>
        <td className="textCenter">{calibrationTableData?.ProbeDiameter}</td>
      </tr>
    </table>
  );
}

export default StripChartTable;
