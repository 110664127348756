import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../Common/TabsStyles';
import './StyleAddCustomer.scss';
import ComponentMainTab from './Components/main';
import {
  MAIN_TAB, COMMENT_TAB, CONTACT_TAB, JOB_TAB, BIDS_TAB, LABEL_MAIN_TAB, LABEL_COMMENT_TAB,
  LABEL_CONTACT_TAB, LABEL_JOB_TAB, LABEL_BIDS_TAB, CUSTOMER_ID, THEMES_TAB, LABEL_THEMES_TAB,
} from './utils/constants';
import ComponentCommentsTab from './Components/comments';
import CustomerJobOrderDataTable from './Components/ComponentJobOrder/Components/CustomerJobOrderDataTable';
import CustomerBidsDataTable from './Components/ComponentBids';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import CustomerContacts from './Components/contact';
import { RootState } from '../../redux/rootState';
import { getCustomerByIdStart, removeSelectedCustomer, setSelectedTab } from './redux/addCustomerSlice';
import StorageUtils from '../../utils/StorageUtils';
import { GetCustomerByIdPayload } from './utils/types';
import { setLoader } from '../../redux/loaderSlice';
import Themes from './Components/ComponnetThemes';
import { stripCustPrefix } from '../../utils/CommonFunctions';
import Homepage from './Components/main/printMainForm';

export default function ScreenAddCustomer() {
  const { selectedTab, isLoading, customerId: isCustomer } = useSelector((state: RootState) => state.addCustomerReducer);
  const dispatch = useDispatch();
  const componentRef = useRef<HTMLDivElement>(null);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedTab(newValue));
  };
  const { customerId } = useParams();

  useEffect(() => {
    const custId = StorageUtils.getString(CUSTOMER_ID);
    if (custId) {
      const payload: GetCustomerByIdPayload = {
        customerId: custId,
        otherCustomer: false,
      };
      dispatch(getCustomerByIdStart(payload));
    }
    dispatch(setSelectedTab(MAIN_TAB));

    return () => {
      dispatch(removeSelectedCustomer());
    };
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (customerId) {
      const payload: GetCustomerByIdPayload = {
        customerId,
        otherCustomer: false,
      };
      dispatch(getCustomerByIdStart(payload));
      StorageUtils.setString(CUSTOMER_ID, customerId);
    }
  }, [customerId]);

  function showSelectedTabComponent(): JSX.Element {
    switch (selectedTab) {
      case MAIN_TAB:
        return <ComponentMainTab />;
      case COMMENT_TAB:
        return <ComponentCommentsTab />;
      case CONTACT_TAB:
        return <CustomerContacts />;
      case BIDS_TAB:
        return <CustomerBidsDataTable />;
      case THEMES_TAB:
        return <Themes />;
      default:
        return <CustomerJobOrderDataTable />;
    }
  }

  interface Tab {
    tab: number;
    selector: string;
  }

  const handleDownloadPDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Customer_Form',
    removeAfterPrint: true,
    copyStyles: true,
  });

  return (
    <>
      <ComponentPageHeader
        subHeading="CUSTOMER"
        fieldId={stripCustPrefix(isCustomer) || ''}
        fieldName="Customer ID"
        printIcon={!!customerId}
        handlePrintClick={handleDownloadPDF}
      />
      <div style={{ display: 'none' }}>
        <Homepage ref={componentRef} />
      </div>
      <div className="container_job globalTabScroll">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          <Tab
            label={LABEL_MAIN_TAB}
            style={selectedTab === MAIN_TAB ? selectedTabStyles : tabStyles}
            value={MAIN_TAB}
          />
          <Tab
            label={LABEL_COMMENT_TAB}
            style={selectedTab === COMMENT_TAB ? selectedTabStyles : tabStyles}
            value={COMMENT_TAB}
            disabled={!isCustomer}
          />
          <Tab
            label={LABEL_CONTACT_TAB}
            style={selectedTab === CONTACT_TAB ? selectedTabStyles : tabStyles}
            value={CONTACT_TAB}
            disabled={!isCustomer}
          />
          <Tab
            label={LABEL_THEMES_TAB}
            style={selectedTab === THEMES_TAB ? selectedTabStyles : tabStyles}
            value={THEMES_TAB}
            disabled={!isCustomer}
          />
          {customerId
            && (
              <Tab
                label={LABEL_JOB_TAB}
                style={selectedTab === JOB_TAB ? selectedTabStyles : tabStyles}
                value={JOB_TAB}
                disabled={!isCustomer}
              />
            )}
          {customerId
            && (
              <Tab
                label={LABEL_BIDS_TAB}
                style={selectedTab === BIDS_TAB ? selectedTabStyles : tabStyles}
                value={BIDS_TAB}
                disabled={!isCustomer}
              />
            )}
        </Tabs>
      </div>
      <div className="div_job_container_info">
        {showSelectedTabComponent()}
      </div>
    </>

  );
}
