/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import ComponentExpenseCustomer from './ComponentExpenseCustomer';
import ComponentExpenseMainTable from './ComponentExpenseMainTable';
import { ExpenseReports } from '../../types';
import ComponentExpenseAnalystTable from './ComponentExpenseAnalystTable';
import { RootState } from '../../../../redux/rootState';
import { CombineReportEnum, PDFReportEnum, ReportPart1 } from '../../helper';

function ExpenseReport() {
  const { reportPdfResponse4, tableContent } = useSelector((state: RootState) => state.reportPdfSliceReducer);

  return (
    <>
      {reportPdfResponse4.Expense_Report?.map((item: ExpenseReports, index: number) => (
        <>
          {index > 0 && <div className="page-break" />}
          <div className="ExpenseReportPDFDiv">
            <div className="topHeader">
              <div>
                {`${ReportPart1(tableContent, CombineReportEnum.COMBINE_EXPENSE_REPORT, PDFReportEnum.PDF_EXPENSE_REPORT, 'Expense Report')} - Page - ${index + 1}`}
                {/* {`Part- ${tableContent.indexOf(CombineReportEnum.COMBINE_EXPENSE_REPORT || PDFReportEnum.PDF_EXPENSE_REPORT)} Expense Report - Page - ${index + 1}`} */}
              </div>
              <div />
            </div>
            <ComponentExpenseCustomer customerData={item.Customerdata} />
            <ComponentExpenseMainTable mainTableData={item} />
            <ComponentExpenseAnalystTable expenseAnalystTable={item} />
          </div>
        </>
      ))}
    </>
  );
}

export default ExpenseReport;
