import React from 'react';
import { useSelector } from 'react-redux';
import { VesselInfoProps } from '../redux/types';
import { RootState } from '../../../redux/rootState';

function CommonTableWithTwoColumn({ data }:VesselInfoProps) {
  const { themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  return (
    <table className="bordered-table w100 mxWidth400 pb30 mb30 noTableBreak">
      <div
        className="tableAfterThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />
      <div
        className="tableBeforeThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />
      <tr>
        <td colSpan={2} className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          {data.VesselName}
        </td>
      </tr>
      <tr>
        <td className="textRight w45">TestEnd</td>
        <td className="textCenter w55">{data.TestEnd}</td>
      </tr>
      <tr>
        <td className="textRight w45">Tube Count</td>
        <td className="textCenter w55">{data.TubeCount}</td>
      </tr>
      <tr>
        <td className="textRight w45">Tube Type</td>
        <td className="textCenter w55">{data.TubeType}</td>
      </tr>
      <tr>
        <td className="textRight w45">Tube Material</td>
        <td className="textCenter w55">{data.Material}</td>
      </tr>
      <tr>
        <td className="textRight w45">OD</td>
        <td className="textCenter w55">{data.OD}</td>
      </tr>
      <tr>
        <td className="textRight w45">*NWT/Under Fins</td>
        <td className="textCenter w55">{data.NwtUnderFins}</td>
      </tr>
      <tr>
        <td className="textRight w45">*NWT/Bell/Land</td>
        <td className="textCenter w55">{data.NWTBell}</td>
      </tr>
      <tr>
        <td className="textRight w45">#/Type Support</td>
        <td className="textCenter w55">{data.TypeSupport}</td>
      </tr>
      <tr>
        <td className="textRight w45">Tube Numbering</td>
        <td className="textCenter w55">{data.TubeNumbering}</td>
      </tr>
      <tr>
        <td className="textRight w45">Row Numbering</td>
        <td className="textCenter w55">{data.RowNumbering}</td>
      </tr>
      <tr>
        <td className="textRight w45">Tube Length +- 2</td>
        <td className="textCenter w55">{`${data.TubeLength} inches`}</td>
      </tr>
    </table>
  );
}

export default CommonTableWithTwoColumn;
