import { stripCustPrefix } from '../../../../utils/CommonFunctions';
import { Empty } from '../../../ScreenAddSmc/Utils/TypeSmc';
import { JobData, JobsTableData } from './types';

export function formatJobsData(data: JobData[]) {
  const res:JobsTableData[] = [];

  if (data.length === 0) return [];

  data.forEach((col:JobData, index) => {
    const obj = {
      sn: index,
      action: 'edit&delete',
      jobId: '',
      custId: '',
      customer: '',
      site: '',
      state: '',
      city: '',
      id: '',
    };

    obj.jobId = col.JobId;
    obj.custId = stripCustPrefix(col.CustId);
    obj.customer = col.CustomerName;
    obj.site = col.SiteName;
    obj.city = col.CityName;
    obj.state = col.StateName;
    obj.id = col.JobId;

    res.push(obj);
  });

  return res;
}

export function formatJobsDataXl(data: JobData[]) {
  const res:Empty = [];

  if (data.length === 0) return [];

  data.forEach((col:JobData) => {
    const obj = {
      // sn: index,
      // action: 'edit&delete',
      JobId: '',
      CustId: '',
      Customer: '',
      Site: '',
      State: '',
      City: '',
      // id: '',
    };

    obj.JobId = col.JobId;
    obj.CustId = stripCustPrefix(col.CustId);
    obj.Customer = col.CustomerName;
    obj.Site = col.SiteName;
    obj.City = col.CityName;
    obj.State = col.StateName;
    // obj.id = col.JobId;

    res.push(obj);
  });

  return res;
}
