import {
  TextField, IconButton, Grid, Card,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { JobOrderSiteFormFields } from '../../utils/constants';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import { extRegex, phoneRegex } from '../../../../utils/regex';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { RootState } from '../../../../redux/rootState';
import { TypesClientSite } from '../../utils/types';
import { RHFAutocompleteField } from '../../../../Common/ComponentAutoSelect';
import { getPinCodeDetailsStart, removePinCodeDetails } from '../../../../redux/masters/masterSlice';

export default function ComponentSite({
  control, watch, setValue, trigger,
}: TypesClientSite): JSX.Element {
  const {
    phoneCodes, citySuggestions, pinCodeDetails, countries,
  } = useSelector((state: RootState) => state.masterDataReducer);
  const siteZip = watch(JobOrderSiteFormFields.SiteZip);
  const dispatch = useDispatch();

  useEffect(() => {
    if (siteZip && siteZip?.length >= 5) {
      dispatch(removePinCodeDetails());
      setValue(JobOrderSiteFormFields.SiteState, '');
      const queryPayload = {
        pinCode: siteZip,
        countryCode: 'US',
      };
      dispatch(getPinCodeDetailsStart(queryPayload));
    } else if (siteZip?.length < 5) {
      dispatch(removePinCodeDetails());
      setValue(JobOrderSiteFormFields.SiteState, '');
    }
  }, [siteZip]);

  useEffect(() => {
    if (pinCodeDetails?.City && siteZip) {
      setValue(JobOrderSiteFormFields.SiteState, pinCodeDetails.StateName);
      trigger([JobOrderSiteFormFields.SiteState]);
    }
  }, [pinCodeDetails]);

  const otherCity = {
    Value: 'Other',
    Text: 'Other',
  };

  const city = watch(JobOrderSiteFormFields.SiteCity);

  const renderCityInput = () => {
    if (city === 'Other') {
      return true;
    }
    return false;
  };

  const handleZipChange = () => {
    setValue(JobOrderSiteFormFields.SiteCity, '');
  };

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          Site
        </div>
        <Controller
          control={control}
          name={JobOrderSiteFormFields.SiteId}
          render={({ field: { value } }) => (
            <TextField
              size="small"
              value={value}
              id={JobOrderSiteFormFields.SiteId}
              placeholder="Site ID *"
              disabled
              InputProps={{
                endAdornment: (
                  <IconButton disabled>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              className="mob-wd-100"
            />
          )}
        />
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Site</label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                isDisable
                name={JobOrderSiteFormFields.SiteName}
                id={JobOrderSiteFormFields.SiteName}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Contact</label>
              <ComponentTextInput
                control={control}
                isDisable
                name={JobOrderSiteFormFields.SiteContact}
                id={JobOrderSiteFormFields.SiteContact}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Address</label>
              <ComponentTextInput
                control={control}
                className="nonCapitalizeField"
                isDisable
                name={JobOrderSiteFormFields.SiteAddress}
                id={JobOrderSiteFormFields.SiteAddress}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Address (Optional)</label>
              <ComponentTextInput
                control={control}
                isDisable
                className="nonCapitalizeField"
                name={JobOrderSiteFormFields.SiteOptionalAddress}
                id={JobOrderSiteFormFields.SiteOptionalAddress}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Country</label>
              <ComponentSelectInput
                name={JobOrderSiteFormFields.SiteCountry}
                handleChange={() => setValue(JobOrderSiteFormFields.SiteZip, '')}
                control={control}
                disabled
                defaultValue="US"
                size="small"
                entries={countries}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Zip</label>
              <ComponentTextInput
                control={control}
                name={JobOrderSiteFormFields.SiteZip}
                regEx={extRegex}
                isDisable
                handleChange={handleZipChange}
                id={JobOrderSiteFormFields.SiteZip}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">State</label>
              <ComponentTextInput
                control={control}
                isDisable
                name={JobOrderSiteFormFields.SiteState}
                id={JobOrderSiteFormFields.SiteState}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">City</label>
              {renderCityInput() ? (
                <RHFAutocompleteField
                  options={citySuggestions || []}
                  control={control}
                  name={JobOrderSiteFormFields.otherSiteCity}
                />
              )
                : (
                  <ComponentSelectInput
                    name={JobOrderSiteFormFields.SiteCity}
                    defaultValue=""
                    control={control}
                    disabled
                    size="small"
                    entries={pinCodeDetails?.City ? [...pinCodeDetails.City, otherCity] : []}
                  />
                )}
            </div>
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Code</label>
              <ComponentSelectInput
                name={JobOrderSiteFormFields.SitePhoneCode}
                control={control}
                defaultValue=""
                size="small"
                disabled
                entries={phoneCodes}
              />
            </div>
          </Grid>
          <Grid item md={5} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Phone</label>
              <ComponentTextInput
                control={control}
                isDisable
                name={JobOrderSiteFormFields.SitePhone}
                id={JobOrderSiteFormFields.SitePhone}
                regEx={phoneRegex}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Cell</label>
              <ComponentTextInput
                control={control}
                isDisable
                name={JobOrderSiteFormFields.SiteCell}
                id={JobOrderSiteFormFields.SiteCell}
                regEx={phoneRegex}
                size="small"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
