import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../../Common/DataTable';
import { SelectedRequestColumnType, SendEquipmentListColumnType } from './utils/types';
import {
  RECEIVE_EQUIPMENT_CONFIRMATION, SEND_EQUIPMENT_CONFIRMATION,
  SEND_EQUIPMENT_CONFIRMATION_INFO_TEXT,
  SelectedRequestColumn, SendEquipmentListColumn, sendAndReceiveEquipmentType,
} from './utils/constants';
import { sendEquipmentType } from '../utils/constants';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import ComponentAddInventory from '../InventoryLandingPage/components/ComponentAddInventory';
import AddEquipmentModal from '../TestEquipmentRequestScreen/Components/AddEquipmentModal/AddEquipmentModal';
import ConfirmationModal from '../../../Common/ConfirmationModal/ConfirmationModal';
import { EquipmentMarkAsSendIcon } from '../../../assets/Icons/InventoryIcons/EquipmentMarkAsSendIcon';
import { ConfirmationIcon } from '../../../assets/Icons/AnalystIcons/ConfirmationIcon';
import UnlinkComponentModal from '../../../Common/UnlinkComponentModal/UnlinkComponentModal';
import {
  getDataForPrintPdfStart, getSendRequestListByIdStart, resetPdfValues, resetSendEquipmentRequestSlice, sendEquipmentStart,
} from './redux/sendEquipmentScreenSlice';
import { RootState } from '../../../redux/rootState';
import { formatEquipmentNeededWithUnlink, formatSendRequestListById } from './utils/helper';
import { getEquipmentNeededDataStart, resetInventoryEquipmentRequest } from '../TestEquipmentRequestScreen/redux/equipmentRequestSlice';
import { setLoader } from '../../../redux/loaderSlice';
import { getInventoryMasterStart } from '../InventoryLandingPage/redux/inventorySlice';
import EquipmentNeededPdfComponent from './component/EquipmentNeededPdfComponent';

function SendEquipmentScreen() {
  const componentRef = useRef(null);
  const { typeId } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');
  const [equipmentNeededPage, setEquipmentNeededPage] = useState(1);
  const [equipmentNeededRowsPerPage, setEquipmentNeededRowsPerPage] = useState(5);
  const [equipmentNeededSearchQuery, setEquipmentNeededSearchQuery] = useState('');
  const [productFormId, setProductFormId] = useState('');
  const [openInventoryForm, setOpenInventoryForm] = useState(false);
  const [showAddEquipmentModal, setShowAddEquipmentModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEquipmentInfo, setShowEquipmentInfo] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [selectedRowIdWithColor, setSelectedRowIdWithColor] = useState('');
  const [selectedUnitId, setSelectedUnitId] = useState('');
  const [linkOffId, setLinkOfId] = useState('');
  const [instruction, setInstruction] = useState('');

  const navigate = useNavigate();
  const { translations } = useContext(LocalizationContext);
  const {
    sendRequestListByIdResult, isLoading, idPdfDataGet, isPdfLoading,
  } = useSelector((state: RootState) => state.sendEquipmentRequestReducer);
  const { equipmentNeededData, equipmentNeededLoading } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);

  const getButtonText = useMemo(() => {
    if (Number(typeId) === sendEquipmentType.SEND_EQUIPMENT) {
      return 'Mark as Sent';
    }
    return 'Mark as Receive';
  }, [typeId, selectedRowIdWithColor]);

  const handleEquipmentNeededData = (id: string) => {
    const params = {
      requestId: id,
      page: equipmentNeededPage,
      rowsPerPage: equipmentNeededRowsPerPage,
      searchQuery: equipmentNeededSearchQuery,
    };
    dispatch(getEquipmentNeededDataStart(params));
  };

  const handleEquipmentRequestData = (rowId = '') => {
    if (!rowId) {
      return;
    }
    handleEquipmentNeededData(rowId);
  };
  useEffect(() => {
    if (selectedRowIdWithColor !== '') {
      handleEquipmentRequestData(selectedRowIdWithColor);
    }
  }, [equipmentNeededPage, equipmentNeededSearchQuery, equipmentNeededRowsPerPage]);

  const getConfirmationModalText = useMemo(() => {
    if (Number(typeId) === sendEquipmentType.SEND_EQUIPMENT) {
      return SEND_EQUIPMENT_CONFIRMATION;
    }
    return RECEIVE_EQUIPMENT_CONFIRMATION;
  }, [typeId]);

  const handleData = () => {
    if (typeId) {
      const params = {
        typeId,
        page,
        rowsPerPage,
        searchQuery,
      };
      dispatch(resetInventoryEquipmentRequest());
      setSelectedRowIdWithColor('');
      dispatch(getSendRequestListByIdStart(params));
    }
  };

  useEffect(() => {
    dispatch(getInventoryMasterStart());
    return () => {
      dispatch(resetSendEquipmentRequestSlice());
      dispatch(resetInventoryEquipmentRequest());
    };
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading || isPdfLoading || equipmentNeededLoading));
  }, [isLoading, isPdfLoading, equipmentNeededLoading]);

  const handleButtonClick = () => {
    if (Number(typeId) === sendEquipmentType.SEND_EQUIPMENT) {
      if (equipmentNeededData?.IsAllAvailable) {
        setShowConfirmationModal(true);
        return;
      }
      setShowEquipmentInfo(true);
    }
    if (Number(typeId) === sendEquipmentType.RECEIVE_EQUIPMENT) {
      setShowConfirmationModal(true);
    }
  };
  const handleCancel = () => {
    navigate(-1);
  };

  const callback = () => {
    setShowAddEquipmentModal(false);
    handleEquipmentNeededData(selectedRowIdWithColor);
  };

  const unlinkCallback = () => {
    setShowUnlinkModal(false);
    handleEquipmentNeededData(selectedRowIdWithColor);
  };

  const onMarkAsSendCallback = () => {
    dispatch(resetInventoryEquipmentRequest());
    setShowConfirmationModal(false);
    handleData();
  };

  const sendEquipmentApiCall = (apiType: number, callbackType: () => void, requestId: string, productId: string) => {
    dispatch(sendEquipmentStart({
      type: apiType,
      callback: callbackType,
      payload: {
        requestId,
        productId,
        unitId: selectedUnitId,
      },
    }));
  };

  const onMarkAsSend = () => {
    if (Number(typeId) === sendEquipmentType.SEND_EQUIPMENT) {
      sendEquipmentApiCall(sendAndReceiveEquipmentType.MARK_AS_SEND, onMarkAsSendCallback, selectedRowIdWithColor, '');
      return;
    }
    sendEquipmentApiCall(sendAndReceiveEquipmentType.MARK_AS_RECEIVE, onMarkAsSendCallback, selectedRowIdWithColor, '');
  };

  const handleUnlink = () => {
    sendEquipmentApiCall(sendAndReceiveEquipmentType.UNLINK, unlinkCallback, selectedRowIdWithColor, linkOffId);
  };

  const handleTabCell = (id: string) => {
    const sendRequestListUnitId = sendRequestListByIdResult?.Data?.find((ele) => ele?.RequestId === id);
    if (sendRequestListUnitId?.JobNumber) {
      setSelectedUnitId(sendRequestListUnitId?.JobNumber);
    }
    setSelectedRowIdWithColor(id);
    handleEquipmentNeededData(id);
  };

  const handleLinkOff = (id: string) => {
    setLinkOfId(id);
    setShowUnlinkModal(true);
  };

  const handleView = (id: string) => {
    setOpenInventoryForm(true);
    setProductFormId(id);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
    copyStyles: true,
  });

  const handlePrintClick = () => {
    dispatch(getDataForPrintPdfStart({
      requestId: selectedRowIdWithColor,
      instructions: instruction,
    }));
  };

  useEffect(() => {
    if (idPdfDataGet) {
      handlePrint();
      dispatch(resetPdfValues(false));
    }
  }, [idPdfDataGet]);

  const showEquipmentList = () => (
    <Grid id="print_invoice" container spacing={2} sx={{ marginTop: '0px', backgroundColor: '#FFFFFF' }}>
      <Grid item xs={12} sm={12} md={12}>
        <DataTable<SelectedRequestColumnType>
          data={equipmentNeededData?.Data?.length > 0
            ? formatEquipmentNeededWithUnlink(equipmentNeededData?.Data, Number(typeId) === sendEquipmentType.RECEIVE_EQUIPMENT)
            : []}
          totalRecords={equipmentNeededData?.TotalRecords}
          columns={SelectedRequestColumn}
          page={equipmentNeededPage}
          setPage={setEquipmentNeededPage}
          rowsPerPage={equipmentNeededRowsPerPage}
          setRowsPerPage={setEquipmentNeededRowsPerPage}
          searchQuery={equipmentNeededSearchQuery}
          setSearchQuery={setEquipmentNeededSearchQuery}
          handleData={() => { }}
          handleDataFromComponent
          textButtonDisabled={selectedRowIdWithColor === ''}
          buttonText="add"
          statusColumnsWithKey={['status']}
          handleButtonClick={() => setShowAddEquipmentModal(true)}
          handleLinkOff={handleLinkOff}
          handleView={handleView}
          fiveRecordPerPage
          handlePrintIcon={handlePrintClick}
          disableHandlePrintIcon={selectedRowIdWithColor === ''}
        />
      </Grid>
      <Grid
        sx={{
          display: 'flex', flexDirection: 'column', paddingBottom: '80px', paddingRight: '40px',
        }}
        item
        xs={12}
        sm={12}
        md={12}
      >
        <div className="div_label_text">
          <label className="label_Style_Customer">
            Instructions For Shipping List
          </label>
          <TextField
            fullWidth
            onChange={(e) => setInstruction(e?.target?.value)}
            value={instruction}
            multiline
            inputProps={{ maxLength: 500 }}
            rows={4.4}
            maxRows={4.4}
          />
        </div>
      </Grid>
    </Grid>
  );
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading={Number(typeId) === sendEquipmentType.SEND_EQUIPMENT ? 'SEND EQUIPMENT' : 'RECEIVE EQUIPMENT'} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className="div_job_container_info customBodyWithoutBtnContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} className="dataTableCustomMargin customDataTableStyles">
                <DataTable<SendEquipmentListColumnType>
                  data={sendRequestListByIdResult?.Data?.length > 0 ? formatSendRequestListById(sendRequestListByIdResult?.Data) : []}
                  totalRecords={sendRequestListByIdResult?.TotalRecords}
                  columns={SendEquipmentListColumn}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleData={handleData}
                  textButtonDisabled={selectedRowIdWithColor === '' || equipmentNeededData?.Data?.length === 0}
                  buttonText={getButtonText}
                  handleButtonClick={handleButtonClick}
                  handleTabCell={handleTabCell}
                  selectedRowIdWithColor={selectedRowIdWithColor}
                  fiveRecordPerPage
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {showEquipmentList()}
                <Grid sx={{ marginTop: '20px' }} item xs={12} sm={12} md={12} lg={12}>
                  <div className="save_and_next_div">
                    <div>
                      <Button className="button_cancel" onClick={handleCancel}>{translations.cancel}</Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {openInventoryForm && (
        <ComponentAddInventory
          productFormId={productFormId}
          show={openInventoryForm}
          setOpenFrom={() => setOpenInventoryForm(false)}
          callback={handleData}
          viewOnly
        />
      )}
      {showAddEquipmentModal && (
        <AddEquipmentModal
          selectedRowIdWithColor={selectedRowIdWithColor}
          show={showAddEquipmentModal}
          setOpenFrom={() => setShowAddEquipmentModal(false)}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          callbackFunction={callback}
          isRedirectFormReceiveEquipment={Number(typeId) === Number(sendEquipmentType.RECEIVE_EQUIPMENT)}
          typeId={Number(typeId)}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          confirmationIcon={<EquipmentMarkAsSendIcon />}
          show={showConfirmationModal}
          setOpenFrom={() => setShowConfirmationModal(false)}
          handleConfirmation={onMarkAsSend}
          text={getConfirmationModalText}
        />
      )}
      {showEquipmentInfo && (
        <ConfirmationModal
          confirmationIcon={<ConfirmationIcon />}
          show={showEquipmentInfo}
          setOpenFrom={() => setShowEquipmentInfo(false)}
          text={SEND_EQUIPMENT_CONFIRMATION_INFO_TEXT}
          readOnly
        />
      )}
      {showUnlinkModal && (
        <UnlinkComponentModal
          handleUnlink={handleUnlink}
          show={showUnlinkModal}
          setOpenFrom={() => setShowUnlinkModal(false)}
        />
      )}
      <div style={{ display: 'none' }}>
        <EquipmentNeededPdfComponent
          ref={componentRef}
          selectedRowIdWithColor={selectedRowIdWithColor}
        />
      </div>
    </>
  );
}

export default SendEquipmentScreen;
