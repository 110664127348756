/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useContext, useEffect,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { TypesDialogBox } from '../../../../utils/interface';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import { RootState } from '../../../../redux/rootState';
import { KitPriceListData, UpdateKitPriceData } from '../utils/TypesKitPriceList';
import { updateKitPrice } from '../Redux/sliceKitPrice';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { KitPriceFormValue } from '../utils/ConstantKitPrice';
import { kitPriceSchema } from '../utils/validation';
import { RegexFiveWith2D } from '../../../../utils/regex';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);
function ComponentKitPriceEditDialog({
  show, setOpenFrom, rowId,
}: TypesDialogBox): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const {
    handleSubmit, control, setValue, formState: { errors }, reset,
  } = useForm<UpdateKitPriceData>({
    resolver: yupResolver(kitPriceSchema as ObjectSchema<UpdateKitPriceData>),
  });
  const { kitPriceList } = useSelector((state: RootState) => state.KitPriceReducer);
  const tableData: KitPriceListData[] = kitPriceList?.map((item, index) => {
    const itemIndex = index;
    const transformedDataItem = {
      sn: itemIndex + 1,
      action: 'edit',
      KitDescriprion: item?.KitDescriprion,
      UnitPrice: item?.UnitPrice,
      id: item?.Id,
    };
    return transformedDataItem;
  });

  useEffect(() => {
    if (rowId) {
      tableData.forEach((rowData) => {
        if (rowData.id === rowId) {
          setValue(KitPriceFormValue.UNIT_PRICE, rowData.UnitPrice);
        }
      });
    }
  }, [rowId, show]);

  const onSubmit = (data:UpdateKitPriceData) => {
    const bodyData = {
      Id: rowId,
      UnitPrice: data.UnitPrice,
    };

    dispatch(updateKitPrice(bodyData));
    setOpenFrom();
  };

  const handleClose = () => {
    setOpenFrom();
    reset();
  };

  const validateNumber = (value: string) => {
    const regex = /^(0|\d+(\.\d+)?)$/;
    return regex.test(value) || 'Please enter a valid number (0 or greater).';
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="modalHeaderTitle">
          {translations.editPrice}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>

              <Grid item md={12} sm={12} xs={12}>

                <div className="div_label_text">
                  <label className="label_Style_job">
                    {translations.price}
                    {' '}
                    ($)
                  </label>

                  <ComponentTextInput
                    control={control}
                    name={KitPriceFormValue.UNIT_PRICE}
                    size="small"
                    regEx={RegexFiveWith2D}
                    id={KitPriceFormValue.UNIT_PRICE}
                    errors={errors}
                    inputProps={{
                      min: 0,
                      validate: validateNumber,
                    }}
                  />
                </div>

              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={handleClose}>{translations.cancel}</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">{translations.save}</Button>
                  </div>
                </div>
              </Grid>

            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ComponentKitPriceEditDialog;
