import { stripCustPrefix } from '../../../../utils/CommonFunctions';
import { Empty } from '../../../ScreenAddSmc/Utils/TypeSmc';
import { JobScheduleListData, JobsScheduleTableData } from '../../UnscheduledJobs/utils/types';

export function formatJobsData(data: JobScheduleListData[]) {
  const res: JobsScheduleTableData[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: JobScheduleListData, index) => {
    const obj = {
      sn: index,
      action: 'schedule&invoice',
      jobId: '',
      custId: '',
      customer: '',
      site: '',
      bidName: '',
      city: '',
      id: '',
      serialToolTip: [''],
      modelToolTip: [''],
      bidId: '',
      isChecked: false,
    };
    obj.id = col.BidId;
    obj.jobId = col.JobId;
    obj.custId = stripCustPrefix(col.CustId);
    obj.customer = col.CustomerName;
    obj.site = col.SiteName;
    obj.city = col.CityName;
    obj.bidName = col.BidName;
    obj.serialToolTip = col.SerialNumber;
    obj.modelToolTip = col.Model;
    obj.bidId = col.BidId;

    res.push(obj);
  });

  return res;
}

export function formatScheduledJobsDataXl(data: JobScheduleListData[]) {
  const res: Empty = [];

  if (data?.length === 0) return [];

  data?.forEach((col: JobScheduleListData) => {
    const obj = {
      JobId: '',
      CustId: '',
      Customer: '',
      Site: '',
      BidName: '',
      City: '',
      SerialNumber: [''],
      Model: [''],
      BidId: '',
    };
    obj.JobId = col.JobId;
    obj.CustId = stripCustPrefix(col.CustId);
    obj.Customer = col.CustomerName;
    obj.Site = col.SiteName;
    obj.City = col.CityName;
    obj.BidName = col.BidName;
    obj.SerialNumber = col.SerialNumber;
    obj.Model = col.Model;
    obj.BidId = col.BidId;

    res.push(obj);
  });

  return res;
}
