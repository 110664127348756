/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useEffect } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog, DialogContent, DialogTitle, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
// import { useParams } from 'react-router-dom';
import { StripChartEditorPropTypes } from '../utils/type';
// import { RootState } from '../../../../redux/rootState';
import { getStripChartByIdStart, resetStripChartData } from '../../redux/sliceVesselInformation';
import ComponentImageEditorStrip from './ComponentImageEditorStrip';
// import ComponentImageEditorStrip from '../../../photos/components/ComponentImageEditorStrip';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function StripChartEditorComponent({ show, setOpenFrom, rowId, setRowId }: StripChartEditorPropTypes): JSX.Element {
  const dispatch = useDispatch();
  // const { jobOrderId, vesselId } = useParams();

  // const { stripChartDataById } = useSelector((state:RootState) => state.VesselInformationReducer);

  useEffect(() => {
    if (rowId && show) {
      dispatch(getStripChartByIdStart({ id: rowId }));
    }
  }, [rowId, show]);

  useEffect(() => () => {
    setRowId('');
    dispatch(resetStripChartData());
  }, []);

  return (
    <div>
      <Dialog
        fullScreen
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenFrom(false)}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          View Strip Chart
          <IconButton
            aria-label="close"
            onClick={() => setOpenFrom(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '0px 0px' }}>
          <ComponentImageEditorStrip />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default StripChartEditorComponent;
