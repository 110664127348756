/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';
import ComponentLineChart from './ComponentLineChart';

function LineChartSection() {
  const { reportPdfResponse4 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const phaseCharts = reportPdfResponse4?.Phase_Charts || [];
  const phaseChartLength = phaseCharts.length;
  return (
    <>
      {
        phaseCharts.map((item, indexVal) => (
          <div>
            {
              item?.map((details, index) => (
                <div key={details.Id}>
                  {index > 0 && <div className="page-break" />}
                  <ComponentLineChart newDetails={details} />
                </div>
              ))
            }
            {indexVal !== phaseChartLength - 1 && <div className="page-break" />}
          </div>
        ))
      }
    </>
  );
}

export default LineChartSection;
