import React from 'react';
import { Card, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { ASMESectionVEnum, FirstCompProps } from '../../utils/type';
import localStorageConstants from '../../../../utils/LocalStorageConstants';
import { ANALYST_ROLE_ID } from '../../../../Routes/constant';
import { RegexWith4N3D } from '../../../../utils/regex';
import { RootState } from '../../../../redux/rootState';

export default function FirstComponent({ control, errors }:FirstCompProps) {
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);
  const { isASMESection } = useSelector((state: RootState) => state.VesselMainReducer);

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          {' '}
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={roleId === ANALYST_ROLE_ID || !isASMESection ? 'label_Disable' : 'label_Style_Customer'}>
                Maximum Scan Speed During Recording (Ft./Sec)
              </label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                isDisable={roleId === ANALYST_ROLE_ID || !isASMESection}
                regEx={RegexWith4N3D}
                // inputProps={{ maxLength: 25 }}
                name={ASMESectionVEnum.maximumScanSpeedDuringRecording}
                id={ASMESectionVEnum.maximumScanSpeedDuringRecording}
                size="small"
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={roleId === ANALYST_ROLE_ID || !isASMESection ? 'label_Disable' : 'label_Style_Customer'}>
                Serial Number Of Eddy Current Instrument
              </label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                isDisable={roleId === ANALYST_ROLE_ID || !isASMESection}
                inputProps={{ maxLength: 30 }}
                name={ASMESectionVEnum.NSerialNumberOfEddyCurrentInstrument}
                id="AnalystForm.firstName"
                size="small"
                errors={errors}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
