export const phoneRegex = /^[0-9!@#$%^&*()_+,\-.?“:;‘{}[\]|\\~`]{1,16}$/;
export const extRegex = /^[0-9!@#$%^&*()_+,\-.?“:;‘{}[\]|\\~`]{1,10}$/;
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const chargeRegex = /^(?!.*\..*\.)([0-9]{0,6}(?:\.[0-9]{0,2})?)$/;
export const percentageRegex = /^(?!.*\..*\.)(100|[0-9]{0,3}(?:\.[0-9]{0,2})?)$/;
export const roundTripMilesRegex = /^(?!.*\..*\.)(100|[0-9]{0,5}(?:\.[0-9]{0,2})?)$/;
export const roundExpenseRegex = /^(?!.*\..*\.)(100|[0-9]{0,5}(?:\.[0-9]{0,2})?)$/;
export const tubeLengthRegex = /^(?!.*\..*\.)(100|[0-9]{0,4}(?:\.[0-9]{0,2})?)$/;
export const alphabeticCharactersRegex = /^[A-Za-z ]+$/;
export const numberRegex = /\d+/g;
export const vesselRegexWith3D = /^(?!.*\..*\.)(100|[0-9]{0,3}(?:\.[0-9]{0,3})?)$/;
export const vesselRegexWith4D = /^(?!.*\..*\.)(100|[0-9]{0,3}(?:\.[0-9]{0,4})?)$/;
export const vesselRegexWith2D = /^(?!.*\..*\.)(100|[0-9]{0,4}(?:\.[0-9]{0,2})?)$/;
export const RegexFiveWith2D = /^(?!.*\..*\.)(100|[0-9]{0,5}(?:\.[0-9]{0,2})?)$/;
export const Regex18With2D = /^(?!.*\..*\.)(100|[0-9]{0,18}(?:\.[0-9]{0,2})?)$/;
export const RegexTwoWith2D = /^(?!.*\..*\.)(100|[0-9]{0,2}(?:\.[0-9]{0,2})?)$/;
// export const pctRegex = /^(100|[0-9]?\d(\.\d{0,2})?)$/;
export const pctRegex = /^(100|[0-9]?\d(\.\d{0,2})?|(\.\d{1,2}))$/;
export const NWTRegex = /^(?!.*\..*\.)(100|[0-9]{0,3}(?:\.[0-9]{0,2})?)$/;
export const RegexWithNumberAndSpecial = /^[0-9!@#$%^&*()_+,\-.?“:;‘{}[\]|\\~`]{1,20}$/;
export const AlphaNumericRegex = /^[a-zA-Z0-9]+$/;
export const AlphaNumericWithLimitRegex = /^[a-zA-Z0-9]{1,5}(\.[0-9]{1,2})?$/;
// export const NumericWithOptionalDecimalRegex = /^(?:[0-9]{1,5}(?:\.[0-9]{1,2})?|[a-zA-Z0-9]{1,5})$/;
export const NumericWithOptionalDecimalRegex = /^(?:[0-9]{1,5}(?:\.[0-9]{1,2})?|(?:\.[0-9]{1,2})|[a-zA-Z0-9]{1,5})$/;
export const RegexWith4N3D = /^(?!.*\..*\.)(100|[0-9]{0,4}(?:\.[0-9]{0,3})?)$/;
