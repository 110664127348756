import React, { useEffect, useContext, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import ComponentPageHeader from '../../../../Common/PageHeader/ComponentPageHeader';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import {
  Empty, EnumOilKitsCustomerInformationForm, InterfaceOilKitsMainFormValues,
} from './utils/types';
import CustomerComponent from './Components/CustomerComponent';
import BillingComponent from './Components/BillingComponent';
import KitsAssignedComponent from './Components/KitsAssignedComponent';
import { RootState } from '../../../../redux/rootState';
import ComponentAddOilKitModal from '../../../../Common/AddOilKitsModal/ComponentAddOilKitModal';
import EndPoints from '../../../../Routes/EndPoints';
import AddCustomerModal from '../../../../Common/AddCustomerModal';
import {
  setOpenViewKitsModal,
  onEditOilKit, onCreateOilKit,
  getDetailsById, gotDetailsById,
  setCustomerId, onCreatedOilKitFailure,
  getOilKitsUnitPrice, resetAddOilKitsState,
} from './redux/addOilKitsSlice';
import { setLoader } from '../../../../redux/loaderSlice';
import ViewOilKitsComponent from './Components/ViewOilKitsComponent';
import { getCustomerByIdStart, removeSelectedCustomer } from '../../../addCustomer/redux/addCustomerSlice';
import { GetCustomerByIdPayload } from '../../../addCustomer/utils/types';
import { MainDefaultValue } from './utils/constants';
import { MainFormSchema } from './utils/validations';
import { stripCustPrefix } from '../../../../utils/CommonFunctions';

export default function ScreenAddOilKits() {
  const { kitId } = useParams();
  const navigate = useNavigate();
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [openAddOilKitModal, setAddOilKitModal] = useState(false);
  const { otherCustomerdetails } = useSelector((state: RootState) => state.addCustomerReducer);
  const {
    openViewKitsModal, editOilKit, isLoading, kitDeleted, kitInputRange, isFilled,
  } = useSelector((state: RootState) => state.addOilKitsReducer);
  const {
    control, handleSubmit, formState: { errors }, watch, setValue, trigger,
  } = useForm<InterfaceOilKitsMainFormValues>({
    defaultValues: MainDefaultValue,
    resolver: yupResolver(MainFormSchema as unknown as ObjectSchema<InterfaceOilKitsMainFormValues>),
  });
  const { kitDataById, oilKitCreated } = useSelector((state: RootState) => state.addOilKitsReducer);
  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    dispatch(getOilKitsUnitPrice());
    return () => {
      dispatch(resetAddOilKitsState());
    };
  }, []);

  useEffect(() => {
    if (kitId) {
      dispatch(getDetailsById(kitId));
    }
    return () => {
      dispatch(removeSelectedCustomer());
      setValue(EnumOilKitsCustomerInformationForm.customer, '');
      dispatch(gotDetailsById([]));
      dispatch(setOpenViewKitsModal(false));
    };
  }, [kitId, kitDeleted]);

  useEffect(() => {
    if (editOilKit) {
      dispatch(onEditOilKit());
      navigate(`/${EndPoints.OIL_KITS}`);
    }
  }, [editOilKit]);

  const handleTabCell = (custId: string) => {
    dispatch(setCustomerId(custId));
    setShowModal(false);
    const payload: GetCustomerByIdPayload = {
      customerId: custId,
      otherCustomer: true,
    };
    setValue(EnumOilKitsCustomerInformationForm.customerSearch, stripCustPrefix(custId));
    dispatch(getCustomerByIdStart(payload));
    trigger(EnumOilKitsCustomerInformationForm.customerSearch);
  };

  useEffect(() => {
    if (otherCustomerdetails) {
      setValue(EnumOilKitsCustomerInformationForm.customer, otherCustomerdetails?.CompanyInformation?.CustomerName);
    }
  }, [otherCustomerdetails]);

  const onSubmit = (values: Empty) => {
    const formattedDate = dayjs(values?.shipDate).format('DD-MM-YYYY');

    const req: Empty = {
      type: 2,
      CustId: `Cust-${values?.customerSearch}`,
      PurchaseOrderNumber: values?.pd,
      ContactNumber: values?.contact,
      ShipDate: formattedDate,
      ShipVia: values?.shipVia,
      ShippingAddress: values?.shipAddress,
      NumberOfKits: values?.kitsNo,
      PerUnitPrice: Number(values?.price),
      AdditionalAmount: Number(values?.additionalAmount),
      TotalAmount: values?.billAmount,
      InVoiceNumber: values?.invoice,
      KitRanges: isFilled ? kitInputRange : [],
    };
    if (kitId) {
      const reqBody = {
        ...req,
        KitId: kitDataById?.KitId,
      };
      dispatch(onEditOilKit(reqBody));
    } else {
      dispatch(onCreateOilKit(req));
    }
  };

  const closeModal = () => {
    dispatch(onCreatedOilKitFailure());
    setAddOilKitModal(false);
    navigate(`/${EndPoints.OIL_KITS}`);
  };

  useEffect(() => {
    if (oilKitCreated) {
      setAddOilKitModal(true);
    }
  }, [oilKitCreated]);
  return (
    <div>
      <ComponentPageHeader subHeading="Oil Kits" />
      <div className="div_job_container_info customBodyWithoutBtnContainer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <CustomerComponent setShowModal={() => setShowModal(true)} setValue={setValue} errors={errors} control={control} watch={watch} />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <KitsAssignedComponent kitId={kitId} />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <BillingComponent setValue={setValue} control={control} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div>
                  <Button
                    onClick={() => navigate(-1)}
                    className="button_cancel"
                  >
                    {translations.cancel}
                  </Button>
                </div>
                <div className="button_margin_left">
                  <Button type="submit" className="button_save_and_next">{translations.save}</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      <ViewOilKitsComponent
        kitId={kitId}
        setOpenFrom={() => dispatch(setOpenViewKitsModal(false))}
        show={openViewKitsModal}
      />
      <AddCustomerModal
        buttonText="Add New Customer"
        show={showModal}
        setOpenFrom={() => setShowModal(false)}
        handleTabCell={handleTabCell}
        redirectLink={EndPoints.ADD_OIL_KITS}
      />
      <ComponentAddOilKitModal show={openAddOilKitModal} setOpenFrom={closeModal} />
    </div>
  );
}
