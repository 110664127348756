import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ASMESectionVFields, UpdateAsme } from '../../utils/type';
import {
  AsmeNotFound, getAsmeByIdStart, getAsmeByIdSuccess, updateAsmeStart,
} from '../../redux/sliceVesselInformation';
import FirstComponent from './firstComponent';
import ProbeCabel from './probeCabel';
import ExtensionCable from './extensionCable';
import FourthComponent from './fourthComponent';
import { RootState } from '../../../../redux/rootState';
import { assignAsmeValues } from '../../utils/helper/asme';
import { asmeInititalValues } from '../../utils/constant';
import localStorageConstants from '../../../../utils/LocalStorageConstants';
import { ANALYST_ROLE_ID } from '../../../../Routes/constant';

function AsmeSectionVComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobOrderId, vesselId } = useParams();
  const { isASMESection } = useSelector((state: RootState) => state.VesselMainReducer);
  const { asmeById, asmeNotFound } = useSelector((state:RootState) => state.VesselInformationReducer);
  const {
    control, handleSubmit, formState: { errors }, setValue,
  } = useForm<ASMESectionVFields>({
    defaultValues: asmeInititalValues,
  });
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  const onSubmit = (data: ASMESectionVFields) => {
    if (asmeNotFound) {
      const payload:UpdateAsme = {
        AsmeId: '',
        VesselId: String(vesselId),
        JobOrder: String(jobOrderId),
        MaxScanSpeed: Number(data.maximumScanSpeedDuringRecording),
        SerialNum: data.NSerialNumberOfEddyCurrentInstrument,
        LengthProbe: Number(data.probeCableLength),
        TypeProbe: data.probeCableType,
        LengthExtention: Number(data.extensionCableLength),
        TypeExtention: data.extensionCableType,
        ProbeManufacture: data.probeMfg,
        ProbeSerialNumber: data.probeSerialNumber,
        ProbeScanDirection: data.probeScanDirection,
      };
      dispatch(updateAsmeStart({ payload, asmeNotFound }));
    }
    const payload:UpdateAsme = {
      AsmeId: `${jobOrderId}-${vesselId}`,
      VesselId: String(vesselId),
      JobOrder: String(jobOrderId),
      MaxScanSpeed: Number(data.maximumScanSpeedDuringRecording),
      SerialNum: data.NSerialNumberOfEddyCurrentInstrument,
      LengthProbe: Number(data.probeCableLength),
      TypeProbe: data.probeCableType,
      LengthExtention: Number(data.extensionCableLength),
      TypeExtention: data.extensionCableType,
      ProbeManufacture: data.probeMfg,
      ProbeSerialNumber: data.probeSerialNumber,
      ProbeScanDirection: data.probeScanDirection,
    };

    dispatch(updateAsmeStart({ payload, asmeNotFound }));
  };

  useEffect(() => {
    dispatch(getAsmeByIdStart(`${jobOrderId}-${vesselId}`));
    return () => {
      dispatch(getAsmeByIdSuccess({
        Id: 0,
        AsmeId: '',
        VesselId: '',
        JobOrder: '',
        MaxScanSpeed: 0,
        SerialNum: '',
        LengthProbe: 0,
        TypeProbe: '',
        LengthExtention: 0,
        TypeExtention: '',
        ProbeManufacture: '',
        ProbeSerialNumber: '',
        ProbeScanDirection: '',
        IsActive: false,
        IsDeleted: false,
        CreatedBy: null,
        CreatedOn: '',
        ModifiedBy: null,
        ModifiedOn: '',
      }));
      dispatch(AsmeNotFound(false));
    };
  }, []);

  useEffect(() => {
    if (asmeById?.AsmeId && isASMESection) {
      assignAsmeValues(asmeById, setValue);
    }
  }, [asmeById, isASMESection]);

  return (
    <div className="div_container_CustomerInfo" id="edit_vessel_defect_search_row">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <FirstComponent control={control} errors={errors} />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <ProbeCabel control={control} errors={errors} />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <ExtensionCable control={control} errors={errors} />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <FourthComponent control={control} errors={errors} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={() => navigate(-1)}>Cancel</Button>
              </div>
              <div className="button_margin_left">
                <Button
                  type="submit"
                  disabled={roleId === ANALYST_ROLE_ID || !isASMESection}
                  className="button_save_and_next"
                >
                  Save
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default AsmeSectionVComponent;
