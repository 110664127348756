/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useEffect } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import { StripChartComponentPropTypes } from './utils/type';
import { StripChartEnum, StripChartFields } from '../utils/type';
import ComponentSelectInput from '../../../Common/ComponentSelectInput';
import { EditStripChart } from '../utils/helper';
import { getStripChartByIdStart, resetStripChartData, updateStripChartStart } from '../redux/sliceVesselInformation';
import { RootState } from '../../../redux/rootState';
import { getDefectDropdownStart } from '../redux/DefectRedux/sliceVesselDefect';
import { setStripChartValues, StripChartDefaultValue, StripChartModalSchema } from '../utils/helper/stripCharthelper';
import RequiredFiled from '../../../Common/RequiredField';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function EditStripChartComponent({ show, setOpenFrom, rowId, page, rowsPerPage, searchQuery }: StripChartComponentPropTypes): JSX.Element {
  const { control, handleSubmit, formState: { errors }, setValue } = useForm<StripChartFields>({
    defaultValues: StripChartDefaultValue,
    resolver: yupResolver(StripChartModalSchema as ObjectSchema<StripChartFields>),
  });
  const dispatch = useDispatch();
  const { jobOrderId, vesselId } = useParams();

  const { stripChartDataById } = useSelector((state:RootState) => state.VesselInformationReducer);
  const { allDefectDropdownData } = useSelector((state: RootState) => state.VesselDefectReducer);

  useEffect(() => {
    if (rowId && show) {
      dispatch(getStripChartByIdStart({ id: rowId }));
    }
  }, [rowId, show]);

  const onSubmit = (data: StripChartFields) => {
    const payLoad = EditStripChart(data, stripChartDataById);
    const getListPayload = {
      jobOrder: stripChartDataById?.JobOrder,
      vesselId: stripChartDataById?.VesselId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(updateStripChartStart({ payLoad, getListPayload }));
    setOpenFrom();
  };

  useEffect(() => {
    const payload = {
      param: 'abc', // ---- Temp  ------->>>>>>
      jobOrder: jobOrderId || '',
      vesselId: vesselId || '',
    };
    dispatch(getDefectDropdownStart(payload));
    return () => {
      dispatch(resetStripChartData());
    };
  }, []);

  useEffect(() => {
    if (stripChartDataById) {
      setStripChartValues(stripChartDataById, setValue);
    }
  }, [stripChartDataById]);

  const filteredSections = allDefectDropdownData?.Section?.filter((section) => section?.Text !== 'All');

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          Edit
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Zone
                    <RequiredFiled />
                  </label>
                  <ComponentTextInput
                    control={control}
                    regEx={/^([1-9][0-9]*)?$/}
                    inputProps={{ maxLength: 1 }}
                    name={StripChartEnum.zone}
                    id={StripChartEnum.zone}
                    size="small"
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Row
                    <RequiredFiled />
                  </label>
                  <ComponentTextInput
                    control={control}
                    regEx={/^[0-9]+$/}
                    inputProps={{ maxLength: 3 }}
                    name={StripChartEnum.row}
                    id={StripChartEnum.row}
                    size="small"
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Tube
                    <RequiredFiled />
                  </label>
                  <ComponentTextInput
                    control={control}
                    regEx={/^[0-9]+$/}
                    inputProps={{ maxLength: 3 }}
                    name={StripChartEnum.tube}
                    id={StripChartEnum.tube}
                    size="small"
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Section
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    control={control}
                    name={StripChartEnum.section}
                    size="small"
                    errors={errors}
                    entries={filteredSections || []}
                  />
                </div>
              </Grid>
              <Grid item md={8} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Title
                    {/* <RequiredFiled /> */}
                  </label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    inputProps={{ maxLength: 50 }}
                    name={StripChartEnum.title}
                    id={StripChartEnum.title}
                    size="small"
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={setOpenFrom}>Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">Save</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditStripChartComponent;
