import React, { Ref } from 'react';
import Billing from './Components/ComponentBilling';
import CompanyInfo from './Components/ComponentCompanyInfo';
import ContracterDetails from './Components/ComponentContracterDetails';
import PrimaryContact from './Components/ComponentPrimaryContact';
import styles from './Components/CompanyInfo.module.css';

export const getPageMargins = `
 @media print {
    @page {
      margin: 30px;
      size: A4;
    }
     body::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 6px double #1f1f1f;
        box-sizing: border-box;
    }
    body {
        margin: 0;
        padding: 0px;
        box-sizing: border-box;
    }
}
`;

const Homepage = React.forwardRef((_props, ref: Ref<HTMLDivElement>) => (
  <div ref={ref} className={styles.homepage}>
    <style>{getPageMargins}</style>
    <h1 className={styles.mainHeading}>Customer Information</h1>

    <CompanyInfo />

    <div className={styles.pageBreak}>
      <PrimaryContact />
    </div>

    <div className={styles.pageBreak}>
      <Billing />
    </div>

    <div className={styles.pageBreak}>
      <ContracterDetails />
    </div>
  </div>
));

export default Homepage;
