import { convertDateRangeToMMDDYYYY } from '../../../../utils/CommonFunctions';
import { EquipmentNeededListingFields } from '../../TestEquipmentRequestScreen/redux/type';
import { GetSendRequestListByIdListData } from '../redux/type';
import { SelectedRequestColumnType, SendEquipmentListColumnType } from './types';

export function formatSendRequestListById(data: GetSendRequestListByIdListData[]) {
  const res: SendEquipmentListColumnType[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: GetSendRequestListByIdListData) => {
    const obj = {
      requestId: '',
      jobNumber: '',
      analyst: '',
      jobDates: '',
      id: '',
    };
    obj.id = col.RequestId;
    obj.requestId = col.RequestId;
    obj.jobNumber = col.JobNumber;
    obj.analyst = col.Analyst;
    obj.jobDates = convertDateRangeToMMDDYYYY(col.JobDates);
    res.push(obj);
  });

  return res;
}

const getAction = (isReceiveRequestList: boolean) => {
  if (isReceiveRequestList) {
    return 'view';
  }
  return 'linkOff&view';
};

export function formatEquipmentNeededWithUnlink(data: EquipmentNeededListingFields[], isReceiveRequestList: boolean) {
  const res: SelectedRequestColumnType[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: EquipmentNeededListingFields, index) => {
    const obj = {
      sn: index,
      action: getAction(isReceiveRequestList),
      status: '',
      product: '',
      category: '',
      description: '',
      serial: '',
      id: '',
      viewOnly: true,
      isChecked: false,
    };
    obj.id = col.ProductNumber;
    obj.status = col.Status;
    obj.product = col.ProductNumber;
    obj.description = col.Description;
    obj.category = col.Category;
    obj.serial = col.SerialNumber;
    res.push(obj);
  });

  return res;
}
