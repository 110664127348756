import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import { showErrorToaster, showSuccessToaster } from '../../../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest, makePutRequest,
} from '../../../../../Common/NetworkOps';
import { GetById, ListingResponse, MasterData } from '../../../../../utils/type';
import {
  AddSitePayload,
  CreateJobPayload,
  CreateJobResponse,
  DeleteBidPayload,
  GetAssocaitedBidPayload,
  GetAssociatedBidsResponse,
  GetAssociatedSites,
  GetJobByIdResponse, GetListingPayload, JobCommentsPayload, SelectedSite, SiteListingDetails, UpdateJobPayload,
} from '../utils/types';
import {
  addCommentFailure,
  addCommentStart,
  addCommentSuccess,
  addSiteFailure,
  addSiteStart,
  addSiteSuccess,
  createJobFailure,
  createJobStart,
  createJobSuccess,
  deleteBidFailure,
  deleteBidStart,
  deleteBidSuccess,
  failureJobGetBids,
  getAssociatedBidsStart,
  getAssociatedBidsSuccess,
  getAssociatedSitesFailure,
  getAssociatedSitesStart,
  getAssociatedSitesSuccess,
  getGeneralSitesFailure, getGeneralSitesStart, getGeneralSitesSuccess, getJobBidsStart, getJobByIdFailure,
  getJobByIdStart, getJobByIdSuccess, getSiteByIdFailure,
  getSiteByIdStart, getSiteByIdSuccess, getSiteTypesFailure, getSiteTypesStart, getSiteTypesSuccess,
  JobStarterActions, setSelectedTab, successJobGetBids, updateJobFailure, updateJobStart, updateJobSuccess,
} from './addJobSlice';
import { getCustomerByIdStart } from '../../../../addCustomer/redux/addCustomerSlice';
import { JobBidsResponse, PayloadTypeJobBidsStart } from '../components/customerSiteInfo/addSiteModal/utils/Type';

// Get General Sites
async function getGeneralSites(data: GetListingPayload): Promise<ListingResponse<SiteListingDetails>> {
  const url = `${Config.jobs.getGeneralSites}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<SiteListingDetails>>(url);
  return result.data;
}

export const getGeneralSitesEpic = (action$ : Observable<JobStarterActions>) => action$.pipe(
  ofType(getGeneralSitesStart.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: GetListingPayload) => from(getGeneralSites(data)).pipe(
    map((res: ListingResponse<SiteListingDetails>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getGeneralSitesSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getGeneralSitesFailure();
    }),
    takeUntil(action$.pipe(filter(getGeneralSitesStart.match))),
    catchError((error) => of(getGeneralSitesFailure(error))),
  )),
);

// Get Site By Id
async function getSiteById(siteId: string): Promise<GetById<SelectedSite>> {
  const url = `${Config.jobs.getGeneralSites}?SiteId=${siteId}`;
  const result = await makeGetRequest<GetById<SelectedSite>>(url);
  return result.data;
}

export const getSiteByIdEpic = (action$ : Observable<JobStarterActions>) => action$.pipe(
  ofType(getSiteByIdStart.type),
  map((x) => x.payload),
  mergeMap((data: string) => from(getSiteById(data)).pipe(
    map((res: GetById<SelectedSite>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getSiteByIdSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getSiteByIdFailure();
    }),
    takeUntil(action$.pipe(filter(getSiteByIdSuccess.match))),
    catchError((error) => of(getSiteByIdFailure(error))),
  )),
);

// Get Associated Sites
async function getAssociatedSites(data: GetAssociatedSites): Promise<ListingResponse<SiteListingDetails>> {
  // eslint-disable-next-line max-len
  const url = `${Config.jobs.associatedSITES}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}&CustId=${data.customerId}`;
  const result = await makeGetRequest<ListingResponse<SiteListingDetails>>(url);
  return result.data;
}

export const getAssociatedSitesEpic = (action$ : Observable<JobStarterActions>) => action$.pipe(
  ofType(getAssociatedSitesStart.type),
  map((x) => x.payload),
  mergeMap((data: GetAssociatedSites) => from(getAssociatedSites(data)).pipe(
    map((res: ListingResponse<SiteListingDetails>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAssociatedSitesSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAssociatedSitesFailure();
    }),
    takeUntil(action$.pipe(filter(getAssociatedSitesStart.match))),
    catchError((error) => of(getAssociatedSitesFailure(error))),
  )),
);

// Add Site
async function addSite(data: AddSitePayload): Promise<GetById<SelectedSite>> {
  // eslint-disable-next-line max-len
  const url = `${Config.jobs.addSite}`;
  const result = await makePostRequest<GetById<SelectedSite>>(url, data);
  return result.data;
}

export const addSiteEpic = (action$ : Observable<JobStarterActions>) => action$.pipe(
  ofType(addSiteStart.type),
  map((x) => x.payload),
  mergeMap((data: AddSitePayload) => from(addSite(data)).pipe(
    mergeMap((res: GetById<SelectedSite>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(addSiteSuccess(), getSiteByIdStart(res.BMT.Result.SiteId));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(addSiteFailure());
    }),
    takeUntil(action$.pipe(filter(addSiteStart.match))),
    catchError((error) => of(addSiteFailure(error))),
  )),
);

// Get Site Types
async function getSiteTypes(): Promise<GetById<MasterData[]>> {
  // eslint-disable-next-line max-len
  const url = `${Config.jobs.getSiteTypes}`;
  const result = await makeGetRequest<GetById<MasterData[]>>(url);
  return result.data;
}

export const getSiteTypesEpic = (action$ : Observable<JobStarterActions>) => action$.pipe(
  ofType(getSiteTypesStart.type),
  map((x) => x.payload),
  mergeMap(() => from(getSiteTypes()).pipe(
    map((res: GetById<MasterData[]>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getSiteTypesSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getSiteTypesFailure();
    }),
    takeUntil(action$.pipe(filter(getSiteTypesStart.match))),
    catchError((error) => of(getSiteTypesFailure(error))),
  )),
);

// Add Job
async function addJob(data: CreateJobPayload): Promise<GetById<CreateJobResponse>> {
  const url = `${Config.jobs.createJob}`;
  const result = await makePostRequest<GetById<CreateJobResponse>>(url, data);
  return result.data;
}

export const addJobEpic = (action$ : Observable<JobStarterActions>) => action$.pipe(
  ofType(createJobStart.type),
  map((x) => x.payload),
  mergeMap((data: CreateJobPayload) => from(addJob(data)).pipe(
    map((res: GetById<CreateJobResponse>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return createJobSuccess(res.BMT.Result.JobId);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return createJobFailure();
    }),
    takeUntil(action$.pipe(filter(createJobStart.match))),
    catchError((error) => of(createJobFailure(error))),
  )),
);

// Add Job Comments
async function addJobComment(data: JobCommentsPayload): Promise<GetById<string>> {
  const url = `${Config.jobs.comment}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}

export const addJobCommentEpic = (action$ : Observable<JobStarterActions>) => action$.pipe(
  ofType(addCommentStart.type),
  map((x) => x.payload),
  mergeMap((data: JobCommentsPayload) => from(addJobComment(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if ((res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) || (res.BMT.ResponseCode === Config.SUCCESS_CODE)) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(addCommentSuccess(), getJobByIdStart(data.JobId), setSelectedTab(3));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(addCommentFailure());
    }),
    takeUntil(action$.pipe(filter(addCommentStart.match))),
    catchError((error) => of(addCommentFailure(error))),
  )),
);

// Get Job By id
async function getJobById(jobId:string): Promise<GetById<GetJobByIdResponse>> {
  // eslint-disable-next-line max-len
  const url = `${Config.jobs.getJobById}?JobId=${jobId}`;
  const result = await makeGetRequest<GetById<GetJobByIdResponse>>(url);
  return result.data;
}

export const getJobByIdEpic = (action$ : Observable<JobStarterActions>) => action$.pipe(
  ofType(getJobByIdStart.type),
  map((x) => x.payload),
  mergeMap((data:string) => from(getJobById(data)).pipe(
    mergeMap((res: GetById<GetJobByIdResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const job = res.BMT.Result;
        const customerPayload = {
          otherCustomer: true,
          customerId: job?.JobInfo?.CustId,
        };
        return of(getJobByIdSuccess(res.BMT.Result), getCustomerByIdStart(customerPayload), getSiteByIdStart(job?.JobInfo?.SiteId));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(getJobByIdFailure());
    }),
    takeUntil(action$.pipe(filter(getJobByIdStart.match))),
    catchError((error) => of(getJobByIdFailure(error))),
  )),
);

// Update Job
async function updateJob(data: UpdateJobPayload): Promise<GetById<string>> {
  // eslint-disable-next-line max-len
  const url = `${Config.jobs.updateJob}`;
  const result = await makePutRequest<GetById<string>>(url, data);
  return result.data;
}

export const updateJobEpic = (action$ : Observable<JobStarterActions>) => action$.pipe(
  ofType(updateJobStart.type),
  map((x) => x.payload),
  mergeMap((data:UpdateJobPayload) => from(updateJob(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(updateJobSuccess(), getJobByIdStart(data.JobId));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(updateJobFailure());
    }),
    takeUntil(action$.pipe(filter(updateJobStart.match))),
    catchError((error) => of(updateJobFailure(error))),
  )),
);

// Get Bids Listing
async function getBidListing(data: GetAssocaitedBidPayload): Promise<ListingResponse<GetAssociatedBidsResponse>> {
  // eslint-disable-next-line max-len
  const url = `${Config.jobs.getBids}?JobId=${data.jobId}&PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<GetAssociatedBidsResponse>>(url);
  return result.data;
}

export const getBidListingEpic = (action$ : Observable<JobStarterActions>) => action$.pipe(
  ofType(getAssociatedBidsStart.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data:GetAssocaitedBidPayload) => from(getBidListing(data)).pipe(
    map((res: ListingResponse<GetAssociatedBidsResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAssociatedBidsSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAssociatedSitesFailure();
    }),
    takeUntil(action$.pipe(filter(getAssociatedBidsStart.match))),
    catchError((error) => of(getAssociatedSitesFailure(error))),
  )),
);

async function deleteBid(data: DeleteBidPayload): Promise<GetById<string>> {
  const url = `${Config.bids.deleteBid}?BidId=${data.bidid}`;
  const result = await makeDeleteRequest<GetById<string>>(url);
  return result.data;
}
export const deleteBidEpic = (action$ : Observable<JobStarterActions>) => action$.pipe(
  ofType(deleteBidStart.type),
  map((x) => x.payload),
  mergeMap((data:DeleteBidPayload) => from(deleteBid(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload:GetAssocaitedBidPayload = {
          page: 1,
          rowsPerPage: 10,
          searchQuery: '',
          jobId: data.jobId,
        };
        return of(deleteBidSuccess(), getAssociatedBidsStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteBidFailure());
    }),
    takeUntil(action$.pipe(filter(deleteBidStart.match))),
    catchError((error) => of(deleteBidFailure(error))),
  )),
);

async function GetJobBidsList(data: PayloadTypeJobBidsStart): Promise<JobBidsResponse> {
  const url = `${Config.bids.listBid}?SiteId=${data.siteId}&pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<JobBidsResponse>(url);
  return result?.data;
}

export const epicGetJobBidsList = (action$: Observable<JobStarterActions>) => action$.pipe(
  filter(getJobBidsStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeJobBidsStart) => from(GetJobBidsList(data)).pipe(
    map((res: JobBidsResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successJobGetBids(res?.BMT.Result);
      }
      return failureJobGetBids(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getJobBidsStart.match))),
    catchError((error: AxiosError<JobBidsResponse>) => of(failureJobGetBids(error.response?.data.BMT.ResponseMessage as string))),
  )),
);
