import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { showErrorToaster, showSuccessToaster } from '../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../Common/Config';
import { makeGetRequest, makePostRequest } from '../../Common/NetworkOps';
import {
  CitySuggestionResponse, GenerateInvoicePayload,
  GenerateInvoiceResponse, GetById, MasterApiResponse, PinCodeApiResponse, PinCodeDetailsQuery, ReportTypeApiResponse, RoleMasterApiResponse,
} from '../../utils/type';
import {
  getCityStateSuggestionFailure,
  getCityStateSuggestionStart,
  getCityStateSuggestionSuccess,
  getCitySuggestionFailure,
  getCitySuggestionStart,
  getCitySuggestionSuccess,
  getMasterDataFailure, getMasterDataStart, getMasterDataSuccess, getPinCodeDetailsFailure,
  getPinCodeDetailsStart, getPinCodeDetailsSuccess, MasterDataActions, onFailRCodeMasterData, onFailReportTypeMasterData, onFailRoleMasterData,
  onGenerateInvoiceFailure, onGenerateInvoiceStart, onGenerateInvoiceSuccess, onGetCodeMasterData, onGetReportTypeMasterData, onGetRoleMasterData,
  onGotCodeMasterData,
  onGotReportTypeMasterData, onGotRoleMasterData,
} from './masterSlice';
import { downloadExcelByUrl } from '../../Common/PageHeader/helper';
import { INVOICE_MESSAGE } from './constant';

async function masterData(): Promise<MasterApiResponse> {
  const url = `${Config.auth.getMasterData}`;
  const result = await makeGetRequest<MasterApiResponse>(url);
  return result.data;
}

export const getMasterDataEpic = (action$: Observable<MasterDataActions>) => action$.pipe(
  ofType(getMasterDataStart.type),
  map((x) => x.payload),
  mergeMap(() => from(masterData()).pipe(
    map((res: MasterApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getMasterDataSuccess(res.BMT.Result);
      }
      return getMasterDataFailure(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getMasterDataStart.match))),
    catchError((error) => of(getMasterDataFailure(error))),
  )),
);

async function getPincodeDetails(data: PinCodeDetailsQuery): Promise<PinCodeApiResponse> {
  const url = `${Config.auth.getPincodeDetails}?PinCode=${data.pinCode}&CountryCode=${data.countryCode}`;
  const result = await makeGetRequest<PinCodeApiResponse>(url);
  return result.data;
}

export const getPincodeDetailsEpic = (action$: Observable<MasterDataActions>) => action$.pipe(
  ofType(getPinCodeDetailsStart.type),
  debounceTime(500),
  map((x) => x.payload),
  mergeMap((data: PinCodeDetailsQuery) => from(getPincodeDetails(data)).pipe(
    map((res: PinCodeApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getPinCodeDetailsSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getPinCodeDetailsFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getMasterDataStart.match))),
    catchError((error) => of(getMasterDataFailure(error))),
  )),
);

async function generateInvoiceApiCall({ type, selectedIds }: GenerateInvoicePayload): Promise<GenerateInvoiceResponse> {
  if (type === 0) {
    const payload = {
      BidIds: selectedIds,
    };
    const url = Config.generateInvoice.generateInvoiceExcel;
    const result = await makePostRequest<GenerateInvoiceResponse>(url, payload);
    return result.data;
  }
  const payload = {
    type,
    KitIds: selectedIds,
  };
  const url = Config.generateInvoice.generateInvoiceExcelFreon;
  const result = await makePostRequest<GenerateInvoiceResponse>(url, payload);
  return result.data;
}

export const generateInvoiceEpic = (action$: Observable<MasterDataActions>) => action$.pipe(
  ofType(onGenerateInvoiceStart.type),
  map((x) => x.payload),
  mergeMap((data) => from(generateInvoiceApiCall(data)).pipe(
    map((res: GenerateInvoiceResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        downloadExcelByUrl(res?.BMT?.Result?.InvoiceUrl);
        showSuccessToaster(INVOICE_MESSAGE);
        return onGenerateInvoiceSuccess(res?.BMT?.Result?.InvoiceUrl);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return onGenerateInvoiceFailure();
    }),
    takeUntil(action$.pipe(filter(onGenerateInvoiceStart.match))),
    catchError(() => of(onGenerateInvoiceFailure())),
  )),
);

async function getCitySuggestions(data: string): Promise<GetById<CitySuggestionResponse>> {
  const url = `${Config.common.getCitySuggestions}?cityName=${data}`;
  const result = await makeGetRequest<GetById<CitySuggestionResponse>>(url);
  return result.data;
}

export const getCitySuggestionEpic = (action$: Observable<MasterDataActions>) => action$.pipe(
  ofType(getCitySuggestionStart.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: string) => from(getCitySuggestions(data)).pipe(
    map((res: GetById<CitySuggestionResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getCitySuggestionSuccess(res.BMT.Result.Data);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getCitySuggestionFailure();
    }),
    takeUntil(action$.pipe(filter(getCitySuggestionStart.match))),
    catchError(() => of(getCitySuggestionFailure())),
  )),
);

async function getRoleMasterDataApiCall(): Promise<RoleMasterApiResponse> {
  const url = `${Config.userManagement.getRoleMasterData}`;
  const result = await makeGetRequest<RoleMasterApiResponse>(url);
  return result.data;
}

export const getRoleMasterDataEpic = (action$: Observable<MasterDataActions>) => action$.pipe(
  ofType(onGetRoleMasterData.type),
  map((x) => x.payload),
  mergeMap(() => from(getRoleMasterDataApiCall()).pipe(
    map((res: RoleMasterApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return onGotRoleMasterData(res?.BMT?.Result);
      }
      return onFailRoleMasterData();
    }),
    takeUntil(action$.pipe(filter(onGetRoleMasterData.match))),
    catchError((error) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(onFailRoleMasterData());
    }),
  )),
);

async function getReportTypeDataApiCall(): Promise<ReportTypeApiResponse> {
  const url = `${Config.reportTypeMaster.getReportType}`;
  const result = await makeGetRequest<ReportTypeApiResponse>(url);
  return result?.data;
}

export const getReportTypeMasterDataEpic = (action$: Observable<MasterDataActions>) => action$.pipe(
  ofType(onGetReportTypeMasterData.type),
  map((x) => x.payload),
  mergeMap(() => from(getReportTypeDataApiCall()).pipe(
    map((res: ReportTypeApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return onGotReportTypeMasterData(res?.BMT?.Result);
      }
      return onFailReportTypeMasterData();
    }),
    takeUntil(action$.pipe(filter(onGetReportTypeMasterData.match))),
    catchError((error) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(onFailReportTypeMasterData());
    }),
  )),
);

async function getCodeDataApiCall(): Promise<ReportTypeApiResponse> {
  const url = `${Config.reportTypeMaster.getCode}`;
  const result = await makeGetRequest<ReportTypeApiResponse>(url);
  return result?.data;
}

export const getCodeMasterDataEpic = (action$: Observable<MasterDataActions>) => action$.pipe(
  ofType(onGetCodeMasterData.type),
  map((x) => x.payload),
  mergeMap(() => from(getCodeDataApiCall()).pipe(
    map((res: ReportTypeApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return onGotCodeMasterData(res?.BMT?.Result);
      }
      return onFailRCodeMasterData();
    }),
    takeUntil(action$.pipe(filter(onGetCodeMasterData.match))),
    catchError((error) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(onFailRCodeMasterData());
    }),
  )),
);

async function getCityStateSuggestions(data: { CityName: string, StateName: string }): Promise<GetById<CitySuggestionResponse>> {
  const url = `${Config.common.getCitySuggestions}?cityName=${data.CityName}&StateName=${data.StateName}`;
  const result = await makeGetRequest<GetById<CitySuggestionResponse>>(url);
  return result.data;
}

export const getCityStateSuggestionEpic = (action$: Observable<MasterDataActions>) => action$.pipe(
  ofType(getCityStateSuggestionStart.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: { CityName: string, StateName: string }) => from(getCityStateSuggestions(data)).pipe(
    map((res: GetById<CitySuggestionResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getCityStateSuggestionSuccess(res.BMT.Result.Data);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getCityStateSuggestionFailure();
    }),
    takeUntil(action$.pipe(filter(getCityStateSuggestionStart.match))),
    catchError(() => of(getCityStateSuggestionFailure())),
  )),
);
