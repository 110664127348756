/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';

function InspectionProcedure() {
  const { reportPdfResponse4, themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  return (
    <div className="p20">
      <table className="w100 logoTable">
        <tr>
          <td>
            <div className="commonTableBorderStyle mt10 p30 w100 pageBorder1000" style={{ borderColor: MainColor }}>
              <div
                className="tableAfterBorderStyle"
                style={{
                  backgroundColor: BorderColor,
                }}
              />
              <div
                className="tableBeforeBorderStyle"
                style={{
                  backgroundColor: BorderColor,
                }}
              />

              <div className="subHeading mt40 textCenter">Inspection Procedure</div>
              <div
                className="commonText mt20 customDataHtmlStyle"
                dangerouslySetInnerHTML={{ __html: reportPdfResponse4?.Inspection_Procedure?.Description }}
              />
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default InspectionProcedure;
