import { Card, Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { ASMESectionVEnum, ExtensionCableProps } from '../../utils/type';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import { TypeDropDownValues } from '../../utils/constant';
import localStorageConstants from '../../../../utils/LocalStorageConstants';
import { ANALYST_ROLE_ID } from '../../../../Routes/constant';
import { RegexWith4N3D } from '../../../../utils/regex';
import { RootState } from '../../../../redux/rootState';

export default function ExtensionCable({ control, errors }:ExtensionCableProps) {
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);
  const { isASMESection } = useSelector((state: RootState) => state.VesselMainReducer);

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          Extension Cable
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={roleId === ANALYST_ROLE_ID || !isASMESection ? 'label_Disable' : 'label_Style_Customer'}>
                Length (Ft.)
              </label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                regEx={RegexWith4N3D}
                isDisable={roleId === ANALYST_ROLE_ID || !isASMESection}
                name={ASMESectionVEnum.extensionCableLength}
                id={ASMESectionVEnum.extensionCableLength}
                size="small"
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={roleId === ANALYST_ROLE_ID || !isASMESection ? 'label_Disable' : 'label_Style_Customer'}>
                Type
              </label>
              <ComponentSelectInput
                name={ASMESectionVEnum.extensionCableType}
                control={control}
                size="small"
                disabled={roleId === ANALYST_ROLE_ID || !isASMESection}
                entries={TypeDropDownValues}
                errors={errors}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
