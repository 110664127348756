import { Tab, Tabs } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import ComponentPageHeader from '../../../../Common/PageHeader/ComponentPageHeader';
import {
  selectedTabStyles, stylesTabIndicator, tabsStyles, tabStyles,
} from '../../../../Common/TabsStyles';
import { RootState } from '../../../../redux/rootState';
import {
  getOilReportByIdStart, resetState, setSelectedTab, updateOilReportStart,
} from '../../redux/oilReportSlice';
import { assignPayload, assignValues } from '../../utils/helper';
import { EditReportForm, ReportDetailsById } from '../../utils/types';
import { Concentration } from './concentration';
import { Main } from './main';
import { Result } from './result';
import { MainFormSchema } from '../../utils/constant';
import { setLoader } from '../../../../redux/loaderSlice';

export function EditReport() {
  const { selectedTab, selectedOilReport, isLoading } = useSelector((state:RootState) => state.oilReportReducer);

  const {
    control, formState: { errors, isValid }, setValue, handleSubmit, trigger,
  } = useForm<EditReportForm>({
    resolver: yupResolver(MainFormSchema as unknown as ObjectSchema<EditReportForm>),
  });
  const { reportId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const onSubmit = (values:EditReportForm) => {
    const payload:ReportDetailsById = assignPayload(values, String(reportId));
    trigger();
    dispatch(updateOilReportStart(payload));
  };

  const TABS = useMemo(() => [
    { value: 0, label: 'Main', component: <Main control={control} errors={errors} trigger={trigger} isValid={isValid} /> },
    { value: 1, label: 'Concentration', component: <Concentration control={control} errors={errors} /> },
    { value: 2, label: 'Result', component: <Result control={control} errors={errors} onSubmit={onSubmit} handleSubmit={handleSubmit} /> },
  ], [errors, isValid]);

  useEffect(() => {
    if (reportId) {
      dispatch(getOilReportByIdStart(String(reportId)));
    }
  }, [reportId]);

  const handleChangeTab = (value: number) => {
    dispatch(setSelectedTab(value));
  };

  useEffect(() => () => {
    dispatch(setSelectedTab(0));
    dispatch(resetState());
  }, []);

  useEffect(() => {
    if (selectedOilReport?.ReportId) {
      assignValues(selectedOilReport, setValue);
    }
  }, [selectedOilReport]);

  return (
    <>
      <ComponentPageHeader
        subHeading="REPORT EDIT"
        fieldName="Customer ID"
        fieldId={selectedOilReport?.CustNo}
      />
      {/* <ComponentBreadcrumb crumbs={breadcrumbs} /> */}
      <div className="container_job">
        <Tabs
          variant="scrollable"
          value={selectedTab}
          onChange={(_, newValue) => handleChangeTab(TABS[newValue].value)}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          {TABS.map((tab) => (
            <Tab
              // disabled={!isValid}
              key={`${tab.value}-${tab.label}`}
              label={tab.label}
              style={selectedTab === tab.value ? selectedTabStyles : tabStyles}
              className="tab_styles"
            />
          ))}
        </Tabs>
      </div>
      <div className="div_job_container_info">
        <div className="div_container_CustomerInfo">
          {TABS[selectedTab].component}
        </div>
      </div>
    </>
  );
}
