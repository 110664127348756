import React from 'react';
import { useSelector } from 'react-redux';
import ComponentHazardTable from './ComponentHazardTable';
import ComponentHazardCheckBox from './ComponentHazardCheckBox';
import { RootState } from '../../../../redux/rootState';

function HazardAnalysis() {
  const { reportPdfResponse4 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  return (
    <div className="ExpenseReportPDFDiv">
      {reportPdfResponse4?.Hazard_Analysis?.[0]?.JobOrder && <ComponentHazardTable />}
      <ComponentHazardCheckBox />
    </div>
  );
}

export default HazardAnalysis;
