export default {
  authBaseUrl: ' https://devapi.taiservices.info/authservice/api/',
  fetchBaseUrl: ' https://devapi.taiservices.info/customer/api/',
  fetchSysPriceUrl: 'https://devapi.taiservices.info/inventory/api/',
  markupBaseUrl: 'https://devapi.taiservices.info/report/api/',
  calenderBaseUrl: 'https://devapi.taiservices.info/report/api/',
  fetchFreonBaseUrl: 'https://devapi.taiservices.info/inventory/api/',
  appVersion: '4.2.1',
  dashboardBaseurl: 'https://devapi.taiservices.info/report/api/',
  jobBaseUrl: 'https://devapi.taiservices.info/inventory/api/',
  oilKitsBaseurl: 'https://devapi.taiservices.info/inventory/api/',
  analystBaseUrl: 'https://devapi.taiservices.info/management/api/',
  EquipmentReqBaseurl: 'https://devapi.taiservices.info/inventory/api/',
  unitBaseUrl: '',
  ColorMasterUrl: 'https://devapi.taiservices.info/report/api/',
  analystBidBaseUrl: 'http://54.175.8.205/api/',
  listsBaseUrl: 'https://devapi.taiservices.info/report/api/',
  SiteBaseurl: 'https://devapi.taiservices.info/inventory/api/',
  InventoryBaseUrl: 'https://devapi.taiservices.info/admin/api/',
  freonInvoiceBaseUrl: 'https://devapi.taiservices.info/management/api/',
  oilReportBaseUrl: 'https://devapi.taiservices.info/customer/api/',
  jobOrderBaseUrl: 'https://devapi.taiservices.info/management/api/',
  ReportTypeUrl: 'https://devapi.taiservices.info/admin/api/',
  AdminBaseUrl: 'https://devapi.taiservices.info/admin/api/',
  PhotosUrl: 'https://devapi.taiservices.info/admin/api/',
  PhotosEditUrl: 'devapi.taiservices.info/admin/api/',
  JSPaintUrl: 'https://devapi.taiservices.info/',
};

export const showBoardPosition = true;
export const showRoleBased = true;
export const showInspection = true;
export const showExpense = true;
export const vesselOption = true;
