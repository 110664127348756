import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { RootState } from '../../redux/rootState';
// import { deleteSmcStart, getSmcUserStart } from './Redux/sliceSmc';
import DataTable from '../../Common/DataTable';
import { setLoader } from '../../redux/loaderSlice';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
// import { exportToExcelData } from '../../Common/PageHeader/helper';
import { GetInsSummaryResponse, InspectionSummaryData } from './utils/types.d';
import { getInspectionSummaryData } from './redux/sliceInspectionSummary';
import EndPoints from '../../Routes/EndPoints';

export default function ScreenInspectionSummary() {
  const Navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const { jobOrder } = useParams();
  const { isLoading, inspectionSummaryData, totalData } = useSelector((state: RootState) => state.inspectionSummaryReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getInspectionSummaryData(params));
  };

  useEffect(() => {
    if (jobOrder) {
      setSearchQuery(jobOrder);
    } else setSearchQuery('');
  }, [jobOrder]);

  useEffect(() => {
    handleData();
  }, []);

  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'jobOrder', label: 'Job Order #' },
      { key: 'vessel', label: 'Vessel' },
      { key: 'reportType', label: 'Report Type' },
      { key: 'testType', label: 'TestType' },
      { key: 'defect', label: 'Defect' },
    ],
    [],
  );

  const data: InspectionSummaryData[] = useMemo(() => inspectionSummaryData?.map((item:GetInsSummaryResponse, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'edit',
      jobOrder: item.JobOrder,
      vessel: item.VesselName,
      reportType: item.ReportType,
      testType: String(item.TestType),
      defect: item.Defect,
      id: `${item.JobOrder}/${item.SummaryId}/${item?.VesselId}/${item?.DefectCode || 'null'}`,
    };
    return transformedDataItem;
  }), [inspectionSummaryData]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleEdit = (id:string) => {
    Navigate(`/${EndPoints.Inspection_Summary}/${id}`);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="Inspection Summary" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <DataTable<InspectionSummaryData>
          data={data}
          columns={columns}
          totalRecords={totalData}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          handleEdit={handleEdit}
        />
      </Grid>
    </Grid>
  );
}
