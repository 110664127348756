import { Grid, Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DataTable from '../../../../../../Common/DataTable';
import { JobAndSiteTableColumns, JobScheduleFields } from '../../../utils/constants';
import { JobScheduleFormInfo, JobsAndSiteTableData, ReadyToSendInterface } from '../../../utils/types';
import { jobsAndSiteData } from '../../../utils/helper';
import DialogBoxAddAnalyst from '../ComponentAddAnalyst/DialogBoxAddAnalyst';
import ClientInfo from './clientInfo';
import { RootState } from '../../../../../../redux/rootState';
import {
  getJobScheduleUnitData, onAddScheduleFailure, onUpdateSiteData, getTravelDataById, onReadyToSendStart,
} from '../../redux/jobScheduleSlice';
import { getJobByIdStart, getSiteByIdStart } from '../../../../../Job/jobStarter/addJob/redux/addJobSlice';
import { getCustomerByIdStart } from '../../../../../addCustomer/redux/addCustomerSlice';
import { GetCustomerByIdPayload } from '../../../../../addCustomer/utils/types';
import { setLoader } from '../../../../../../redux/loaderSlice';
import { Empty } from '../../../../../ScreenAddSmc/Utils/TypeSmc';
import {
  getCityStateSuggestionStart, getPinCodeDetailsStart, removePinCodeDetails, resetCityStateSuggestion,
} from '../../../../../../redux/masters/masterSlice';
import AddReportModal from '../AddReportModal';
import { siteInfoFormSchema, UnitJobDefaultValue } from '../../utils/helper';
import { scrollToErrorField, stripCustPrefix } from '../../../../../../utils/CommonFunctions';
import { showErrorToaster } from '../../../../../../Common/ComponentToast/ComponentSuccessToasts';

export default function ComponentCustomerInfo() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [openAnalyst, setOpenAnalyst] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [openAddReportModal, setAddOpenReportModal] = useState(false);
  const [unitId, setUnitId] = useState('');
  const [unitEditData, setUnitEditData] = useState({});
  const [data, setData] = useState<JobsAndSiteTableData[]>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control, setValue, getValues, watch, handleSubmit, formState: { errors }, trigger, setError,
  } = useForm<JobScheduleFormInfo>({
    defaultValues: UnitJobDefaultValue,
    resolver: yupResolver(siteInfoFormSchema as Empty),
  });

  const {
    jobScheduleUnitData, isUnitLoading, addedSchedule, navigateFromScheduleTab,
  } = useSelector((state: RootState) => state.jobScheduleReducer);
  const { bidId, jobId } = useParams();
  const { selectedJob, selectedSite } = useSelector((state: RootState) => state.jobStarterReducer);
  const { selectedCustomerDetails } = useSelector((state: RootState) => state.addCustomerReducer);
  const [isLoading, setisLoading] = useState(true);
  const { pinCodeDetails, cityStateSuggestions } = useSelector((state: RootState) => state.masterDataReducer);

  const zip = watch(JobScheduleFields.siteZip);
  const country = watch(JobScheduleFields.siteCountry);

  useEffect(() => {
    if (zip && zip?.length >= 5) {
      dispatch(removePinCodeDetails());
      dispatch(resetCityStateSuggestion());
      setValue(JobScheduleFields.siteState, '');
      // setValue(JobScheduleFields.siteCity, '');
      const queryPayload = {
        pinCode: zip,
        countryCode: country,
      };
      dispatch(getPinCodeDetailsStart(queryPayload));
    } else if (zip && zip?.length < 5) {
      dispatch(removePinCodeDetails());
      dispatch(resetCityStateSuggestion());
      setValue(JobScheduleFields.siteState, '');
      // setValue(JobScheduleFields.siteCity, '');
    }
  }, [zip, country]);

  useEffect(() => {
    if (pinCodeDetails?.City && zip) {
      setValue(JobScheduleFields.siteState, pinCodeDetails.StateName);
    }
  }, [pinCodeDetails]);

  useEffect(() => {
    if (jobId) {
      setisLoading(true);
      dispatch(getJobByIdStart(jobId));
    }
    return () => {
      dispatch(removePinCodeDetails());
    };
  }, []);

  useEffect(() => {
    if (selectedJob?.JobInfo?.CustId) {
      const payload: GetCustomerByIdPayload = {
        customerId: selectedJob?.JobInfo?.CustId,
        otherCustomer: false,
      };
      dispatch(getCustomerByIdStart(payload));
    }
    if (selectedJob?.JobInfo?.SiteId) {
      dispatch(getSiteByIdStart(selectedJob?.JobInfo.SiteId));
    }
  }, [selectedJob]);

  function setCustomerInfo() {
    if (selectedCustomerDetails) {
      setValue(JobScheduleFields.customerId, stripCustPrefix(selectedJob?.JobInfo?.CustId));
      setValue(JobScheduleFields.company, selectedCustomerDetails.CompanyInformation?.CustomerName);
      setValue(JobScheduleFields.contact, selectedCustomerDetails.CompanyInformation?.Phone);
      setValue(JobScheduleFields.address, selectedCustomerDetails.CompanyInformation?.Address);
      setValue(JobScheduleFields.addressOptional, selectedCustomerDetails.CompanyInformation?.OptionalAddress);
      setValue(JobScheduleFields.zip, selectedCustomerDetails.CompanyInformation?.ZipCode);
      setValue(JobScheduleFields.state, selectedCustomerDetails.CompanyInformation?.StateName);
      setValue(JobScheduleFields.city, selectedCustomerDetails.CompanyInformation?.CityName);
      setValue(JobScheduleFields.code, selectedCustomerDetails.CompanyInformation?.PhoneCode);
      setValue(JobScheduleFields.phone, selectedCustomerDetails.CompanyInformation?.Phone);
    }
    if (selectedJob) {
      setValue(JobScheduleFields.contactInfo, selectedJob?.JobInfo?.CallerContact);
      setValue(JobScheduleFields.cellInfo, selectedJob?.JobInfo?.CallerCell);
      setValue(JobScheduleFields.codeInfo, selectedJob?.JobInfo?.CallerPhoneCode);
      setValue(JobScheduleFields.phoneInfo, selectedJob?.JobInfo?.CallerPhone);
    }
  }

  useEffect(() => {
    setCustomerInfo();
  }, [selectedCustomerDetails]);

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
      BidId: bidId,
    };
    dispatch(getJobScheduleUnitData(payload));
    dispatch(getTravelDataById(bidId));
  };

  useEffect(() => {
    if (addedSchedule) {
      handleData();
      dispatch(onAddScheduleFailure());
    }
  }, [addedSchedule]);

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    dispatch(setLoader(isUnitLoading));
  }, [isUnitLoading]);

  const handleAddButton = (id: string, key: string) => {
    setUnitId(id);
    const unitData = jobScheduleUnitData?.Data?.find((ele: Empty) => ele?.UnitId === id);
    setUnitEditData(unitData);
    if (key === 'reportAction') {
      return setAddOpenReportModal(true);
    }
    return setOpenAnalyst(true);
  };

  const isScheduleJob = useMemo(() => {
    const getUrl = window.location.href;
    return getUrl && getUrl?.includes('unscheduled-jobs');
  }, []);

  const saveJobAndSiteDetails = () => {
    const jobAndSiteDetails = getValues();
    const payload = {
      SiteId: jobAndSiteDetails?.siteId,
      SiteName: jobAndSiteDetails?.site ?? '',
      Contact: jobAndSiteDetails?.siteContact ?? '',
      Address: jobAndSiteDetails?.siteAddress ?? '',
      OptionalAddress: jobAndSiteDetails?.siteAddressOptional ?? '',
      Pincode: jobAndSiteDetails?.siteZip ?? '',
      CityName: jobAndSiteDetails?.otherSiteCity,
      IsOtherCity: false,
      StateName: jobAndSiteDetails?.siteState ?? '',
      CountryCode: jobAndSiteDetails?.siteCountry ?? '',
      PhoneCode: jobAndSiteDetails?.sitePhoneCode ?? '',
      Phone: jobAndSiteDetails?.sitePhone ?? '',
      Cell: jobAndSiteDetails?.siteCell ?? '',
      Note: jobAndSiteDetails?.siteNotes ?? '',
    };

    if (zip && payload.CityName) {
      const cityNew1 = pinCodeDetails?.City.find(((item) => item.Value === payload.CityName));
      if (cityNew1) {
        if (navigateFromScheduleTab || !isScheduleJob) {
          const checkedReadyToSend: ReadyToSendInterface[] = data?.map((ele) => ({
            UnitId: ele?.id,
            IsReadyToSend: ele?.readyToSend || false,
          })) || [];
          const checkedReadyToSendPayload = {
            UnitFlags: checkedReadyToSend,
          };
          dispatch(onReadyToSendStart(checkedReadyToSendPayload));
        }
        dispatch(onUpdateSiteData({ payload }));
      } else {
        setError(JobScheduleFields.otherSiteCity, { type: 'custom', message: 'Select a valid City Name' });
        showErrorToaster('Select a valid City Name');
      }
    } else if (zip && !payload.CityName) {
      if (navigateFromScheduleTab || !isScheduleJob) {
        const checkedReadyToSend: ReadyToSendInterface[] = data?.map((ele) => ({
          UnitId: ele?.id,
          IsReadyToSend: ele?.readyToSend || false,
        })) || [];
        const checkedReadyToSendPayload = {
          UnitFlags: checkedReadyToSend,
        };
        dispatch(onReadyToSendStart(checkedReadyToSendPayload));
      }
      dispatch(onUpdateSiteData({ payload }));
    } else if (!zip && payload.CityName) {
      const cityNew2 = cityStateSuggestions?.find(((item) => item.City === payload.CityName));
      if (cityNew2) {
        if (navigateFromScheduleTab || !isScheduleJob) {
          const checkedReadyToSend: ReadyToSendInterface[] = data?.map((ele) => ({
            UnitId: ele?.id,
            IsReadyToSend: ele?.readyToSend || false,
          })) || [];
          const checkedReadyToSendPayload = {
            UnitFlags: checkedReadyToSend,
          };
          dispatch(onReadyToSendStart(checkedReadyToSendPayload));
        }
        dispatch(onUpdateSiteData({ payload }));
      } else {
        setError(JobScheduleFields.otherSiteCity, { type: 'custom', message: 'Select a valid City Name' });
        showErrorToaster('Select a valid City Name');
      }
    } else {
      if (navigateFromScheduleTab || !isScheduleJob) {
        const checkedReadyToSend: ReadyToSendInterface[] = data?.map((ele) => ({
          UnitId: ele?.id,
          IsReadyToSend: ele?.readyToSend || false,
        })) || [];
        const checkedReadyToSendPayload = {
          UnitFlags: checkedReadyToSend,
        };
        dispatch(onReadyToSendStart(checkedReadyToSendPayload));
      }
      dispatch(onUpdateSiteData({ payload }));
    }
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleEdit = (id: string, key: string) => {
    setUnitId(id);
    const unitData = jobScheduleUnitData?.Data?.find((ele: Empty) => ele?.UnitId === id);
    setUnitEditData(unitData);
    if (key === 'reportAction') {
      return setAddOpenReportModal(true);
    }
    return setOpenAnalyst(true);
  };

  const getJobAndSiteTableColumns = useMemo(() => {
    if (navigateFromScheduleTab || !isScheduleJob) {
      return [...JobAndSiteTableColumns, { key: 'readyToSend', label: 'ReadyToSend' },
      ];
    }
    return JobAndSiteTableColumns;
  }, []);

  const handleCheckBox = (rowId: string) => {
    const updatedData = data?.map((element: JobsAndSiteTableData) => {
      if (element?.id === rowId) {
        return {
          ...element,
          readyToSend: !element?.readyToSend,
        };
      }
      return element;
    });
    setData(updatedData);
  };

  useEffect(() => {
    if (jobScheduleUnitData?.Data) {
      setData(jobsAndSiteData(jobScheduleUnitData?.Data));
    }
  }, [jobScheduleUnitData?.Data]);

  useEffect(() => {
    if (selectedSite?.SiteId) {
      dispatch(getCityStateSuggestionStart({
        CityName: selectedSite?.City || '',
        StateName: selectedSite?.State || '',
      }));
    }
  }, [selectedSite, bidId]);

  useEffect(() => {
    if (errors) {
      const errorField = Object.keys(errors)[0];
      scrollToErrorField(errorField);
    }
  }, [errors]);

  return (
    <div className="div_container_CustomerInfo">
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <ClientInfo control={control} setValue={setValue} watch={watch} errors={errors} trigger={trigger} />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <DataTable<JobsAndSiteTableData>
            data={data?.length > 0 ? data : []}
            columns={getJobAndSiteTableColumns}
            totalRecords={jobScheduleUnitData?.TotalRecords}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            handleAdd={handleAddButton}
            handleReportEdit={handleEdit}
            checkBoxColumns={['readyToSend']}
            handleCheckBox={handleCheckBox}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <form onSubmit={handleSubmit(saveJobAndSiteDetails)}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={() => navigate(-1)}> Back </Button>
              </div>
              <div className="button_margin_left">
                <Button type="submit" className="button_save_and_next">Save & Next</Button>
              </div>
            </div>
          </form>
        </Grid>
      </Grid>
      <DialogBoxAddAnalyst
        unitId={unitId}
        show={openAnalyst}
        unitEditData={unitEditData}
        setOpenFrom={() => setOpenAnalyst(false)}
      />
      {openAddReportModal && (
        <AddReportModal
          show={openAddReportModal}
          openReportModal={openReportModal}
          unitEditData={unitEditData}
          setOpenReportModal={setOpenReportModal}
          setOpenFrom={setAddOpenReportModal}
          callback={handleData}
        />
      )}
    </div>
  );
}
