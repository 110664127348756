import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MAIN_TAB } from '../utils/constants';
import {
  ClientSiteFormValues,
  CustomerData, DeleteApiPayload, GetUnitPriceJobOrderResponse, JobOrderMainData, JobOrderPricingData, JobOrderResult,
  ResultDataById, SiteDataTypes, UnitInfoOrderFormValues, UnitPriceJobOrderPayload, VesselsInfoData,
} from '../utils/types';

const initialState = {
  selectedTab: MAIN_TAB,
  isLoading: false,
  jobOrderData: {} as JobOrderResult,
  JobOrderMainData: {} as JobOrderMainData,
  VesselsInfoData: {} as VesselsInfoData,
  JobOrderPricingData: {} as JobOrderPricingData,
  ClientData: {} as CustomerData,
  SiteData: {} as SiteDataTypes,
  AnalystNoteData: {} as string[],
  Comments: {} as string,
  unitPriceJobOrder: {} as GetUnitPriceJobOrderResponse,
  mainFormData: {} as UnitInfoOrderFormValues,
  clientSiteFormData: {} as ClientSiteFormValues,
  UnitId: '' as string,
  AnalystId: '' as string,
  checkedIds: [] as string[],
};

export const sliceJobOrder = createSlice({
  name: 'jobOrderEpic',
  initialState,
  reducers: {
    jobOrderMainForm(state, action: PayloadAction<UnitInfoOrderFormValues>) {
      return {
        ...state,
        mainFormData: action.payload,
      };
    },
    jobOrderClientSiteForm(state, action: PayloadAction<ClientSiteFormValues>) {
      return {
        ...state,
        clientSiteFormData: action.payload,
      };
    },
    setSelectedTab(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },
    removeSelectedJobOrder() {
      return initialState;
    },
    getJobOrderData(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getJobOrderDataSuccess(state, action: PayloadAction<JobOrderResult>) {
      return {
        ...state,
        isLoading: false,
        jobOrderData: action.payload,
      };
    },
    getJobOrderDataFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteJobOrderStart(state, _action: PayloadAction<DeleteApiPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteJobOrderSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteJobOrderFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getJobOrderById(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getJobOrderSuccessById(state, action: PayloadAction<ResultDataById>) {
      return {
        ...state,
        isLoading: false,
        JobOrderMainData: action.payload.JobOrderMainData,
        VesselsInfoData: action.payload.VesselsInfoData,
        JobOrderPricingData: action.payload.JobOrderPricingData,
        ClientData: action.payload.CustomerData,
        SiteData: action.payload.SiteData,
        AnalystNoteData: action.payload.AnalystNoteData,
        Comments: action.payload.Comments,
        UnitId: action.payload.UnitId,
        AnalystId: action.payload.AnalystId,
      };
    },
    getJobOrderFailureById(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    resetJobOrderMain(state) {
      return {
        ...state,
        JobOrderMainData: {} as JobOrderMainData,
      };
    },
    // Get Unit Price For Job Order
    getUnitPriceJobOrderStart(state, _action: PayloadAction<UnitPriceJobOrderPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getUnitPriceJobOrderSuccess(state, action: PayloadAction<GetUnitPriceJobOrderResponse>) {
      return {
        ...state,
        unitPriceJobOrder: action.payload,
        isLoading: false,
      };
    },
    getUnitPriceJobOrderFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Update Job Order
    updateJobOrderStart(state, _action:PayloadAction<ResultDataById>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateJobOrderSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateJobOrderFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Job Order Ids
    setCheckedIds(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        checkedIds: action.payload,
      };
    },
    // JobOrder Move Workspace
    JobOrderMoveWorkspaceStart(state, _action:PayloadAction<string[]>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    JobOrderMoveWorkspaceSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    JobOrderMoveWorkspaceFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});
export const {
  setSelectedTab, removeSelectedJobOrder, getJobOrderData, getJobOrderDataSuccess, getJobOrderDataFailure,
  deleteJobOrderStart, deleteJobOrderSuccess, deleteJobOrderFailure, getJobOrderById, getJobOrderSuccessById, getJobOrderFailureById,
  getUnitPriceJobOrderStart, getUnitPriceJobOrderSuccess, getUnitPriceJobOrderFailure, jobOrderMainForm, jobOrderClientSiteForm, updateJobOrderStart,
  updateJobOrderSuccess, updateJobOrderFailure, resetJobOrderMain, setCheckedIds,
  JobOrderMoveWorkspaceStart, JobOrderMoveWorkspaceSuccess, JobOrderMoveWorkspaceFailure,
} = sliceJobOrder.actions;
export const jobOrderReducer = sliceJobOrder.reducer;

export type JobOrderActions =
  | ReturnType<typeof jobOrderMainForm>
  | ReturnType<typeof jobOrderClientSiteForm>
  | ReturnType<typeof setSelectedTab>
  | ReturnType<typeof removeSelectedJobOrder>
  | ReturnType<typeof getJobOrderData>
  | ReturnType<typeof getJobOrderDataSuccess>
  | ReturnType<typeof getJobOrderDataFailure>
  | ReturnType<typeof deleteJobOrderStart>
  | ReturnType<typeof deleteJobOrderSuccess>
  | ReturnType<typeof deleteJobOrderFailure>
  | ReturnType<typeof getJobOrderById>
  | ReturnType<typeof getJobOrderSuccessById>
  | ReturnType<typeof getJobOrderFailureById>
  | ReturnType<typeof getUnitPriceJobOrderStart>
  | ReturnType<typeof getUnitPriceJobOrderSuccess>
  | ReturnType<typeof getUnitPriceJobOrderFailure>
  | ReturnType<typeof updateJobOrderStart>
  | ReturnType<typeof updateJobOrderSuccess>
  | ReturnType<typeof updateJobOrderFailure>
  | ReturnType<typeof resetJobOrderMain>
  | ReturnType<typeof setCheckedIds>
  | ReturnType<typeof JobOrderMoveWorkspaceStart>
  | ReturnType<typeof JobOrderMoveWorkspaceSuccess>
  | ReturnType<typeof JobOrderMoveWorkspaceFailure>;
