/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import { offsetList } from '../../../utils/tubeChartConstant';
import {
  AllDefects,
  Circle, GridType, PolygonInterface, RemovedConfigType, ToggleAction, TubeChartDefects, TubeConfiguration, VanishType,
} from '../../../utils/tubeChartTypes';

export class Polygon implements PolygonInterface {
  rows: number;

  columns: number;

  gridType: GridType;

  circles: Circle[][];

  history: ToggleAction[];

  allDefects: AllDefects[];

  totalVisible: number;

  isReverseEnd: boolean;

  section: string;

  constructor(tubeChartDetails:TubeConfiguration) {
    // initialization of all properties of grid
    const offsetType = offsetList[Number(tubeChartDetails.Offset) as 2 | 0 | 1];
    this.rows = Number(tubeChartDetails.NumOfRows);
    this.columns = Number(tubeChartDetails.RowLength);
    this.gridType = offsetType as GridType;
    this.circles = this.generateNormalGrid();
    this.history = [];
    this.allDefects = [];
    this.totalVisible = (this.rows) * (this.columns);
    this.isReverseEnd = !tubeChartDetails.IsOte;
    this.section = tubeChartDetails.section || '';

    // populating data from api
    if (tubeChartDetails?.CircleInfos?.length > 0) {
      this.populateData(tubeChartDetails);
    }

    // check if reverse end , if it is reverse end , reverse the main array
    if (this.isReverseEnd) {
      this.reverseEnd();
    }

    // assign relative values to all circles as per user see
    this.assignRelativeValues();
  }

  // function to clear the undo operations ( it is cleared on save)
  public ClearUndo() {
    this.history = [];
  }

  // Initilization of grid with initial values
  public generateNormalGrid(): Circle[][] {
    const circles: Circle[][] = [];
    const rowRange = Array.from({ length: this.rows }, (_, i) => i);
    const colRange = Array.from({ length: this.columns }, (_, i) => i);

    for (const row of rowRange) {
      const rowCircles: Circle[] = [];
      for (const col of colRange) {
        // here undoAffected is the array which stores the index of the undo by which respective circle is affected
        rowCircles.push({
          x: row, y: col, visible: true, undoAffected: [], relX: row, relY: col, defectColor: '', defectTag: '',
        });
      }
      circles.push(rowCircles);
    }
    return circles;
  }

  // Function to assign relative position to every circle
  public assignRelativeValues() {
    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.columns; j++) {
        if (this.circles[i][j].visible) {
          const { relX, relY } = this.getRelativeCoordinates(i, j);
          this.circles[i][j].relX = relX;
          this.circles[i][j].relY = relY;
        } else {
          this.circles[i][j].relX = 0;
          this.circles[i][j].relY = 0;
        }
      }
    }
  }

  // Function to populate the data from api
  public populateData(tubeChartDetails:TubeConfiguration): Circle[][] {
    const remArr:RemovedConfigType = tubeChartDetails.RemovedConfig;

    for (const [key, value] of Object.entries(remArr)) {
      for (let i = 0; i < value.length; i++) {
        this.circles[Number(key)][value[i]].visible = false;
        this.totalVisible--;
      }
    }

    return this.circles;
  }

  public getCircles(): Circle[][] {
    return this.circles;
  }

  // This function will trigger whenever any user click on any circle
  public toggleVisibility(x: number, y: number, vanishType: VanishType): void {
    this.shouldVanish(x, y, vanishType, this.history.length, false);

    // History array records all the actions by user
    this.history.push({ x, y, vanishType });
  }

  public replaceValues(x:number, y:number, value:boolean, operationNumber:number, isUndo:boolean): void {
    // If circle has already same value as required then return
    if (this.circles[x][y].visible === value) {
      return;
    }

    // Condition if this function is called on undo button click or tube click
    if (isUndo) {
      const tempInd = this.circles[x][y].undoAffected.findIndex((val) => val === operationNumber);
      if (tempInd === -1) {
        return;
      }
      this.circles[x][y].visible = value;
      this.circles[x][y].undoAffected.splice(tempInd, 1);
    } else {
      this.circles[x][y].undoAffected.push(operationNumber);
      this.circles[x][y].visible = value;
    }

    // Handle count of total visible
    if (!value) {
      this.totalVisible--;
    } else {
      this.totalVisible++;
    }
  }

  public shouldVanish(x: number, y: number, vanishType: VanishType, operationNumber: number, isUndo:boolean): void {
    const replacedVal = !this.circles[x][y].visible;
    // switch case for type of onclick dropdown
    switch (vanishType) {
      case VanishType.Individual:
        this.replaceValues(x, y, replacedVal, operationNumber, isUndo);
        break;

      case VanishType.Left:
        if (this.gridType === GridType.TopEccentric) {
          for (let i = x; i >= 0;) {
            this.replaceValues(i, y, replacedVal, operationNumber, isUndo);
            i -= 2;
          }
          break;
        }
        for (let i = y; i >= 0; i--) {
          this.replaceValues(x, i, replacedVal, operationNumber, isUndo);
        }
        break;

      case VanishType.Right:
        if (this.gridType === GridType.TopEccentric) {
          for (let i = x; i < this.rows;) {
            this.replaceValues(i, y, replacedVal, operationNumber, isUndo);
            i += 2;
          }
          break;
        }
        for (let i = y; i < this.columns; i++) {
          this.replaceValues(x, i, replacedVal, operationNumber, isUndo);
        }
        break;

      case VanishType.Top:
        if (this.gridType === GridType.TopEccentric) {
          for (let i = y; i >= 0; i--) {
            this.replaceValues(x, i, replacedVal, operationNumber, isUndo);
          }
          break;
        }
        for (let i = x; i >= 0;) {
          this.replaceValues(i, y, replacedVal, operationNumber, isUndo);
          if (this.gridType === GridType.LeftEccentric) {
            i -= 2;
          } else {
            i -= 1;
          }
        }
        break;

      case VanishType.Down:
        if (this.gridType === GridType.TopEccentric) {
          for (let i = y; i < this.columns; i++) {
            this.replaceValues(x, i, replacedVal, operationNumber, isUndo);
          }
          break;
        }
        for (let i = x; i < this.rows;) {
          this.replaceValues(i, y, replacedVal, operationNumber, isUndo);
          if (this.gridType === GridType.LeftEccentric) {
            i += 2;
          } else {
            i += 1;
          }
        }
        break;
      default:
    }
    this.assignRelativeValues();
  }

  public undo(): Circle[][] {
    const lastAction = this.history.pop();

    // call the function and this the return the affect of respective action
    if (lastAction) {
      this.shouldVanish(lastAction.x, lastAction.y, lastAction.vanishType, this.history.length, true);
    }
    return this.circles;
  }

  // Function for calculating relative coordinates
  public getRelativeCoordinates(x: number, y: number): { relX: number, relY: number } {
    let vanishedRows = 0;
    let vanishedCols = 0;

    // Count total rows vanished before the user clicked circle
    for (let i = 0; i < x; i++) {
      let t = false;
      for (let j = 0; j < this.columns; j++) {
        if (this.circles[i][j].visible) {
          t = true;
          break;
        }
      }
      if (!t) {
        vanishedRows++;
      }
    }
    // Count total columns vanished before the user clicked circle
    for (let i = 0; i < y; i++) {
      if (!this.circles[x][i].visible) {
        vanishedCols++;
      }
    }

    const relX = x - vanishedRows + 1;
    const relY = y - vanishedCols + 1;

    return { relX, relY };
  }

  public reverseEnd(): void {
    if (this.gridType === GridType.TopEccentric) {
      this.circles.reverse();
    } else {
      for (let i = 0; i < this.rows; i++) {
        this.circles[i] = this.circles[i].reverse();
      }
    }
  }

  // this function requires optimization
  public displayDefects(defects:TubeChartDefects[]): void {
    // First sort the defects array
    function getfinalDefects() {
      return defects?.slice()?.sort((a, b) => {
        if (a.Row < b.Row) return -1;
        if (a.Row > b.Row) return 1;
        if (a.Tube < b.Tube) return -1;
        if (a.Tube > b.Tube) return 1;
        return 0;
      });
    }

    const finalDefects = getfinalDefects();

    const len = defects?.length;
    // let p = 1;
    let k = 0;
    while (k < len) {
      for (let i = 0; i < this.rows; i++) {
        for (let j = 0; j < this.columns; j++) {
          // eslint-disable-next-line max-len
          const condn = (this.circles[i][j].relX === finalDefects[k]?.Row) && (this.circles[i][j].relY === finalDefects[k]?.Tube);

          if (this.circles[i][j].visible && condn) {
            // const tag = `D${p}`;
            if (finalDefects[k]?.Description) {
              // eslint-disable-next-line @typescript-eslint/no-loop-func
              if (!this.allDefects.find((ele) => ele.color === finalDefects[k].Color)) {
                const obj:AllDefects = {
                  color: finalDefects[k].Color,
                  description: finalDefects[k]?.Description || '',
                  tag: finalDefects[k]?.Code,
                  actionRequired: finalDefects[k]?.ActionRequired,
                };
                // p++;
                this.allDefects.push(obj);
              }
            }
            this.circles[i][j].defectColor = finalDefects[k].Color;
            this.circles[i][j].defectTag = finalDefects[k].Code;
            k++;
          }
        }
      }
      k++;
    }
  }

  // Function to remove Defects
  public removeDefects(): void {
    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.columns; j++) {
        this.circles[i][j].defectColor = '';
        this.circles[i][j].defectTag = '';
      }
    }
  }
}
