import React from 'react';
import { useSelector } from 'react-redux';
import styles from './CompanyInfo.module.css';
import { RootState } from '../../../../../../redux/rootState';

export default function Billing() {
  const {
    sameCompanyAddress, creditCardRequired, approvedPO, billingAddress, billingOptionalAddress, billingCountry, billingZip, billingState, billingCity,
  } = useSelector(
    (state: RootState) => state.addCustomerReducer.formData,
  );
  const formatBoolean = (value: boolean) => (value ? 'Yes' : 'No');
  return (
    <div className={styles.companyInfoSection}>
      <h2 className={styles.heading}>Billing Information</h2>
      <div className={styles.section}>
        <div className={styles.innerSection}>
          <label>Address: </label>
          {' '}
          {billingAddress}
        </div>
        <div className={styles.innerSection}>
          <label>Address (Optional): </label>
          {billingOptionalAddress}
        </div>
        <div className={styles.innerSection}>
          <label>Country: </label>
          {billingCountry}
        </div>
        <div className={styles.innerSection}>
          <label>Zip: </label>
          {billingZip}
        </div>
        <div className={styles.innerSection}>
          <label>State: </label>
          {billingState}
        </div>
        <div className={styles.innerSection}>
          <label>City: </label>
          {billingCity}
        </div>
        <div className={styles.innerSection}>
          <label>Same as Company Address:</label>
          {' '}
          {formatBoolean(sameCompanyAddress)}
        </div>
        <div className={styles.innerSection}>
          <label>Credit Card Required:</label>
          {' '}
          {formatBoolean(creditCardRequired)}
        </div>
        <div className={styles.innerSection}>
          <label>Approved for PO: </label>
          {formatBoolean(approvedPO)}
        </div>
      </div>
    </div>
  );
}
