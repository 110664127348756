import React from 'react';
import { PhaseChartVesselProps } from '../types';

function PhaseChartTable({ tableDetails, primaryColor, secondaryColor }: PhaseChartVesselProps) {
  return (
    <table className="bordered-table w100">
      <div
        className="tableAfterThickBorderStyle"
        style={{
          backgroundColor: primaryColor,
        }}
      />
      <div
        className="tableBeforeThickBorderStyle"
        style={{
          backgroundColor: primaryColor,
        }}
      />
      <tr>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: secondaryColor }}>
          Material
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: secondaryColor }}>
          Tube Type
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: secondaryColor }}>
          OD
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: secondaryColor }}>
          Wall
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: secondaryColor }}>
          Test Type
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: secondaryColor }}>
          Frequency
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: secondaryColor }}>
          Probe Diameter
        </td>
      </tr>
      <tr>
        <td className="textCenter">{tableDetails.MaterialName}</td>
        <td className="textCenter">{tableDetails.TubeType}</td>
        <td className="textCenter">{tableDetails.Od}</td>
        <td className="textCenter">{tableDetails.Wall}</td>
        <td className="textCenter">{tableDetails.TestType}</td>
        <td className="textCenter">{tableDetails.Frequency}</td>
        <td className="textCenter">{tableDetails.ProbeDiameter}</td>
      </tr>
    </table>
  );
}

export default PhaseChartTable;
