import React, { useState } from 'react';
import {
  Grid, Card, Accordion, AccordionSummary, Typography, AccordionDetails,
} from '@mui/material';
import { TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CustomExpandIcon } from '../../../../../../../utils/AccordionUtils/AccordionUtils';
import ComponentTextInput from '../../../../../../../Common/ComponentTextinput/ComponentTextInput';
import { JobScheduleFields } from '../../../../utils/constants';
import { JobFlightProps } from '../../../../utils/types';
import { RegexFiveWith2D } from '../../../../../../../utils/regex';
import { CommonDateInput } from '../../../../../../../Common/CommonDateInput';

function FlightOut({
  control,
  errors,
  flightOutdepartureTime,
  setFlightOutDipartureTime,
  flightOutarivalTime,
  setFlightOutArivalTime,
}: JobFlightProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel2d-content"
        id="panel2d-header"
        className="accordionHeading"
        onClick={handleExpand}
        expandIcon={(
          <div />
        )}
      >
        <div className="customAccordionHeader">
          <div className="leftSideHeading">
            <CustomExpandIcon />
            <Typography style={{ marginLeft: '8px', marginRight: '8px' }} className="typography_Accordion">Flight Out</Typography>
          </div>
          <div className="rightSideHeading">
            {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails className="borderTop">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <div className="customerInfo_main_container">
                <Grid container spacing={2}>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Date</label>
                      <CommonDateInput
                        control={control}
                        errors={errors}
                        name={JobScheduleFields.flightOutDate}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Airlines</label>
                      <ComponentTextInput
                        control={control}
                        inputProps={{ maxLength: 20 }}
                        name={JobScheduleFields.flightOutAirline}
                        size="small"
                        id={JobScheduleFields.flightOutAirline}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Departure Time</label>
                      <TimePicker
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        format="hh:mm A"
                        value={flightOutdepartureTime}
                        onChange={(newValue) => setFlightOutDipartureTime(newValue)}
                        className="timerInput nonCapitalizeField"
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Arrival Time</label>
                      <TimePicker
                        value={flightOutarivalTime}
                        onChange={(newValue) => setFlightOutArivalTime(newValue)}
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                          seconds: renderTimeViewClock,
                        }}
                        format="hh:mm A"
                        className="timerInput nonCapitalizeField"
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">$ / Seat</label>
                      <ComponentTextInput
                        control={control}
                        regEx={RegexFiveWith2D}
                        name={JobScheduleFields.flightOutSeat}
                        size="small"
                        id={JobScheduleFields.flightOutSeat}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Confirmation #</label>
                      <ComponentTextInput
                        control={control}
                        inputProps={{ maxLength: 50 }}
                        name={JobScheduleFields.flightOutConfirmation}
                        size="small"
                        id={JobScheduleFields.flightOutConfirmation}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default FlightOut;
