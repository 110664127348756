import React from 'react';
import { useSelector } from 'react-redux';
import styles from './CompanyInfo.module.css';
import { RootState } from '../../../../../../redux/rootState';

export default function PrimaryContact() {
  const {
    title, firstName, lastName, primaryEmailId, contactCode, phone, alternativePhone,
  } = useSelector(
    (state: RootState) => state.addCustomerReducer.formData,
  );
  const { salutations } = useSelector((state: RootState) => state.masterDataReducer);
  const salutationTitle = salutations?.find((item) => item?.Value === String(title))?.Text;

  return (
    <div className={styles.companyInfoSection}>
      <h2 className={styles.heading}>Primary Contact</h2>
      <div className={styles.section}>
        <div className={styles.innerSection}>
          <label>Name: </label>
          {' '}
          {(firstName || lastName) ? (
            <>
              {salutationTitle}
              {' '}
              {firstName}
              {' '}
              {lastName}
            </>
          ) : (
            <span />
          )}

        </div>
        <div className={styles.innerSection}>
          <label>Email ID: </label>
          {' '}
          {primaryEmailId}
        </div>
        <div className={styles.innerSection}>
          <label>Phone: </label>
          {' '}
          {phone ? (
            <>
              {contactCode}
              {' '}
              {phone}
            </>
          ) : (
            <span />
          )}

        </div>
        <div className={styles.innerSection}>
          <label>Alternative Phone: </label>
          {' '}
          {alternativePhone ? (
            <>
              {contactCode}
              {' '}
              {alternativePhone}
            </>
          ) : (
            <span />
          )}
        </div>
      </div>
    </div>
  );
}
