import { TubeChartLibraryData, TubeChartLibraryListColumnType } from './types';

export function formatTubeChartLibraryData(data: TubeChartLibraryData[]) {
  const res: TubeChartLibraryListColumnType[] = [];

  if (!data || data?.length === 0) return [];

  data?.forEach((col: TubeChartLibraryData) => {
    const obj: TubeChartLibraryListColumnType = {
      action: 'delete',
      sections: col.Section,
      mfg: col.Manufacturer || '',
      model: col.Model || '',
      vessel: col.Vessel || '',
      tubeCount: col.TubeCount?.toString() || '',
      id: col.TubeId || '',
    };

    res.push(obj);
  });

  return res;
}
