import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CityStateDetails,
  MasterDataInitalState, MasterResponse, PinCodeDetails,
  PinCodeDetailsQuery, RoleMasterApiResult,
  TypeReportTypeDetails,
} from '../../utils/type';

const initialState: MasterDataInitalState = {
  countries: [],
  salutations: [],
  historyStack: [],
  markups: [],
  phoneCodes: [],
  pinCodeDetails: {} as PinCodeDetails,
  pinCodeQueryDetails: {} as PinCodeDetailsQuery,
  isError: false,
  excelDownloadUrl: '',
  citySuggestions: [],
  cityStateSuggestions: [],
  isLoading: false,
  isCityLoading: false,
  isCityStateLoading: false,
  rolesList: {} as RoleMasterApiResult,
  cityDetails: [],
  reportTypeList: {} as TypeReportTypeDetails[],
  codeList: {} as TypeReportTypeDetails[],
  stateList: [],
  showDeleteIcon: false,
};

export const masterDataSlice = createSlice({
  name: 'masterData',
  initialState,
  reducers: {

    // Get City Suggestion

    getCitySuggestionStart(state, _action: PayloadAction<string>) {
      return {
        ...state,
        isCityLoading: true,
        citySuggestions: [],
      };
    },
    getCitySuggestionSuccess(state, action: PayloadAction<CityStateDetails[]>) {
      return {
        ...state,
        isCityLoading: false,
        citySuggestions: action.payload,
      };
    },
    getCitySuggestionFailure(state) {
      return {
        ...state,
        isCityLoading: false,
      };
    },
    resetCitySuggestion(state) {
      return {
        ...state,
        citySuggestions: [],
        isCityLoading: false,
      };
    },
    setHistoryStack(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        historyStack: action.payload,
      };
    },
    getMasterDataStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getMasterDataSuccess(state, action: PayloadAction<MasterResponse>) {
      return {
        ...state,
        isLoading: false,
        countries: action.payload.Country,
        salutations: action.payload.Salutations,
        markups: action.payload.Markups,
        phoneCodes: action.payload.PhoneCodes,
        stateList: action.payload.State,
      };
    },
    getMasterDataFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    getPinCodeDetailsStart(state, action: PayloadAction<PinCodeDetailsQuery>) {
      return {
        ...state,
        isLoading: true,
        isError: false,
        pinCodeQueryDetails: action.payload,
      };
    },
    getPinCodeDetailsSuccess(state, action: PayloadAction<PinCodeDetails>) {
      return {
        ...state,
        isLoading: false,
        pinCodeDetails: action.payload,
        isError: false,
      };
    },
    getPinCodeDetailsFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
        isError: true,
      };
    },
    removePinCodeDetails(state) {
      return {
        ...state,
        pinCodeDetails: null,
        isError: false,
      };
    },
    errorState(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isError: action.payload,
      };
    },
    onGenerateInvoiceStart(state, _action) {
      return {
        ...state,
        excelDownloadUrl: '',
      };
    },
    onGenerateInvoiceSuccess(state, action: PayloadAction<string>) {
      return {
        ...state,
        excelDownloadUrl: action.payload,
      };
    },
    onGenerateInvoiceFailure(state) {
      return {
        ...state,
        excelDownloadUrl: '',
      };
    },
    // ROLE MASTER DATA
    onGetRoleMasterData(state) {
      return {
        ...state,
        rolesList: {} as RoleMasterApiResult,
      };
    },
    onGotRoleMasterData(state, action: PayloadAction<RoleMasterApiResult>) {
      return {
        ...state,
        rolesList: action.payload,
      };
    },
    onFailRoleMasterData(state) {
      return {
        ...state,
        rolesList: {} as RoleMasterApiResult,
      };
    },
    // REPORT TYPE MASTER DATA
    onGetReportTypeMasterData(state) {
      return {
        ...state,
        reportTypeList: {} as TypeReportTypeDetails[],
      };
    },
    onGotReportTypeMasterData(state, action: PayloadAction<TypeReportTypeDetails[]>) {
      return {
        ...state,
        reportTypeList: action.payload,
      };
    },
    onFailReportTypeMasterData(state) {
      return {
        ...state,
        reportTypeList: {} as TypeReportTypeDetails[],
      };
    },
    // CODE MASTER DATA
    onGetCodeMasterData(state) {
      return {
        ...state,
        codeList: {} as TypeReportTypeDetails[],
      };
    },
    onGotCodeMasterData(state, action: PayloadAction<TypeReportTypeDetails[]>) {
      return {
        ...state,
        codeList: action.payload,
      };
    },
    onFailRCodeMasterData(state) {
      return {
        ...state,
        codeList: {} as TypeReportTypeDetails[],
      };
    },
    // Get City State Suggestion

    getCityStateSuggestionStart(state, _action: PayloadAction<{ CityName: string, StateName: string }>) {
      return {
        ...state,
        isCityStateLoading: true,
        cityStateSuggestions: [],
      };
    },
    getCityStateSuggestionSuccess(state, action: PayloadAction<CityStateDetails[]>) {
      return {
        ...state,
        isCityStateLoading: false,
        cityStateSuggestions: action.payload,
      };
    },
    getCityStateSuggestionFailure(state) {
      return {
        ...state,
        isCityStateLoading: false,
      };
    },
    resetCityStateSuggestion(state) {
      return {
        ...state,
        cityStateSuggestions: [],
        isCityStateLoading: false,
      };
    },
    ShowDeleteIcon(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        showDeleteIcon: action.payload,
      };
    },
  },
});

export const {
  getMasterDataStart, getMasterDataSuccess, getMasterDataFailure, getPinCodeDetailsStart,
  getPinCodeDetailsSuccess, getPinCodeDetailsFailure, removePinCodeDetails, errorState,
  setHistoryStack, onGenerateInvoiceStart, onGenerateInvoiceSuccess, onGenerateInvoiceFailure,
  getCitySuggestionStart, getCitySuggestionSuccess, getCitySuggestionFailure, onGetRoleMasterData,
  onGotRoleMasterData, onFailRoleMasterData, resetCitySuggestion, onGetReportTypeMasterData, onGotReportTypeMasterData, onFailReportTypeMasterData,
  onGetCodeMasterData, onGotCodeMasterData, onFailRCodeMasterData,
  getCityStateSuggestionStart, getCityStateSuggestionSuccess, getCityStateSuggestionFailure, resetCityStateSuggestion,
  ShowDeleteIcon,
} = masterDataSlice.actions;
export const masterDataReducer = masterDataSlice.reducer;

export type MasterDataActions =
| ReturnType<typeof getMasterDataStart>
| ReturnType<typeof getMasterDataSuccess>
| ReturnType<typeof getMasterDataFailure>
| ReturnType<typeof getPinCodeDetailsStart>
| ReturnType<typeof getPinCodeDetailsSuccess>
| ReturnType<typeof getPinCodeDetailsFailure>
| ReturnType<typeof removePinCodeDetails>
| ReturnType<typeof errorState>
| ReturnType<typeof setHistoryStack>
| ReturnType<typeof onGenerateInvoiceStart>
| ReturnType<typeof onGenerateInvoiceSuccess>
| ReturnType<typeof onGenerateInvoiceFailure>
| ReturnType<typeof getCitySuggestionStart>
| ReturnType<typeof getCitySuggestionSuccess>
| ReturnType<typeof getCitySuggestionFailure>
| ReturnType<typeof onGetRoleMasterData>
| ReturnType<typeof onGotRoleMasterData>
| ReturnType<typeof onFailRoleMasterData>
| ReturnType<typeof resetCitySuggestion>
| ReturnType<typeof onGetReportTypeMasterData>
| ReturnType<typeof onGotReportTypeMasterData>
| ReturnType<typeof onFailReportTypeMasterData>
| ReturnType<typeof onGetCodeMasterData>
| ReturnType<typeof onGotCodeMasterData>
| ReturnType<typeof onFailRCodeMasterData>
| ReturnType<typeof getCityStateSuggestionStart>
| ReturnType<typeof getCityStateSuggestionSuccess>
| ReturnType<typeof getCityStateSuggestionFailure>
| ReturnType<typeof resetCityStateSuggestion>
| ReturnType<typeof ShowDeleteIcon>;
