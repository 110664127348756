import React from 'react';
import { TubeChartGrid } from '../../VesselInformation/components/TubeChart/Diagram/tubeChartGrid';
import { TubeChartLayoutProps } from '../types';

function TubeChartLayout({ poly }:TubeChartLayoutProps) {
  return (
    <div className="p10 noScrollWidth noTableBreak w100 textCenter">
      {poly?.totalVisible > 0 && (
      <TubeChartGrid
        polygon={poly}
        polyCircles={[[...poly.circles]]}
        index={0}
        setPolyCircles={() => {}}
        isPdfReport
      />
      )}
    </div>
  );
}

export default TubeChartLayout;
