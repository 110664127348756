import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
// import domtoimage from 'dom-to-image';
import domtoimage from 'dom-to-image-more';
// import html2canvas from 'html2canvas';
import { TubeChartGrid } from './tubeChartGrid';
import {
  Circle,
  GetTubeChartPayload,
  PolygonInterface,
  TubeConfiguration,
  UpdateTubeConfiguration,
} from '../../../utils/tubeChartTypes';
import ComponentPageHeader from '../../../../../Common/PageHeader/ComponentPageHeader';
import { Polygon } from './gridClass';
import TubeChartPanel from './tubeChartPanel';
import { RootState } from '../../../../../redux/rootState';
import {
  getTubeChartByIdStart, getTubeChartsStart,
  setSelectedSection,
  updateTubeChartScreenShotStart,
  updateTubeChartStart,
} from '../../../redux/tubeChart/tubeChartSlice';
import { setLoader } from '../../../../../redux/loaderSlice';
import styles from './index.module.scss';
import { getUpdateTubePayload } from '../../../utils/helper/tubeChart';
import { showErrorToaster, showSuccessToaster } from '../../../../../Common/ComponentToast/ComponentSuccessToasts';
import { isScreenShotStart } from '../../../redux/sliceVesselInformation';

export function TubeDiagram() {
  const [polygonList, setPolygonList] = useState<PolygonInterface[]>([]);
  const [polyCircles, setPolyCircles] = useState<Circle[][][]>([]);
  const [totalTubeCount, setTotalTubeCount] = useState(0);
  const [isScreenShortLoading, setIsScreenShortLoading] = useState(false);

  const [isScreenshotTaken, setIsScreenshotTaken] = useState(false);
  const [screenshotPayload, setScreenshotPayload] = useState < UpdateTubeConfiguration | null >(null);

  const {
    chartList, tubeChartDefects,
    selectedSection, isLoading,
  } = useSelector((state:RootState) => state.TubeChartReducer);
  const {
    startScreenShot,
  } = useSelector((state:RootState) => state.VesselInformationReducer);
  const { tubeId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const componentRef = useRef<HTMLDivElement>(null);

  const selectedSectionIndex = useMemo(() => {
    if (selectedSection) {
      return chartList.findIndex((details) => details.Panel === selectedSection);
    }
    return 0;
  }, [selectedSection, chartList?.length]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    const fetchType = searchParams.get('type');
    const jobOrder = searchParams.get('jobOrder');
    const vessel = searchParams.get('vessel');
    if (fetchType === 'all') {
      const payload:GetTubeChartPayload = {
        JobOrder: String(jobOrder),
        VesselId: String(vessel),
        Type: 2,
      };
      dispatch(getTubeChartsStart(payload));
    } else {
      dispatch(getTubeChartByIdStart(String(tubeId)));
    }
  }, [tubeId, searchParams]);

  // --------------------Old Code without Zoom -----------------
  // useEffect(() => {
  //   if (chartList.length > 0) {
  //     const polyArr:PolygonInterface[] = [];

  //     chartList.forEach((chartDetails:TubeConfiguration) => {
  //       const tempPoly = new Polygon(chartDetails);
  //       polyArr.push(tempPoly);
  //     });

  //     setPolygonList([...polyArr]);
  //   }
  // }, [chartList]);

  useEffect(() => {
    if (chartList.length > 0) {
      const polyArr: PolygonInterface[] = [];

      const updatedPolyArr = chartList.map((poly, index) => ({
        ...poly,
        section: chartList.length === 1 ? selectedSection : `${index + 1}`,
      }));

      updatedPolyArr.forEach((chartDetails: TubeConfiguration) => {
        const tempPoly = new Polygon(chartDetails);
        polyArr.push(tempPoly);
      });

      setPolygonList([...polyArr]);
    }
  }, [chartList]);

  useEffect(() => {
    if (tubeChartDefects?.length > 0) {
      polygonList[Number(selectedSectionIndex)].displayDefects(tubeChartDefects);
      const temp = [...polyCircles];
      temp[selectedSectionIndex] = polygonList[Number(selectedSectionIndex)].circles;
      setPolyCircles([...temp]);
    }
  }, [tubeChartDefects]);

  const handleUndo = () => {
    if (polygonList.length > 0) {
      polygonList[Number(selectedSectionIndex)].undo();
      const temp = [...polyCircles];
      temp[selectedSectionIndex] = polygonList[Number(selectedSectionIndex)].circles;
      setPolyCircles([...temp]);
    }
  };

  const handleSectionChange = (value:string) => {
    dispatch(setSelectedSection(value));
    // const ind = chartList.findIndex((details) => details.Panel === value);
    // setPolyCircles([...polygonList[ind].circles]);
  };

  const handleSave = () => {
    const jobOrder = String(searchParams.get('jobOrder'));
    const vessel = String(searchParams.get('vessel'));
    const payload = getUpdateTubePayload(
      jobOrder,
      vessel,
      polygonList,
      chartList,
    );

    dispatch(updateTubeChartStart(payload));
    polygonList[Number(selectedSectionIndex)]?.ClearUndo();
    polygonList[Number(selectedSectionIndex)]?.removeDefects();
  };

  useEffect(() => {
    const totalCount = polygonList?.reduce((acc, curr:PolygonInterface) => acc + curr.totalVisible, 0);
    setTotalTubeCount(totalCount);
  }, [polygonList?.[Number(selectedSectionIndex)]?.totalVisible]);

  // const handleScreenshot = () => {
  //   dispatch(isScreenShotStart(true));
  //   polygonList?.forEach((item) => {
  //     item.removeDefects();
  //   });

  //   if (!componentRef.current) {
  //     return;
  //   }

  //   setIsScreenShortLoading(true);
  //   const scale = 10;
  //   const margin = 20;

  //   const width = componentRef.current.scrollWidth + margin * 2;
  //   const height = componentRef.current.scrollHeight + margin * 2;

  //   domtoimage.toPng(componentRef.current, {
  //     quality: 1,
  //     width: (width + margin * 2) * scale,
  //     height: (height + margin * 2) * scale,
  //     style: {
  //       transform: `scale(${scale})`,
  //       transformOrigin: 'top left',
  //       padding: `${margin}px`,
  //       width: `${width}px`,
  //       height: `${height}px`,
  //       backgroundColor: 'white',
  //     },
  //   })
  //     .then((dataUrl) => {
  //       const base64Image = dataUrl.split(',')[1];

  //       const jobOrder = String(searchParams.get('jobOrder'));
  //       const vessel = String(searchParams.get('vessel'));

  //       let payload = getUpdateTubePayload(
  //         jobOrder,
  //         vessel,
  //         polygonList,
  //         chartList,
  //       );

  //       payload = { ...payload, ImageBytes: base64Image, TubeCount: String(totalTubeCount) };

  //       dispatch(updateTubeChartScreenShotStart(payload));
  //       dispatch(isScreenShotStart(false));
  //       showSuccessToaster('Screenshot captured successfully');
  //       setIsScreenShortLoading(false);
  //     })
  //     .catch(() => {
  //       setIsScreenShortLoading(false);
  //       dispatch(isScreenShotStart(false));
  //       showErrorToaster('Failed to capture screenshot');
  //     });
  // };

  // const handleScreenshot = () => {
  //   const container = componentRef.current;
  //   dispatch(isScreenShotStart(true));
  //   polygonList?.forEach((item) => {
  //     item.removeDefects();
  //   });

  //   if (!container) {
  //     return;
  //   }
  //   setIsScreenShortLoading(true);
  //   domtoimage.toPng(container, {
  //     height: container.scrollHeight,
  //     width: container.scrollWidth,
  //     style: {
  //       // transform: 'scale(2)',
  //       transformOrigin: 'top left',
  //       overflow: 'visible',
  //       // backgroundColor: 'white',
  //     },
  //   })
  //     .then((dataUrl) => {
  //       const base64Image = dataUrl.split(',')[1];

  //       const jobOrder = String(searchParams.get('jobOrder'));
  //       const vessel = String(searchParams.get('vessel'));

  //       let payload = getUpdateTubePayload(
  //         jobOrder,
  //         vessel,
  //         polygonList,
  //         chartList,
  //       );

  //       payload = { ...payload, ImageBytes: base64Image, TubeCount: String(totalTubeCount) };

  //       dispatch(updateTubeChartScreenShotStart(payload));
  //       dispatch(isScreenShotStart(false));
  //       showSuccessToaster('Screenshot captured successfully');
  //       setIsScreenShortLoading(false);
  //     })
  //     .catch(() => {
  //       setIsScreenShortLoading(false);
  //       dispatch(isScreenShotStart(false));
  //       showErrorToaster('Failed to capture screenshot');
  //     });
  // };

  // const handleScreenshot = () => {
  //   const container = componentRef.current;
  //   if (!container) return;

  //   dispatch(isScreenShotStart(true));
  //   polygonList?.forEach((item) => item.removeDefects());

  //   setIsScreenShortLoading(true);

  //   html2canvas(container, {
  //     useCORS: true, // Ensures external images are captured if any
  //     scale: 1, // Increase scale for better quality
  //     windowWidth: container.scrollWidth, // Set to capture full width
  //     windowHeight: container.scrollHeight, // Set to capture full height
  //   })
  //     .then((canvas) => {
  //       const dataUrl = canvas.toDataURL();
  //       const base64Image = dataUrl.split(',')[1];

  //       console.log(dataUrl, 'ppp');
  //       console.log(base64Image, 'ppp');

  //       const jobOrder = String(searchParams.get('jobOrder'));
  //       const vessel = String(searchParams.get('vessel'));

  //       let payload = getUpdateTubePayload(
  //         jobOrder,
  //         vessel,
  //         polygonList,
  //         chartList,
  //       );

  //       payload = { ...payload, ImageBytes: base64Image, TubeCount: String(totalTubeCount) };

  //       dispatch(updateTubeChartScreenShotStart(payload));
  //       dispatch(isScreenShotStart(false));
  //       showSuccessToaster('Screenshot captured successfully');
  //       setIsScreenShortLoading(false);
  //     })
  //     .catch(() => {
  //       setIsScreenShortLoading(false);
  //       dispatch(isScreenShotStart(false));
  //       showErrorToaster('Failed to capture screenshot');
  //     });
  // };

  const handleScreenshot = () => {
    dispatch(isScreenShotStart(true));
    const container = componentRef.current;
    if (!container) return;

    polygonList?.forEach((item) => item.removeDefects());
    setIsScreenShortLoading(true);

    const originalStyle = {
      height: container.style.height,
      overflow: container.style.overflow,
    };

    container.style.height = 'auto';
    container.style.overflow = 'visible';

    // Allow DOM to update before taking screenshot
    requestAnimationFrame(() => {
      domtoimage.toPng(container, {
        quality: 1,
        width: container.scrollWidth,
        height: container.scrollHeight,
        style: { backgroundColor: 'white' },
      })
        .then((dataUrl) => {
          const base64Image = dataUrl.split(',')[1];

          const jobOrder = String(searchParams.get('jobOrder'));
          const vessel = String(searchParams.get('vessel'));

          let payload = getUpdateTubePayload(
            jobOrder,
            vessel,
            polygonList,
            chartList,
          );

          payload = { ...payload, ImageBytes: base64Image, TubeCount: String(totalTubeCount) };

          setScreenshotPayload(payload); // Set payload in state
          setIsScreenshotTaken(true); // Mark screenshot as taken
          showSuccessToaster('Screenshot captured successfully');
        })
        .catch(() => {
          showErrorToaster('Failed to capture screenshot');
        })
        .finally(() => {
          setIsScreenShortLoading(false);
          dispatch(isScreenShotStart(false));
          container.style.height = originalStyle.height;
          container.style.overflow = originalStyle.overflow;
        });
    });
  };

  useEffect(() => {
    if (isScreenshotTaken && screenshotPayload) {
      dispatch(updateTubeChartScreenShotStart(screenshotPayload));
      setIsScreenshotTaken(false); // Reset screenshot status
      setScreenshotPayload(null); // Reset payload after dispatch
    }
  }, [isScreenshotTaken, screenshotPayload, dispatch]);

  // const handleScreenshot = () => {
  //   dispatch(isScreenShotStart(true));
  //   const container = componentRef.current;
  //   if (!container) return;

  //   polygonList?.forEach((item) => item.removeDefects());
  //   setIsScreenShortLoading(true);

  //   const originalStyle = {
  //     height: container.style.height,
  //     overflow: container.style.overflow,
  //   };

  //   container.style.height = 'auto';
  //   container.style.overflow = 'visible';

  //   domtoimage.toPng(container, {
  //     quality: 1,
  //     width: container.scrollWidth,
  //     height: container.scrollHeight,
  //     style: { backgroundColor: 'white' },
  //   })
  //     .then((dataUrl) => {
  //       const base64Image = dataUrl.split(',')[1];

  //       const jobOrder = String(searchParams.get('jobOrder'));
  //       const vessel = String(searchParams.get('vessel'));

  //       let payload = getUpdateTubePayload(
  //         jobOrder,
  //         vessel,
  //         polygonList,
  //         chartList,
  //       );

  //       payload = { ...payload, ImageBytes: base64Image, TubeCount: String(totalTubeCount) };

  //       dispatch(updateTubeChartScreenShotStart(payload));
  //       dispatch(isScreenShotStart(false));
  //       showSuccessToaster('Screenshot captured successfully');
  //       setIsScreenShortLoading(false);
  //     })
  //     .catch(() => {
  //       setIsScreenShortLoading(false);
  //       dispatch(isScreenShotStart(false));
  //       showErrorToaster('Failed to capture screenshot');
  //     })
  //     .finally(() => {
  //       container.style.height = originalStyle.height;
  //       container.style.overflow = originalStyle.overflow;
  //     });
  // };

  const tubeChartDirectionStyle = chartList?.[0]?.Layout === '2' ? styles.displayTubeRow : styles.displayBlock;
  const tubeChartDirectionScreenShotStyle = chartList?.[0]?.Layout === '2' ? styles.printDisplayTubeRow : styles.displayScreenShotBlock;

  return (
    <div className={styles.vesselTubeChartContainer}>
      <ComponentPageHeader subHeading="Tube Chart" />
      <TubeChartPanel
        handleUndo={handleUndo}
        handleSectionChange={handleSectionChange}
        tubeCount={polygonList?.[Number(selectedSectionIndex)]?.totalVisible}
        isUndoDisable={polygonList?.[Number(selectedSectionIndex)]?.history?.length === 0}
        totalTubeCount={totalTubeCount}
        handleScreenshot={handleScreenshot}
        isScreenShortLoading={isScreenShortLoading}
      />
      <div className={`${styles.vesselDiv} save_and_next_div`}>
        <div className="button_margin_left">
          <Button className="button_save_and_next" onClick={handleSave}>Save</Button>
        </div>
      </div>
      <div ref={componentRef} className={startScreenShot ? tubeChartDirectionScreenShotStyle : tubeChartDirectionStyle}>
        {polygonList?.map((item, index) => (
          <TubeChartGrid
            polygon={item}
            polyCircles={polyCircles}
            index={index}
            setPolyCircles={setPolyCircles}
          />
        ))}
      </div>
    </div>
  );
}
