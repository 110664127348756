import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';

function ComponentHazardTable() {
  const { reportPdfResponse4, themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  return (
    <div style={{ marginTop: '20px' }}>
      <table className="bordered-table w100">
        <div
          className="tableAfterThickBorderStyle"
          style={{
            backgroundColor: BorderColor,
          }}
        />
        <div
          className="tableBeforeThickBorderStyle"
          style={{
            backgroundColor: BorderColor,
          }}
        />
        <tr>
          <td className=" tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>Job Order</td>
          <td className="textCenter">
            {reportPdfResponse4?.Hazard_Analysis?.[0]?.JobOrder}
          </td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>Site</td>
          <td className="textCenter">{reportPdfResponse4?.Hazard_Analysis?.[0]?.SiteName}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>Analyst</td>
          <td className="textCenter">{reportPdfResponse4?.JobOrderData?.AnalystName}</td>
        </tr>
      </table>
    </div>
  );
}

export default ComponentHazardTable;
