import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';

function AbbreviationsTable() {
  const { reportPdfResponse4, themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  return (
    <table className="bordered-table w100">
      <div
        className="tableAfterThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />
      <div
        className="tableBeforeThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />

      <tr>
        <td className="tableGreenHeading textCenter boldText w45" style={{ backgroundColor: MainColor }}>
          Abbreviation
        </td>
        <td className="tableGreenHeading textCenter boldText w55" style={{ backgroundColor: MainColor }}>
          Explanation
        </td>
      </tr>
      {reportPdfResponse4?.Abbrevations?.map((data) => (
        <tr>
          <td className="textCenter">{data.Abbreviation}</td>
          <td className="textCenter">{data.Explanation}</td>
        </tr>
      ))}
    </table>
  );
}

export default AbbreviationsTable;
