export const TubeChartLibraryListColumn = [
  { key: 'action', label: 'Action' },
  { key: 'sections', label: 'Sections' },
  { key: 'mfg', label: 'Mfg' },
  { key: 'model', label: 'Model' },
  { key: 'vessel', label: 'Vessel' },
  { key: 'tubeCount', label: 'Tube Count' },
];

export const tempTubeChartLibraryData = {
  Data: [
    {
      Sections: 'Section A',
      Manufacturer: 'MfgCorp',
      Model: 'Model X1',
      Vessel: 'Vessel Alpha',
      TubeCount: 10,
      RequestId: 'REQ123',
    },
    {
      Sections: 'Section B',
      Manufacturer: 'TubeMakers',
      Model: 'Model Z7',
      Vessel: 'Vessel Beta',
      TubeCount: 8,
      RequestId: 'REQ124',
    },
    {
      Sections: 'Section C',
      Manufacturer: 'MegaTubes',
      Model: 'Model Y9',
      Vessel: 'Vessel Gamma',
      TubeCount: 15,
      RequestId: 'REQ125',
    },
    {
      Sections: 'Section D',
      Manufacturer: 'PipeWorks',
      Model: 'Model Q3',
      Vessel: 'Vessel Delta',
      TubeCount: 12,
      RequestId: 'REQ126',
    },
  ],
  TotalRecords: 4,
};
