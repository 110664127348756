export default {
  LOGIN_PAGE: '/login',
  SCREEN_OTP: '/otp',
  SCREEN_FORGOT_PASSWORD: '/forgot-password',
  SCREEN_ACCOUNT_RESET: '/reset',
  SCREEN_SUCCESS: '/success',
  SCREEN_DASHBOARD: '/home/dashboard',
  SCREEN_HOME: 'home',
  SCREEN_CUSTOMER_DETAILS: 'customers',
  SCREEN_ADD_SMC: '/smc/add-smc',
  SCREEN_PROFILE: '/profile',
  PROFILE_EDIT: '/profile/edit',
  DASHBOARD: '/dashboard',
  HOME: 'home',
  Customers: 'customers',
  ADD_CUSTOMER: '/customers/add-customer',
  ADD_BID: '/customer/add-bid',
  SMC: '/smc',
  PRICE_LIST: 'price-list',
  KIT_PRICES: 'kit-prices',
  MARKUPS: 'markups',

  JOBS: 'jobs',
  ADD_JOB: '/jobs/add-job',
  ADD_JOB_WITH_ID: '/jobs/add-job',
  BID_WORKSHEET: '/jobs/bid-worksheet',
  ADD_JOB_BID: '/jobs/add-bid',
  ADD_UNIT: '/add-unit',
  UNIT: '/unit',

  FREON_CYLINDER: '/freon-cylinder',
  ADD_FREON_CYLINDER: '/freon-cylinder/add-freon',
  EDIT_FREON_CYLINDER: '/freon-cylinder/:kitId',
  CALENDER_SCREEN: 'calender',
  CHANGE_PASSWORD: 'change-password',
  TUBE_CHARGE: 'tube-chart/:tubeId?',
  TUBE_CHART: 'tube-chart',

  TYPE_LIST: 'type-list',
  STYLE_LIST: 'style-list',
  MANUFACTURER_LIST: 'manufacturer-list',
  VESSEL_LIST: 'vessel-list',
  TEST_END_LIST: 'test-end-list',
  SUPPORT_LIST: 'support-list',
  TUBE_LIST: 'tube-list',
  MATERIAL_LIST: 'material-list',
  REPORT_TYPE_LIST: 'report-list',
  DEFAULT_LIST: 'default-list',
  EQUIPMENT_CATEGORY: 'equipment-category-list',

  OIL_KITS: 'oilKits',
  ADD_OIL_KITS: '/oilKits/add-oilKits',
  ANALYST: 'analyst',
  ADD_ANALYST: '/analyst/add-analyst',
  EDIT_ANALYST: 'analyst/:analystId',
  EDIT_OIS_KITS: 'oilKits/:kitId',
  UNSCHEDULED_JOB: 'unscheduled-jobs',
  SCHEDULED_JOB: 'scheduled-jobs',
  OIL_REPORT: 'oil-report',
  EDIT_UNSCHEDULED_JOB: 'unscheduled-jobs/:bidId/:jobId',
  EDIT_SCHEDULED_JOB: 'scheduled-jobs/:bidId/:jobId',
  BID_PDF_SCREEN: 'bid-pdf',
  PRINT_PDF_SCREEN: 'print-preview',

  CALENDER: '/calender',
  WEEKCALENDER: '/calender/w',
  MONTHCALENDER: '/calender/m',
  SITES: '/sites',
  EDIT_SITES: '/sites/:siteId',
  CALIBRATION_PROCEDURE: 'calibration-procedure',
  INSPECTION_PROCEDURE: 'inspection-procedure',
  RECOMMENDATION_CODES: 'recommendation-codes',
  RECOMMENDATION_TEXT: 'recommendation-text',
  RBAC: 'RBAC',
  INVENTORY_MANAGEMENT: 'inventory-management',
  EQUIPMENT_NEEDED_FOR_JOBS: '/inventory-management/equipment-needed-for-jobs',
  EQUIPMENT_REQUEST: '/inventory-management/equipment-request',
  INVENTORY_LANDING_PAGE: '/inventory-management/inventory',
  TEST_EQUIPMENT_REQUEST_FORM: '/inventory-management/equipment-request-form',
  ANALYST_DEFECT: 'analyst-defect',
  DEFECT_CODE: 'defect-code',
  SEND_EQUIPMENT_SCREEN: 'send-equipment-screen',
  BOARD_POSITION: 'board-position',
  USER_MANAGEMENT: 'user-management',
  VESSEL_INFORMATION: 'vessel-information',
  ADD_VESSEL_INFORMATION: '/vessel-information/add',
  EDIT_VESSEL_INFORMATION_TabId: '/vessel-information/:jobOrderId/:vesselId/:tabId',
  EDIT_VESSEL_INFORMATION: '/vessel-information/:jobOrderId/:vesselId',
  EXPENSE_REPORTS: 'expense-reports',
  JOB_ORDER: 'job-order',
  Inspection_Summary: 'inspection-summary',
  RECOMMENDATIONS: 'recommendations',
  DATASHEETS: 'datasheets',
  PHOTOS: 'photos',
  REPORTS: 'reports',
  EDIT_ANALYST_REPORT: '/reports/:jobOrderId/:vesselId/:analystId',
  REPORT_WORKSPACE: 'report-workspace',
  COMBINE_REPORTS: 'combine-reports',
  GENERATE_PDF: 'generate-pdf',
  REPORT_CHECKLIST: 'report-checklist',
  HAZARD_ANALYSIS: 'hazard-analysis',
  INSPECTION_LETTER: 'inspection-letter',
  TUBE_CHART_LIBRARY: 'tube-chart-library',
};
