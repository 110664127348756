export enum BidWorkSheetFormNames {
  customerMarkup = 'customerMarkup',
  custId = 'custId',
  customerName = 'customerName',
  customerSMC = 'customerSMC',
  gulfCoastFL = 'gulfCoastFL',
  siteSMC = 'siteSMC',
  outOfCountry = 'outOfCountry',
  site = 'site',
  address = 'address',
  zip = 'zip',
  state = 'state',
  city = 'city',
  country = 'country',
  siteType = 'siteType',
  analystName = 'analystName',
  drivenFrom = 'drivenFrom',
  analystAddress = 'analystAddress',
  analystZip = 'analystZip',
  analystState = 'analystState',
  analystCity = 'analystCity',
  analystCountry = 'analystCountry',
  roundTripMiles = 'roundTripMiles',
}

export const UnitListingColumns = [
  // { key: 'sn', label: 'SN' },
  { key: 'action', label: 'Action' },
  { key: 'location', label: 'Location' },
  { key: 'model', label: 'Model' },
  { key: 'serialNumber', label: 'Serial #' },
  { key: 'vesselsToolTip', label: 'Vessels' },
  { key: 'tubeCount', label: 'Tube Cnt' },
  { key: 'price', label: 'Price' },
];

export const BidPricingDefaultValues = {
  tubeCharge: 0,
  calculatedDays: 0,
  overRideDays: 0,
  fluxLeakageDays: 0,
  driveExpense: 0,
  lodgePerDiem: 0,
  flightCharge: 0,
  airfare: 0,
  totalFlightExpense: 0,
  equipmentCharges: 0,
  otherCharges: 0,
  totalMiscExpense: 0,
  communicationCharges: 0,
  parkingChanges: 0,
  safetyCharges: 0,
  nightCharges: 0,
  otSaturday: 0,
  otSunday: 0,
  otHoliday: 0,
  safetyTraining: 0,
  safetyTrainingCharges: 0,
  additionalCharges: 0,
  totalExpenses: 0,
  markup: 0,
  fluxShipping: 0,
  absShipping: 0,
  nonProdDays: 0,
  nonProdDaysAmount: 0,
  totalBid: 0,
};

export enum BidPriceDetailNames {
  TubeCharge = 'tubeCharge',
  CalculatedDays = 'calculatedDays',
  CorrectionFactor = 'correctionFactor',
  CorrectionType = 'correctionType',
  OverRideDays = 'overRideDays',
  FluxLeakageDays = 'fluxLeakageDays',
  DriveExpense = 'driveExpense',
  LodgePerDiem = 'lodgePerDiem',
  FlightCharge = 'flightCharge',
  Airfare = 'airfare',
  TotalFlightExpense = 'totalFlightExpense',
  EquipmentCharges = 'equipmentCharges',
  OtherCharges = 'otherCharges',
  TotalMiscExpense = 'totalMiscExpense',
  IsCommunicationCharges = 'isCommunicationCharges',
  CommunicationCharges = 'CommunicationCharge',
  IsParking = 'isParking',
  ParkingChanges = 'ParkingCharge',
  IsSafetyCharges = 'isSafetyCharges',
  SafetyCharges = 'SafetyCharge',
  IsNightCharges = 'isNightCharges',
  NightCharges = 'NightCharge',
  IsOTSaturday = 'isOTSaturday',
  OTSaturday = 'OtSaturday',
  IsOTSunday = 'isOTSunday',
  OTSunday = 'OtSunday',
  IsOTHoliday = 'isOTHoliday',
  OTHoliday = 'OtHoliday',
  IsSafetyTraining = 'isSafetyTraining',
  SafetyTraining = 'safetyTraining',
  SafetyTrainingCharges = 'safetyTrainingCharges',
  TotalExpenses = 'totalExpenses',
  Markup = 'markup',
  FluxShipping = 'fluxShipping',
  AbsShipping = 'absShipping',
  AdditionalCharges = 'additionalCharges',
  NonProdDays = 'nonProdDays',
  NonProdDaysAmount = 'nonProdDaysAmount',
  TotalBid = 'totalBid',
}
