import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Grid, Tab, Tabs,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../../Common/TabsStyles';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import ComponentRequest from './Components/ComponentRequest/ComponentRequest';
import ComponentEquipmentNeed from './Components/ComponentEquipmentNeed/ComponentEquipmentNeed';
import ComponentHistory from './Components/ComponentHistory/ComponentHistory';
import { CreateData, RequestForm, RequestMainFormValues } from './utils/types';
import { MainDefaultValue, validationSchema } from './utils/constants';
import { addEquipmentStart, resetIsRequestCreated } from './redux/sliceTestEquipments';
import { getUnitByIdStart, setSelectedTab } from '../ScreenUnitInformation/redux/unitInfoSlice';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';
import {
  getEquipmentDetailsByRequestIdStart, resetInventoryEquipmentRequest,
} from '../../InventoryManagement/TestEquipmentRequestScreen/redux/equipmentRequestSlice';
import { ADMIN_REQUEST, REJECT_REQUEST } from '../ScreenUnitInformation/utils/constants';
import localStorageConstants from '../../../utils/LocalStorageConstants';
import { ADMIN_ROLE_ID } from '../../../Routes/constant';
import { JobIcon } from '../../../assets/NavBarIcons';
import ComponentBreadcrumb from '../../../Common/ComponentBreadcrumb/ComponentBreadcrumb';
import { gaugeToInch } from '../../../utils/CommonFunctions';

export default function TestEquipmentRequestTabs() {
  const navigate = useNavigate();
  const {
    control, handleSubmit, formState: { errors }, watch, setValue, reset,
  } = useForm<RequestMainFormValues>({
    defaultValues: MainDefaultValue,
    resolver: yupResolver(validationSchema as ObjectSchema<RequestMainFormValues>),
  });
  const request = 1;
  const equipmentNeeded = 2;
  const history = 3;
  const dispatch = useDispatch();
  const { unitId, reqId, statusId } = useParams();
  const [comment, setComment] = useState(EditorState.createEmpty());
  const [value, setValues] = useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValues(newValue);
  };
  const rejectedRequest = Number(statusId) === REJECT_REQUEST || Number(statusId) === ADMIN_REQUEST;
  const showButton = statusId ? rejectedRequest : true;
  const {
    equipmentDetailsLoading,
    inventoryEquipmentMasterData,
    equipmentDetailsByRequestIdData,
  } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);

  const { selectedUnit } = useSelector((state: RootState) => state.unitInfoReducer);
  const { isRequestCreated } = useSelector((state: RootState) => state.equipmentReducer);
  const vesselValue = watch(RequestForm.vessel);
  const materialValue = watch(RequestForm.material);
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  const bidID = selectedUnit?.UnitInfo?.BidId;

  const unitID = equipmentDetailsByRequestIdData?.UnitId;
  const jobID = equipmentDetailsByRequestIdData?.JobId;

  const breadcrumbs = [
    { label: 'Job', url: `/jobs/${jobID || ''}`, icon: <JobIcon /> },
    { label: 'Bids', url: `/jobs/bid-worksheet/${bidID || ''}`, icon: <JobIcon /> },
    { label: 'Units', url: `/unit/${unitID || unitId}`, icon: <JobIcon /> },
    { label: 'Equipments', url: '/test-request', icon: <JobIcon /> },
  ];

  const disableSaveButton = useMemo(() => {
    if (Number(roleId) === ADMIN_ROLE_ID) {
      if (vesselValue && materialValue) {
        return false;
      }
      return true;
    }
    if (vesselValue && materialValue) {
      return false;
    }
    return true;
  }, [vesselValue, materialValue]);

  useEffect(() => {
    if (reqId) {
      dispatch(getEquipmentDetailsByRequestIdStart(reqId));
    }
  }, [reqId]);

  useEffect(() => {
    dispatch(setLoader(equipmentDetailsLoading));
  }, [equipmentDetailsLoading]);

  useEffect(() => {
    if (setValue && equipmentDetailsByRequestIdData?.RequestId && inventoryEquipmentMasterData?.Vessel?.length > 0) {
      setValue(RequestForm.jobId, equipmentDetailsByRequestIdData?.JobId);
      setValue(RequestForm.vessel, equipmentDetailsByRequestIdData?.VesselId?.toString());
      setValue(RequestForm.tubeType, equipmentDetailsByRequestIdData?.TubeId?.toString());
      setValue(RequestForm.workHrs, equipmentDetailsByRequestIdData?.WorkHours?.toString());
      setValue(RequestForm.material, equipmentDetailsByRequestIdData?.MaterialId?.toString());
      setValue(RequestForm.OD, equipmentDetailsByRequestIdData?.OuterDiameter?.toString());
      setValue(RequestForm.ID, equipmentDetailsByRequestIdData?.InnerDiameter?.toString());
      setValue(RequestForm.retestOf, equipmentDetailsByRequestIdData?.RetestOf);
      setValue(RequestForm.NWT, equipmentDetailsByRequestIdData?.Nwt);
      setValue(RequestForm.NWTBellLand, equipmentDetailsByRequestIdData?.NwtBellLand);
      setValue(RequestForm.NWTUnderFins, equipmentDetailsByRequestIdData?.NwtUnderfils);
      setValue(RequestForm.adminNote, equipmentDetailsByRequestIdData?.AdminComments);
      if (equipmentDetailsByRequestIdData?.TubeLength) {
        setValue(RequestForm.tubeLength, equipmentDetailsByRequestIdData?.TubeLength ?? '');
      }
      setValue(RequestForm.tubeCount, equipmentDetailsByRequestIdData?.NumberOfTubes);
      setValue(RequestForm.vertical, true);
      return;
    }
    reset();
  }, [equipmentDetailsByRequestIdData, inventoryEquipmentMasterData?.Vessel, selectedUnit]);

  useEffect(() => {
    if (unitId) {
      dispatch(getUnitByIdStart(String(unitId)));
    }
  }, [unitId]);

  useEffect(() => {
    if (selectedUnit) {
      setValue(RequestForm.OD, selectedUnit?.UnitInfo?.OdOfTubes?.toString());
      setValue(RequestForm.safetyEquipmentRequired, selectedUnit?.UnitInfo?.SafetyEquipmentRequired);
      setValue(RequestForm.outside, selectedUnit?.UnitInfo?.Outside);
      setValue(RequestForm.flux, selectedUnit?.UnitInfo?.TestType === 'FLT');
      setValue(RequestForm.absDiff, selectedUnit?.UnitInfo?.TestType === 'ADT');
      setValue(RequestForm.crsDiff, selectedUnit?.UnitInfo?.TestType === 'ECT');
      setValue(RequestForm.serialId, selectedUnit?.UnitInfo?.SerialNo);
      setValue(RequestForm.model, selectedUnit?.UnitInfo?.Model);
      setValue(RequestForm.jobId, unitId || '');
      setValue(RequestForm.manufacturer, String(selectedUnit?.UnitInfo?.ManufacturerId));
    }
  }, [selectedUnit]);

  const getHtmlFromEditorHTMLtoString = (newEditorState: EditorState): string => {
    const contentState = newEditorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  const callback = () => {
    if (Number(roleId) === ADMIN_ROLE_ID) {
      setValues(equipmentNeeded);
      return;
    }
    navigate(-1);
    dispatch(setSelectedTab(3));
  };

  useEffect(() => {
    if (isRequestCreated) {
      dispatch(resetIsRequestCreated());
      callback();
    }
  }, [isRequestCreated]);

  const onSubmit = (data: RequestMainFormValues) => {
    const arr = String(unitId).split('-');
    const createData: CreateData = {
      UnitId: String(unitId),
      JobId: arr?.[0] || '',
      VesselId: Number(data?.vessel) || null,
      TubeId: Number(data?.tubeType) || 0,
      MaterialId: Number(data?.material) || null,
      OuterDiameter: Number(data?.OD) || 0,
      InnerDiameter: Number(data?.ID) || 0,

      Nwt: data.units ? gaugeToInch(data?.NWT) : data?.NWT || '',
      NwtBellLand: data.units ? gaugeToInch(data?.NWTBellLand) : data?.NWTBellLand || '',
      NwtUnderFills: data.units ? gaugeToInch(data?.NWTUnderFins) : data?.NWTUnderFins || '',

      // Nwt: data?.NWT || '',
      // NwtBellLand: data?.NWTBellLand || '',
      // NwtUnderFills: data?.NWTUnderFins || '',
      WorkHrs: Number(data?.workHrs) || 0,
      RetestOf: data?.retestOf || '',
      Note: getHtmlFromEditorHTMLtoString(comment) || '',
      Vertical: data.vertical,
      ApprovalDate: '2023-08-10',
      Status: Number(roleId) === ADMIN_ROLE_ID ? 2 : 1,
      AdminComments: data?.adminNote,
    };
    if (!reqId) {
      dispatch(addEquipmentStart({
        payload: createData,
        callback,
        isAdmin: Number(roleId) === ADMIN_ROLE_ID,
        navigate,
      }));
      return;
    }
    const updatedData: CreateData = {
      RequestId: reqId,
      ...createData,
    };
    dispatch(addEquipmentStart({
      payload: updatedData,
      callback,
      isAdmin: Number(roleId) === ADMIN_ROLE_ID,
      navigate,
    }));
  };

  useEffect(() => {
    dispatch(resetInventoryEquipmentRequest());
    return () => {
      setComment(EditorState.createEmpty());
    };
  }, []);

  function showComponentListingDetails(): JSX.Element {
    if (value === request) {
      return (
        <div className="div_container_CustomerInfo">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <ComponentRequest setComment={setComment} comment={comment} control={control} errors={errors} watch={watch} setValue={setValue} />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                {showButton && (
                  <div className="save_and_next_div">
                    <div>
                      <Button onClick={() => navigate(-1)} className="button_cancel">Cancel</Button>
                    </div>
                    <div className="button_margin_left">
                      <Button
                        disabled={disableSaveButton}
                        className={disableSaveButton ? 'disableBtn' : 'button_save_and_next'}
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </form>
        </div>
      );
    } if (value === equipmentNeeded) {
      return (
        <div className="div_container_CustomerInfo">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <ComponentEquipmentNeed />
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div className="div_container_CustomerInfo">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <ComponentHistory />
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <>
      <ComponentPageHeader subHeading="TEST EQUIPMENT REQUEST" fieldId={reqId} fieldName="Request ID" />
      <ComponentBreadcrumb crumbs={breadcrumbs} />
      <div className="container_job">
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          <Tab
            label="Request"
            style={value === request ? selectedTabStyles : tabStyles}
            className="tab_styles"
            value={request}
          />
          <Tab
            label="Equipment Needed"
            style={value === equipmentNeeded ? selectedTabStyles : tabStyles}
            className="tab_styles"
            disabled={!statusId}
            value={equipmentNeeded}
          />
          <Tab
            label="Activity History"
            style={value === history ? selectedTabStyles : tabStyles}
            disabled={!statusId}
            className="tab_styles"
            value={history}
          />
        </Tabs>
      </div>
      <div className="div_job_new">
        {showComponentListingDetails()}
      </div>
    </>
  );
}
