/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable max-len */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';
import { RootState } from '../../../../redux/rootState';
import localStorageConstants from '../../../../utils/LocalStorageConstants';
import Config from '../../../../Common/Config';

function ComponentImageEditorStrip() {
  const { stripChartDataById } = useSelector((state: RootState) => state.VesselInformationReducer);
  const [isImageLoading, setIsImageLoading] = useState(true); // State to handle loading

  const ImageUrl = stripChartDataById?.ImageFile;
  const StripChartId = stripChartDataById.Id;

  const token = localStorage.getItem(localStorageConstants.TOKEN);
  const userID = localStorage.getItem(localStorageConstants.USER_ID);

  return (
    <div style={{ width: '100%', height: '93vh', position: 'relative' }}>
      {isImageLoading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isImageLoading} // Display Backdrop while image is loading
        >
          <CircularProgress />
        </Backdrop>
      )}
      {ImageUrl && (
        <iframe
          src={`${Config.JSPaintURl.JsPaintURL}#load:${encodeURIComponent(ImageUrl)},.stripChartId:${StripChartId},.Token:${token},.UserId:${userID},.ApiUrl:${Config.vesselInformation.stripChartImageEdit}`}
          width="100%"
          height="100%"
          style={{ border: 'none', display: isImageLoading ? 'none' : 'block' }}
          onLoad={() => setIsImageLoading(false)}
        />
      )}
    </div>
  );
}

export default ComponentImageEditorStrip;
