import {
  Grid, Card, TextField, Tooltip, IconButton,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import React, { useContext } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ComponentTextInput from '../../../../../../Common/ComponentTextinput/ComponentTextInput';
import { EnumOilKitsCustomerInformationForm, TypeOilKitsICustomerInformation } from '../../utils/types';
import { LocalizationContext } from '../../../../../../Common/Localization/LocalizationProvider';
import { CommonDateInput } from '../../../../../../Common/CommonDateInput';

export default function CustomerComponent({ control, setShowModal, errors }: TypeOilKitsICustomerInformation): JSX.Element {
  const { translations } = useContext(LocalizationContext);

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          <Controller
            name={EnumOilKitsCustomerInformationForm.customerSearch}
            control={control}
            render={({ field: { value } }) => (
              <TextField
                size="small"
                placeholder="Customer ID *"
                value={value}
                error={!!errors.customerSearch}
                helperText={errors?.customerSearch?.message}
                autoComplete="off"
                onClick={setShowModal}
                InputProps={{
                  endAdornment: (
                    <Tooltip title={translations.searchHere}>
                      <IconButton onClick={setShowModal}>
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Disable">{translations.Customer}</label>
              <ComponentTextInput
                isDisable
                control={control}
                name={EnumOilKitsCustomerInformationForm.customer}
                id={EnumOilKitsCustomerInformationForm.customer}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations.contactOilKits}
              </label>
              <ComponentTextInput
                control={control}
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 16 }}
                name={EnumOilKitsCustomerInformationForm.contact}
                id={EnumOilKitsCustomerInformationForm.contact}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations.shipDateOilKits}
              </label>
              {/* <DatePicker
                value={shipDate}
                onChange={(newValue) => dispatch(setShipDate(newValue))}
                slotProps={{ textField: { size: 'small' } }}
              // defaultValue={defaultValue}
              /> */}
              <CommonDateInput
                control={control}
                errors={errors}
                name={EnumOilKitsCustomerInformationForm.shipDate}
              />
            </div>
          </Grid>

          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations.shipViaOilKits}
              </label>
              <ComponentTextInput
                errors={errors}
                control={control}
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 20 }}
                name={EnumOilKitsCustomerInformationForm.shipVia}
                id={EnumOilKitsCustomerInformationForm.shipVia}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={12} xs={12} lg={4} xl={4}>
            <div className="div_label_text">
              <label className="label_Style_job">
                Shipping Address
              </label>
              <ComponentTextInput
                control={control}
                inputProps={{ maxLength: 200 }}
                name={EnumOilKitsCustomerInformationForm.shipAddress}
                size="small"
                id={EnumOilKitsCustomerInformationForm.shipAddress}
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12} lg={8} xl={8}>
            <div className="div_label_text">
              <label className="label_Style_disabled">
                Customer Address
              </label>
              <ComponentTextInput
                control={control}
                isDisable
                name={EnumOilKitsCustomerInformationForm.customerAddress}
                size="small"
                id={EnumOilKitsCustomerInformationForm.customerAddress}
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12} lg={8} xl={8}>
            <div className="div_label_text">
              <label className="label_Style_disabled">
                Customer Address(Optional)
              </label>
              <ComponentTextInput
                control={control}
                isDisable
                name={EnumOilKitsCustomerInformationForm.customerAddressOptional}
                size="small"
                id={EnumOilKitsCustomerInformationForm.customerAddressOptional}
                errors={errors}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
