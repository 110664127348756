import { Button, Grid } from '@mui/material';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { LocalizationContext } from '../../../../../Common/Localization/LocalizationProvider';
import './StyleMainTab.scss';
import DataTable from '../../../../../Common/DataTable';
import {
  AnalystLeaveData, LeaveDayFormEnum, LeaveDaysFormFields, VacationData,
} from '../../utils/types';
import {
  FYE_CONSTANT, TableColumns, VACATION_CONFIRMATION_MSG, addLeaveType,
} from '../../utils/constants';
import ComponentVacationModal from './vacationModal/ComponentVacationModal';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import RequiredFiled from '../../../../../utils/RequiredField';
import {
  addAnalystLeave, deleteAnalystLeave, getAnalystLeaveData, getAnalystLeaveMasterData,
} from '../../redux/AddAnalystSlice';
import { setLoader } from '../../../../../redux/loaderSlice';
import { RootState } from '../../../../../redux/rootState';
import ComponentDeleteModal from '../../../../../Common/DeleteModal/ComponentDeleteModal';
import { getAnalystMasterStart } from '../../../../Job/ScreenBidWorkSheetJob/redux/bidWorksheetSlice';
import ConfirmationModal from '../../../../../Common/ConfirmationModal/ConfirmationModal';
import { ConfirmationIcon } from '../../../../../assets/Icons/AnalystIcons/ConfirmationIcon';
import { convertDateFormat } from '../../../../../utils/CommonFunctions';

function VacationComponent() {
  const { analystId } = useParams();
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [rowId, setRowId] = useState('');
  const [openVacationModal, setOpenVacationModel] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const {
    control, getValues, setValue, watch,
  } = useForm<LeaveDaysFormFields>();
  const { isAnalystLeaveDataLoading, analystLeaveData } = useSelector((state: RootState) => state.AddAnalystSliceReducer);
  const { analystList } = useSelector((state: RootState) => state.bidsReducer);

  const watchLeaveDaysAllowed = watch(LeaveDayFormEnum.leaveDaysAllowed);
  const watchLeaveDaysRemaining = watch(LeaveDayFormEnum.leaveDaysRemaining);
  const analystName = useMemo(() => analystList?.find((ele) => String(ele?.Value) === String(analystId)), [analystList]);

  function getCurrentYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    // 9 represents October (months are 0-indexes)
    if (currentMonth < FYE_CONSTANT) {
      return `${currentYear - 1}-${currentYear}`;
    }

    return `${currentYear}-${(currentYear + 1)}`;
  }

  function getCurrentYearPayload() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    // 9 represents October (months are 0-indexes)
    if (currentMonth < FYE_CONSTANT) {
      return currentYear;
    }
    return currentYear + 1;
  }

  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setSelectedId(id);
  };

  useEffect(() => {
    dispatch(setLoader(isAnalystLeaveDataLoading));
  }, [isAnalystLeaveDataLoading]);

  const handleEdit = (id: string) => {
    setRowId(id);
    setOpenVacationModel(true);
  };

  const handleData = () => {
    const payload = {
      analystId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getAnalystLeaveData(payload));
  };

  useEffect(() => {
    if (analystId) {
      handleData();
      dispatch(getAnalystLeaveMasterData());
      dispatch(getAnalystMasterStart());
    }
  }, [analystId]);

  const handleButtonClick = () => {
    setRowId('');
    setOpenVacationModel(true);
  };

  const vacationDataTableData: VacationData[] = useMemo(() => analystLeaveData?.Data?.map((item: AnalystLeaveData, index: number) => {
    const transformedDataItem = {
      id: item?.Id?.toString(),
      sn: index,
      action: 'edit&delete',
      from: convertDateFormat(item?.StartDate),
      to: convertDateFormat(item?.EndDate),
    };
    return transformedDataItem;
  }), [analystLeaveData?.Data]);

  const closeConfirmationModal = () => {
    handleData();
    setShowConfirmationModal(false);
  };

  const updateLeaveAllowed = (data: LeaveDaysFormFields) => {
    const payload = {
      BalanceLeaveId: analystLeaveData?.BalanceLeaveId,
      AnalystId: analystId,
      FinancialYear: getCurrentYearPayload(),
      AllowedLeave: data?.leaveDaysAllowed || '',
      VacationSickLeaveSum: data?.leaveDaysRemaining || '',
    };
    dispatch(addAnalystLeave({ payload, type: addLeaveType.BALANCE_LEAVE_ADD_UPDATE, callback: closeConfirmationModal }));
  };
  const onFinalSubmit = () => {
    const data = getValues();
    if (Number(analystLeaveData?.BalanceLeaveId) > 0) {
      updateLeaveAllowed(data);
      return;
    }
    const payload = {
      AnalystId: analystId,
      FinancialYear: getCurrentYearPayload(),
      AllowedLeave: data?.leaveDaysAllowed || '',
      VacationSickLeaveSum: data?.leaveDaysRemaining || '',
    };
    dispatch(addAnalystLeave({ payload, type: addLeaveType.BALANCE_LEAVE_ADD_UPDATE, callback: closeConfirmationModal }));
  };

  const afterDeletedLeave = () => {
    setDeleteModal(false);
    handleData();
  };

  const handleDeleteModal = () => {
    dispatch(deleteAnalystLeave({ selectedId, callback: afterDeletedLeave }));
  };

  const handleCloseComponentVacationModal = () => {
    handleData();
    setOpenVacationModel(false);
  };

  useEffect(() => {
    if (analystLeaveData?.AllowedLeave === 0) {
      return;
    }
    const leaveRemaining = Number(analystLeaveData?.AllowedLeave) - Number(analystLeaveData?.VacationSickLeaveSum);
    if (analystLeaveData?.AllowedLeave) {
      setValue(LeaveDayFormEnum.leaveDaysAllowed, String(analystLeaveData?.AllowedLeave));
    }
    if (leaveRemaining) {
      setValue(LeaveDayFormEnum.leaveDaysRemaining, String(leaveRemaining));
    }
  }, [analystLeaveData?.AllowedLeave, analystLeaveData?.VacationSickLeaveSum]);

  useEffect(() => {
    if (watchLeaveDaysAllowed) {
      const totalDaysRemaining = Number(watchLeaveDaysAllowed) - Number(analystLeaveData?.VacationSickLeaveSum);
      setValue(LeaveDayFormEnum.leaveDaysRemaining, String(totalDaysRemaining));
      return;
    }
    setValue(LeaveDayFormEnum.leaveDaysRemaining, '');
  }, [watchLeaveDaysAllowed]);

  const handleSave = (dayAllow: string | undefined) => {
    if (Number(dayAllow) === 0 || Number(dayAllow) < 0) {
      setShowConfirmationModal(true);
      return;
    }
    onFinalSubmit();
  };

  const handleCancel = () => {
    if (analystLeaveData?.AllowedLeave) {
      setValue(LeaveDayFormEnum.leaveDaysAllowed, String(analystLeaveData?.AllowedLeave));
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
              <DataTable<VacationData>
                handleButtonClick={handleButtonClick}
                data={vacationDataTableData?.length > 0 ? vacationDataTableData : []}
                columns={TableColumns}
                totalRecords={analystLeaveData?.TotalRecords}
                buttonText="Add Leave"
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleData={handleData}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <div className="vacationMainWrapper">
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    {`Leave Days Allowed for FYE ${getCurrentYear()}`}
                    <RequiredFiled />
                  </label>
                  <ComponentTextInput
                    control={control}
                    regEx={/^(?:[1-9]|[1-9][0-9]|99)$/}
                    name={LeaveDayFormEnum.leaveDaysAllowed}
                    className="nonCapitalizeField"
                    id={LeaveDayFormEnum.leaveDaysAllowed}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Leave Days Remaining</label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={LeaveDayFormEnum.leaveDaysRemaining}
                    className="nonCapitalizeField"
                    id={LeaveDayFormEnum.leaveDaysRemaining}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button
                      onClick={handleCancel}
                      className="button_cancel"
                    >
                      {translations.cancel}
                    </Button>
                  </div>
                  <div className="button_margin_left">
                    <Button
                      disabled={!watchLeaveDaysAllowed}
                      onClick={() => handleSave(watchLeaveDaysRemaining)}
                      className={!watchLeaveDaysAllowed ? 'disableBtn' : 'button_save_and_next'}
                    >
                      {translations.save}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {
        openVacationModal && (
          <ComponentVacationModal
            rowId={rowId || ''}
            show={openVacationModal}
            setOpenFrom={() => setOpenVacationModel(false)}
            handleCloseComponentVacationModal={handleCloseComponentVacationModal}
          />
        )
      }
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />
      {
        showConfirmationModal && (
          <ConfirmationModal
            confirmationIcon={<ConfirmationIcon />}
            show={showConfirmationModal}
            setOpenFrom={() => setShowConfirmationModal(false)}
            handleConfirmation={onFinalSubmit}
            text={`${analystName?.Text} ${VACATION_CONFIRMATION_MSG}`}
          />
        )
      }
    </div>
  );
}

export default VacationComponent;
