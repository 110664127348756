import * as yup from 'yup';
import { maxDatePicker } from '../../../../utils/Constant';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const mainFormSchema = yup.object().shape({
  sirName: yup.string(),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string(),
  address: yup.string(),
  zip: yup.string().required('Zip is required'),
  state: yup.string(),
  city: yup.string(),
  otherAnalystCity: yup.string(),
  country: yup.string(),
  code: yup.string(),
  phone: yup.string(),
  cell: yup.string(),
  email: yup.string().required('Email is required').matches(emailRegex, 'Invalid email address'),
  initials: yup.string().required('Initial is required'),
  territory: yup.string(),
  hireDate: yup.date().max(maxDatePicker),
  lsInitials: yup.string(),
  boZip: yup.string().required('Zip is required'),
  boState: yup.string(),
  // boCity: yup.string().required('City is required'),
  // otherBOCity: yup.string().required('City is required'),
  otherBOCity: yup.string().test({
    name: 'required-with-siteCity',
    test(value) {
      const { boCity } = this.parent;
      if (boCity === 'Other') {
        return !!value || this.createError(
          {
            message: 'City is required',
          },
        );
      }
      return true;
    },
  }),
  boCity: yup.string().test({
    name: 'required-with-siteCity1',
    test(value) {
      const { boCity } = this.parent;
      if (boCity === '') {
        return !!value || this.createError(
          {
            message: 'City is required',
          },
        );
      }
      return true;
    },
  }),
  boCountry: yup.string(),
  sbZip: yup.string(),
  sbState: yup.string(),
  sbCity: yup.string(),
  otherSBCity: yup.string(),
  sbCountry: yup.string(),
});

export const addLeaveFormSchema = yup.object().shape({
  leaveType: yup.string().required('Leave Type is required'),
  description: yup.string(),
});
