import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import english from '../../../assets/lang/english';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';
import { Empty } from '../../ScreenAddSmc/Utils/TypeSmc';
import { getJobScheduleListData, setNavigateFromScheduleTab, setSelectedTab } from '../UnscheduledJobs/addJob/redux/jobScheduleSlice';
import { JobsScheduleTableData } from '../UnscheduledJobs/utils/types';
import { FIRST_SELECTED_TAB, JobScheduleTableColumns } from '../UnscheduledJobs/utils/constants';
import { exportToExcelData } from '../../../Common/PageHeader/helper';
import { formatJobsData, formatScheduledJobsDataXl } from './utils/helper';
import { onGenerateInvoiceStart } from '../../../redux/masters/masterSlice';
import DataTable from '../../../Common/DataTable';

function ScheduledJobs() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState<JobsScheduleTableData[]>([]);
  const dispatch = useDispatch();
  const { jobScheduleData, isLoading } = useSelector((state: RootState) => state.jobScheduleReducer);

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
      type: 1,
    };
    dispatch(getJobScheduleListData(payload));
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    handleData();
  }, []);

  const handleScheduleClick = (row: Empty) => {
    dispatch(setSelectedTab(FIRST_SELECTED_TAB));
    dispatch(setNavigateFromScheduleTab(true));
    navigate(`${row?.id}/${row?.jobId}`);
  };

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: formatScheduledJobsDataXl(jobScheduleData?.Data),
      },
      'JobsSchedule_list',
    );
  };

  const handleGenerateInvoice = (id: string) => {
    const payload = {
      type: 0,
      selectedIds: [id],
    };
    dispatch(onGenerateInvoiceStart(payload));
  };

  const handleCheckBoxWithKey = (id: string) => {
    const updatedData = data?.map((element: JobsScheduleTableData) => {
      if (element?.bidId === id) {
        return {
          ...element,
          isChecked: !element?.isChecked,
        };
      }
      return element;
    });
    setData(updatedData);
  };
  useEffect(() => {
    if (jobScheduleData?.Data) {
      setData(formatJobsData(jobScheduleData?.Data));
    }
  }, [jobScheduleData]);

  const handleButtonClick = () => {
    const jobIds: string[] = [];
    data?.forEach((element) => {
      if (element?.isChecked) {
        jobIds.push(element?.bidId);
      }
    });
    const payload = {
      type: 0,
      selectedIds: jobIds,
    };
    dispatch(onGenerateInvoiceStart(payload));
  };
  const jobSelected = useMemo(() => data?.every((ele) => !ele?.isChecked), [data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading={String(english.ScheduledJobs)} downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <DataTable<JobsScheduleTableData>
          data={data?.length > 0 ? data : []}
          columns={JobScheduleTableColumns}
          totalRecords={jobScheduleData?.TotalRecords}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          handleScheduleClick={handleScheduleClick}
          handleGenerateInvoice={handleGenerateInvoice}
          checkBoxWithKey={['sn']}
          handleCheckBoxWithKey={handleCheckBoxWithKey}
          buttonText="Generate Invoice"
          handleButtonClick={handleButtonClick}
          textButtonDisabled={jobSelected}
        />
      </Grid>
    </Grid>
  );
}

export default ScheduledJobs;
