/* eslint-disable react/no-danger */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/rootState';

function TableOne() {
  const { themePdfColor, reportPdfResponse1 } = useSelector((state: RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;
  const tickersData = reportPdfResponse1?.Ticklers_Data;

  return (
    <div style={{ width: '360px' }}>
      <table className="bordered-table w100">
        <div
          className="tableAfterThickBorderStyle"
          style={{
            backgroundColor: BorderColor,
          }}
        />
        <div
          className="tableBeforeThickBorderStyle"
          style={{
            backgroundColor: BorderColor,
          }}
        />
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Job Code</td>
          <td className="textCenter">{tickersData?.JobOrder}</td>
          {' '}
          {/* Job Code */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Manufacturer</td>
          <td className="textCenter">{tickersData?.Manufacturer}</td>
          {' '}
          {/* Manufacturer */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Style</td>
          <td className="textCenter">{tickersData?.Style}</td>
          {' '}
          {/* Style */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Type</td>
          <td className="textCenter">{tickersData?.Type}</td>
          {' '}
          {/* Type */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Model</td>
          <td className="textCenter">{tickersData?.Model}</td>
          {' '}
          {/* Model */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Serial #</td>
          <td className="textCenter">{tickersData?.SerialNumber}</td>
          {' '}
          {/* Serial Number */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Inspected by</td>
          <td className="textCenter">{tickersData?.InspectedBy}</td>
          {' '}
          {/* Inspected by */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Reviewed by</td>
          <td className="textCenter">{tickersData?.ReviewedBy}</td>
          {' '}
          {/* Reviewed by */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Location</td>
          <td className="textCenter">{tickersData?.Location}</td>
          {' '}
          {/* Location */}
        </tr>
        {/* <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Retest</td>
          <td className="textCenter">{tickersData?.Retest}</td>
          {' '}
        </tr> */}
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Send Report To</td>
          <td className="textCenter">{tickersData?.SendReportTo}</td>
          {' '}
          {/* Send Report To */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Job Dates</td>
          <td className="textCenter">{tickersData?.JobDates}</td>
          {' '}
          {/* Job Dates */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Purchase Order</td>
          <td className="textCenter">{tickersData?.PurchaseOrder}</td>
          {' '}
          {/* Purchase Order */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Expenses</td>
          <td className="textCenter">{tickersData?.Expenses}</td>
          {' '}
          {/* Expenses */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Tested Before</td>
          <td className="textCenter">{tickersData?.TestedBefore ? 'Yes' : 'No'}</td>
          {' '}
          {/* Tested Before */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Leak</td>
          <td className="textCenter">{tickersData?.Leak ? 'Yes' : 'No'}</td>
          {' '}
          {/* Leak */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>By</td>
          <td className="textCenter">{tickersData?.By}</td>
          {' '}
          {/* By */}
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Comments</td>
          <td className="textCenter">
            <div
              style={{ wordBreak: 'break-word', wordWrap: 'break-word' }}
              className="customDataHtmlStyle"
              dangerouslySetInnerHTML={{ __html: tickersData?.Comments || 'N/A' }}
            />
          </td>
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Informed Cust</td>
          <td className="textCenter">{tickersData?.InformedCust ? 'Yes' : 'No'}</td>
          {' '}
          {/* Informed Cust */}
        </tr>
      </table>
    </div>
  );
}

export default TableOne;
