// import React from 'react';
// import { SummaryInspProps } from '../types';

// function SummaryInspectionTable({ summaryDetails, primaryColor, secondaryColor }: SummaryInspProps) {
//   return (
//     <div className="w100 centerAlign mt40">
//       <table className="bordered-table w70 noTableBreak">
//         <div
//           className="tableAfterThickBorderStyle"
//           style={{
//             backgroundColor: primaryColor,
//           }}
//         />
//         <div
//           className="tableBeforeThickBorderStyle"
//           style={{
//             backgroundColor: primaryColor,
//           }}
//         />
//         <tr>
//           <td colSpan={3} className="tableGreenHeading textCenter boldText" style={{ backgroundColor: secondaryColor }}>
//             {`${summaryDetails.VesselName}: ${summaryDetails.TubeCount} Tubes`}
//           </td>
//         </tr>
//         <tr>
//           <td colSpan={3} className="tableGreenHeading textCenter boldText" style={{ backgroundColor: secondaryColor }}>
//             Tubes Tested:
//           </td>
//         </tr>
//         <tr>
//           <td className="textRight w48">Significant/Measurable Indications</td>
//           <td className="textCenter w26">Tubes Marked</td>
//           <td className="textCenter w26">Percent of Bundle</td>
//         </tr>
//         {summaryDetails?.Defects?.map((details) => (
//           <tr>
//             <td className="textRight w48">{details.Defect}</td>
//             <td className="textCenter w26">{details.DefectTypeCount}</td>
//             <td className="textCenter w26">{details.Percentage}</td>
//           </tr>
//         ))}
//         <tr>
//           <td className="textRight w48">Totals</td>
//           <td className="textCenter w26">{summaryDetails?.TotalDefects}</td>
//           <td className="textCenter w26">{summaryDetails?.TotalPercentage}</td>
//         </tr>
//       </table>
//     </div>
//   );
// }

// export default SummaryInspectionTable;

import React from 'react';
import { SummaryInspProps, Defect } from '../types';
import tickIconRed from '../../../assets/Icons/tick-icon-red.svg';

// const dynamicChunkArray = (array: any[], firstChunkSize: number, subsequentChunkSize: number): any[][] => {
//   const result: any[][] = [];
//   // Handle the first chunk with 15 rows
//   result.push(array.slice(0, firstChunkSize));

//   // Handle the rest of the array in chunks of 25
//   for (let i = firstChunkSize; i < array.length; i += subsequentChunkSize) {
//     result.push(array.slice(i, i + subsequentChunkSize));
//   }
//   return result;
// };

// Helper function to create a first chunk of 15 and subsequent chunks of 25

// Helper function to create chunks of 15 for the first table and 25 for subsequent tables
const createChunks = (array: Defect[], firstChunkSize: number, subsequentChunkSize: number): Defect[][] => {
  const result: Defect[][] = [];
  if (array.length > 0) {
    result.push(array.slice(0, firstChunkSize));
  }
  for (let i = firstChunkSize; i < array.length; i += subsequentChunkSize) {
    result.push(array.slice(i, i + subsequentChunkSize));
  }

  return result;
};

function SummaryInspectionTable({ summaryDetails, secondaryColor }: SummaryInspProps) {
  const defects = summaryDetails?.Defects || [];
  const chunks = createChunks(defects, 22, 25);

  return (
    <div className="w100 centerAlign mt20 d-block">
      {chunks.map((defectChunk, chunkIndex) => (
        <table
          className="bordered-table w70 noTableBreak"
          style={{ margin: '0px auto', display: 'block' }}
        >
          {/* Header Section */}
          <thead>
            <tr>
              <td colSpan={4} className="tableGreenHeading textCenter boldText" style={{ backgroundColor: secondaryColor }}>
                {`${summaryDetails.VesselName}: ${summaryDetails.TubeCount} Tubes`}
              </td>
            </tr>
            <tr>
              <td colSpan={4} className="tableGreenHeading textCenter boldText" style={{ backgroundColor: secondaryColor }}>
                Tubes Tested:
              </td>
            </tr>
            <tr>
              <td className="textRight w48">Significant/Measurable Indications</td>
              <td className="textCenter w26">Tubes Marked</td>
              <td className="textCenter w26">Percent of Bundle</td>
              <td className="textCenter w26">Action Req.</td>
            </tr>
          </thead>
          {/* Body Section */}
          <tbody>
            {defectChunk.map((details) => (
              <tr>
                <td className="textRight w48" style={{ color: details.ActionRequired ? 'red' : 'inherit' }}>{details.Defect}</td>
                <td className="textCenter w26">{details.DefectTypeCount}</td>
                <td className="textCenter w26">{details.Percentage}</td>
                <td className="textCenter w26">{details.ActionRequired && <img src={tickIconRed} alt="icon" width="18px" />}</td>
              </tr>
            ))}
            {/* Totals row only on the last chunk */}
            {chunkIndex === chunks.length - 1 && (
            <tr>
              <td className="textRight w48">Total</td>
              <td className="textCenter w26">{summaryDetails?.TotalDefects}</td>
              <td className="textCenter w26">{summaryDetails?.TotalPercentage}</td>
              <td className="textCenter w26">{}</td>
            </tr>
            )}
          </tbody>
        </table>
      ))}
    </div>
  );
}

export default SummaryInspectionTable;
