import React from 'react';
import { useSelector } from 'react-redux';
import VesselBayLengthGraph from './vesselBayLengthGraph';
import { RootState } from '../../../redux/rootState';
import { BayLengthDetails } from '../types';
import { CombineReportEnum, PDFReportEnum, ReportPart1 } from '../helper';

function VesselBayLengthInfoSection() {
  const { reportPdfResponse1, themePdfColor, tableContent } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor } = themePdfColor;

  return (
    <div className="p10">
      <div className="pageHeader">
        <div className="topHeader">
          <div>
            {ReportPart1(tableContent, CombineReportEnum.COMBINE_SUPPORT_BAY_LENGTH, PDFReportEnum.PDF_SUPPORT_BAY_LENGTH, 'Support Bay Length')}
          </div>
          <div>
            S/N
            {reportPdfResponse1?.JobOrderData?.SerialNumber}
          </div>
        </div>
        <div className="underlineHeadingText textCenter">Vessel Bay Length Information</div>
      </div>

      {reportPdfResponse1?.Support_Bay_Length?.map((data, index: number) => (
        <>
          {data.map((bayDetails: BayLengthDetails, bayIndex: number) => (
            <>
              {index > 0 && (<div className="page-break" />)}
              <VesselBayLengthGraph bayDetails={bayDetails} primaryColor={BorderColor} />
              {bayIndex < data.length - 1 && <div className="page-break" />}
            </>
          ))}
        </>
      ))}
      {/* {reportPdfResponse1?.Support_Bay_Length?.map((data, index: number) => (
        data.map((bayDetails:BayLengthDetails) => (
          <>
            {index > 0 && (<div className="page-break" />)}
            <VesselBayLengthGraph bayDetails={bayDetails} primaryColor={BorderColor} />
          </>
        ))
      ))} */}
    </div>
  );
}

export default VesselBayLengthInfoSection;
