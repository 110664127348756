import { Editor } from 'react-draft-wysiwyg';
import React, { useEffect, useState } from 'react';
import { Grid, Card, Button } from '@mui/material';
import {
  ContentState,
  EditorState,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch, useSelector } from 'react-redux';
import { customOptions } from '../../../../Common/TextEditorOptions';
import { setSelectedTab, updateJobOrderStart } from '../../redux/sliceJobOrder';
import { ANALYST_NOTE_TAB } from '../../utils/constants';
import { RootState } from '../../../../redux/rootState';
import { formatJobOrderPayload } from '../../utils/helper';

function ScreenComments() {
  const [comment, setComment] = useState(EditorState.createEmpty());
  const { numberOfTubes, tubeLength, displayVessels } = useSelector((state: RootState) => state.unitInfoReducer);
  const {
    Comments, AnalystNoteData, mainFormData, clientSiteFormData, UnitId, AnalystId, unitPriceJobOrder, VesselsInfoData,
  } = useSelector((state: RootState) => state?.jobOrderReducer);
  const dispatch = useDispatch();
  const {
    SerialNo,
  } = useSelector((state: RootState) => state.unitInfoReducer);
  const handleComment = (newEditorState: EditorState) => {
    setComment(newEditorState);
  };

  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const getHtmlFromEditorHTMLtoString = (newEditorState: EditorState): string => {
    const contentState = newEditorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  useEffect(() => {
    if (Comments) {
      const convertedComment = htmlToEditorState(Comments);
      setComment(convertedComment);
    }
  }, [Comments]);

  const handleSave = () => {
    const newComment = getHtmlFromEditorHTMLtoString(comment);
    const payload = formatJobOrderPayload(
      UnitId,
      AnalystId,
      VesselsInfoData,
      mainFormData,
      clientSiteFormData,
      displayVessels,
      unitPriceJobOrder,
      numberOfTubes,
      tubeLength,
      AnalystNoteData,
      newComment,
      SerialNo,
    );
    dispatch(updateJobOrderStart(payload));
  };

  const handleChangeTab = () => {
    dispatch(setSelectedTab(ANALYST_NOTE_TAB));
  };

  return (
    <div className="div_container_CustomerInfo">
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <Card className="comment-card">
                <div className="customerInfo_container">
                  <div className="customerInfo">
                    Comment
                  </div>
                </div>
                <div className="customerInfo_main_container">
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                      <div className="editorContainer">
                        <Editor
                          editorState={comment}
                          onEditorStateChange={handleComment}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          localization={{ locale: 'en' }}
                          toolbar={customOptions}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <div className="save_and_next_div">
            <div>
              <Button className="button_cancel" onClick={handleChangeTab}>Back</Button>
            </div>
            <div className="button_margin_left">
              <Button className="button_save_and_next" onClick={handleSave}>Save</Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ScreenComments;
