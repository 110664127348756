import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import EndPoints from './EndPoints';
import { ScreenSidebar, Transactions } from '../Screens/ComponentNavBar/ScreenSidebar';
import DashBoard from '../Screens/ComponentNavBar/DashBoard';
import ScreenAddCustomer from '../Screens/addCustomer';
import ScreenAddBid from '../Screens/ScreenAddBid/ScreenAddBid';
import ScreenAddSmc from '../Screens/ScreenAddSmc';
import ScreenBidWorksheetJobTabs from '../Screens/Job/ScreenBidWorkSheetJob';
import ScreenUnitInformationTabs from '../Screens/Job/ScreenUnitInformation';
import ScreenTestEquipmentRequestTabs from '../Screens/Job/ScreenTestEquipmentRequest/TestEquipmentRequestTabs';
import ScreenModifyJob from '../Screens/Job/ScreenJobDetails/ScreenJobDetails';
import ScreenModifyJobTabs from '../Screens/Job/ScreenModifyJob/ScreenModifyJobTabs';
import SMC from '../Screens/smc';
import ScreenProfile from '../Screens/Profile/Index';
import ScreenCustomerDetail from '../Screens/customer';
import ScreenPriceList from '../Screens/System/PriceList/ScreenPriceList';
import ScreenMarkupsList from '../Screens/System/Markups/ScreenMarkupsList';
import ScreenKitPriceList from '../Screens/System/KitPrices/ScreenKitPriceList';
import { JobStarterListing } from '../Screens/Job/jobStarter';
import AddJob from '../Screens/Job/jobStarter/addJob';
import ScreenFreonCylinder from '../Screens/Kits/FreonCylinder/FreonCylinderList/ScreenFreonCylinder';
import ScreenAddFreonCylinder from '../Screens/Kits/FreonCylinder/AddFreonCylinder/ScreenAddFreonCylinder';
import ChangePassword from '../Screens/ChangePassword';
import ScreenOilKits from '../Screens/Kits/OilKits/ScreenOilKits';
import ScreenAddOilKits from '../Screens/Kits/OilKits/ScreenAddOilKits';
import AnalystListScreen from '../Screens/AnalystScreen/AnalystListScreen/AnalystListScreen';
import AnalystAddScreen from '../Screens/AnalystScreen/AnalystAddScreen/AnalystAddScreen';
import UnscheduledJobs from '../Screens/JobScheduling/UnscheduledJobs';
import EditJobSchedule from '../Screens/JobScheduling/UnscheduledJobs/addJob';
import ScheduledJobs from '../Screens/JobScheduling/ScheduledJobs';
import ScreenBidPdf from '../Screens/ScreenBidPdf';
import Calender from '../Screens/calender';
import ScreenVesselList from '../Screens/Lists/VesselLists/ScreenVesselList';
import ScreenTestEndList from '../Screens/Lists/TestEndLists/ScreenTestEndList';
import ScreenSupportList from '../Screens/Lists/SupportLists/ScreenSupportList';
import ScreenManufacturerList from '../Screens/Lists/ManufacturerLists/ScreenManufacturerList';
import ScreenMaterialList from '../Screens/Lists/MaterialLists/ScreenMaterialList';
import ScreenTubeList from '../Screens/Lists/TubeList/ScreenTubeList';
import ScreenTypeLists from '../Screens/Lists/UnitTypeLists/ScreenTypeLists';
import ScreenStyleList from '../Screens/Lists/UnitStyleLists/ScreenStyleList';
import ScreenReportList from '../Screens/Lists/ReportList/ScreenReportList';
import ScreenDefaultTypeStyle from '../Screens/Lists/DefaultTypeStyleList/ScreenDefaultTypeStyle';
import ScreenSites from '../Screens/Sites';
import ScreenEditSite from '../Screens/Sites/Components';
import ScreenCalibrationProcedure from '../Screens/Utilities/calibrationProcedure';
import InspectionProcedureComponent from '../Screens/Utilities/inspectionProcedure';
import RecommendationCodesScreen from '../Screens/Utilities/recommendationCodes';
import RecommendationTextScreen from '../Screens/Utilities/recommendationText';
import ScreenRBAC from '../Screens/RBAC';
import InventoryManagement from '../Screens/InventoryManagement';
import EquipmentNeededForJobs from '../Screens/InventoryManagement/EquipmentNeededForJobs';
import EquipmentRequest from '../Screens/InventoryManagement/EquipmentRequest';
import InventoryLandingPage from '../Screens/InventoryManagement/InventoryLandingPage';
import TestEquipmentRequestScreen from '../Screens/InventoryManagement/TestEquipmentRequestScreen';
import localStorageConstants from '../utils/LocalStorageConstants';
import { ADMIN_ROLE_ID, ANALYST_ROLE_ID } from './constant';
import AnalystDefectMenu from '../Screens/Utilities/AnalystDefectMenu';
import ScreenDefectCodeList from '../Screens/Utilities/DefectCode';
import ScreenEquipmentCategoryList from '../Screens/Lists/EquipmentCategoryLists/ScreenEquipmentCategoryList';
import SendEquipmentScreen from '../Screens/InventoryManagement/SendEquipmentScreen';
import { OilReport } from '../Screens/oilReport';
import { EditReport } from '../Screens/oilReport/components/editOilReport';
import BoardPosition from '../Screens/boardPosition';
import UserManagement from '../Screens/UserManagement';
import VesselInformation from '../Screens/VesselInformation';
import EditVesselInfo from '../Screens/VesselInformation/editVessel';
import { TubeDiagram } from '../Screens/VesselInformation/components/TubeChart/Diagram';
import ScreenJobOrder from '../Screens/jobOrder';
import ScreenEditJobOrder from '../Screens/jobOrder/components';
import ScreenInspectionSummary from '../Screens/inspectionSummary';
import TabsInspectionSummary from '../Screens/inspectionSummary/components/tabsInspectionSummary';
import ScreenExpenseReports from '../Screens/expenseReports';
import ScreenExpense from '../Screens/expenseReports/components';
import ReportingSystem from '../Screens/taiReportingSystem';
import ScreenRecommendations from '../Screens/ScreenRecommendations';
import ScreenDataSheets from '../Screens/datasheets';
import ComponentDataSheet from '../Screens/datasheets/components/ComponentDataSheet';
import ScreenPhoto from '../Screens/photos';
import ComponentInspection from '../Screens/ScreenRecommendations/Components/selectedRecom';
// import DialogImageEditor from '../Screens/photos/components/ComponentImageEditor';
import ReportsInformation from '../Screens/analystReports';
import ReportsDashBoard from '../Screens/analystReports/components/ReportsDashBoard';
import ScreenReportWorkspace from '../Screens/reportWorkspace/reportWorkspace';
import ComponentCreateReport from '../Screens/reportWorkspace/components/componentCreateReport';
import ComponentGeneratePDF from '../Screens/taiReportingSystem/components/ComponentGeneratePDF';
import ComponentReportChecklist from '../Screens/taiReportingSystem/components/ComponentReportChecklist';
import ComponentPDFinBox from '../Screens/taiReportingSystem/components/ComponentPDFinBox';
import ScreenHazardAnalysis from '../Screens/ScreenHazardAnalysis/ScreenHazardAnalysis';
import ComponentHazardForm from '../Screens/ScreenHazardAnalysis/components/ComponentHazardForm';
import ScreenInspectionLetter from '../Screens/inspectionLetter/ScreenInspectionLetter';
import ScreenTubeChartLibrary from '../Screens/tubeChartLibrary/ScreenTubeChartLibrary';
import PaintComponent from '../Screens/photos/components/PaintComponent';

function PrivateRoute() {
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  return (
    <Routes>
      <Route path="/" element={<ScreenSidebar />}>
        {roleId === ANALYST_ROLE_ID ? (
          <>
            <Route path="*" element={<Navigate to={EndPoints.DASHBOARD} />} />
            <Route path={EndPoints.DASHBOARD} element={<ReportsDashBoard />} />
            <Route path={EndPoints.SCREEN_PROFILE} element={<ScreenProfile />} />
            <Route path={EndPoints.REPORTS} element={<ReportsInformation />} />
            <Route path={EndPoints.EDIT_ANALYST_REPORT} element={<EditVesselInfo />} />
            <Route path={EndPoints.CHANGE_PASSWORD} element={<ChangePassword />} />
          </>
        ) : (
          <>
            <Route path={EndPoints.DASHBOARD} element={<DashBoard />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path={EndPoints.Customers} element={<ScreenCustomerDetail />} />
            <Route path={`${EndPoints.Customers}/:customerId`} element={<ScreenAddCustomer />} />
            <Route path={EndPoints.ADD_CUSTOMER} element={<ScreenAddCustomer />} />
            <Route path={`${EndPoints.ADD_BID}/:jobId`} element={<ScreenAddBid />} />
            <Route path={EndPoints.SMC} element={<SMC />} />
            <Route path={`${EndPoints.SMC}/:smcId`} element={<ScreenAddSmc />} />
            <Route path={EndPoints.SCREEN_ADD_SMC} element={<ScreenAddSmc />} />
            <Route path={EndPoints.SCREEN_PROFILE} element={<ScreenProfile />} />
            <Route path={EndPoints.JOBS} element={<JobStarterListing />} />
            <Route path={`${EndPoints.JOBS}/:jobId`} element={<AddJob />} />
            <Route path={EndPoints.ADD_JOB} element={<AddJob />} />
            <Route path={`${EndPoints.BID_WORKSHEET}/:bidId`} element={<ScreenBidWorksheetJobTabs />} />
            <Route path={`${EndPoints.ADD_JOB_BID}/:jobId`} element={<ScreenBidWorksheetJobTabs />} />
            <Route path={`${EndPoints.ADD_JOB_WITH_ID}/:customerId`} element={<AddJob />} />
            <Route path={`${EndPoints.ADD_UNIT}/:bidId`} element={<ScreenUnitInformationTabs />} />
            <Route path={`${EndPoints.UNIT}/:unitId`} element={<ScreenUnitInformationTabs />} />
            <Route path="test-request/:unitId" element={<ScreenTestEquipmentRequestTabs />} />
            <Route path="test-request/:unitId/:reqId/:statusId" element={<ScreenTestEquipmentRequestTabs />} />
            <Route path="modify-job" element={<ScreenModifyJob />} />
            <Route path="modify-job/:id" element={<ScreenModifyJobTabs />} />
            <Route path={EndPoints.PRICE_LIST} element={<ScreenPriceList />} />
            <Route path={EndPoints.KIT_PRICES} element={<ScreenKitPriceList />} />
            <Route path={EndPoints.MARKUPS} element={<ScreenMarkupsList />} />
            <Route path={EndPoints.FREON_CYLINDER} element={<ScreenFreonCylinder />} />
            <Route path={EndPoints.TUBE_CHARGE} element={<TubeDiagram />} />
            <Route path={EndPoints.ADD_FREON_CYLINDER} element={<ScreenAddFreonCylinder />} />
            <Route path={EndPoints.EDIT_FREON_CYLINDER} element={<ScreenAddFreonCylinder />} />
            <Route path={EndPoints.CHANGE_PASSWORD} element={<ChangePassword />} />
            <Route path={EndPoints.OIL_KITS} element={<ScreenOilKits />} />
            <Route path={EndPoints.EDIT_OIS_KITS} element={<ScreenAddOilKits />} />
            <Route path={EndPoints.ADD_OIL_KITS} element={<ScreenAddOilKits />} />
            <Route path={EndPoints.ANALYST} element={<AnalystListScreen />} />
            <Route path={EndPoints.ADD_ANALYST} element={<AnalystAddScreen />} />
            <Route path={EndPoints.EDIT_ANALYST} element={<AnalystAddScreen />} />
            <Route path={EndPoints.CALENDER} element={<Calender />} />
            <Route path={EndPoints.MONTHCALENDER} element={<Calender />} />
            <Route path={EndPoints.WEEKCALENDER} element={<Calender />} />
            <Route path={EndPoints.TYPE_LIST} element={<ScreenTypeLists />} />
            <Route path={EndPoints.STYLE_LIST} element={<ScreenStyleList />} />
            <Route path={EndPoints.MANUFACTURER_LIST} element={<ScreenManufacturerList />} />
            <Route path={EndPoints.VESSEL_LIST} element={<ScreenVesselList />} />
            <Route path={EndPoints.TEST_END_LIST} element={<ScreenTestEndList />} />
            <Route path={EndPoints.SUPPORT_LIST} element={<ScreenSupportList />} />
            <Route path={EndPoints.MATERIAL_LIST} element={<ScreenMaterialList />} />
            <Route path={EndPoints.TUBE_LIST} element={<ScreenTubeList />} />
            <Route path={EndPoints.REPORT_TYPE_LIST} element={<ScreenReportList />} />
            <Route path={EndPoints.DEFAULT_LIST} element={<ScreenDefaultTypeStyle />} />
            <Route path={EndPoints.EQUIPMENT_CATEGORY} element={<ScreenEquipmentCategoryList />} />
            <Route path={EndPoints.UNSCHEDULED_JOB} element={<UnscheduledJobs />} />
            <Route path={EndPoints.SCHEDULED_JOB} element={<ScheduledJobs />} />
            <Route path={EndPoints.OIL_REPORT} element={<OilReport />} />
            <Route path={`${EndPoints.OIL_REPORT}/:reportId`} element={<EditReport />} />
            <Route path={EndPoints.EDIT_UNSCHEDULED_JOB} element={<EditJobSchedule />} />
            <Route path={EndPoints.EDIT_SCHEDULED_JOB} element={<EditJobSchedule />} />
            <Route path={EndPoints.BID_PDF_SCREEN} element={<ScreenBidPdf />} />
            <Route path={EndPoints.SITES} element={<ScreenSites />} />
            <Route path={EndPoints.EDIT_SITES} element={<ScreenEditSite />} />
            <Route path={EndPoints.CALIBRATION_PROCEDURE} element={<ScreenCalibrationProcedure />} />
            <Route path={EndPoints.INSPECTION_PROCEDURE} element={<InspectionProcedureComponent />} />
            <Route path={EndPoints.RECOMMENDATION_CODES} element={<RecommendationCodesScreen />} />
            <Route path={EndPoints.RECOMMENDATION_TEXT} element={<RecommendationTextScreen />} />
            <Route path={`${EndPoints.BID_PDF_SCREEN}/:jobId`} element={<ScreenBidPdf />} />
            <Route path={EndPoints.RBAC} element={<ScreenRBAC />} />
            <Route path={EndPoints.INVENTORY_MANAGEMENT} element={<InventoryManagement />} />
            <Route path={EndPoints.EQUIPMENT_NEEDED_FOR_JOBS} element={<EquipmentNeededForJobs />} />
            <Route path={EndPoints.EQUIPMENT_REQUEST} element={<EquipmentRequest />} />
            <Route path={EndPoints.INVENTORY_LANDING_PAGE} element={<InventoryLandingPage />} />
            <Route path={EndPoints.TEST_EQUIPMENT_REQUEST_FORM} element={<TestEquipmentRequestScreen />} />
            <Route path={`${EndPoints.TEST_EQUIPMENT_REQUEST_FORM}/:requestId`} element={<TestEquipmentRequestScreen />} />
            {Number(roleId) === ADMIN_ROLE_ID
          && <Route path={`${EndPoints.TEST_EQUIPMENT_REQUEST_FORM}/:requestId/:roleValue`} element={<TestEquipmentRequestScreen />} />}
            <Route path={EndPoints.ANALYST_DEFECT} element={<AnalystDefectMenu />} />
            <Route path={EndPoints.DEFECT_CODE} element={<ScreenDefectCodeList />} />
            <Route path={`${EndPoints.SEND_EQUIPMENT_SCREEN}/:typeId`} element={<SendEquipmentScreen />} />
            <Route path={EndPoints.BOARD_POSITION} element={<BoardPosition />} />
            <Route path={EndPoints.USER_MANAGEMENT} element={<UserManagement />} />
            <Route path={EndPoints.VESSEL_INFORMATION} element={<VesselInformation />} />
            <Route path={EndPoints.EDIT_VESSEL_INFORMATION} element={<EditVesselInfo />} />
            <Route path={EndPoints.EDIT_VESSEL_INFORMATION_TabId} element={<EditVesselInfo />} />
            <Route path={EndPoints.EXPENSE_REPORTS} element={<ScreenExpenseReports />} />
            <Route path={`${EndPoints.EXPENSE_REPORTS}/:jobOrder`} element={<ScreenExpenseReports />} />
            <Route path={`${EndPoints.EXPENSE_REPORTS}/:jobOrder/:expenseId`} element={<ScreenExpense />} />
            <Route path={EndPoints.JOB_ORDER} element={<ScreenJobOrder />} />
            <Route path={`${EndPoints.JOB_ORDER}/:orderId`} element={<ScreenEditJobOrder />} />
            <Route path={EndPoints.Inspection_Summary} element={<ScreenInspectionSummary />} />
            <Route path={`${EndPoints.Inspection_Summary}/:jobOrder`} element={<ScreenInspectionSummary />} />
            <Route path={`${EndPoints.Inspection_Summary}/:jobOrder/:inspectionId/:vesselId/:defectCode`} element={<TabsInspectionSummary />} />
            <Route path={`${EndPoints.REPORT_WORKSPACE}`} element={<ReportingSystem />} />
            <Route path={`${EndPoints.REPORT_WORKSPACE}/:id/:custId`} element={<ComponentPDFinBox />} />
            <Route path={`${EndPoints.RECOMMENDATIONS}`} element={<ScreenRecommendations />} />
            <Route path={`${EndPoints.RECOMMENDATIONS}/`} element={<ScreenRecommendations />} />
            <Route path={`${EndPoints.RECOMMENDATIONS}/:recommendationId`} element={<ComponentInspection />} />
            <Route path={`${EndPoints.DATASHEETS}`} element={<ScreenDataSheets />} />
            <Route path={`${EndPoints.DATASHEETS}/:sheetId`} element={<ComponentDataSheet />} />
            <Route path={EndPoints.PHOTOS} element={<ScreenPhoto />} />
            <Route path={`${EndPoints.PHOTOS}/:jobOrder`} element={<ScreenPhoto />} />
            {/* <Route path={`${EndPoints.PHOTOS}/:jobOrder/:photoId`} element={<DialogImageEditor />} /> */}
            {/* <Route path={`${EndPoints.PHOTOS}/:jobOrder/:photoId`} element={<PaintComponent />} /> */}
            <Route path={`${EndPoints.REPORT_WORKSPACE}/${EndPoints.COMBINE_REPORTS}`} element={<ScreenReportWorkspace />} />
            <Route path={`${EndPoints.REPORT_WORKSPACE}/${EndPoints.COMBINE_REPORTS}/:jobId`} element={<ComponentCreateReport />} />
            <Route path={`${EndPoints.REPORT_WORKSPACE}/${EndPoints.GENERATE_PDF}/:jobOrderId`} element={<ComponentGeneratePDF />} />
            <Route path={`${EndPoints.REPORT_WORKSPACE}/${EndPoints.REPORT_CHECKLIST}`} element={<ComponentReportChecklist />} />
            <Route path={`${EndPoints.HAZARD_ANALYSIS}`} element={<ScreenHazardAnalysis />} />
            <Route path={`${EndPoints.HAZARD_ANALYSIS}/:jobId`} element={<ComponentHazardForm />} />
            <Route path={`${EndPoints.INSPECTION_LETTER}`} element={<ScreenInspectionLetter />} />
            <Route path={`${EndPoints.TUBE_CHART_LIBRARY}`} element={<ScreenTubeChartLibrary />} />
            <Route path="*" element={<Navigate to={EndPoints.DASHBOARD} />} />
            <Route path="/" element={<Navigate to={EndPoints.DASHBOARD} />} />
          </>
        )}
      </Route>
      <Route path={`${EndPoints.PHOTOS}/:jobOrder/:photoId`} element={<PaintComponent />} />
    </Routes>
  );
}

export default PrivateRoute;
