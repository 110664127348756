/* eslint-disable max-len */
import {
  Grid, Card, Button,
} from '@mui/material';
import React, {
  useState, useEffect, useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { useReactToPrint } from 'react-to-print';
import {
  DragDropContext, Draggable, DraggableProvided, Droppable, DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { useReactToPrint } from 'react-to-print';
import styles from '../utils/styles.module.scss';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import { getListContentsListStart } from '../../taiReportingSystem/redux/sliceReportWorkspace';
import { RootState } from '../../../redux/rootState';
import { SectionData } from '../utils/types';
import ScreenReportPdf from '../../ScreenReportPdf';
import { DragIcon } from '../../../assets/dragIcon';
// import { DocumentParts } from '../../ScreenReportPdf/constant';
import { DocumentParts } from '../../ScreenReportPdf/constant';
import {
  getReportPdf1Start, getReportPdf2Start, getReportPdf3Start, getReportPdf4Start, setCheckedSections,
} from '../../ScreenReportPdf/redux/reportPdfSlice';
import { GetReportPdfPayload } from '../../ScreenReportPdf/types';
import { setLoader } from '../../../redux/loaderSlice';

export default function ComponentCreateReport(): JSX.Element {
  const { jobId } = useParams<{ jobId: string }>();
  const dispatch = useDispatch();

  const [jobOrderData, setJobOrderData] = useState<string[]>([]);
  const [sectionData, setSectionData] = useState<SectionData[]>([]);
  const { allListContents } = useSelector((state:RootState) => state.reportWorkspaceReducer);
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    removeAfterPrint: true,
    copyStyles: true,
  });

  useEffect(() => {
    if (jobId) {
      const jobOrdersArray = jobId.split(',');
      setJobOrderData(jobOrdersArray);
    }
    dispatch(getListContentsListStart(true));
  }, [jobId]);

  useEffect(() => {
    if (allListContents?.length > 0) {
      const data = allListContents?.map((content) => ({
        partNumber: content.PartNumber,
        title: content.Title,
        checked: false,
      }));

      data[0].checked = true;

      setSectionData(data);
    }
  }, [allListContents]);

  // const handleChange = (id:number) => {
  //   const temp = [...sectionData];

  //   temp[id - 1].checked = !temp[id - 1].checked;
  //   if (id === 1) {
  //     temp[1].checked = !temp[0].checked;
  //   }

  //   if (id === 2) {
  //     temp[0].checked = !temp[1].checked;
  //   }
  //   setSectionData([...temp]);
  // };

  const handleChange = (partNumber: number) => {
    const temp = [...sectionData];

    // Find the index of the item that corresponds to the partNumber
    const index = temp.findIndex((item) => item.partNumber === partNumber);

    if (index !== -1) {
      // Toggle the 'checked' value of the current item
      temp[index].checked = !temp[index].checked;

      // Apply custom logic for specific partNumbers
      if (partNumber === 1) {
        const part2Index = temp.findIndex((item) => item.partNumber === 2);
        if (part2Index !== -1) temp[part2Index].checked = !temp[index].checked;
      }

      if (partNumber === 2) {
        const part1Index = temp.findIndex((item) => item.partNumber === 1);
        if (part1Index !== -1) temp[part1Index].checked = !temp[index].checked;
      }
    } else {
      return;
    }

    setSectionData([...temp]);
  };

  const handleButtonClick = () => {
    let str1 = '';
    let str2 = '';
    let str3 = '';
    let str4 = '';

    let isTOC = false;

    sectionData?.forEach((element) => {
      if (element.checked) {
        if (Number(element.partNumber) >= 0 && Number(element.partNumber) <= Number(DocumentParts.Defect_Graph)) {
          if (Number(element.partNumber) === Number(DocumentParts.Table_of_Contents)) {
            isTOC = true;
            str1 += '';
          } else {
            str1 += `${element.partNumber},`;
          }
        } else if (Number(element.partNumber) >= Number(DocumentParts.Summary_of_Inspection) && Number(element.partNumber) <= Number(DocumentParts.Data_Sheets)) {
          str2 += `${element.partNumber},`;
        } else if (Number(element.partNumber) >= Number(DocumentParts.Tube_Charts) && Number(element.partNumber) < Number(DocumentParts.Phase_Charts)) {
          str3 += `${element.partNumber},`;
        } else {
          str4 += `${element.partNumber},`;
        }
      }
    });

    const finalString = (str1 + str2 + str3 + str4 + (isTOC ? DocumentParts.Table_of_Contents : ''));

    dispatch(setCheckedSections(finalString?.split(',')));
    const jobOrderId = jobOrderData.join(',');

    if (str1.length > 0) {
      const payload:GetReportPdfPayload = {
        jobOrderId,
        Sections: str1.slice(0, -1),
      };
      dispatch(getReportPdf1Start(payload));
    }

    if (str2.length > 0) {
      const payload2:GetReportPdfPayload = {
        jobOrderId,
        Sections: str2.slice(0, -1),
      };
      dispatch(getReportPdf2Start(payload2));
    }

    if (str3.length > 0) {
      const payload3:GetReportPdfPayload = {
        jobOrderId,
        Sections: str3.slice(0, -1),
      };
      dispatch(getReportPdf3Start(payload3));
    }

    if (str4.length > 0) {
      const payload4:GetReportPdfPayload = {
        jobOrderId,
        Sections: str4.slice(0, -1),
      };
      dispatch(getReportPdf4Start(payload4));
    }

    dispatch(setLoader(true));
    setTimeout(() => {
      handlePrint();
      dispatch(setLoader(false));
    }, 8000);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedData = Array.from(jobOrderData);
    const [movedItem] = reorderedData.splice(result.source.index, 1);
    reorderedData.splice(result.destination.index, 0, movedItem);

    setJobOrderData(reorderedData);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading="REPORTING WORKSPACE" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <Card style={{ height: '83vh', overflow: 'auto' }}>
            <div className="space_between_row">
              <div className="Bid_pervious">
                Create Report
              </div>
            </div>
            <Grid container spacing={1} style={{ padding: '20px' }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided: DroppableProvided) => (
                      <div
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        <Card>
                          <div className="customerInfo_container" style={{ backgroundColor: '#00A7511A' }}>
                            <div style={{ fontSize: '13px', fontWeight: 500, color: '#212529' }}>
                              Selected Job Orders
                            </div>
                          </div>
                          {jobOrderData.map((key, index) => (
                            <Draggable key={key} draggableId={key} index={index}>
                              {(
                                draggableProvided: DraggableProvided,
                              ) => (
                                <div
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                >
                                  <div
                                    className="list_box"
                                    key={key}
                                    style={{
                                      border: '1px solid #E9EBEC',
                                      padding: '10px',
                                      marginBottom: '1px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <label
                                      className="container_label"
                                      style={{ marginLeft: '6px' }}
                                    >
                                      {key}
                                    </label>
                                    <DragIcon />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {droppableProvided.placeholder}
                        </Card>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div style={{ padding: '16px', border: '1px solid #00A751' }}>
                  <Card>
                    <div className="customerInfo_container" style={{ backgroundColor: '#00A7511A' }}>
                      <div style={{ fontSize: '13px', fontWeight: 500, color: '#212529' }}>
                        Combination Report
                      </div>
                    </div>
                    <div style={{ paddingTop: '0px', height: '56vh', overflow: 'auto' }}>
                      {sectionData.map((item) => (
                        <div
                          className="list_box"
                          key={item.partNumber}
                          style={{
                            border: '1px solid #E9EBEC', padding: '10px', marginBottom: '1px',
                          }}
                        >
                          <div className="container_checkmark_div">
                            <label className="container_checkmark">
                              <input
                                type="checkbox"
                                checked={item.checked}
                                onChange={() => handleChange(item.partNumber)}
                              />
                              <span className="checkmark" />
                            </label>
                            <label
                              className="container_label"
                              style={{ marginLeft: '6px' }}
                            >
                              {item.title}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Card>
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div className="button_margin_left">
                    <Button
                      type="button"
                      className={`${styles.marginRightButton} button_save_and_next`}
                      onClick={handleButtonClick}
                    >
                      Preview
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <ScreenReportPdf printRef={printRef} />
    </>
  );
}
