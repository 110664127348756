import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MAIN_TAB } from '../utils/constants';
import {
  AddCustomerInitialState, ContactDetails, CustomerDetails, GetContactsQuery, AddCustomerResponse, GetContactListApiData,
  SelectedCustomerDetails, SelectedContact, GetContactByIdPayload, CommentApiPayload, GetCustomerByIdPayload,
  PaginationResponse, BidsListResult, PayloadTypeDeleteBid, PayloadTypeGetBidsStart, BidData, ThemeDetails,
  GetJobOrderListingPayload,
  GetJobOrderResponse,
  CustomerJobPaginationResponse, MainFormValues,
} from '../utils/types';
import { PinCodeDetails, PinCodeDetailsQuery } from '../../../utils/type';

const initialState: AddCustomerInitialState = {
  customerDetails: {} as CustomerDetails,
  contactDetails: {} as ContactDetails,
  isLoading: false,
  contactList: [],
  errorMessage: '',
  selectedCustomerDetails: {} as SelectedCustomerDetails,
  selectedContact: {} as SelectedContact,
  getContactsQuery: {} as GetContactsQuery,
  selectedTab: MAIN_TAB,
  customerId: '',
  contactTotalEntries: 0,
  commentDetails: {} as CommentApiPayload,
  otherCustomerdetails: {} as SelectedCustomerDetails,
  bidsList: [] as BidData[],
  totalEntries: 0,
  selectedColor: '',
  isSuccess: false,
  themeLogoURL: '',
  themeDetails: {} as ThemeDetails,
  billingPinCodeDetails: {} as PinCodeDetails,
  customerJobOrderData: [] as GetJobOrderResponse[],
  totalJobOrderData: 0 as number,
  formData: {} as MainFormValues,
};

export const addCustomerSlice = createSlice({
  name: 'addCustomer',
  initialState,
  reducers: {

    setSelectedTab(state, action:PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },
    setFormData(state, action: PayloadAction<MainFormValues>) {
      return {
        ...state,
        formData: action.payload,
      };
    },
    removeSelectedCustomer() {
      return initialState;
    },

    removeSelectedContact(state) {
      return {
        ...state,
        selectedContact: null,
      };
    },

    // AddCustomer
    addCustomerStart(state, action: PayloadAction<CustomerDetails>) {
      return {
        ...state,
        isLoading: true,
        customerDetails: action.payload,
      };
    },
    addCustomerSuccess(state, action:PayloadAction<AddCustomerResponse>) {
      return {
        ...state,
        isLoading: false,
        customerId: action.payload.CustomerId,
      };
    },
    addCustomerFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    // Get Customer
    getCustomerByIdStart(state, action: PayloadAction<GetCustomerByIdPayload>) {
      return {
        ...state,
        isLoading: true,
        customerId: action.payload.customerId,
      };
    },
    getCustomerByIdSuccess(state, action:PayloadAction<SelectedCustomerDetails>) {
      return {
        ...state,
        isLoading: false,
        selectedCustomerDetails: action.payload,
        commentDetails: action.payload.Comments,
        themeDetails: action.payload.ThemeDetails,
      };
    },

    getOtherCustomerByIdSuccess(state, action:PayloadAction<SelectedCustomerDetails>) {
      return {
        ...state,
        isLoading: false,
        otherCustomerdetails: action.payload,
      };
    },

    getCustomerByIdFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    // AddContact
    addContactStart(state, action: PayloadAction<ContactDetails>) {
      return {
        ...state,
        isLoading: true,
        contactDetails: action.payload,
      };
    },

    addContactSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    addContactFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    // GetContacts
    getContactsStart(state, action: PayloadAction<GetContactsQuery>) {
      return {
        ...state,
        isLoading: true,
        getContactsQuery: action.payload,
      };
    },
    getContactsSuccess(state, action:PayloadAction<GetContactListApiData>) {
      return {
        ...state,
        isLoading: false,
        contactList: action.payload.Data,
        contactTotalEntries: action.payload.TotalRecords,
      };
    },
    getContactsFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    // EditCustomer
    editCustomerStart(state, action:PayloadAction<CustomerDetails>) {
      return {
        ...state,
        isLoading: true,
        customerDetails: action.payload,
      };
    },
    editCustomerSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    editCustomerFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    // EditContact
    editContactStart(state, _action:PayloadAction<ContactDetails>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    editContactSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    editContactFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    // Delete Contact
    deleteContactStart(state, _action:PayloadAction<GetContactByIdPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteContactSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteContactFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    // Get Contact By Id
    getContactByIdStart(state, _action:PayloadAction<string>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getContactByIdSuccess(state, action:PayloadAction<SelectedContact>) {
      return {
        ...state,
        isLoading: false,
        selectedContact: action.payload,
      };
    },
    getContactByIdFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    // Add Comment
    addCommentStart(state, _action: PayloadAction<CommentApiPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    addCommentSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    addCommentFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    // Update Comment
    updateCommentStart(state, _action: PayloadAction<CommentApiPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateCommentSuccess(state, action: PayloadAction<CommentApiPayload>) {
      return {
        ...state,
        isLoading: false,
        commentDetails: action.payload,
      };
    },
    updateCommentFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    getBidsStart(state, _action: PayloadAction<PayloadTypeGetBidsStart>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetBids(state, action: PayloadAction<PaginationResponse<BidsListResult>>) {
      return {
        ...state,
        isLoading: false,
        bidsList: action?.payload.Data,
        totalEntries: action.payload.TotalRecords,
      };
    },
    failureGetBids(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    deleteBidStart(state, _action: PayloadAction<PayloadTypeDeleteBid>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteBid(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteBid(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    startUploadLogo(state, _action) {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    },
    successUploadLogo(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        themeLogoURL: action.payload,
      };
    },
    failureUploadLogo(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
      };
    },
    setMainColor(state, action: PayloadAction<string>) {
      return {
        ...state,
        selectedColor: action.payload,
      };
    },
    startCreateTheme(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successCreateTheme(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureCreateTheme(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    resetSuccessState(state) {
      return {
        ...state,
        isSuccess: false,
      };
    },
    getBillPinCodeDetailsStart(state, _action: PayloadAction<PinCodeDetailsQuery>) {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    },
    getBillPinCodeDetailsSuccess(state, action: PayloadAction<PinCodeDetails>) {
      return {
        ...state,
        isLoading: false,
        billingPinCodeDetails: action.payload,
        isError: false,
      };
    },
    getBillPinCodeDetailsFailure(state) {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    },
    removeBillPinCodeDetails(state) {
      return {
        ...state,
        billingPinCodeDetails: null,
        isError: false,
      };
    },
    getCustomerJobOrderData(state, _action: PayloadAction<GetJobOrderListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getCustomerJobOrderDataSuccess(state, action: PayloadAction<CustomerJobPaginationResponse<GetJobOrderResponse>>) {
      return {
        ...state,
        isLoading: false,
        customerJobOrderData: action.payload.items,
        totalJobOrderData: action.payload.TotalCount,
      };
    },
    getCustomerJobOrderDataFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  addCustomerStart, addCustomerSuccess, addCustomerFailure, addContactStart, addContactSuccess, addContactFailure,
  getContactsStart, getContactsSuccess, getContactsFailure, editCustomerStart, editCustomerSuccess, editCustomerFailure,
  editContactStart, editContactSuccess, editContactFailure, setSelectedTab, getCustomerByIdStart, getCustomerByIdSuccess,
  getCustomerByIdFailure, removeSelectedCustomer, getContactByIdStart, getContactByIdSuccess,
  getContactByIdFailure, deleteContactStart, deleteContactFailure, deleteContactSuccess,
  removeSelectedContact, addCommentStart, addCommentSuccess, addCommentFailure, updateCommentStart,
  updateCommentSuccess, updateCommentFailure, getOtherCustomerByIdSuccess, getBidsStart, successGetBids, failureGetBids,
  deleteBidStart, successDeleteBid, failureDeleteBid, startUploadLogo, successUploadLogo, failureUploadLogo, setMainColor,
  startCreateTheme, successCreateTheme, failureCreateTheme, resetSuccessState, getBillPinCodeDetailsStart, getBillPinCodeDetailsSuccess,
  getBillPinCodeDetailsFailure, removeBillPinCodeDetails,
  getCustomerJobOrderData, getCustomerJobOrderDataSuccess, getCustomerJobOrderDataFailure, setFormData,
} = addCustomerSlice.actions;
export const addCustomerReducer = addCustomerSlice.reducer;

export type AddCustomerActions =
    | ReturnType<typeof addCustomerStart>
    | ReturnType<typeof addCustomerSuccess>
    | ReturnType<typeof addCustomerFailure>
    | ReturnType<typeof addContactStart>
    | ReturnType<typeof addContactSuccess>
    | ReturnType<typeof addContactFailure>
    | ReturnType<typeof getContactsStart>
    | ReturnType<typeof getContactsSuccess>
    | ReturnType<typeof getContactsFailure>
    | ReturnType<typeof editCustomerFailure>
    | ReturnType<typeof editCustomerStart>
    | ReturnType<typeof editCustomerSuccess>
    | ReturnType<typeof editContactFailure>
    | ReturnType<typeof editContactStart>
    | ReturnType<typeof editContactSuccess>
    | ReturnType<typeof setSelectedTab>
    | ReturnType<typeof getCustomerByIdFailure>
    | ReturnType<typeof getCustomerByIdStart>
    | ReturnType<typeof getCustomerByIdSuccess>
    | ReturnType<typeof removeSelectedCustomer>
    | ReturnType<typeof getContactByIdStart>
    | ReturnType<typeof getContactByIdSuccess>
    | ReturnType<typeof getContactByIdFailure>
    | ReturnType<typeof deleteContactFailure>
    | ReturnType<typeof deleteContactStart>
    | ReturnType<typeof deleteContactSuccess>
    | ReturnType<typeof removeSelectedContact>
    | ReturnType<typeof addCommentStart>
    | ReturnType<typeof addCommentSuccess>
    | ReturnType<typeof addCommentFailure>
    | ReturnType<typeof updateCommentStart>
    | ReturnType<typeof updateCommentSuccess>
    | ReturnType<typeof updateCommentFailure>
    | ReturnType<typeof getOtherCustomerByIdSuccess>
    | ReturnType<typeof getBidsStart>
    | ReturnType<typeof successGetBids>
    | ReturnType<typeof failureGetBids>
    | ReturnType<typeof deleteBidStart>
    | ReturnType<typeof successDeleteBid>
    | ReturnType<typeof failureDeleteBid>
    | ReturnType<typeof startUploadLogo>
    | ReturnType<typeof successUploadLogo>
    | ReturnType<typeof failureUploadLogo>
    | ReturnType<typeof setMainColor>
    | ReturnType<typeof startCreateTheme>
    | ReturnType<typeof successCreateTheme>
    | ReturnType<typeof failureCreateTheme>
    | ReturnType<typeof resetSuccessState>
    | ReturnType<typeof getBillPinCodeDetailsStart>
    | ReturnType<typeof getBillPinCodeDetailsSuccess>
    | ReturnType<typeof getBillPinCodeDetailsFailure>
    | ReturnType<typeof removeBillPinCodeDetails>
    | ReturnType<typeof getCustomerJobOrderData>
    | ReturnType<typeof getCustomerJobOrderDataSuccess>
    | ReturnType<typeof setFormData>
    | ReturnType<typeof getCustomerJobOrderDataFailure>;
