import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import DataTable from '../../../../../Common/DataTable';
import EndPoints from '../../../../../Routes/EndPoints';
import { getCustomerJobOrderData } from '../../../redux/addCustomerSlice';
import { JobOderCustomerData } from '../../../utils/types';
import { RootState } from '../../../../../redux/rootState';
import { formatDateString } from '../../../../../utils/CommonFunctions';

export default function ScreenInspectionSummary() {
  const Navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const { customerJobOrderData, totalJobOrderData } = useSelector((state: RootState) => state.addCustomerReducer);

  const handleData = () => {
    const params = {
      custId: customerId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getCustomerJobOrderData(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  const columns = useMemo(
    () => [
      { key: 'action', label: 'Action' },
      { key: 'clientName', label: 'Client Name' },
      { key: 'jobId', label: 'Job #' },
      { key: 'model', label: 'Model' },
      { key: 'serialNumber', label: 'Serial #' },
      { key: 'siteCity', label: 'Site City' },
      { key: 'siteName', label: 'Site Name' },
      { key: 'startDate', label: 'Start Date' },
    ],
    [],
  );

  const data: JobOderCustomerData[] = useMemo(() => customerJobOrderData?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'edit',
      clientName: item.ClientName,
      jobId: item.JobId,
      model: item.Model,
      serialNumber: String(item.SerialNumber),
      siteCity: item.SiteCity,
      siteName: item.SiteName,
      startDate: formatDateString(item?.StartDate),
      id: `${item.JobId}`,
    };
    return transformedDataItem;
  }), [customerJobOrderData]);

  const handleEdit = (id:string) => {
    Navigate(`/${EndPoints.JOB_ORDER}/${id}`);
  };

  return (
    <div id="job-tab-content">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable<JobOderCustomerData>
            data={data}
            columns={columns}
            totalRecords={totalJobOrderData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            handleEdit={handleEdit}
          />
        </Grid>
      </Grid>
    </div>
  );
}
