import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddOilKitsInitialState, AddOilKitsResult, Empty } from '../utils/types';

const initialState: AddOilKitsInitialState = {
  isLoading: false,
  openViewKitsModal: false,
  oilKitCreated: false,
  isKitAdded: false,
  kitStartCount: '',
  kitEndCount: '',
  isKitRangeLoading: false,
  kitRangeListData: {} as AddOilKitsResult,
  kitDeleted: false,
  kitDataById: [],
  editOilKit: false,
  kitInputRange: [],
  customerId: '',
  isFilled: false,
  oilKitPrice: 0,
};

export const addOilKitsSlice = createSlice({
  name: 'addOilKits',
  initialState,
  reducers: {
    resetAddOilKitsState() {
      return initialState;
    },
    setOpenViewKitsModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        openViewKitsModal: action.payload,
      };
    },
    setCustomerId(state, action: PayloadAction<string>) {
      return {
        ...state,
        customerId: action.payload,
      };
    },
    setKitStartCount(state, action: PayloadAction<string | null>) {
      return {
        ...state,
        kitStartCount: action.payload,
      };
    },
    setKitEndCount(state, action: PayloadAction<string | null>) {
      return {
        ...state,
        kitEndCount: action.payload,
      };
    },
    onCreateOilKit(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    onCreatedOilKit(state) {
      return {
        ...state,
        oilKitCreated: true,
      };
    },
    onAddKit(state) {
      return {
        ...state,
        isKitAdded: false,
      };
    },
    onAddedKit(state) {
      return {
        ...state,
        isKitAdded: true,
      };
    },
    onCreatedOilKitFailure(state) {
      return {
        ...state,
        isLoading: false,
        oilKitCreated: false,
      };
    },
    getKitRangeList(state, _action) {
      return {
        ...state,
        isKitRangeLoading: true,
      };
    },
    getKitRangeListData(state, action: PayloadAction<Empty>) {
      return {
        ...state,
        kitRangeListData: action.payload,
      };
    },
    onDeleteKit(state, _action) {
      return {
        ...state,
        kitDeleted: false,
      };
    },
    onDeletedKit(state) {
      return {
        ...state,
        kitDeleted: true,
      };
    },
    getDetailsById(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    gotDetailsById(state, action: PayloadAction<Empty>) {
      return {
        ...state,
        isLoading: false,
        kitDataById: action.payload,
      };
    },
    onEditOilKit(state) {
      return {
        ...state,
        editOilKit: false,
      };
    },
    resetEditOilKit(state) {
      return {
        ...state,
        editOilKit: false,
      };
    },
    onEditSuccessOilKit(state) {
      return {
        ...state,
        editOilKit: true,
      };
    },
    setKitsRange(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        kitInputRange: action.payload,
      };
    },
    isKitRangeFilled(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isFilled: action.payload,
      };
    },
    getOilKitsUnitPrice(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    gotOilKitsUnitPrice(state, action: PayloadAction<number>) {
      return {
        ...state,
        oilKitPrice: action.payload,
      };
    },
    gotOilKitsUnitPriceFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  setOpenViewKitsModal, onCreateOilKit, onCreatedOilKit, onCreatedOilKitFailure,
  onAddedKit, setKitStartCount, setKitEndCount, getKitRangeList, getKitRangeListData,
  onAddKit, onDeleteKit, onDeletedKit, getDetailsById, gotDetailsById, onEditOilKit, onEditSuccessOilKit,
  setKitsRange, setCustomerId, isKitRangeFilled, getOilKitsUnitPrice, gotOilKitsUnitPrice, gotOilKitsUnitPriceFailure,
  resetEditOilKit, resetAddOilKitsState,
} = addOilKitsSlice.actions;
export const addOilKitsReducer = addOilKitsSlice.reducer;

export type AddOilKitsActions =
  | ReturnType<typeof setOpenViewKitsModal>
  | ReturnType<typeof onCreateOilKit>
  | ReturnType<typeof onCreatedOilKit>
  | ReturnType<typeof onCreatedOilKitFailure>
  | ReturnType<typeof onAddedKit>
  | ReturnType<typeof setKitStartCount>
  | ReturnType<typeof setKitEndCount>
  | ReturnType<typeof getKitRangeList>
  | ReturnType<typeof getKitRangeListData>
  | ReturnType<typeof onAddKit>
  | ReturnType<typeof onDeleteKit>
  | ReturnType<typeof onDeletedKit>
  | ReturnType<typeof getDetailsById>
  | ReturnType<typeof gotDetailsById>
  | ReturnType<typeof onEditOilKit>
  | ReturnType<typeof setKitsRange>
  | ReturnType<typeof setCustomerId>
  | ReturnType<typeof isKitRangeFilled>
  | ReturnType<typeof getOilKitsUnitPrice>
  | ReturnType<typeof gotOilKitsUnitPrice>
  | ReturnType<typeof gotOilKitsUnitPriceFailure>
  | ReturnType<typeof resetEditOilKit>
  | ReturnType<typeof resetAddOilKitsState>
  | ReturnType<typeof onEditSuccessOilKit>;
