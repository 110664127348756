import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';
import DataTable from '../../../../Common/DataTable';
import ComponentDeleteModal from '../../../../Common/DeleteModal/ComponentDeleteModal';
import { setLoader } from '../../../../redux/loaderSlice';
import { deleteBidStart, getBidsStart } from '../../redux/addCustomerSlice';
import { BidData } from './Utils/TypeBids';

export default function Bids() {
  const Navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBidId, setSelectedBidId] = useState('');

  const dispatch = useDispatch();
  const { customerId } = useParams();
  const { bidsList, isLoading, totalEntries } = useSelector((state: RootState) => state.addCustomerReducer);

  const handleData = () => {
    const params = {
      customerId: customerId || '',
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getBidsStart(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleDelete = (id: string) => {
    setShowDeleteModal(true);
    setSelectedBidId(id);
  };

  const handelDeleteModal = () => {
    const paramData = {
      bidId: selectedBidId,
      customerId: customerId || '',
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteBidStart(paramData));
    setShowDeleteModal(false);
  };

  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Actions' },
      { key: 'BidId', label: 'Bid ID' },
      { key: 'JobId', label: 'Job #' },
      { key: 'BidName', label: 'Bid Name' },
      { key: 'BidCreationDate', label: 'Bid Date' },
      { key: 'SiteName', label: 'Site City' },
      { key: 'City', label: 'City' },
    ],
    [],
  );

  const data: BidData[] = useMemo(() => bidsList.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      id: item?.BidId,
      action: 'edit&delete',
      BidId: item?.BidId,
      JobId: item?.JobId,
      BidName: item?.BidName,
      BidCreationDate: item?.BidCreationDate,
      SiteName: item?.SiteName,
      City: item?.City,
    };
    return transformedDataItem;
  }), [bidsList]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleEdit = (id: string) => {
    Navigate(`/jobs/bid-worksheet/${id}`);
  };

  return (
    <div id="bids-tab-content">
      <DataTable<BidData>
        data={data}
        columns={columns}
        totalRecords={totalEntries}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />
    </div>
  );
}
