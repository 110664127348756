/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable max-len */
// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import Config from '../../../Common/Config';
// import { RootState } from '../../../redux/rootState';
// import ComponentLoader from '../../../Common/ComponentLoader/ComponentLoader';
// import localStorageConstants from '../../../utils/LocalStorageConstants';
// import { getPhotoById, resetPhotoData } from '../redux/photosSlice';

// function PaintComponent() {
//   const { photoDataById, isLoading } = useSelector((state: RootState) => state.photosReducer);
//   const { photoId } = useParams();
//   const dispatch = useDispatch();

//   const token = localStorage.getItem(localStorageConstants.TOKEN);
//   const userID = localStorage.getItem(localStorageConstants.USER_ID);
//   const ImageUrl = photoDataById?.PhotoPath;

//   useEffect(() => {
//     if (photoId) {
//       const payload = {
//         id: photoId,
//       };
//       dispatch(getPhotoById(payload));
//     }
//     return () => {
//       dispatch(resetPhotoData());
//     };
//   }, [photoId]);

//   return (
//     <>
//       {isLoading
//         && (
//           <div className="loader_styles">
//             <ComponentLoader />
//           </div>
//         )}
//       <div style={{
//         width: '100%',
//         height: '100vh',
//       }}
//       >
//         <iframe
//           src={`https://devapi.taiservices.info/#load:${ImageUrl},id:${photoDataById.Id},photoId:${photoDataById.PhotoId},vesselName:${photoDataById.VesselName},fileNam:${photoDataById.FileName},Token:${token},UserId:${userID},JobOrder:${photoDataById.JobOrder},ApiUrl:${Config.photosBaseUrl.updatePhotoEditUrl}`}
//           width="100%"
//           height="100%"
//           style={{ border: 'none' }}
//         />
//       </div>
//     </>
//   );
// }

// export default PaintComponent;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop'; // Material-UI Backdrop
import CircularProgress from '@mui/material/CircularProgress'; // Material-UI CircularProgress
import Config from '../../../Common/Config';
import { RootState } from '../../../redux/rootState';
import localStorageConstants from '../../../utils/LocalStorageConstants';
import { getPhotoById, resetPhotoData } from '../redux/photosSlice';

function PaintComponent() {
  const { photoDataById, isLoading } = useSelector((state: RootState) => state.photosReducer);
  const { photoId } = useParams();
  const dispatch = useDispatch();

  const [isImageLoading, setIsImageLoading] = useState(true); // State for iframe loading

  const token = localStorage.getItem(localStorageConstants.TOKEN);
  const userID = localStorage.getItem(localStorageConstants.USER_ID);
  const ImageUrl = photoDataById?.PhotoPath;

  useEffect(() => {
    if (photoId) {
      const payload = {
        id: photoId,
      };
      dispatch(getPhotoById(payload));
    }
    return () => {
      dispatch(resetPhotoData());
    };
  }, [photoId]);

  return (
    <>
      {/* Backdrop with CircularProgress for global isLoading */}
      {isLoading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
      )}

      {/* Image loading state managed for iframe */}
      <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
        {isImageLoading && (
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isImageLoading}
          >
            <CircularProgress />
          </Backdrop>
        )}
        <iframe
          src={`${Config.JSPaintURl.JsPaintURL}#load:${ImageUrl},.id:${photoDataById?.Id},.photoId:${photoDataById?.PhotoId},.vesselName:${photoDataById?.VesselName},.fileNam:${photoDataById?.FileName},.Token:${token},.UserId:${userID},.JobOrder:${photoDataById?.JobOrder},.ApiUrl:${Config.photosBaseUrl.updatePhotoEditUrl}`}
          width="100%"
          height="100%"
          style={{ border: 'none', display: isImageLoading ? 'none' : 'block' }}
          onLoad={() => setIsImageLoading(false)} // Hide loading when iframe finishes loading
        />
      </div>
    </>
  );
}

export default PaintComponent;
