import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../Common/DataTable';
import { RootState } from '../../../../redux/rootState';
import { setLoader } from '../../../../redux/loaderSlice';
import ComponentDeleteModal from '../../../../Common/DeleteModal/ComponentDeleteModal';
import ComponentTestEndListDialog from './ComponentTestEndListDialog';
import { deleteTestEndListStart, getTestEndListStart } from '../redux/sliceTestEndList';
import { TestEndList } from '../utils/types';

function ComponentTestEndListTable() {
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTestEndListId, setSelectedTestEndListId] = useState<string>('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();

  const { isLoading, TestEndListData } = useSelector((state: RootState) => state.ListTestEndReducer);
  const { showDeleteIcon } = useSelector((state: RootState) => state.masterDataReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getTestEndListStart(params));
  };

  const handleAction = (id: string) => {
    setOpen(true);
    setRowId(id);
  };

  const handleButtonClick = () => {
    setRowId('');
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const TableColumns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'Description', label: 'Description' },
    ],
    [],
  );

  const tableData: TestEndList[] = useMemo(() => TestEndListData?.Data?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: showDeleteIcon ? 'edit&delete' : 'edit',
      Description: item?.Description,
      id: String(item?.Id),
    };
    return transformedDataItem;
  }), [TestEndListData]);

  const handleDelete = (id: string) => {
    setShowDeleteModal(true);
    setSelectedTestEndListId(id);
  };

  const handelDeleteModal = () => {
    const paramData = {
      Id: selectedTestEndListId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteTestEndListStart(paramData));
    setShowDeleteModal(false);
  };

  return (
    <>
      <DataTable<TestEndList>
        handleButtonClick={handleButtonClick}
        data={tableData}
        columns={TableColumns}
        totalRecords={TestEndListData?.TotalRecords}
        buttonText="Add"
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        handleEdit={handleAction}
        handleDelete={handleDelete}
      />
      <ComponentTestEndListDialog show={open} setOpenFrom={handleButtonClick} rowId={rowId} handleData={handleData} />
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />
    </>
  );
}

export default ComponentTestEndListTable;
