import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';
import { CustomerData } from '../../types';

interface ComponentExpenseCustomerProps {
  customerData: CustomerData;
}

function ComponentExpenseCustomer({ customerData }: ComponentExpenseCustomerProps) {
  const { themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  return (
    <table className="bordered-table w100">
      <div
        className="tableAfterThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />
      <div
        className="tableBeforeThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />
      <tr>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Customer
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Customer Name
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Location
        </td>
      </tr>
      <tr>
        <td className="textCenter">{customerData?.CustomerId}</td>
        <td className="textCenter">{customerData?.CustomerName}</td>
        <td className="textCenter">{customerData?.Location}</td>
      </tr>
    </table>
  );
}

export default ComponentExpenseCustomer;
