import React, { useEffect, useMemo } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ComponentCustomerInfo from './components/customerSiteInfo';
import ComponentBid from './components/bid';
import {
  selectedTabStyles, tabStyles, tabsStyles, stylesTabIndicator,
} from '../../../../Common/TabsStyles';
import ComponentPageHeader from '../../../../Common/PageHeader/ComponentPageHeader';
// import ComponentCommentsTab from './components/comments';
import { RootState } from '../../../../redux/rootState';
import { setLoader } from '../../../../redux/loaderSlice';
import {
  getJobByIdStart, getSiteTypesStart, resetJobStarterState, setSelectedTab,
} from './redux/addJobSlice';
import { getCustomerByIdStart, removeSelectedCustomer } from '../../../addCustomer/redux/addCustomerSlice';
import { GetCustomerByIdPayload } from '../../../addCustomer/utils/types';
import EndPoints from '../../../../Routes/EndPoints';
import ComponentBreadcrumb from '../../../../Common/ComponentBreadcrumb/ComponentBreadcrumb';
import { JobIcon } from '../../../../assets/NavBarIcons';

const breadcrumbs = [
  { label: 'Job', url: '/jobs', icon: <JobIcon /> },
];

export default function AddJob() {
  const TABS = useMemo(() => [
    { id: 1, label: 'Customer & Site Information', component: <ComponentCustomerInfo /> },
    // { id: 2, label: 'Comments', component: <ComponentCommentsTab /> },
    { id: 2, label: 'Associated Bid Worksheets', component: <ComponentBid /> },
  ], []);

  const {
    isLoading, siteTypes, selectedtab, jobId: JId,
  } = useSelector((state: RootState) => state.jobStarterReducer);
  const { isLoading: customerLoading } = useSelector((state: RootState) => state.addCustomerReducer);
  const { historyStack } = useSelector((state:RootState) => state.masterDataReducer);
  const dispatch = useDispatch();

  const { jobId, customerId } = useParams();

  const handleBackAction = (prevUrl:string) => {
    if (prevUrl.includes(EndPoints.ADD_JOB_BID)) {
      const arr = prevUrl.split('/');
      const id = arr[arr.length - 1];
      dispatch(getJobByIdStart(id));
      dispatch(setSelectedTab(2));
    } else if (prevUrl.includes(EndPoints.BID_WORKSHEET)) {
      dispatch(setSelectedTab(2));
    }
  };

  useEffect(() => {
    const prevUrl = historyStack[historyStack.length - 1] || '';
    handleBackAction(prevUrl);
  }, []);

  useEffect(() => {
    if (siteTypes.length === 0) {
      dispatch(getSiteTypesStart());
    }
    return () => {
      dispatch(resetJobStarterState());
      dispatch(removeSelectedCustomer());
    };
  }, []);

  useEffect(() => {
    const prevUrl = historyStack[historyStack.length - 1] || '';
    if (customerId && !prevUrl.includes(EndPoints.ADD_JOB_BID)) {
      const payload: GetCustomerByIdPayload = {
        customerId,
        otherCustomer: true,
      };
      dispatch(getCustomerByIdStart(payload));
    }
  }, [customerId]);

  useEffect(() => {
    const prevUrl = historyStack[historyStack.length - 1] || '';
    if (jobId && !prevUrl.includes(EndPoints.ADD_JOB_BID)) {
      dispatch(getJobByIdStart(jobId));
    }
  }, [jobId]);

  const handleChangeTab = (tabId: number) => {
    dispatch(setSelectedTab(tabId));
  };

  useEffect(() => {
    dispatch(setLoader(isLoading || customerLoading));
  }, [isLoading, customerLoading]);

  return (
    <>
      <ComponentPageHeader subHeading="JOB STARTER" fieldId={JId} fieldName="Job ID" />
      <ComponentBreadcrumb crumbs={breadcrumbs} />
      <div className="container_job">
        <Tabs
          variant="scrollable"
          value={selectedtab - 1}
          onChange={(_, newValue) => handleChangeTab(TABS[newValue].id)}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.label}
              style={selectedtab === tab.id ? selectedTabStyles : tabStyles}
              className="tab_styles"
              disabled={!JId}
            />
          ))}
        </Tabs>
      </div>
      <div className="div_job_new">
        <div className="div_container_CustomerInfo">
          {TABS[selectedtab - 1].component}
        </div>
      </div>
    </>
  );
}
