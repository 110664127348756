/* eslint-disable max-len */
import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, Observable, of, switchMap, takeUntil,
} from 'rxjs';
import Config from '../../../Common/Config';
import { makeDeleteRequest, makeGetRequest } from '../../../Common/NetworkOps';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  deleteTubeChartLibraryFailure, deleteTubeChartLibraryStart, deleteTubeChartLibrarySuccess,
  getTubeChartImageFailure,
  getTubeChartImageStart,
  getTubeChartImageSuccess,
  getTubeChartLibraryFailure, getTubeChartLibraryStart, getTubeChartLibrarySuccess, TubeChartLibraryActions,
} from './tubeChartLibrarySlice';
import { GetById, ListingResponse } from '../../../utils/type';
import {
  DeleteTubeChartLibraryPayload, GetTubeChartLibraryImagePayload, GetTubeChartLibraryListingPayload, TubeChartImageResponse, TubeChartLibraryData,
} from '../utils/types';

// Get Tube Chart Library List
async function getTubeChartLibraryList(data: GetTubeChartLibraryListingPayload): Promise<ListingResponse<TubeChartLibraryData>> {
  const url = `${Config.TubeChartLibraryURL.GetTubeChartLibraryDataUrl}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<TubeChartLibraryData>>(url);
  return result.data;
}
export const getTubeChartLibraryEpic = (action$: Observable<TubeChartLibraryActions>) => action$.pipe(
  ofType(getTubeChartLibraryStart.type),
  map((x) => x.payload),
  debounceTime(250),
  switchMap((data: GetTubeChartLibraryListingPayload) => from(getTubeChartLibraryList(data)).pipe(
    map((res: ListingResponse<TubeChartLibraryData>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getTubeChartLibrarySuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getTubeChartLibraryFailure();
    }),
    takeUntil(action$.pipe(filter(getTubeChartLibraryStart.match))),
    catchError((error) => of(getTubeChartLibraryFailure(error))),
  )),
);

// Delete Tube Chart Library

async function deleteTubeChartLibrary(data:DeleteTubeChartLibraryPayload): Promise<GetById<string>> {
  const url = `${Config.TubeChartLibraryURL.DeleteTubeChartLibraryDataUrl}/${data.id}`;
  const result = await makeDeleteRequest<GetById<string>>(url);
  return result.data;
}
export const deleteTubeChartLibraryEpic = (action$: Observable<TubeChartLibraryActions>) => action$.pipe(
  ofType(deleteTubeChartLibraryStart.type),
  map((x) => x.payload),
  switchMap((data:DeleteTubeChartLibraryPayload) => from(deleteTubeChartLibrary(data)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload = {
          page: data.page,
          rowsPerPage: data.rowsPerPage,
          searchQuery: data.searchQuery,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return (deleteTubeChartLibrarySuccess(), getTubeChartLibraryStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return deleteTubeChartLibraryFailure();
    }),
    takeUntil(action$.pipe(filter(deleteTubeChartLibraryStart.match))),
    catchError((error) => of(deleteTubeChartLibraryFailure(error))),
  )),
);

// Get Tube Chart Library Image
async function getTubeChartLibraryImage(data: GetTubeChartLibraryImagePayload): Promise<TubeChartImageResponse> {
  const url = `${Config.TubeChartLibraryURL.GetTubeChartLibraryImageUrl}?tubeId=${data.id}`;
  const result = await makeGetRequest<TubeChartImageResponse>(url);
  return result.data;
}
export const getTubeChartLibraryImageEpic = (action$: Observable<TubeChartLibraryActions>) => action$.pipe(
  ofType(getTubeChartImageStart.type),
  map((x) => x.payload),
  debounceTime(250),
  switchMap((data: GetTubeChartLibraryImagePayload) => from(getTubeChartLibraryImage(data)).pipe(
    map((res: TubeChartImageResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getTubeChartImageSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getTubeChartImageFailure();
    }),
    takeUntil(action$.pipe(filter(getTubeChartImageStart.match))),
    catchError((error) => of(getTubeChartImageFailure(error))),
  )),
);
