import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';

function CommonTableWithMultipleColumn() {
  const { reportPdfResponse1, themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  return (
    <table className="bordered-table w100">
      <div
        className="tableAfterThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />

      {/* Simulating the ::before pseudo-element */}
      <div
        className="tableBeforeThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />
      <tr>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Manufacturer
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Model
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Style
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Serial Number
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Type
        </td>
      </tr>
      <tr>
        <td className="textCenter">{reportPdfResponse1?.JobOrderData?.Manufacturer}</td>
        <td className="textCenter">{reportPdfResponse1?.JobOrderData?.Model}</td>
        <td className="textCenter">{reportPdfResponse1?.JobOrderData?.Style}</td>
        <td className="textCenter">{reportPdfResponse1?.JobOrderData?.SerialNumber}</td>
        <td className="textCenter">{reportPdfResponse1?.JobOrderData?.MachineType}</td>
      </tr>
    </table>
  );
}

export default CommonTableWithMultipleColumn;
