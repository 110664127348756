import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { exportToExcelData } from '../../Common/PageHeader/helper';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../Common/DataTable';
import { ReportsWorkspaceListingInterface } from './utils/types';
import { RootState } from '../../redux/rootState';
import { setLoader } from '../../redux/loaderSlice';
import { ReportWorkspaceTableColumns } from './utils/helpers';
import { showWarnToaster } from '../../Common/ComponentToast/ComponentSuccessToasts';
import { getCombineReportDataStart, setCheckedIds } from '../taiReportingSystem/redux/sliceReportWorkspace';

function ScreenReportWorkspace() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const {
    combineReportListData, totalCombineReport, isLoading, checkedIds,
  } = useSelector((state: RootState) => state.reportWorkspaceReducer);
  const [data, setData] = useState<ReportsWorkspaceListingInterface[]>([]);

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };
    dispatch(getCombineReportDataStart(payload));
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const tableData: ReportsWorkspaceListingInterface[] = useMemo(() => {
    if (combineReportListData?.length > 0) {
      const transformedData = combineReportListData?.map((ele) => ({
        jobOrder: ele,
        id: `${ele}`,
        isChecked: checkedIds.includes(`${ele}`),
      }));
      return transformedData;
    }
    return [];
  }, [combineReportListData, checkedIds]);

  useEffect(() => {
    if (combineReportListData) {
      setData(tableData);
    }
  }, [combineReportListData, tableData]);

  const handleCheckBoxWithKey = (id: string) => {
    const jobIds: string[] = [...checkedIds];
    const updatedData = data?.map((element: ReportsWorkspaceListingInterface) => {
      if (element?.id === id) {
        if (!element.isChecked) {
          jobIds.push(element.id);
        } else {
          const ind = jobIds.findIndex((ele) => ele === id);
          jobIds.splice(ind, 1);
        }
        return {
          ...element,
          isChecked: !element?.isChecked,
        };
      }
      return element;
    });

    setData(updatedData);
    dispatch(setCheckedIds(jobIds));
  };

  useEffect(() => {
    handleData();
    return () => {
      dispatch(setCheckedIds([]));
    };
  }, []);

  const excelData = useMemo(() => combineReportListData?.map((item) => ({
    JobOrder: item,
  })), [combineReportListData]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'REPORT WORKSPACE',
    );
  };

  const handleButtonClick = () => {
    if (checkedIds.length < 2 || checkedIds.length > 3) {
      showWarnToaster('Please select a minimum of 2 and a maximum of 3 job orders.');
      return;
    }
    const payload = {
      selectedIds: checkedIds,
    };
    navigate(`${payload.selectedIds}`);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ComponentPageHeader subHeading="REPORTING WORKSPACE" downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} className="dataTableCustomMargin">
        <DataTable<ReportsWorkspaceListingInterface>
          data={data?.length > 0 ? data : []}
          columns={ReportWorkspaceTableColumns}
          totalRecords={totalCombineReport}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          tableHeader="Job Order"
          customClassName="reportWorkspaceTableStyle"
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          checkBoxWithKey={['sn']}
          handleCheckBoxWithKey={handleCheckBoxWithKey}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12} className="dataTableCustomMargin">
        <div className="save_and_next_div">
          <div>
            <Button className="button_cancel" onClick={() => navigate(-1)}>Cancel</Button>
          </div>
          <div className="button_margin_left">
            <Button
              type="button"
              onClick={() => handleButtonClick()}
              disabled={Boolean(checkedIds.length === 0)}
              className="button_save_and_next"
            >
              Select Job Order
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default ScreenReportWorkspace;
