/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/rootState';

function TableTwo() {
  const { themePdfColor, reportPdfResponse1 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;
  const tubeData = reportPdfResponse1?.Ticklers_Data?.VesselInfo;

  return (
    <div style={{ width: '300px' }}>
      {/* <table className="bordered-table w100">
        <div
          className="tableAfterThickBorderStyle"
          style={{
            backgroundColor: BorderColor,
          }}
        />
        <div
          className="tableBeforeThickBorderStyle"
          style={{
            backgroundColor: BorderColor,
          }}
        />
        <tr>
          <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
            Vessel
          </td>
          <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
            Count
          </td>
          <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
            Length
          </td>
        </tr>
        <tr>
          <td className="textCenter">Cond</td>
          <td className="textCenter">23432</td>
          <td className="textCenter">23</td>
        </tr>
        <tr>
          <td className="textCenter">Cond</td>
          <td className="textCenter">23432</td>
          <td className="textCenter">23</td>
        </tr>
        <tr>
          <td className="textCenter">Cond</td>
          <td className="textCenter">23432</td>
          <td className="textCenter">23</td>
        </tr>
      </table> */}
      <table className="bordered-table w100">
        <div
          className="tableAfterThickBorderStyle"
          style={{
            backgroundColor: BorderColor,
          }}
        />
        <div
          className="tableBeforeThickBorderStyle"
          style={{
            backgroundColor: BorderColor,
          }}
        />
        <tr>
          <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
            Vessel
          </td>
          <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
            Count
          </td>
          <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
            Length
          </td>
        </tr>

        {tubeData?.map((item, index) => (
          <tr key={index}>
            <td className="textCenter">{item?.VesselName}</td>
            <td className="textCenter">{item?.Count}</td>
            <td className="textCenter">{item?.Length}</td>
          </tr>
        ))}
      </table>
    </div>
  );
}

export default TableTwo;
