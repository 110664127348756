import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import english from '../../assets/lang/english';
import { RootState } from '../../redux/rootState';
import DataTable from '../../Common/DataTable';
import ComponentDeleteModal from '../../Common/DeleteModal/ComponentDeleteModal';
import { setLoader } from '../../redux/loaderSlice';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import { exportToExcelData } from '../../Common/PageHeader/helper';
import { JobOrderData } from './utils/types';
import {
  deleteJobOrderStart, getJobOrderData, JobOrderMoveWorkspaceStart, setCheckedIds,
} from './redux/sliceJobOrder';

export default function ScreenJobOrder() {
  const Navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedJobOrderId, setSelectedJobOrderId] = useState('');
  const [data, setData] = useState<JobOrderData[]>([]);

  const dispatch = useDispatch();
  const { jobOrderData, isLoading, checkedIds } = useSelector((state: RootState) => state.jobOrderReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getJobOrderData(params));
  };

  useEffect(() => {
    handleData();
    return () => {
      dispatch(setCheckedIds([]));
    };
  }, []);

  const handleDelete = (id: string) => {
    setShowDeleteModal(true);
    setSelectedJobOrderId(id);
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(false);
    const payload = {
      page,
      rowsPerPage,
      searchQuery,
      id: selectedJobOrderId,
    };
    dispatch(deleteJobOrderStart(payload));
    dispatch(setCheckedIds([]));
  };

  const columns = useMemo(
    () => [
      { key: 'sn', label: '#' },
      { key: 'action', label: 'Action' },
      { key: 'ReadyToSend', label: 'Ready To Send' },
      { key: 'JobOrder', label: 'Job Order #' },
      { key: 'Customer', label: 'Customer' },
      { key: 'Model', label: 'Model' },
      { key: 'Serial', label: 'Serial #' },
      { key: 'Site', label: 'Site' },
      { key: 'SiteCity', label: 'Site City' },
    ],
    [],
  );

  const apiData: JobOrderData[] = useMemo(() => jobOrderData?.Data?.map((item, index) => {
    const transformedDataItem = {
      ReadyToSend: item?.IsReadyToSend,
      Date: item?.Date,
      JobOrder: item?.JobOrderNumber,
      Customer: item?.CustomerName,
      Model: item?.Model,
      Serial: item?.SerialNumber,
      Site: item?.SiteName,
      SiteCity: item?.SiteCity,
      action: 'edit&delete',
      sn: index,
      id: item?.JobOrderNumber,
      isChecked: checkedIds.includes(`${item?.JobOrderNumber}`),
    };
    return transformedDataItem;
  }), [jobOrderData, checkedIds]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleEdit = (id: string) => {
    Navigate(`${id}`);
  };

  const handleCheckBoxWithKey = (id: string) => {
    const jobIds: string[] = [...checkedIds];
    const updatedData = data?.map((element: JobOrderData) => {
      if (element?.id === id) {
        if (!element.isChecked) {
          jobIds.push(element.id);
        } else {
          const ind = jobIds.findIndex((ele) => ele === id);
          jobIds.splice(ind, 1);
        }
        return {
          ...element,
          isChecked: !element?.isChecked,
        };
      }
      return element;
    });

    setData(updatedData);
    dispatch(setCheckedIds(jobIds));
  };

  useEffect(() => {
    if (jobOrderData) {
      setData(apiData);
    }
  }, [jobOrderData, apiData]);

  const excelData = useMemo(() => jobOrderData?.Data?.map((item) => {
    const transformedDataItem = {
      ReadyToSend: item?.IsReadyToSend ? 'Yes' : 'No',
      Date: item?.Date,
      Job_Order: item?.JobOrderNumber,
      Customer: item?.CustomerName,
      Model: item?.Model,
      Serial_Number: item?.SerialNumber,
      Site_Name: item.SiteName,
      Site_City: item?.SiteCity,
    };
    return transformedDataItem;
  }), [jobOrderData]);

  const handleButtonClick = () => {
    dispatch(JobOrderMoveWorkspaceStart(checkedIds));
  };

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Job_Order_list',
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading={String(english.Job_Order)} downloadExcel={downloadExcel} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable<JobOrderData>
            data={data}
            columns={columns}
            totalRecords={jobOrderData?.TotalRecords}
            checkBoxColumns={['ReadyToSend']}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            checkBoxWithKey={['sn']}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleCheckBoxWithKey={handleCheckBoxWithKey}
            buttonText="Move to Workspace"
            handleButtonClick={() => handleButtonClick()}
            textButtonDisabled={Boolean(checkedIds.length === 0)}
          />
        </Grid>
      </Grid>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />
    </>
  );
}
