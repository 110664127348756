/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  Controller, Control, Path, FieldValues, FieldErrors,
} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { getCityStateSuggestionStart, resetCityStateSuggestion } from '../../redux/masters/masterSlice';
import { Empty } from '../../Screens/ScreenAddSmc/Utils/TypeSmc';
import { RootState } from '../../redux/rootState';

// interface RHFAutocompleteFieldProps<O extends { City: string }, TField extends FieldValues> {
interface RHFAutocompleteFieldProps<TField extends FieldValues> {
  control: Control<TField>;
  name: Path<TField>;
  // options: O[];
  options: Empty;
  id: string;
  placeholder?: string;
  errors?: FieldErrors;
  disabled?: boolean;
  setValue?: Empty;
  companyZip?: string;
  companyState?: string;
  inputValue?: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

// eslint-disable-next-line max-len
// export function RHFAutocompleteFieldWithState<O extends { City: string }, TField extends FieldValues>({ errors, disabled, ...props }: RHFAutocompleteFieldProps<O, TField>) {
export function RHFAutocompleteFieldWithState<TField extends FieldValues>({ errors, disabled, ...props }: RHFAutocompleteFieldProps<TField>) {
  const {
    control, options, name, setValue, companyZip, companyState, id, inputValue, setInputValue,
  } = props;
  // const [inputValue, setInputValue] = React.useState('');
  const { isCityStateLoading } = useSelector((state: RootState) => state.masterDataReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    // if (!inputValue) {
    //   return;
    // }
    if (inputValue && !companyZip) {
      dispatch(getCityStateSuggestionStart({
        CityName: inputValue || '',
        StateName: companyState || '',
      }));
    }
  }, [inputValue, companyState]);

  const error = errors ? errors[name] : null;

  useEffect(() => {
    dispatch(resetCityStateSuggestion());
  }, []);

  const handleEndAdornmentClick = () => {
    if (name) {
      setValue(name, '');
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          <Autocomplete
            size="small"
            freeSolo
            key={`${companyZip}-${companyState}`}
            id={id}
            disabled={disabled}
            loading={isCityStateLoading}
            // value={value ? options?.find((option) => value === option?.City) ?? null : null}
            // getOptionLabel={(option: Empty) => option.City}
            // eslint-disable-next-line max-len
            value={companyZip ? options?.find((option: Empty) => value === option?.Value) : value ? options?.find((option: Empty) => value === option?.City) ?? null : null}
            getOptionLabel={companyZip ? (option: Empty) => option.Text : (option: Empty) => option.City}
            onChange={(event, newValue) => {
              // onChange(newValue ? (newValue as Empty)?.City : inputValue ?? null);
              onChange(companyZip ? (newValue as Empty)?.Value : newValue ? (newValue as Empty)?.City : inputValue ?? null);
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              onChange(newInputValue as Empty);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                placeholder="Search city here..."
                className="nonCapitalizeField"
                error={Boolean(error?.message)}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                    <div onClick={handleEndAdornmentClick}>
                      {isCityStateLoading ? <span className="cityCircularLoading"><CircularProgress size={20} /></span> : null}
                      {params.InputProps.endAdornment}
                    </div>
                  ),
                }}
              />
            )}
          />
          {error && <span className="errorMessage">{String(error.message)}</span>}
        </>
      )}
    />
  );
}
