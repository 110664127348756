/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomCheckbox from '../../../Common/ComponentCustomCheckBox';
import { expenseFormSchema } from '../utils/validations';
import { ExpenseFormDefaultValues } from '../utils/constants';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import { ExpenseFormEnum, ExpenseMainFormAPIValues, ExpenseMainFormValues } from '../utils/types.d';
import ComponentScannedReceipts from './ComponentScannedReceipts';
import EndPoints from '../../../Routes/EndPoints';
import { setLoader } from '../../../redux/loaderSlice';
import { RootState } from '../../../redux/rootState';
import {
  cashFieldNames, chargeFieldNames, expenseData, getRequestPayload, setValues,
} from '../utils/helper';
import { clearExpenseReportData, getExpenseReportById, updateExpenseReportStart } from '../redux/expenseReportSlice';
import { roundExpenseRegex } from '../../../utils/regex';

function ScreenExpense() {
  const {
    control, formState: { errors }, handleSubmit, setValue, getValues, watch,
  } = useForm<ExpenseMainFormValues>({
    defaultValues: ExpenseFormDefaultValues,
    resolver: yupResolver(expenseFormSchema as unknown as ObjectSchema<ExpenseMainFormValues>),
  });

  const { isLoading, expenseReportDataById } = useSelector((state: RootState) => state.expenseReportReducer);
  const dispatch = useDispatch();
  const { expenseId } = useParams();

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const cashValues = watch(cashFieldNames);
  const chargeValues = watch(chargeFieldNames);

  const totalCash = cashValues.reduce((acc, value) => acc + Number(value), 0);
  const totalCharge = chargeValues.reduce((acc, value) => acc + Number(value), 0);

  const handleAction = () => {
    setOpen(true);
  };

  const handleButtonClick = () => {
    setOpen(!open);
  };

  const onSubmit = (values: ExpenseMainFormValues) => {
    const reqBody = getRequestPayload(values, expenseReportDataById, totalCash, totalCharge);
    dispatch(updateExpenseReportStart(reqBody as unknown as ExpenseMainFormAPIValues));
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    const payload = {
      id: expenseId,
    };
    dispatch(getExpenseReportById(payload));
    return () => {
      dispatch(clearExpenseReportData());
    };
  }, []);

  useEffect(() => {
    if (expenseReportDataById) {
      setValues(expenseReportDataById, setValue);
    }
  }, [expenseReportDataById]);

  const onCashChange = (name: string, totalName: string, value: number, onChange: (...event: any[]) => void) => {
    if (!roundExpenseRegex.test(value.toString())) {
      return;
    }
    const cashValue = Number(getValues(name as keyof ExpenseMainFormValues));
    const totalValue = Number(getValues(totalName as keyof ExpenseMainFormValues));
    setValue(name as keyof ExpenseMainFormValues, value);
    setValue(totalName as keyof ExpenseMainFormValues, totalValue - cashValue + value);
    onChange(value);
  };

  const onChargeChange = (name: string, totalName: string, value: number, onChange: (...event: any[]) => void) => {
    if (!roundExpenseRegex.test(value.toString())) {
      return;
    }
    const chargeValue = Number(getValues(name as keyof ExpenseMainFormValues));
    const totalValue = Number(getValues(totalName as keyof ExpenseMainFormValues));
    setValue(name as keyof ExpenseMainFormValues, value);
    setValue(totalName as keyof ExpenseMainFormValues, totalValue - chargeValue + value);
    onChange(value);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="div_sites_container">
          <Card>
            <div className="customerInfo_container">
              <div className="customerInfo">
                Expense report
              </div>
              <div className="customer_info_checkbox" style={{ maxWidth: '800px' }}>
                <div className="customer_info_header_checkbox mobileInfoBox">
                  <CustomCheckbox
                    name={ExpenseFormEnum.incomplete}
                    control={control}
                    isDisable
                    label="Incomplete"
                  />
                </div>
                <div className="dropdown_side_label">
                  Date
                </div>
                <ComponentTextInput
                  isDisable
                  control={control}
                  errors={errors}
                  name={ExpenseFormEnum.date}
                  size="small"
                  id={ExpenseFormEnum.date}
                />
                {/* <div className="dropdown_container"> */}
                <div className="dropdown_side_label">
                  Analyst
                </div>
                <ComponentTextInput
                  isDisable
                  control={control}
                  errors={errors}
                  name={ExpenseFormEnum.analyst}
                  size="small"
                  id={ExpenseFormEnum.analyst}
                />
                <div className="dropdown_side_label" style={{ width: '380px' }}>
                  Job Order #
                </div>
                <ComponentTextInput
                  isDisable
                  control={control}
                  name={ExpenseFormEnum.jobOrder}
                  id={ExpenseFormEnum.jobOrder}
                  size="small"
                  className="mob_mr_12"
                />
              </div>
            </div>
            <TableContainer>
              <Table sx={{ minWidth: 900 }}>
                <TableHead style={{ background: '#e5f6ed' }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: '139px' }}>
                      Expense
                    </TableCell>
                    <TableCell align="left" style={{ width: '265px' }}>
                      Cash
                    </TableCell>
                    <TableCell align="left" style={{ width: '265px' }}>
                      Charge
                    </TableCell>
                    <TableCell align="left">
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    expenseData().map((vessel) => (
                      <TableRow key={vessel?.id}>
                        <TableCell align="left" style={{ background: '#e5f6ed' }}>
                          {vessel.vesselName}
                        </TableCell>
                        <TableCell align="left">
                          <Controller
                            control={control}
                            name={vessel.cashName as keyof ExpenseMainFormValues}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                size="small"
                                fullWidth
                                type="number"
                                onChange={(e) => onCashChange(vessel.cashName, vessel.totalName, Number(e.target.value), onChange)}
                                value={String(value)}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Controller
                            control={control}
                            name={vessel?.chargeName as keyof ExpenseMainFormValues}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                size="small"
                                type="number"
                                fullWidth
                                onChange={(e) => onChargeChange(vessel.chargeName, vessel.totalName, Number(e.target.value), onChange)}
                                value={String(value)}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Controller
                            control={control}
                            name={vessel?.totalName as keyof ExpenseMainFormValues}
                            render={({ field: { value } }) => (
                              <TextField
                                size="small"
                                disabled
                                value={value && Number(value)?.toFixed(2)}
                              />
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  }
                  <TableRow>
                    <TableCell align="center" style={{ background: '#e5f6ed' }}>
                      Total
                    </TableCell>
                    <TableCell align="center">
                      {`$${Number(totalCash).toFixed(2)}`}
                    </TableCell>
                    <TableCell align="center">
                      $
                      {Number(totalCharge).toFixed(2)}
                    </TableCell>
                    <TableCell align="left">
                      $
                      {Number(totalCash + totalCharge).toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Card style={{ marginTop: '20px' }}>
            <div className="customerInfo_container">
              <div className="customerInfo">
                {' '}
              </div>
            </div>
            <div className="customerInfo_main_container">
              <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Misc Description
                    </label>
                    <ComponentTextInput
                      name={ExpenseFormEnum.miscDescription}
                      id={ExpenseFormEnum.miscDescription}
                      control={control}
                      inputProps={{ maxLength: 500 }}
                      size="small"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div" style={{ marginTop: '20px' }}>
              <div>
                <Button className="button_cancel" onClick={() => navigate(`/${EndPoints.EXPENSE_REPORTS}`)}>Cancel</Button>
              </div>
              <div className="button_margin_left">
                <Button
                  className="button_save_and_next"
                  onClick={handleAction}
                >
                  Scanned Receipts
                </Button>
              </div>
              <div className="button_margin_left">
                <Button
                  type="submit"
                  className="button_save_and_next"
                >
                  Save
                </Button>
              </div>
            </div>
          </Grid>
        </div>
      </form>
      <ComponentScannedReceipts show={open} setOpenFrom={handleButtonClick} jobOrderId={expenseReportDataById?.JobOrder} />
    </>
  );
}

export default ScreenExpense;
