/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useContext, useEffect,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { TypesDialogBox } from '../../../../utils/interface';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import { RootState } from '../../../../redux/rootState';
import {
  PriceLIstData, SchemaPriceList, UpdatePriceListFormData, UpdateSystemPriceData,
} from '../utils/TypesPriceList';
import { getSystemPriceStart, resetStateData, updateSystemPriceList } from '../Redux/slicePriceList';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { PriceListsFormValue } from '../utils/ConstantsPriceList';
import { priceListSchema } from '../utils/validation';
import { RegexFiveWith2D } from '../../../../utils/regex';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);
function ComponentPriceEditDialog({ show, setOpenFrom, rowId }: TypesDialogBox): JSX.Element {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const {
    handleSubmit, setValue, control, formState: { errors }, reset,
  } = useForm<UpdateSystemPriceData>({
    resolver: yupResolver(priceListSchema as ObjectSchema<SchemaPriceList>),
  });
  const { SystemPriceList, isSuccess, tableStaticData } = useSelector((state: RootState) => state.SystemPriceListReducer);

  const tableData: PriceLIstData[] = SystemPriceList?.Data?.map((item, index) => {
    const itemIndex = index;
    const transformedDataItem = {
      sn: itemIndex + 1,
      action: 'edit',
      KitDescription: item?.KitDescription,
      MeasurementType: item?.MeasurementType,
      Price: item?.Price,
      FixedPrice: item?.FixedPrice,

      TubeAvailable: item?.TubeAvailable,
      Quantity: item?.Quantity,
      id: String(item?.Id),
    };
    return transformedDataItem;
  });

  useEffect(() => {
    if (rowId) {
      tableData.forEach((rowData) => {
        if (rowData.id === rowId) {
          setValue(PriceListsFormValue.KTDESCRIPTION, rowData.KitDescription);
          setValue(PriceListsFormValue.PRICE, rowData.Price);
          setValue(PriceListsFormValue.QUANTITY, rowData.Quantity);
        }
      });
    }
  }, [rowId, show]);

  const onSubmit = (data: UpdateSystemPriceData) => {
    const bodyData: UpdatePriceListFormData = {
      id: rowId,
      description: data.KitDescription,
      price: Number(data.Price),
      tubeQuantity: Number(data.Quantity),
    };

    dispatch(updateSystemPriceList(bodyData));
    setOpenFrom();
  };

  const handleClose = () => {
    setOpenFrom();
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      const params = {
        page: tableStaticData?.page,
        rowsPerPage: tableStaticData?.rowsPerPage,
        searchQuery: tableStaticData?.searchQuery,
      };
      dispatch(getSystemPriceStart(params));
      dispatch(resetStateData());
    }
  }, [isSuccess]);

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="modalHeaderTitle">
          {translations.priceListEdit}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">{translations.description}</label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={PriceListsFormValue.KTDESCRIPTION}
                    size="small"
                    id={PriceListsFormValue.KTDESCRIPTION}
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    {translations.price}
                    {' '}
                    ($)
                  </label>
                  <ComponentTextInput
                    control={control}
                    name={PriceListsFormValue.PRICE}
                    size="small"
                    regEx={RegexFiveWith2D}
                    id={PriceListsFormValue.PRICE}
                    errors={errors}
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'end' },
                    }}
                  />
                </div>
              </Grid>

              {tableData?.map((items) => (
                items.TubeAvailable === true && items.id === rowId
                  ? (
                    <Grid item md={6} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">{translations.minTubeQty}</label>
                        <ComponentTextInput
                          control={control}
                          name={PriceListsFormValue.QUANTITY}
                          size="small"
                          inputProps={{ min: 0 }}
                          id={PriceListsFormValue.QUANTITY}
                          errors={errors}
                        />
                      </div>
                    </Grid>
                  ) : ''
              ))}

              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={handleClose}>{translations.cancel}</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">{translations.save}</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ComponentPriceEditDialog;
