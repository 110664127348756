import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';
import { ExpenseReports } from '../../types';

function ComponentExpenseAnalystTable({ expenseAnalystTable }: { expenseAnalystTable: ExpenseReports }) {
  const { reportPdfResponse4, themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const expenseResponse = expenseAnalystTable;
  const { BorderColor, MainColor } = themePdfColor;
  return (
    <div style={{ marginTop: '20px' }}>
      <table className="bordered-table w100">
        <div
          className="tableAfterThickBorderStyle"
          style={{
            backgroundColor: BorderColor,
          }}
        />
        <div
          className="tableBeforeThickBorderStyle"
          style={{
            backgroundColor: BorderColor,
          }}
        />
        <tr>
          <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
            Analyst
          </td>
          <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
            Total Cash Due
          </td>
          <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
            Tot. Charges
          </td>
          <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
            Tot. Expenses
          </td>
        </tr>
        <tr>
          <td className="textCenter">{reportPdfResponse4?.JobOrderData?.AnalystName}</td>
          <td className="textCenter">{expenseResponse?.TotalCash?.TotalCash}</td>
          <td className="textCenter">{expenseResponse?.TotalCheque?.TotalCheque}</td>
          <td className="textCenter">
            {Number(expenseResponse?.TotalCash?.TotalCash) + Number(expenseResponse?.TotalCheque?.TotalCheque)}
          </td>
        </tr>
      </table>
    </div>
  );
}

export default ComponentExpenseAnalystTable;
