import React from 'react';
import { useSelector } from 'react-redux';
import styles from './CompanyInfo.module.css';
import { RootState } from '../../../../../../redux/rootState';

function CompanyInfo() {
  const {
    customerAlert, smc, companyOtherCity, bidAlert, bidAlertNote, customerNote, markup,
    companyName, customerName, address, companyState, companyZip,
    companyCountryCode, companyPhone, companyExt, companyCountry, companyAddress,
  } = useSelector(
    (state: RootState) => state.addCustomerReducer.formData,
  );
  const {
    markups, countries,
  } = useSelector((state: RootState) => state.masterDataReducer);
  const selectedCountry = countries?.find((item) => item?.Value === companyCountry)?.Text;
  const selectedMarkupLabel = markups?.find((item) => item?.Value === String(markup))?.Text;

  const formatBoolean = (value: boolean) => (value ? 'Yes' : 'No');
  return (
    <div className={styles.companyInfoSection}>
      <h2 className={styles.heading}>Company Information</h2>
      <div className={styles.section}>
        <div className={styles.innerSection}>
          <label>Customer Name: </label>
          {' '}
          {customerName}
        </div>
        <div className={styles.innerSection}>
          <label>Address: </label>
          {' '}
          {address}
        </div>
        <div className={styles.innerSection}>
          <label>Address (Optional): </label>
          {' '}
          {companyAddress}
        </div>
        <div className={styles.innerSection}>
          <label>Phone: </label>
          {' '}
          {companyPhone ? (
            <>
              {companyCountryCode}
              {' '}
              {companyPhone}
            </>
          ) : (
            <span />
          )}
        </div>
        <div className={styles.innerSection}>
          <label>Ext: </label>
          {' '}
          {companyExt}
        </div>
        <div className={styles.innerSection}>
          <label>Country: </label>
          {' '}
          {selectedCountry}
        </div>
        <div className={styles.innerSection}>
          <label>Zip: </label>
          {' '}
          {companyZip}
        </div>
        <div className={styles.innerSection}>
          <label>State: </label>
          {' '}
          {companyState}
        </div>
        <div className={styles.innerSection}>
          <label>City: </label>
          {' '}
          {companyOtherCity}
        </div>
        <div className={styles.innerSection}>
          <label>Company Name (SMC): </label>
          {' '}
          {companyName}
        </div>
        <div className={styles.innerSection}>
          <label>Bid Alert Notes: </label>
          {' '}
          {bidAlertNote}
        </div>
        <div className={styles.innerSection}>
          <label>Customer Alert Notes: </label>
          {' '}
          {customerNote}
        </div>
        <div className={styles.innerSection}>
          <label>Customer Alert: </label>
          {' '}
          {formatBoolean(customerAlert)}
        </div>
        <div className={styles.innerSection}>
          <label>SMC: </label>
          {' '}
          {formatBoolean(smc)}
        </div>
        <div className={styles.innerSection}>
          <label>Bid Alert: </label>
          {' '}
          {formatBoolean(bidAlert)}
        </div>
        <div className={styles.innerSection}>
          <label>Markup: </label>
          {' '}
          {selectedMarkupLabel}
        </div>
      </div>
    </div>
  );
}

export default CompanyInfo;
