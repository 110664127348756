import { formatDate, stripCustPrefix } from '../../../../../utils/CommonFunctions';
import { FreonCylinderListData, KitData } from './TypesFreonCylinderList';

export function formatTableData(data: KitData[]) {
  const res: FreonCylinderListData[] = [];

  data.forEach((col: KitData, index) => {
    const obj = {
      id: '',
      sn: index,
      action: 'edit&delete&invoice',
      KitId: '',
      numberOfKit: '',
      custId: '',
      customerName: '',
      purchaseOrderNumber: '',
      shipDate: '',
      billAmount: '',
      billed: true,
      shipVia: '',
      createdBy: '',
      isChecked: false,
    };

    obj.id = col.KitId;
    obj.KitId = col.KitId;
    obj.numberOfKit = col.NumberOfKit;
    obj.custId = stripCustPrefix(col.CustId);
    obj.customerName = col.CustomerName;
    obj.purchaseOrderNumber = col.PurchaseOrderNumber;
    obj.shipDate = formatDate(col.ShipDate);
    obj.billAmount = `$ ${col.BillAmount}`;
    obj.billed = col.Billed;
    obj.shipVia = col.ShipVia;
    obj.createdBy = col.CreatedBy;

    res.push(obj);
  });

  return res;
}
