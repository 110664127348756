/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import React, { useEffect, useMemo, useState } from 'react';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Pagination,
  Box,
  Select,
  MenuItem,
  Stack,
  Radio,
  TableContainer,
  Paper,
  Button,
  Tooltip,
  IconButton,
} from '@mui/material';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
// eslint-disable-next-line import/no-cycle
import SearchHeader from '../SearchHeader';
import styles from './index.module.scss';
import {
  DeleteIcon, EditIcon, LinkOff, CheckedIcon, SelectedCheckBoxIcon, AddIcon, ScheduleIcon, InvoiceIcon, ViewOnlyIcon,
} from '../../assets/TableIcons';
import NoData from '../../assets/NoData.svg';
import { Empty } from '../../Screens/ScreenAddSmc/Utils/TypeSmc';
import { convertDateFormat, getItemIndex } from '../../utils/CommonFunctions';
import { IssueHistoryIcon } from '../../assets/TableIcons/IssueHistoryIcon';
import { SendMailIcon } from '../../assets/TableIcons/SendMailIcon';
import { Entries } from '../../Screens/InventoryManagement/InventoryLandingPage/redux/type';
import { ViewIcon } from '../../assets/TableIcons/eyeIcon';
import { CloseIcon } from '../../assets/TableIcons/CloseIcon';

interface TableColumn {
  key: string;
  label: string;
  content?: JSX.Element;
}

interface ListingTableProps<T> {
  columns: TableColumn[]; // columnsData
  data: T[]; // Rows Data
  totalRecords: number; // Total Records
  buttonText?: string; // Text on Header button
  handleButtonClick?: () => void; // Header Button OnCLick
  page?: number; // Current Page
  setPage?: (page: number) => void; // SetCurrent Page
  rowsPerPage?: number; // No if rows to be shown
  setRowsPerPage?: (rowsPerPage: number) => void; // Set no of rowsPerPage
  handleData: () => void; // Function which fetch data of rows from api
  searchQuery?: string; // Search query string
  setSearchQuery?: (search: string) => void; // Change search Query
  handleEdit?: (id: string) => void; // Edit Icon OnClick Function
  handleReportEdit?: (id: string, key: string) => void;
  handleViews?: (id: string) => void;
  handleGenerateInvoice?: (id: string) => void // Generate Invoice Function
  handleDelete?: (id: string) => void; // Delete Icon Onclick Function
  handleCheckBox?: (id: string) => void // CheckBox OnClick Function
  checkBoxColumns?: string[] // Array of Columns That contain checkBoxColumns
  handleLinkOff?: (id: string) => void; // LinkOff Icon Onclick function
  handleTabCell?: (id: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void; // Table cell OnClick Function
  tableHeader?: string;
  labelOne?: string;
  labelTwo?: string;
  valueOne?: number;
  valueTwo?: string;
  dateRange?: string;
  handleDateRangeChange?: (value: DateRange) => void;
  dateRangeData?: DateRange | null;
  radioButtonColumns?: string[];
  selectedRadio?: string;
  setSelectedRadio?: (val: string) => void;
  numberOfUnits?: string;
  setNumberOfUnits?: (units: string) => void;
  // numberUnits?: string;
  checkBoxWithKey?: string[];
  handleCheckBoxWithKey?: (id: string) => void;
  searchButton?: string;
  handleSearchButtonClick?: () => void;
  customClassName?: string;
  handleAdd?: (id: string, key: string) => void;
  handleScheduleClick?: (row: T) => void; // LinkOff Icon Onclick function
  searchButtonDisable?: boolean;
  radioButtonColumnsWithKey?: string[]
  selectedRadioWithKey?: string;
  setSelectedRadioWithKey?: (val: string) => void;
  htmlColumnsWithKey?: string[];
  issueButton?: string;
  handleIssueButton?: () => void;
  issueButtonDisable?: boolean;
  equipmentTypeSelectLabel?: string;
  setSelectedEquipmentType?: (value: string) => void;
  selectedEquipmentType?: Entries[]
  inventoryTypeSelectLabel?: string;
  setSelectedInventory?: (value: string) => void;
  selectedInventory?: Entries[];
  selectedEquipmentTypeValue?: string;
  selectedInventoryValue?: string;
  handleIssueHistory?: (id: string) => void;
  statusColumnsWithKey?: string[];
  rowId?: string;
  textButtonDisabled?: boolean;
  generateInvoiceButton?: string;
  handleGenerateInvoiceButton?: () => void;
  generateInvoiceButtonDisabled?: boolean;
  handleView?: (id: string) => void;
  selectedRowIdWithColor?: string;
  fiveRecordPerPage?: boolean;
  handlePrintIcon?: () => void;
  disableHandlePrintIcon?: boolean;
  handleDataFromComponent?: boolean;
  handleOilReportEmailModal?: (id: string) => void;
  handleActiveDeactivate?: (id: string) => void;
  handleButtonClickFifth?: () => void;
  buttonNameFifth?: string;
  filter?: boolean;
  handleEmail?: () => void;
  handleClose?: (id: string) => void;
  mailIconDisabled?: boolean;
}

function DataTable<T extends {
  viewOnly?: boolean;
  analystAction?: Empty; isChecked?: boolean, id: string, kitno?: Empty, kits?: string, reTest?: boolean, IsReportPresent?: boolean,
}>({
  columns, data, totalRecords, page, setPage, rowsPerPage, setRowsPerPage, handleData, searchQuery, setSearchQuery,
  handleDelete, handleCheckBox, checkBoxColumns, handleLinkOff, handleTabCell, handleDateRangeChange,
  dateRangeData, radioButtonColumns, selectedRadio, setSelectedRadio,
  tableHeader, buttonText, handleButtonClick, handleEdit, checkBoxWithKey, handleCheckBoxWithKey, searchButton,
  handleSearchButtonClick, numberOfUnits, setNumberOfUnits, customClassName, handleAdd, handleScheduleClick, searchButtonDisable,
  radioButtonColumnsWithKey, selectedRadioWithKey, setSelectedRadioWithKey, handleGenerateInvoice, htmlColumnsWithKey,
  issueButton, handleIssueButton, issueButtonDisable, equipmentTypeSelectLabel, setSelectedEquipmentType, selectedEquipmentType,
  inventoryTypeSelectLabel, setSelectedInventory, selectedInventory, selectedEquipmentTypeValue,
  selectedInventoryValue, handleIssueHistory, statusColumnsWithKey, rowId, textButtonDisabled,
  generateInvoiceButton, handleGenerateInvoiceButton, generateInvoiceButtonDisabled, handleView, selectedRowIdWithColor,
  fiveRecordPerPage = false, handlePrintIcon, disableHandlePrintIcon, handleDataFromComponent, handleOilReportEmailModal,
  handleActiveDeactivate, handleButtonClickFifth, buttonNameFifth, handleViews, handleReportEdit, filter = false, handleEmail, handleClose,
  mailIconDisabled, ...props
}: ListingTableProps<T>) {
  const [sortBy, setSortBy] = useState<keyof T | string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');

  function handleSort(key: string) {
    if (sortBy === key) {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else if (sortOrder === 'desc') {
        setSortOrder('');
        setSortBy('');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortBy(key);
      setSortOrder('asc');
    }
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (setPage) {
      setPage(value);
    }
  };

  useEffect(() => {
    if (handleDataFromComponent) {
      return;
    }
    handleData();
  }, [page, rowsPerPage]);

  useEffect(() => {
    // This will run when there is only one record in the listing and the user deletes that record.
    if (page && setPage && rowsPerPage && searchQuery === '' && totalRecords) {
      const currentRecords = (page - 1) * rowsPerPage;
      if (totalRecords > currentRecords) {
        return;
      }
      setPage(page - 1);
    }
  }, [totalRecords]);

  useEffect(() => {
    if (handleDataFromComponent) {
      return;
    }
    if (setPage) {
      setPage(1);
    }
    handleData();
  }, [searchQuery]);

  function sortData(list: T[]) {
    if (!sortBy) return list;

    const sortedData = [...list].sort((a, b) => {
      const valueA = a[sortBy as keyof T];
      const valueB = b[sortBy as keyof T];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        if (sortOrder === 'asc') {
          return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
        }
        return valueB.localeCompare(valueA, undefined, { sensitivity: 'base' });
      } if (typeof valueA === 'number' && typeof valueB === 'number') {
        if (sortOrder === 'asc') {
          return valueA - valueB;
        }
        return valueB - valueA;
      }

      // Fallback comparison for other types
      return 0;
    });
    return sortedData;
  }

  const sortedData = useMemo(() => sortData(data), [data, sortOrder, sortBy]);

  const totalPages = Math.ceil(totalRecords / (rowsPerPage || 1));

  const handlePreviousClick = (event: React.ChangeEvent<unknown>) => {
    if (page) {
      handleChange(event, page - 1);
    }
  };

  const handleNextClick = (event: React.ChangeEvent<unknown>) => {
    if (page) {
      handleChange(event, page + 1);
    }
  };

  const getSearchHeader = () => {
    if (buttonText && setSearchQuery) {
      return (
        <SearchHeader
          handlePrintIcon={handlePrintIcon}
          buttonName={buttonText}
          searchButtonDisable={searchButtonDisable}
          labelOne={props.labelOne}
          labelTwo={props.labelTwo}
          valueOne={props.valueOne}
          valueTwo={props.valueTwo}
          dateRange={props.dateRange}
          searchButton={searchButton}
          handleButtonClick={handleButtonClick}
          searchQuery={(searchQuery || '')}
          setSearchQuery={setSearchQuery}
          handleSearchButtonClick={handleSearchButtonClick}
          handleDateRangeChange={handleDateRangeChange}
          dateRangeData={dateRangeData}
          numberOfUnits={numberOfUnits}
          setNumberOfUnits={setNumberOfUnits}
          issueButton={issueButton}
          handleIssueButton={handleIssueButton}
          issueButtonDisable={issueButtonDisable}
          equipmentTypeSelectLabel={equipmentTypeSelectLabel}
          setSelectedEquipmentType={setSelectedEquipmentType}
          selectedEquipmentType={selectedEquipmentType}
          inventoryTypeSelectLabel={inventoryTypeSelectLabel}
          setSelectedInventory={setSelectedInventory}
          selectedInventory={selectedInventory}
          selectedEquipmentTypeValue={selectedEquipmentTypeValue}
          selectedInventoryValue={selectedInventoryValue}
          textButtonDisabled={textButtonDisabled}
          generateInvoiceButton={generateInvoiceButton}
          handleGenerateInvoiceButton={handleGenerateInvoiceButton}
          generateInvoiceButtonDisabled={generateInvoiceButtonDisabled}
          disableHandlePrintIcon={disableHandlePrintIcon}
          handleButtonClickFifth={handleButtonClickFifth}
          buttonNameFifth={buttonNameFifth}
          filter={filter}
          handleEmail={handleEmail}
          mailIconDisabled={mailIconDisabled}
        />
      );
    }
    if (setSearchQuery) {
      return (
        <SearchHeader
          searchQuery={(searchQuery || '')}
          setSearchQuery={setSearchQuery}
          rowId={rowId}
          filter={filter}
          handleEmail={handleEmail}
          mailIconDisabled={mailIconDisabled}
        />
      );
    }
    if (buttonText) {
      return (
        <SearchHeader
          buttonName={buttonText}
          handleButtonClick={handleButtonClick}
          filter={filter}
          handleEmail={handleEmail}
          mailIconDisabled={mailIconDisabled}
        />
      );
    }
    return null;
  };

  const getStatusClass = (key: string) => {
    if (key === 'Available') {
      return styles.statusColumnsWithKeyStyle;
    }
    return styles.redStatusColumnsWithKeyStyle;
  };

  const toolTipTitle = (toolTipData: Empty, key: string, TooltipTitle: string) => {
    let tooltipMainTitle = '';
    if (TooltipTitle === 'Serial #') {
      tooltipMainTitle = 'Serial Number';
    } else if (TooltipTitle === 'Model') {
      tooltipMainTitle = 'Model Number';
    } else if (TooltipTitle === 'Vessel') {
      tooltipMainTitle = 'Vessel';
    } else if (TooltipTitle === 'Equipment') {
      tooltipMainTitle = 'Equipment';
    } else {
      tooltipMainTitle = '';
    }
    if (toolTipData && toolTipData[key]?.length === 0) {
      return '';
    }

    return (
      <div className="customTooltip">
        <div className="customTooltipHeading">{tooltipMainTitle}</div>
        {toolTipData && toolTipData[key]?.map((ele: string, index: number) => (
          <div className="customTooltipList">
            <div className="customTooltipNumber">{`${index + 1}.`}</div>
            <div className="customTooltipContent">{ele}</div>
          </div>
        ))}
      </div>
    );
  };

  const analystActionTipTitle = (toolTipData: Empty) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="customTooltipList">{`Analyst Name - ${toolTipData?.analystAction?.AnalystName}`}</div>
      <div className="customTooltipList">{`Start Date - ${convertDateFormat(toolTipData?.analystAction?.StartDate)}`}</div>
      <div className="customTooltipList">{`End Date - ${convertDateFormat(toolTipData?.analystAction?.EndDate)}`}</div>
    </div>
  );

  const showViewIcon = (key: string, rowData: T) => {
    if (key?.includes('view')) {
      if (rowData?.viewOnly) {
        return (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (handleView) {
                handleView(rowData?.id);
              }
            }}
            className="mr15"
          >
            <ViewOnlyIcon />
          </span>
        );
      }
      return (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (handleEdit) {
              handleEdit(rowData?.id);
            }
          }}
          className="mr15"
        >
          <EditIcon />
        </span>
      );
    }
    return null;
  };

  const getPagination = () => {
    if (sortedData?.length === 0) {
      return (
        <div className={styles.NoData}><img src={NoData} alt="No data found" /></div>
      );
    }
    if (setRowsPerPage && rowsPerPage) {
      return (
        <div className="table-pagination-style">
          <div className="table-pagination-number-per-page">
            <span className="font-size">Show</span>
            <span className="select-style">
              <Select
                value={rowsPerPage}
                className="table-select-style"
                onChange={(e) => {
                  // This Will Redirect To First Page When rowsPerPage Count Will be Change.
                  if (setPage) {
                    setPage(Number(1));
                  }
                  setRowsPerPage(Number(e.target.value));
                }}
              >
                {fiveRecordPerPage && <MenuItem value={5}>5</MenuItem>}
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </span>
            <span className="font-size">entries</span>
          </div>
          <Stack spacing={2} className="table-pagination-number">
            <Button
              variant="outlined"
              color="primary"
              disabled={page === 1}
              onClick={handlePreviousClick}
              className="previousBtnPagination"
            >
              Previous
            </Button>
            <Pagination
              count={Math.ceil(totalRecords / rowsPerPage)}
              page={page}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
              className="pagination"
              hidePrevButton
              hideNextButton
            />
            <Button
              variant="outlined"
              color="primary"
              disabled={page === totalPages}
              onClick={handleNextClick}
              className="nextBtnPagination"
            >
              Next
            </Button>

          </Stack>
        </div>
      );
    }
    return null;
  };

  // HIGHLIGHTING CURRENT CLICKED ROW
  const getRowClass = (id: string) => {
    if (selectedRowIdWithColor && selectedRowIdWithColor === id) {
      return styles.selectedRowColorClass;
    }
    return '';
  };

  return (
    <Box>
      <div className="space_between_row">
        {
          tableHeader ? (
            <div className="Bid_pervious">
              {tableHeader}
            </div>
          ) : ''
        }
        {getSearchHeader()}
      </div>
      <TableContainer component={Paper} className={`tableBody ${customClassName || ''}`}>
        <Table className={styles.common_data_table}>
          <TableHead className={styles.tableHeader}>
            <TableRow>
              {columns?.map((column: TableColumn) => (
                <TableCell key={column.key} style={{ background: '#e5f6ed', zIndex: 0 }}>
                  {column.label.includes('Action') ? (
                    column.label
                  ) : (
                    <TableSortLabel
                      active={sortBy === column.key}
                      direction={sortBy === column.key ? (sortOrder || 'asc') : 'asc'}
                      onClick={() => handleSort(column.key)}
                    >
                      {column.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row: T) => (
              // eslint-disable-next-line max-len
              <TableRow className={getRowClass(row.id)} onClick={(event) => { if (handleTabCell) { handleTabCell(row.id, event); } }} key={Math.random()} style={{ cursor: handleTabCell ? 'pointer' : 'default' }}>
                {columns.map((column: TableColumn) => {
                  // Action table row
                  if (column.key === 'action') {
                    return (
                      // eslint-disable-next-line max-len
                      <TableCell key={`${column.key} ${Math.floor(Math.random() * 900) + 100}`} sx={{ justifyContent: 'space-around' }}>
                        {String(row[column.key as keyof T])?.includes('invoice') && (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (handleGenerateInvoice) {
                                handleGenerateInvoice(row.id);
                              }
                            }}
                            className="mr15"
                          >
                            <Tooltip
                              placement="top"
                              title="Generate Invoice"
                              arrow
                            >
                              <IconButton style={{ paddingLeft: '0px' }}>
                                <InvoiceIcon />
                              </IconButton>
                            </Tooltip>
                          </span>
                        )}
                        {String(row[column.key as keyof T])?.includes('eye') && (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (handleViews) {
                                handleViews(row.id);
                              }
                            }}
                            className="mr15"
                          >
                            <ViewIcon />
                          </span>
                        )}
                        {String(row[column.key as keyof T])?.includes('edit') && (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (handleEdit) {
                                handleEdit(row.id);
                              }
                            }}
                            className="mr15"
                          >
                            <EditIcon />
                          </span>
                        )}
                        {String(row[column.key as keyof T])?.includes('close') && (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (handleClose) {
                                handleClose(row.id);
                              }
                            }}
                            className="mr15"
                          >
                            <CloseIcon />
                          </span>
                        )}
                        {String(row[column.key as keyof T])?.includes('activeDeactivate') && (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (handleActiveDeactivate) {
                                handleActiveDeactivate(row.id);
                              }
                            }}
                            className="mr15"
                          >
                            <NoAccountsIcon style={{ color: row['status' as keyof T] === 'Active' ? 'rgb(240, 101, 72)' : 'rgb(0, 167, 81)' }} />
                          </span>
                        )}
                        {showViewIcon(String(row[column.key as keyof T]), row)}
                        {String(row[column.key as keyof T])?.includes('delete') && (
                          <span
                            className="delete-icon-class"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (handleDelete) {
                                handleDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon />
                          </span>
                        )}
                        {String(row[column.key as keyof T])?.includes('issueHistory') && (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (handleIssueHistory) {
                                handleIssueHistory(row.id);
                              }
                            }}
                            className="mr15"
                          >
                            <Tooltip
                              placement="top"
                              title="Issue History"
                              arrow
                            >
                              <IconButton style={{ marginLeft: '10px' }}>
                                <IssueHistoryIcon />
                              </IconButton>
                            </Tooltip>
                          </span>
                        )}
                        {String(row[column.key as keyof T])?.includes('sendMail') && (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (handleOilReportEmailModal) {
                                handleOilReportEmailModal(row.id);
                              }
                            }}
                            className="mr15"
                          >
                            <Tooltip
                              placement="top"
                              title="Send Mail"
                              arrow
                            >
                              <IconButton style={{ marginLeft: '10px' }}>
                                <SendMailIcon />
                              </IconButton>
                            </Tooltip>
                          </span>
                        )}
                        {String(row[column.key as keyof T])?.includes('linkOff') && (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (handleLinkOff) {
                                handleLinkOff(row.id);
                              }
                            }}
                          >
                            <LinkOff />
                          </span>
                        )}
                        {String(row[column.key as keyof T])?.includes('schedule') && (
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (handleScheduleClick) {
                                handleScheduleClick(row);
                              }
                            }}
                          >
                            <Tooltip
                              placement="top"
                              title="Unit View"
                              arrow
                            >
                              <IconButton style={{ paddingLeft: '0px' }}>
                                <ScheduleIcon />
                              </IconButton>
                            </Tooltip>
                          </span>
                        )}
                      </TableCell>
                    );
                  }
                  if (column.key === 'reportAction') {
                    const { IsReportPresent } = row;
                    if (IsReportPresent) {
                      return (
                        // eslint-disable-next-line max-len
                        <TableCell key={`${column.key} ${Math.floor(Math.random() * 900) + 100}`} sx={{ justifyContent: 'space-around' }}>
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (handleReportEdit) {
                                handleReportEdit(row.id, column.key);
                              }
                            }}
                          >
                            <EditIcon />
                          </span>
                        </TableCell>
                      );
                    }
                    return (
                      // eslint-disable-next-line max-len
                      // <TableCell key={`${column.key} ${Math.floor(Math.random() * 900) + 100}`} sx={{ justifyContent: 'space-around' }}>
                      //   <span>
                      //     <DisabledAddIcon />
                      //   </span>
                      // </TableCell>
                      <TableCell key={`${column.key} ${Math.floor(Math.random() * 900) + 100}`} sx={{ justifyContent: 'space-around' }}>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (handleAdd) {
                              handleAdd(row.id, column.key);
                            }
                          }}
                        >
                          <AddIcon />
                        </span>
                      </TableCell>
                    );
                  }
                  if (column.key === 'analystAction') {
                    if (row[column.key as keyof T]) {
                      return (
                        // eslint-disable-next-line max-len
                        <TableCell key={`${column.key} ${Math.floor(Math.random() * 900) + 100}`} sx={{ justifyContent: 'space-around' }}>
                          <span
                            className="commonCenter pointer"
                            onClick={() => {
                              if (handleReportEdit) {
                                handleReportEdit(row.id, column.key);
                              }
                            }}
                          >
                            <span className="mr6"><EditIcon /></span>
                            <Tooltip
                              title={row.analystAction ? analystActionTipTitle(row) : ''}
                              placement="top"
                              arrow
                            >
                              <div className="tooltipMainTab">
                                Scheduled
                              </div>
                            </Tooltip>
                          </span>
                        </TableCell>
                      );
                    }
                    return (
                      // eslint-disable-next-line max-len
                      <TableCell key={`${column.key} ${Math.floor(Math.random() * 900) + 100}`} sx={{ justifyContent: 'space-around' }}>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (handleAdd) {
                              handleAdd(row.id, column.key);
                            }
                          }}
                        >
                          <AddIcon />
                        </span>
                      </TableCell>
                    );
                  }
                  if (column.key?.includes('ToolTip')) {
                    return (
                      // eslint-disable-next-line max-len
                      <TableCell key={`${column.key} ${Math.floor(Math.random() * 900) + 100}`}>
                        <Tooltip
                          title={toolTipTitle(row, column.key, column.label)}
                          // title={toolTipTitle(row, column.key, column.label)}
                          placement="top"
                          arrow
                        >
                          <div className="tooltipMainTab">
                            {column?.label}
                          </div>
                        </Tooltip>
                      </TableCell>
                    );
                  }
                  if (checkBoxColumns?.includes(column.key)) {
                    return (
                      // eslint-disable-next-line max-len
                      <TableCell key={`${column.key}_${Math.floor(Math.random() * 900) + 100}`}>
                        <label className="container_checkmark">
                          <input
                            type="checkbox"
                            checked={Boolean(row[column.key as keyof T])}
                            onChange={() => {
                              if (handleCheckBox) {
                                handleCheckBox(row.id);
                              }
                            }}
                          // className="p-0"
                          />
                          <span className="checkmark customCheckboxMiddle" />
                        </label>
                      </TableCell>
                    );
                  }

                  if (checkBoxWithKey?.includes(column.key)) {
                    return (
                      // eslint-disable-next-line max-len
                      <TableCell key={`${column.key}_${Math.floor(Math.random() * 900) + 100}`}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span
                            style={{ cursor: 'pointer', marginRight: '20px' }}
                            onClick={() => {
                              if (handleCheckBoxWithKey) {
                                handleCheckBoxWithKey(row?.id);
                              }
                            }}
                          >
                            {row?.isChecked ? <SelectedCheckBoxIcon /> : <CheckedIcon />}
                          </span>
                          {/* <p>{getItemIndex(page || 1, rowsPerPage || 10, Number(row[column.key as keyof T]))}</p> */}
                        </div>
                      </TableCell>
                    );
                  }

                  if (radioButtonColumns?.includes(column.key)) {
                    return (
                      // eslint-disable-next-line max-len
                      <TableCell key={`${column.key}_${Math.floor(Math.random() * 900) + 100}`}>
                        <Radio
                          checked={row.id === selectedRadio}
                          onClick={() => {
                            if (setSelectedRadio) {
                              setSelectedRadio(row.id);
                            }
                          }}
                          className="tableRadioBox"
                        />
                      </TableCell>
                    );
                  }
                  if (radioButtonColumnsWithKey?.includes(column.key)) {
                    return (
                      // eslint-disable-next-line max-len
                      <TableCell key={`${column.key}_${Math.floor(Math.random() * 900) + 100}`}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Radio
                            checked={row.id === selectedRadioWithKey}
                            onClick={() => {
                              if (setSelectedRadioWithKey) {
                                setSelectedRadioWithKey(row.id);
                              }
                            }}
                            className="tableRadioBox"
                          />
                          {/* <p style={{ marginLeft: '5px' }}>
                            {getItemIndex(page || 1, rowsPerPage || 10, Number(row[column.key as keyof T]))}
                            </p> */}
                        </div>
                      </TableCell>
                    );
                  }
                  if (htmlColumnsWithKey?.includes(column.key)) {
                    return (
                      // eslint-disable-next-line max-len
                      <TableCell key={`${column.key}_${Math.floor(Math.random() * 900) + 100}`}>
                        <div dangerouslySetInnerHTML={{ __html: String(row[column.key as keyof T]) }} />
                      </TableCell>
                    );
                  }
                  if (statusColumnsWithKey?.includes(column.key)) {
                    return (
                      // eslint-disable-next-line max-len
                      <TableCell key={`${column.key}_${Math.floor(Math.random() * 900) + 100}`}>
                        <div className={getStatusClass(String(row[column.key as keyof T]))}>
                          {String(row[column.key as keyof T])}
                        </div>
                      </TableCell>
                    );
                  }
                  if (column.key === 'sn') {
                    return (
                      <TableCell key={`${column.key}_${Math.floor(Math.random() * 900) + 100}`}>
                        <p>
                          {getItemIndex(page || 1, rowsPerPage || 10, Number(row[column.key as keyof T]))}
                        </p>
                      </TableCell>
                    );
                  }
                  return (
                    // eslint-disable-next-line max-len
                    <TableCell key={`${column.key}_${Math.floor(Math.random() * 900) + 100}`}>
                      {/* Old Line of Code  */}
                      {/* <p>{String(row[column.key as keyof T])}</p> */}
                      {typeof row[column.key as keyof T] === 'string' ? (
                      // Render as string
                        <p>{String(row[column.key as keyof T])}</p>
                      ) : typeof row[column.key as keyof T] === 'boolean' ? (
                      // Render as boolean
                        <p>{row[column.key as keyof T] ? 'True' : 'False'}</p>
                      ) : (
                      // Render as JSX element
                        row[column.key as keyof T] as React.ReactNode
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {getPagination()}
    </Box>
  );
}
export default DataTable;
