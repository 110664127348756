import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../Common/DataTable';
import { tempTubeChartLibraryData, TubeChartLibraryListColumn } from './utils/constants';
import { TubeChartLibraryListColumnType } from './utils/types';
import { formatTubeChartLibraryData } from './utils/helper';
import ComponentDeleteModal from '../../Common/DeleteModal/ComponentDeleteModal';
import { deleteTubeChartLibraryStart, getTubeChartImageStart, getTubeChartLibraryStart } from './redux/tubeChartLibrarySlice';
import { RootState } from '../../redux/rootState';
import { setLoader } from '../../redux/loaderSlice';

function ScreenTubeChartLibrary() {
  const dispatch = useDispatch();
  //   const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTubeChartId, setSelectedTubeChartId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRowIdWithColor, setSelectedRowIdWithColor] = useState('');

  const {
    isLoading, stripChartData, totalStripChart, tubeChartImage,
  } = useSelector((state: RootState) => state.TubeChartLibraryReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getTubeChartLibraryStart(params));
    setSelectedRowIdWithColor('');
  };

  const handleTabCell = (id: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event) {
      const target = event.target as HTMLElement;

      if (target.closest('.delete-icon-class')) {
        return;
      }
    }

    if (!showDeleteModal) {
      dispatch(getTubeChartImageStart({ id }));
      setSelectedRowIdWithColor(id);
    }
  };

  const handleDelete = (id: string) => {
    setShowDeleteModal(true);
    setSelectedTubeChartId(id);
  };

  const handelDeleteModal = () => {
    const paramData = {
      id: selectedTubeChartId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteTubeChartLibraryStart(paramData));
    setShowDeleteModal(false);
    setSelectedRowIdWithColor('');
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const showTubeChartImage = (newImage: string) => (
    <Grid container sx={{ marginTop: '0px', backgroundColor: '#FFFFFF' }}>
      <Grid item xs={12} sm={12} md={12}>
        <div style={{
          overflow: 'scroll',
          height: 'auto',
          width: '100%',
        }}
        >
          <img
            src={newImage}
            alt="Tube img"
            style={{
              width: 'auto',
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </div>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading="Tube Chart Library" />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className="div_job_container_info customBodyWithoutBtnContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={selectedRowIdWithColor ? 6 : 12} className="dataTableCustomMargin customDataTableStyles">
                <DataTable<TubeChartLibraryListColumnType>
                  data={tempTubeChartLibraryData?.Data?.length > 0 ? formatTubeChartLibraryData(stripChartData) : []}
                  totalRecords={totalStripChart}
                  columns={TubeChartLibraryListColumn}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleData={handleData}
                  handleDelete={handleDelete}
                  handleTabCell={handleTabCell}
                  selectedRowIdWithColor={selectedRowIdWithColor}
                />
              </Grid>
              {selectedRowIdWithColor && (
              <Grid item xs={12} sm={12} md={6}>
                {showTubeChartImage(tubeChartImage)}
              </Grid>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />
    </>
  );
}

export default ScreenTubeChartLibrary;
