import { ofType } from 'redux-observable';
import {
  catchError, filter, from, map, mergeMap, Observable, of, switchMap, takeUntil,
} from 'rxjs';

import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../../Common/Config';
import { makeGetRequest, makePostRequest } from '../../../../Common/NetworkOps';
import { GetById } from '../../../../utils/type';
import {
  getChartListSuccess,
  getTubeChartByIdFailure,
  getTubeChartByIdStart,
  getTubeChartDefectsFailure,
  getTubeChartDefectsStart,
  getTubeChartDefectsSuccess,
  getTubeChartsFailure, getTubeChartsStart, getTubeChartsSuccess, setSectionValues, setSelectedSection, TubeChartActions,
  updateTubeChartFailure,
  updateTubeChartScreenShotFailure,
  updateTubeChartScreenShotStart,
  updateTubeChartScreenShotSuccess,
  updateTubeChartStart,
  updateTubeChartSuccess,
} from './tubeChartSlice';
import {
  GetTubeChartPayload, TubeChartConfiguration, TubeChartDefects, TubeChartDefectsPayload, TubeConfiguration, UpdateTubeConfiguration,
} from '../../utils/tubeChartTypes';
import { sectionValues } from '../../utils/tubeChartConstant';

async function getTubeCharts(data: GetTubeChartPayload): Promise<GetById<TubeChartConfiguration[] | TubeConfiguration[]>> {
  const url = `${Config.vesselInformation.tubeChart.getTubeChart}?VesselId=${data.VesselId}&JobOrder=${data.JobOrder}&Type=${data.Type}`;
  const result = await makeGetRequest<GetById<TubeChartConfiguration[] | TubeConfiguration[]>>(url);
  return result.data;
}

export const getTubeChartsEpic = (action$: Observable<TubeChartActions>) => action$.pipe(
  ofType(getTubeChartsStart.type),
  map((x) => x.payload),
  switchMap((data: GetTubeChartPayload) => from(getTubeCharts(data)).pipe(
    mergeMap((res: GetById<TubeChartConfiguration[] | TubeConfiguration[]>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        if (data.Type === 2) {
          const chartList = res.BMT.Result as TubeConfiguration[];
          const selectedPanel = chartList[0]?.Panel;
          let updatedSectionValues = sectionValues;
          if (chartList.length === 1) {
            updatedSectionValues = sectionValues.filter((item) => item.Value === selectedPanel);
          }
          return of(getChartListSuccess(chartList), setSectionValues(updatedSectionValues), setSelectedSection(selectedPanel));
        }
        return of(getTubeChartsSuccess(res.BMT.Result as TubeChartConfiguration[]));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(getTubeChartsFailure());
    }),
    takeUntil(action$.pipe(filter(getTubeChartsStart.match))),
    catchError((error) => of(getTubeChartsFailure(error))),
  )),
);

async function getTubeChartById(chartId: string): Promise<GetById<TubeConfiguration>> {
  const url = `${Config.vesselInformation.tubeChart.getTubeChart}/${chartId}`;
  const result = await makeGetRequest<GetById<TubeConfiguration>>(url);
  return result.data;
}

export const getTubeChartByIdEpic = (action$: Observable<TubeChartActions>) => action$.pipe(
  ofType(getTubeChartByIdStart.type),
  map((x) => x.payload),
  switchMap((data: string) => from(getTubeChartById(data)).pipe(
    mergeMap((res: GetById<TubeConfiguration>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const panelName = res.BMT.Result.Panel;
        const updatedSectionValues = sectionValues.filter((item) => item.Value === panelName);
        return of(getChartListSuccess([res.BMT.Result]), setSectionValues(updatedSectionValues), setSelectedSection(panelName));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(getTubeChartByIdFailure());
    }),
    takeUntil(action$.pipe(filter(getTubeChartByIdStart.match))),
    catchError((error) => of(getTubeChartByIdFailure(error))),
  )),
);

async function getTubeChartDefects(data: TubeChartDefectsPayload): Promise<GetById<TubeChartDefects[]>> {
  // eslint-disable-next-line max-len
  const url = `${Config.vesselInformation.tubeChart.getTubeChartDefects}?JobOrder=${data.jobOrderId}&VesselId=${data.vesselid}&ChartId=${data.chartId}`;
  const result = await makeGetRequest<GetById<TubeChartDefects[]>>(url);
  return result.data;
}

export const getTubeChartDefectsEpic = (action$: Observable<TubeChartActions>) => action$.pipe(
  ofType(getTubeChartDefectsStart.type),
  map((x) => x.payload),
  switchMap((data: TubeChartDefectsPayload) => from(getTubeChartDefects(data)).pipe(
    map((res: GetById<TubeChartDefects[]>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getTubeChartDefectsSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(getTubeChartDefectsFailure());
    }),
    takeUntil(action$.pipe(filter(getTubeChartDefectsStart.match))),
    catchError((error) => of(getTubeChartDefectsFailure(error))),
  )),
);

async function updateTubeChart(data: UpdateTubeConfiguration): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.tubeChart.getTubeChart}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}

export const updateTubeChartEpic = (action$: Observable<TubeChartActions>) => action$.pipe(
  ofType(updateTubeChartStart.type),
  map((x) => x.payload),
  switchMap((data: UpdateTubeConfiguration) => from(updateTubeChart(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(updateTubeChartSuccess());
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(updateTubeChartFailure());
    }),
    takeUntil(action$.pipe(filter(updateTubeChartStart.match))),
    catchError((error) => of(updateTubeChartFailure(error))),
  )),
);

async function updateTubeChartWithScreenShot(data: UpdateTubeConfiguration): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.tubeChart.updateAddTubeChartToLibrary}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}

export const updateTubeChartScreenShotEpic = (action$: Observable<TubeChartActions>) => action$.pipe(
  ofType(updateTubeChartScreenShotStart.type),
  map((x) => x.payload),
  switchMap((data: UpdateTubeConfiguration) => from(updateTubeChartWithScreenShot(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(updateTubeChartScreenShotSuccess());
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(updateTubeChartScreenShotFailure());
    }),
    takeUntil(action$.pipe(filter(updateTubeChartScreenShotStart.match))),
    catchError((error) => of(updateTubeChartScreenShotFailure(error))),
  )),
);
