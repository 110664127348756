import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';

function BarChartTable() {
  const { reportPdfResponse1, themePdfColor } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;

  return (
    <table className="bordered-table w100 noTableBreak">
      <div
        className="tableAfterThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />
      <div
        className="tableBeforeThickBorderStyle"
        style={{
          backgroundColor: BorderColor,
        }}
      />
      <tr>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Location
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Model
        </td>
        <td className="tableGreenHeading textCenter boldText" style={{ backgroundColor: MainColor }}>
          Serial Number
        </td>
      </tr>
      <tr>
        <td className="textCenter">
          {reportPdfResponse1?.FrontPage?.Location?.split('\n').map((line, index) => (
            <React.Fragment key={`${line[0]}-${index + 1}`}>
                &nbsp;
              {line}
              <br />
            </React.Fragment>
          ))}
        </td>
        <td className="textCenter">{reportPdfResponse1?.JobOrderData?.Model}</td>
        <td className="textCenter">{`${reportPdfResponse1?.JobOrderData?.SerialNumber}`}</td>
      </tr>
      <tr>
        <td className="textCenter p10" colSpan={3}>
          Note: A graph indicating the number of tubes marked for each category will be generated when tubes are marked.
          Indications from previous inspections will be displayed as cross hatch bars.
        </td>
      </tr>
    </table>
  );
}

export default BarChartTable;
