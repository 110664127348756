import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationResponse } from '../../../utils/type';
import {
  DeleteTubeChartLibraryPayload,
  GetTubeChartLibraryListingPayload, TubeChartLibraryData,
  InitialStateTubeChartLibrary,
  GetTubeChartLibraryImagePayload,
} from '../utils/types';

const initialState: InitialStateTubeChartLibrary = {
  isLoading: false,
  stripChartData: [],
  totalStripChart: 0,
  tubeChartImage: '',
};

const TubeChartLibrarySlice = createSlice({
  name: 'TubeChartLibrarySlice',
  initialState,
  reducers: {
    getTubeChartLibraryStart(state, _action:PayloadAction<GetTubeChartLibraryListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getTubeChartLibrarySuccess(state, action:PayloadAction<PaginationResponse<TubeChartLibraryData>>) {
      return {
        ...state,
        isLoading: false,
        stripChartData: action.payload.Data,
        totalStripChart: action.payload.TotalRecords,
      };
    },

    getTubeChartLibraryFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    resetTubeChartLibraryData(state) {
      return {
        ...state,
        stripChartData: [],
      };
    },
    // ----Delete Tube Chart Library
    deleteTubeChartLibraryStart(state, _action: PayloadAction<DeleteTubeChartLibraryPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteTubeChartLibrarySuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteTubeChartLibraryFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getTubeChartImageStart(state, _action:PayloadAction<GetTubeChartLibraryImagePayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getTubeChartImageSuccess(state, action:PayloadAction<{ Image: string }>) {
      return {
        ...state,
        isLoading: false,
        tubeChartImage: action.payload.Image,
      };
    },

    getTubeChartImageFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getTubeChartLibraryStart, getTubeChartLibrarySuccess, getTubeChartLibraryFailure,
  deleteTubeChartLibraryStart, deleteTubeChartLibrarySuccess, deleteTubeChartLibraryFailure,
  resetTubeChartLibraryData,
  getTubeChartImageStart, getTubeChartImageSuccess, getTubeChartImageFailure,
} = TubeChartLibrarySlice.actions;
export const TubeChartLibraryReducer = TubeChartLibrarySlice.reducer;

export type TubeChartLibraryActions =
  | ReturnType<typeof getTubeChartLibraryStart>
  | ReturnType<typeof getTubeChartLibrarySuccess>
  | ReturnType<typeof getTubeChartLibraryFailure>
  | ReturnType<typeof deleteTubeChartLibraryStart>
  | ReturnType<typeof deleteTubeChartLibrarySuccess>
  | ReturnType<typeof deleteTubeChartLibraryFailure>
  | ReturnType<typeof resetTubeChartLibraryData>
  | ReturnType<typeof getTubeChartImageStart>
  | ReturnType<typeof getTubeChartImageSuccess>
  | ReturnType<typeof getTubeChartImageFailure>;
