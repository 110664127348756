import React, { useEffect } from 'react';
import {
  Button,
  Card, Grid,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentSelectInput from '../../../Common/ComponentSelectInput';
import { ADD_INFO_TAB, MainFormNames } from '../utils/constants';
import { InspectionMainFormValues } from '../utils/types';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import CustomCheckbox from '../../../Common/ComponentCustomCheckBox';
import { getInspectionSummaryDataById, setInspectionFormValue, setSelectedTab } from '../redux/sliceInspectionSummary';
import { RootState } from '../../../redux/rootState';
import { setValues } from '../utils/helper';

function MainInspectionSummary() {
  const dispatch = useDispatch();
  const { inspectionId, vesselId, defectCode } = useParams();
  const navigate = useNavigate();
  const {
    control, setValue, watch, getValues,
  } = useForm<InspectionMainFormValues>({
    defaultValues: {
      [MainFormNames.reportType]: '',
    },
    // resolver: yupResolver(unitInfoValidationSchema),
  });
  const { reportTypeList } = useSelector((state:RootState) => state.masterDataReducer);
  const { inspectionSummaryDataById } = useSelector((state: RootState) => state.inspectionSummaryReducer);
  const reportType = watch(MainFormNames.reportType);

  useEffect(() => {
    const payload = {
      summaryId: inspectionId || '',
      vesselId: vesselId || '',
      defectCode: defectCode || '',
      navigate,
    };
    dispatch(getInspectionSummaryDataById(payload));
  }, []);

  useEffect(() => {
    if (inspectionSummaryDataById) {
      setValues(inspectionSummaryDataById, setValue);
    }
  }, [inspectionSummaryDataById]);

  useEffect(() => {
    dispatch(setInspectionFormValue(getValues(MainFormNames.reportType)));
  }, [reportType]);

  return (
    <>
      <Card>
        <div className="customerInfo_container" style={{ justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}>
          <span className="customerID">
            Job Order #
          </span>
          <div className="job_input_div">
            <div className="job_input_style">{inspectionSummaryDataById?.JobOrder}</div>
          </div>
        </div>
        <div className="customerInfo_main_container">
          <Grid container spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_job">
                  Report Type
                </label>
                <ComponentSelectInput
                  control={control}
                  name={MainFormNames.reportType}
                  size="small"
                  entries={reportTypeList || []}
                />
              </div>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Vessel
                </label>
                <ComponentTextInput
                  control={control}
                  name={MainFormNames.Vessel}
                  id={MainFormNames.Vessel}
                  isDisable
                  size="small"
                />
              </div>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Test Type
                </label>
                <ComponentTextInput
                  control={control}
                  name={MainFormNames.testType}
                  id={MainFormNames.testType}
                  isDisable
                  size="small"
                />
              </div>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_job">
                  Defect
                </label>
                <ComponentTextInput
                  control={control}
                  name={MainFormNames.Defect}
                  id={MainFormNames.Defect}
                  isDisable
                  size="small"
                />
              </div>
            </Grid>
            <Grid item md={10} sm={12} xs={12} />
            {/* <Grid item md={2} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_job">
                  Order Code
                </label>
                <ComponentTextInput
                  control={control}
                  name={MainFormNames.orderCode}
                  id={MainFormNames.orderCode}
                  isDisable
                  size="small"
                />
              </div>
            </Grid> */}
            <Grid item md={1} sm={6} xs={12}>
              <div style={{ marginTop: '13px' }}>
                <label>&nbsp;</label>
                <CustomCheckbox
                  control={control}
                  isDisable
                  name={MainFormNames.spotTest}
                  label="Spot Test"
                />
              </div>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Pct.
                </label>
                <ComponentTextInput
                  control={control}
                  isDisable
                  name={MainFormNames.Pct}
                  id={MainFormNames.Pct}
                  size="small"
                />
              </div>
            </Grid>
            <Grid item md={9} sm={12} xs={12} />
            <Grid item md={2} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Tube Count
                </label>
                <ComponentTextInput
                  control={control}
                  isDisable
                  name={MainFormNames.tubeCount}
                  id={MainFormNames.tubeCount}
                  size="small"
                />
              </div>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Tubes Tested
                </label>
                <ComponentTextInput
                  control={control}
                  isDisable
                  name={MainFormNames.tubesTested}
                  id={MainFormNames.tubesTested}
                  size="small"
                />
              </div>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Number of Tube
                </label>
                <ComponentTextInput
                  control={control}
                  isDisable
                  name={MainFormNames.numberOfTube}
                  id={MainFormNames.numberOfTube}
                  size="small"
                />
              </div>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Percent of Bundle
                </label>
                <ComponentTextInput
                  control={control}
                  isDisable
                  name={MainFormNames.percentOfBundle}
                  id={MainFormNames.percentOfBundle}
                  size="small"
                />
              </div>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Previously Plugged
                </label>
                <ComponentTextInput
                  control={control}
                  isDisable
                  name={MainFormNames.previouslyPlugged}
                  id={MainFormNames.previouslyPlugged}
                  size="small"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
      <Card style={{ margin: '20px 0px' }}>
        <div className="customerInfo_container">
          <div className="customerInfo">
            Unit Specification
          </div>
        </div>
        <div className="customerInfo_main_container">
          <Grid container spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Manufacturer
                </label>
                <ComponentTextInput
                  control={control}
                  isDisable
                  name={MainFormNames.Manufacturer}
                  id={MainFormNames.Manufacturer}
                  size="small"
                />
              </div>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Model
                </label>
                <ComponentTextInput
                  control={control}
                  isDisable
                  name={MainFormNames.Model}
                  id={MainFormNames.Model}
                  size="small"
                />
              </div>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Serial #
                </label>
                <ComponentTextInput
                  control={control}
                  isDisable
                  name={MainFormNames.serialNumber}
                  id={MainFormNames.serialNumber}
                  size="small"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
      <Grid item md={12} sm={12} xs={12}>
        <div className="save_and_next_div">
          <div>
            <Button className="button_cancel" onClick={() => navigate(-1)}>Cancel</Button>
          </div>
          <div className="button_margin_left">
            <Button type="button" className="button_save_and_next" onClick={() => dispatch(setSelectedTab(ADD_INFO_TAB))}>Save & Next</Button>
          </div>
        </div>
      </Grid>
    </>
  );
}

export default MainInspectionSummary;
