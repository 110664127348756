/* eslint-disable max-len */
import dayjs from 'dayjs';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { DefectInformationEnum } from './type';
import { Entries } from '../../../Common/ComponentSelectInput/types';
import { DefectInformationFields, MenuAPIData, MenuDataType } from './defectType';
import { VesselInformationMainEnum } from './mainTypes.d';

export const MAIN_TAB = 0;
export const DEFECT_TAB = 1;
export const TUBE_CHART_TAB = 2;
export const BAY_LENGTHS_TAB = 3;
export const ASME_SECTION_V_TAB = 4;
export const PHASE_CHART_TAB = 5;
export const STRIP_CHART_TAB = 6;
// export const PDF_REPORT = 7;

export const LABEL_MAIN_TAB = 'Main';
export const LABEL_DEFECT_TAB = 'Defect';
export const LABEL_TUBE_CHART_TAB = 'Tube Chart';
export const LABEL_BAY_LENGTHS_TAB = 'Bay Lengths';
export const LABEL_ASME_SECTION_V_TAB = 'ASME Section V';
export const LABEL_PHASE_CHART_TAB = 'Phase Chart';
export const LABEL_STRIP_CHART_TAB = 'Strip Chart';
export const LABEL_PDF_REPORT = 'PDF Report';

export const MainDefaultValue = {
  [VesselInformationMainEnum.vessel]: '',
  [VesselInformationMainEnum.testEnd]: '',
  [VesselInformationMainEnum.tubeType]: '',
  [VesselInformationMainEnum.material]: '',
  [VesselInformationMainEnum.support]: '',
  [VesselInformationMainEnum.NWT]: '',
  [VesselInformationMainEnum.nwtBellLand]: '',
  [VesselInformationMainEnum.nwtUnderFins]: '',
  [VesselInformationMainEnum.probeDiameter]: '',
  [VesselInformationMainEnum.OD]: '',
  [VesselInformationMainEnum.OD1]: '',
  [VesselInformationMainEnum.OD2]: '',
  [VesselInformationMainEnum.OD3]: '',

  [VesselInformationMainEnum.tubeCount]: '',
  [VesselInformationMainEnum.tubeLength]: '',

  [VesselInformationMainEnum.analyst]: '',
  [VesselInformationMainEnum.inspectionDate]: dayjs(new Date()),
  [VesselInformationMainEnum.retestDate]: dayjs(new Date()),
};

export const TubeChartValue = {
  tubeSize: '',
  numberOfRows: '',
  rowLength: '',
  vesselTop: '',
  offset: '',
  numberOfTube: '',
  originalTestEnd: false,
  reserveTestEnd: true,
};

export const BayLengthValue = {
  support: '',
  tubeCount: '',
  tubeLength: '',
};

export enum StartTimeFields {
  date = 'date',
  time = 'time',
}

export enum InsertTimeFields {
  date = 'date',
  time = 'time',
}

export enum FinishTimeFields {
  date = 'date',
  time = 'time',
}

export const StartTimeDefaultData = {
  date: null,
  time: null,
};

export const InsertTimeDefaultData = {
  date: null,
  time: null,
};

export const FinishTimeDefaultData = {
  date: null,
  time: null,
};

export const AddDefectDefaultData = {
  section: '',
  code: '',
  location: '',
  area: '',
  date: null,
  time: null,
  row: '',
  tube: '',
};

export const ReplaceFieldsDefaultData = {
  parameter: '1',
  section: '',
  search: '',
  searchArea: '',
  replaceWith: '',
  replaceWithArea: '',
};

export const ParameterOptions = [
  {
    Value: '1',
    Text: 'Defect',
  },
  {
    Value: '2',
    Text: 'Location',
  },
];

const handleLocationChange = (setValue: UseFormSetValue<DefectInformationFields>) => {
  setValue(DefectInformationEnum.area, '');
};

export const AddDefectSelectFields = (
  allDefectDropdownData: MenuAPIData,
  locationWatch: string,
  setValue: UseFormSetValue<DefectInformationFields>,
  trigger: UseFormTrigger<DefectInformationFields>,
) => {
  let areaEntries:MenuDataType[] = [];
  let areaDisabled = false;

  const filteredSections = allDefectDropdownData?.Section?.filter((section) => section?.Text !== 'All');

  if (locationWatch === 'Support') {
    areaEntries = allDefectDropdownData?.AreaSupportReplace || [];
    areaDisabled = false;
    // setValue(DefectInformationEnum.area, '');
    // trigger(DefectInformationEnum.area);
  } else if (locationWatch === 'Bay') {
    areaEntries = allDefectDropdownData?.AreaBayReplace || [];
    areaDisabled = false;
    // setValue(DefectInformationEnum.area, '');
    // trigger(DefectInformationEnum.area);
  } else {
    // setValue(DefectInformationEnum.area, '');
    trigger(DefectInformationEnum.area);
    areaEntries = [];
    areaDisabled = true;
  }

  return [
    {
      label: 'Section', name: DefectInformationEnum.section, entries: filteredSections || [], disabled: false,
    },
    {
      label: 'Code', name: DefectInformationEnum.code, entries: allDefectDropdownData?.DefectCodeReplace || [], disabled: false,
    },
    {
      label: 'Location', name: DefectInformationEnum.location, entries: allDefectDropdownData?.LocationReplace || [], disabled: false, onChange: () => { handleLocationChange(setValue); },
    },
    {
      label: 'Area',
      name: DefectInformationEnum.area,
      entries: areaEntries,
      disabled: areaDisabled,
    },
  ];
};

export const AddDefectTextFields = [
  { label: 'Row', name: DefectInformationEnum.row },
  { label: 'Tube', name: DefectInformationEnum.tube },
];
export const TypeDropDownValues:Entries[] = [
  { Value: 'Coaxial', Text: 'Coaxial' },
  { Value: 'Shielded Twisted Pair', Text: 'Shielded Twisted Pair' },
];

export const ProbeScanValues = [
  { Value: 'Push', Text: 'Push' },
  { Value: 'Pull', Text: 'Pull' },
  { Value: 'Push/Pull', Text: 'Push/Pull' },
];

export const asmeInititalValues = {
  maximumScanSpeedDuringRecording: '',
  NSerialNumberOfEddyCurrentInstrument: '',
  probeCableLength: '',
  probeCableType: '',
  extensionCableLength: '',
  extensionCableType: '',
  probeMfg: '',
  probeSerialNumber: '',
  probeScanDirection: '',
};

export const EntriesConstants = {
  TE: 'Test End',
  OTE: 'Opposite Test End',
  SUPPORT: 'Support',
  BAY: 'Bay',
  ALL: 'All',
  BLANK: '',
};

export const getReplaceAreaEntries = (replaceWith: string, data: MenuAPIData) => {
  if (replaceWith === EntriesConstants.TE || replaceWith === EntriesConstants.OTE || replaceWith === EntriesConstants.ALL) {
    return [];
  } if (replaceWith === EntriesConstants.SUPPORT) {
    return data?.AreaSupportReplace || [];
  }
  if (replaceWith === EntriesConstants.BAY) {
    return data?.AreaBayReplace || [];
  }
  return [];
};

export const areaDisable = (replaceWith: string) => {
  if (replaceWith === EntriesConstants.TE || replaceWith === EntriesConstants.OTE || replaceWith === EntriesConstants.ALL) {
    return true;
  } if (replaceWith === EntriesConstants.SUPPORT) {
    return false;
  }
  if (replaceWith === EntriesConstants.BAY) {
    return false;
  }
  return false;
};

export const getSearchAreaEntries = (replaceWith: string, data: MenuAPIData) => {
  if (replaceWith === EntriesConstants.TE || replaceWith === EntriesConstants.OTE || replaceWith === EntriesConstants.ALL) {
    return [];
  } if (replaceWith === EntriesConstants.SUPPORT) {
    return data?.AreaSupportSearch || [];
  }
  if (replaceWith === EntriesConstants.BAY) {
    return data?.AreaBaySearch || [];
  }
  return [];
};
