import { RecommendationCodesApiData } from '../Redux/Type';
import { RecommendationCodesData } from './TypeRecommendationCodes';

export function formatTableData(data: RecommendationCodesApiData[], showDeleteIcon: boolean) {
  const res: RecommendationCodesData[] = [];
  data.forEach((col: RecommendationCodesApiData, index) => {
    const obj = {
      sn: index,
      action: showDeleteIcon ? 'edit&delete' : 'edit',
      definition: '',
      descriptionHtml: '',
      id: '',
    };

    obj.definition = col.Definition;
    obj.descriptionHtml = col.Description;
    obj.id = col.Id;

    res.push(obj);
  });

  return res;
}
