import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/rootState';

function TableThree() {
  const { themePdfColor, reportPdfResponse1 } = useSelector((state: RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;
  const tickerData = reportPdfResponse1?.Ticklers_Data;

  return (
    <div style={{ paddingTop: '20px', width: '90%', margin: '0 auto' }}>
      <table className="bordered-table w100">
        <div
          className="tableAfterThickBorderStyle"
          style={{
            backgroundColor: BorderColor,
          }}
        />
        <div
          className="tableBeforeThickBorderStyle"
          style={{
            backgroundColor: BorderColor,
          }}
        />
        <tr>
          <td className="tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Client #</td>
          <td className="textCenter">Client</td>
          <td className="textCenter">Site</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Name</td>
          <td className="textCenter">{tickerData?.Name}</td>
          <td className="textCenter">{tickerData?.SiteName}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Address</td>
          <td className="textCenter">{tickerData?.SiteAddress}</td>
          <td className="textCenter">{tickerData?.SiteOptionalAddress}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>City, State, Zip</td>
          {/* <td className="textCenter">{`${tickerData?.City}, ${tickerData?.State}, ${tickerData?.Zip}`}</td>
          <td className="textCenter">{`${tickerData?.SiteCity}, ${tickerData?.SiteCity}, ${tickerData?.SiteZip}`}</td> */}
          <td className="textCenter">
            {tickerData?.City
              ? tickerData.City + (tickerData.State || tickerData.Zip ? ', ' : '')
              : ''}
            {tickerData?.State
              ? tickerData.State + (tickerData.Zip ? ', ' : '')
              : ''}
            {tickerData?.Zip || ''}
          </td>

          <td className="textCenter">
            {tickerData?.SiteCity
              ? tickerData.SiteCity + (tickerData.SiteState || tickerData.SiteZip ? ', ' : '')
              : ''}
            {tickerData?.SiteState
              ? tickerData.SiteState + (tickerData.SiteZip ? ', ' : '')
              : ''}
            {tickerData?.SiteZip || ''}
          </td>

        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Contact</td>
          <td className="textCenter">{tickerData?.Contact}</td>
          <td className="textCenter">{tickerData?.SiteContact}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter" style={{ backgroundColor: MainColor }}>Phone</td>
          <td className="textCenter">{tickerData?.Phone}</td>
          <td className="textCenter">{tickerData?.SitePhone}</td>
        </tr>
      </table>
    </div>
  );
}

export default TableThree;
