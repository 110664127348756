import {
  Control, FieldErrors, SetFieldValue, UseFormWatch,
} from 'react-hook-form';
import { VesselInformationFields } from './type.d';

export enum VesselInformationMainEnum {
  vessel = 'vessel',
  testEnd = 'testEnd',
  tubeType = 'tubeType',
  material = 'material',
  support = 'support',
  NWT = 'NWT',
  nwtBellLand = 'NWTBellLand',
  nwtUnderFins = 'NWTUnderFins',
  probeDiameter = 'probeDiameter',
  OD = 'OD',
  OD1 = 'OD1',
  OD2 = 'OD2',
  OD3 = 'OD3',

  tubeCount = 'tubeCount',
  tubeLength = 'tubeLength',

  analyst = 'analyst',
  inspectionDate = 'inspectionDate',
  retestDate = 'retestDate',
}

export interface FirstPropsType {
  control: Control<T>;
  errors: FieldErrors;
  jobOrderId: string;
  watch: UseFormWatch<VesselInformationFields>;
  setValue: SetFieldValue;
}

export interface DataType {
  Value: string;
  Text: string;
  Description?: boolean;
}

export interface VesselMainDataById {
  Id: number;
  VesselId: number;
  VesselName: string;
  JobOrder: string;
  MaterialId: number;
  MaterialType: string;
  SupportId: number;
  Support: string;
  Od: number;
  Od2: string;
  Od3: string;
  Nwt: number;
  NwtBellLand: number;
  NwtUnderFills: number;
  ProbeDiameter: number;
  TubeTypeId: number;
  TubeType: string;
  TubeCount: string,
  TubeLength: number,
  TestEnd: string;
  TestEndId: number;
  Analyst: string;
  AnalystId: number;
  InspectionDate: string;
  RetestDate: string;
  IsMultiOffset: boolean;
}

export interface GetDropDownList {
  Text: string;
  Value: string;
  Description?: boolean | string;
}

export interface CommonFieldsProps {
  tubeTypeData: GetDropDownList[];
  testEndData: GetDropDownList[];
  materialData: GetDropDownList[];
  supportData: GetDropDownList[];
  vesselData: GetDropDownList[];
  errors: FieldErrors;
  vesselMainData: VesselMainDataById;
  isNWTTubeTypeEnabled: GetDropDownList;
  roleId: string | null;
}

export interface VesselMainInitialValues {
  vesselMainData: VesselMainDataById;
  isLoading: boolean;
  tubeTypeData: GetDropDownList[];
  testEndData: GetDropDownList[];
  materialData: GetDropDownList[];
  supportData: GetDropDownList[];
  vesselData: GetDropDownList[];
  isASMESection: boolean;
}

export interface GetVesselMainPayload {
  jobId: string;
  vesselId: string;
}

export interface VesselMainPayloadAPI {
  Id: number;
  VesselId: number;
  JobOrder: string;
  RecordId: string;
  TestEndId: number;
  TubeTypeId: number;
  MaterialId: number;
  SupportId: number;
  Od: number;
  // Od1: number;
  Od2: string;
  Od3: string;
  Nwt: number;
  NwtBellLand: number;
  NwtUnderFills: number;
  AnalystId: string;
  InspectionDate: string;
  RetestDate: string;
  ProbeDiameter: number;
  TubeCount: string;
  TubeLength: number;
  TubeNumbering: boolean;
}

export interface VesselPayloadType {
  id: string;
}
