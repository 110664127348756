import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../Common/DataTable';
import { RootState } from '../../../../redux/rootState';
import { setLoader } from '../../../../redux/loaderSlice';
import ComponentDeleteModal from '../../../../Common/DeleteModal/ComponentDeleteModal';
import ComponentTypeListDialog from './ComponentTypeListDialog';
import { deleteTypeListStart, getTypeListStart } from '../redux/sliceTypeList';
import { TypeList } from '../utils/types';

function ComponentTypeListTable() {
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTypeListId, setSelectedTypeListId] = useState<string>('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();

  const { isLoading, TypeListData } = useSelector((state: RootState) => state.ListTypeReducer);
  const { showDeleteIcon } = useSelector((state: RootState) => state.masterDataReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getTypeListStart(params));
  };

  const handleAction = (id: string) => {
    setOpen(true);
    setRowId(id);
  };

  const handleButtonClick = () => {
    setRowId('');
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const TableColumns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'Description', label: 'Description' },
    ],
    [],
  );

  const tableData: TypeList[] = useMemo(() => TypeListData?.Data?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: showDeleteIcon ? 'edit&delete' : 'edit',
      Description: item?.Description,
      id: String(item?.Id),
    };
    return transformedDataItem;
  }), [TypeListData]);

  const handleDelete = (id: string) => {
    setShowDeleteModal(true);
    setSelectedTypeListId(id);
  };

  const handelDeleteModal = () => {
    const paramData = {
      Id: selectedTypeListId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteTypeListStart(paramData));
    setShowDeleteModal(false);
  };

  return (
    <>
      <DataTable<TypeList>
        handleButtonClick={handleButtonClick}
        data={tableData}
        columns={TableColumns}
        totalRecords={TypeListData?.TotalRecords}
        buttonText="Add"
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        handleEdit={handleAction}
        handleDelete={handleDelete}
      />
      <ComponentTypeListDialog show={open} setOpenFrom={handleButtonClick} rowId={rowId} handleData={handleData} />
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />
    </>
  );
}

export default ComponentTypeListTable;
