import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import tickIconRed from '../../../assets/Icons/tick-icon-red.svg';
import { RootState } from '../../../redux/rootState';
import DataSheetTopTable from './dataSheetTopTable';
import { CombineReportEnum, PDFReportEnum, ReportPart1 } from '../helper';

function DataSheetTable() {
  const { reportPdfResponse2, themePdfColor, tableContent } = useSelector((state: RootState) => state.reportPdfSliceReducer);
  const { BorderColor, MainColor } = themePdfColor;
  const dataSheetResult = useMemo(() => {
    const res = [];
    const len = reportPdfResponse2?.Data_Sheets?.ResultDatasheet?.length;
    for (let i = 0; i < len;) {
      res.push(reportPdfResponse2?.Data_Sheets?.ResultDatasheet?.slice(i, i + 18));
      i += 18;
    }

    return res;
  }, [reportPdfResponse2]);

  const lastIndex = dataSheetResult.length - 1;

  return (
    <>
      {dataSheetResult.map((dataResult, index) => (
        <>
          <div className="pageHeader">
            <div className="topHeader">
              <div className={`${index > 0 ? 'pt30 pb10' : ''}`}>
                {ReportPart1(tableContent, CombineReportEnum.COMBINE_DATA_SHEETS, PDFReportEnum.PDF_DATA_SHEETS, 'Data Sheets')}
                {index > 0 && (
                  <>
                    <br />
                    Data Sheets - Page
                    {' '}
                    {index + 1}
                  </>
                )}
              </div>
              <div className={`${index > 0 ? 'pt30 pb10' : ''}`}>{`S/N ${reportPdfResponse2?.JobOrderData?.SerialNumber}`}</div>
            </div>
            {index === 0 && <div className="underlineHeadingText textCenter">Data Sheet</div>}
          </div>
          {index === 0 && <DataSheetTopTable />}
          <table className="bordered-table w100 mt40">
            <div
              className="tableAfterThickBorderStyle"
              style={{
                backgroundColor: BorderColor,
              }}
            />
            <div
              className="tableBeforeThickBorderStyle"
              style={{
                backgroundColor: BorderColor,
              }}
            />
            <tr>
              <td className="tableGreenHeading textCenter boldText w8" style={{ backgroundColor: MainColor }}>
                Section
              </td>
              <td className="tableGreenHeading textCenter boldText w6" style={{ backgroundColor: MainColor }}>
                Row
              </td>
              <td className="tableGreenHeading textCenter boldText w6" style={{ backgroundColor: MainColor }}>
                Tube
              </td>
              <td className="tableGreenHeading textCenter boldText w17" style={{ backgroundColor: MainColor }}>
                Vessel
              </td>
              <td className="tableGreenHeading textCenter boldText w50" style={{ backgroundColor: MainColor }}>
                Description
              </td>
              <td className="tableGreenHeading textCenter boldText w13" style={{ backgroundColor: MainColor }}>
                Area
              </td>
              <td className="tableGreenHeading textCenter boldText w8" style={{ backgroundColor: MainColor }}>
                Action Req.
              </td>
            </tr>
            {dataResult.map((details) => (
              details.Row !== 0 || details.Tube !== 0 ? (
                <tr key={`${details.Section}-${details.Row}-${details.Tube}`}>
                  <td className="textCenter">{details.Section}</td>
                  <td className="textCenter">{details.Row}</td>
                  <td className="textCenter">{details.Tube}</td>
                  <td className="textCenter">{details.VesselName}</td>
                  <td className="textCenter" style={details.ActionRequired ? { color: 'red' } : {}}>{details.DefectDescription}</td>
                  <td className="textCenter">{details.Area}</td>
                  <td className="textCenter">{details.ActionRequired && <img src={tickIconRed} alt="icon" width="18px" />}</td>
                </tr>
              ) : (
                <tr>
                  <td className="textCenter boldText" colSpan={7} style={{ backgroundColor: '#f8f9fa' }}>
                    {details.Description}
                  </td>
                </tr>
              )
            ))}
          </table>
          {index !== lastIndex && (
          <div className="page-break" />
          )}
        </>
      ))}
    </>
  );
}

export default DataSheetTable;
