import { Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../../Common/DataTable';
import { DataSheetEditData } from '../utils/types';
import { RootState } from '../../../redux/rootState';
import { getDataSheetEdit } from '../redux/sliceDataSheet';
import { setLoader } from '../../../redux/loaderSlice';
import { exportToExcelData } from '../../../Common/PageHeader/helper';
import { convertDateInString } from '../../../utils/CommonFunctions';

function ComponentDataSheet() {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { sheetId } = useParams();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { DataSheetEdit, isLoading } = useSelector((state: RootState) => state.dataSheetReducer);

  const handleData = () => {
    const payload = {
      page,
      rowsPerPage,
      searchQuery,
      sheetId,
    };
    dispatch(getDataSheetEdit({ payload, navigate }));
  };

  useEffect(() => {
    handleData();
  }, []);

  const data: DataSheetEditData[] = useMemo(() => DataSheetEdit?.Records?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      vessel: item?.VesselName,
      row: item?.Row,
      tube: item?.Tube,
      area: item?.Area,
      description: convertDateInString(item?.Description),
      reqAction: item?.ActionRequired,
      id: item?.id,
    };
    return transformedDataItem;
  }), [DataSheetEdit]);

  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'vessel', label: 'Vessel' },
      { key: 'row', label: 'Row' },
      { key: 'tube', label: 'Tube' },
      { key: 'area', label: 'Area' },
      { key: 'description', label: 'Description' },
      { key: 'reqAction', label: 'Req Action' },
    ],
    [],
  );

  const excelData = useMemo(() => DataSheetEdit?.Records?.map((item) => {
    const transformedDataItem = {
      vessel: item?.VesselName,
      row: item?.Row,
      tube: item?.Tube,
      area: item?.Area,
      description: item?.Description,
      reqAction: item?.ActionRequired ? 'Yes' : 'No',
    };
    return transformedDataItem;
  }), [DataSheetEdit]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Data_sheet_edit',
    );
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="Data Sheet View" downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <DataTable<DataSheetEditData>
          data={data}
          columns={columns}
          totalRecords={DataSheetEdit?.TotalRecords}
          page={page}
          setPage={setPage}
          checkBoxColumns={['reqAction']}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
        />
      </Grid>
    </Grid>
  );
}

export default ComponentDataSheet;
